import { generateNamesObject } from '@orbis-systems/orbis-react-toolkit';

import { Grid, styled } from '@mui/material';

export const applicationFormSkeletonClasses = generateNamesObject(
	['detailsSection', 'menuSection'],
	'StyledApplicationFormSkeleton',
);

export const StyledApplicationFormSkeleton = styled(Grid)(({ theme }) => ({
	[`& .${applicationFormSkeletonClasses.detailsSection}`]: {
		background: theme.palette.background.paper,
		borderRadius: 6,
	},
	[`& .${applicationFormSkeletonClasses.menuSection}`]: {
		background: theme.palette.background.paper,
	},
}));
