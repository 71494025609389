import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useCallback, useEffect } from 'react';
import { useTimer } from 'react-timer-hook';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { showToast } from '@app/providers/ToastProvider';
import { getVerificationEmailCodeExpiring } from '@app/redux/auth/auth.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { VerifyEmailFormProps, VerifyEmailFormValues } from './interfaces';
import verifyEmailFormValidationScheme from './validationScheme';

function useVerifyEmailForm({ initialValues, onSubmit }: Pick<VerifyEmailFormProps, 'initialValues' | 'onSubmit'>) {
	const codeExpiring = useAppSelector(getVerificationEmailCodeExpiring);

	const { seconds, minutes, restart } = useTimer({
		expiryTimestamp: new Date(codeExpiring || '00:00'),
	});

	const { startRequest: sendVerificationEmailCode, isPending: isSendingVerificationEmailCode } = useTriggeredRequest(
		AccountClientRequests.Users.sendVerificationEmailCode,
	);

	const formik = useFormik<VerifyEmailFormValues>({
		initialValues,
		validationSchema: verifyEmailFormValidationScheme,
		onSubmit,
	});

	const handleSendVerificationEmailCode = useCallback(async () => {
		try {
			const response = await sendVerificationEmailCode();

			restart(new Date(response.email_verification.expires_at || '00:00'));
		} catch (e) {
			const { response } = e as AxiosError<{
				messages: string[];
			}>;

			showToast.error(response?.data?.messages?.join(' ') || 'Oops. Something went wrong.');
		}
	}, [restart, sendVerificationEmailCode]);

	useEffect(() => {
		handleSendVerificationEmailCode();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		isSendingVerificationEmailCode,
		handleSendVerificationEmailCode,
		minutes,
		seconds,
		formik,
	};
}

export default useVerifyEmailForm;
