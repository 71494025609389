import React from 'react';
import { FormControlGroup, FormControlGroupProps, FormRadioButton, FormRadioButtonProps } from '../../FormControls';
import { MutualFundDividends } from '../lookups';
import { fieldNames } from '../schema';

export type MutualFundDividendsSelectProps = {
    formControlGroupProps?: Partial<FormControlGroupProps>;
    formRadioButtonProps?: Partial<FormRadioButtonProps>;
};

export const MutualFundDividendsSelect: React.FunctionComponent<MutualFundDividendsSelectProps> = ({
    formControlGroupProps,
    formRadioButtonProps,
}) => {
    return (
        <FormControlGroup
            row
            fullWidth
            outlined
            variant="outlined"
            label="Dividends"
            {...formControlGroupProps}
            name={fieldNames.mutualFundDividends}
        >
            <FormRadioButton
                label="Reinvest"
                {...formRadioButtonProps}
                name={fieldNames.mutualFundDividends}
                value={MutualFundDividends.Reinvest}
            />
            <FormRadioButton
                label="Pay in Cash"
                {...formRadioButtonProps}
                name={fieldNames.mutualFundDividends}
                value={MutualFundDividends.PayInCash}
            />
        </FormControlGroup>
    );
};
