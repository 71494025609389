import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { showToast } from '@app/providers/ToastProvider';
import { getAssetsCountries } from '@app/redux/assets/assets.selectors';
import { useAppSelector } from '@app/redux/hooks';

function useCountryFieldApplicationForm({
	applicationId,
	questionId,
	isTreatyCountry,
}: {
	applicationId: number;
	questionId: number;
	isTreatyCountry: boolean;
}) {
	const countries = useAppSelector(getAssetsCountries);

	const { isPending: isPendingSaveAnswer, startRequest: saveStateAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.saveTextAnswer,
	);

	const { isPending: isPendingDeleteAnswer, startRequest: deleteAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.deleteAnswer,
	);

	const handleSaveAnswer = async (state: string) => {
		try {
			await saveStateAnswer({
				application_id: applicationId,
				question_id: questionId,
				data: state,
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	const handleDeleteAnswer = async () => {
		try {
			await deleteAnswer({
				application_id: applicationId,
				question_id: questionId,
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	return {
		options: countries
			.filter(({ is_treaty }) => (isTreatyCountry ? is_treaty : true))
			.map(country => ({
				id: `application-${applicationId}-question-${questionId}-country-${country.id}`,
				value: country.code,
				label: country.name,
				is_enabled: country.is_enabled,
			})),
		loading: isPendingSaveAnswer || isPendingDeleteAnswer,
		handleSaveAnswer,
		handleDeleteAnswer,
	};
}

export default useCountryFieldApplicationForm;
