import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import configRequest from '@api/requests/configRequest';

import { showToast } from '@app/providers/ToastProvider';

export interface VerifyPhoneData {
	code: string;
}

export type VerifyPhoneResponse = void;

const verifyPhone = configRequest<VerifyPhoneResponse, VerifyPhoneData>({
	requestPrefix: 'auth/verifyPhone',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.AUTH.VERIFICATION.PHONE.VERIFY(),
		method: HTTP_METHODS_ENUM.POST,
		data,
	}),
	onSuccess: () => showToast.success('Phone has been verified successfully.'),
});

export default verifyPhone;
