import React from 'react';

function branch<OuterProps extends JSX.IntrinsicAttributes>({
	factoryFunction = () => false,
	RightView = () => <div>Provide Alternative View</div>,
}: {
	factoryFunction: (props: OuterProps) => boolean;
	RightView: (props: OuterProps) => JSX.Element;
}) {
	return function wrappedComponent(LeftView: (props: OuterProps) => JSX.Element) {
		return function componentOuterProps(props: OuterProps) {
			return factoryFunction(props) ? <RightView {...props} /> : <LeftView {...props} />;
		};
	};
}

export default branch;
