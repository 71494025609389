import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosRequestConfig } from 'axios';

import httpClient from '@api/httpClient';

export interface ApiErrorInterface {
	status: number;
	error: string;
}

export interface ConfigRequestProps<ThunkArg> {
	requestPrefix: string;
	reqConfigCreator: (input?: ThunkArg) => AxiosRequestConfig;
	onSuccess?: (input?: ThunkArg) => void;
}

function configRequest<Returned, ThunkArg>({
	requestPrefix,
	reqConfigCreator,
	onSuccess,
}: ConfigRequestProps<ThunkArg>) {
	return createAsyncThunk<
		Returned,
		ThunkArg,
		{
			rejectValue: AxiosError;
		}
	>(requestPrefix, async (input, { rejectWithValue }) => {
		const reqConfig = reqConfigCreator(input);

		try {
			const { data } = await httpClient.request<Returned>(reqConfig);

			onSuccess?.(input);

			return data as Returned;
		} catch (e: unknown) {
			return rejectWithValue(e as AxiosError);
		}
	});
}

export default configRequest;
