import React from 'react';
import ReactDOM from 'react-dom';

import App from '@app/index';

import './styles/global.scss';

const app = document.getElementById('app') as HTMLDivElement;

ReactDOM.render(<App />, app);
