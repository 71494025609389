import { AsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useRef } from 'react';

import { useAppDispatch } from '@app/redux/hooks';

export default function useFetch<I, O>(
	fetchAsyncFn: AsyncThunk<O, I, { rejectValue: AxiosError }>,
	deps: unknown[],
	args: I,
) {
	const dispatch = useAppDispatch();
	const idle = useRef(true);
	const [data, setData] = useState<O>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		async function runFetch() {
			setLoading(true);

			try {
				const result = await dispatch(fetchAsyncFn(args)).unwrap();

				setError(false);
				setData(result);
			} catch (e) {
				setError(true);
				setData(undefined);
			} finally {
				idle.current = false;
				setLoading(false);
			}
		}

		runFetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps]);

	return {
		data,
		loading,
		error,
		idle: idle.current,
	};
}
