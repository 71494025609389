import React from 'react';
import { FormMoneyField, FormNumberFieldProps } from '../../FormControls';
import { cryptoFieldNames as fieldNames } from '../schema';

export type OrderStopPriceSelectProps = Partial<FormNumberFieldProps>;

export const OrderStopPriceSelect: React.FunctionComponent<OrderStopPriceSelectProps> = (props) => {
    return (
        <FormMoneyField label="Stop Price" size="small" variant={'standard'} {...props} name={fieldNames.stopPrice} />
    );
};
