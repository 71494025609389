import React from 'react';

import { Button, CircularProgress, Typography } from '@mui/material';

import useNextGenRedirect from './hooks';

function NextGenRedirect({
	isRedirectToNextGenSSO,
	isRedirectToNextGenLogin,
}: {
	isRedirectToNextGenSSO?: boolean;
	isRedirectToNextGenLogin?: boolean;
}) {
	const { loading, handleRedirectToNextGenSSO, handleRedirectToNextGenLogin } = useNextGenRedirect();

	return (
		<Button
			variant="contained"
			color="secondary"
			onClick={
				(isRedirectToNextGenSSO && handleRedirectToNextGenSSO) ||
				(isRedirectToNextGenLogin && handleRedirectToNextGenLogin) ||
				undefined
			}
			disabled={loading}
			sx={{ textTransform: 'initial', width: 200, height: 32 }}
		>
			{loading ? (
				<CircularProgress size={15} color={'info'} variant={'indeterminate'} />
			) : (
				<Typography variant={'caption'}>
					<b>Launch Crypto Trading</b>
				</Typography>
			)}
		</Button>
	);
}

export default NextGenRedirect;
