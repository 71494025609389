import React from 'react';
import { useHistory } from 'react-router';

import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { Grid, Stack, Typography } from '@mui/material';

import ResetPasswordForm from '@components/forms/resetPassword';

import useResetPassword from '@pages/Auth/ForgotPasswordPage/containers/ResetPassword/hooks';

import ArrowIcon from 'jsx:@assets/svgs/arrowLeft.svg';

import { BackButton } from './styles';

function ResetPasswordPage() {
	const { initialValues, onSubmit } = useResetPassword();

	const history = useHistory();
	const generateRoutePath = useGenerateRoutePath();

	return (
		<Grid container>
			<BackButton onClick={() => history.push(generateRoutePath(RoutePaths.AUTH.FORGOT_PASSWORD_METHOD))}>
				<Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={1}>
					<ArrowIcon />

					<Typography variant="body2">
						<b>Back to method</b>
					</Typography>
				</Stack>
			</BackButton>

			<ResetPasswordForm initialValues={initialValues} onSubmit={onSubmit} />
		</Grid>
	);
}

export default ResetPasswordPage;
