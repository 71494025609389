import changePassword from './changePassword';
import sendVerificationEmailCode from './sendVerificationEmailCode';
import sendVerificationPhoneCode from './sendVerificationPhoneCode';
import update from './update';
import verifyEmail from './verifyEmail';
import verifyPhone from './verifyPhone';

const UsersRequests = {
	update,
	changePassword,
	verifyEmail,
	sendVerificationEmailCode,
	sendVerificationPhoneCode,
	verifyPhone,
};

export default UsersRequests;
