enum APPLICATION_STATUSES_ENUM {
	CREATED = 'created',
	IN_PROGRESS = 'in_progress',
	SUBMITTED = 'submitted',
	LEXIS_NEXIS_CHECK_OK = 'lexis_nexis_check_ok',
	LEXIS_NEXIS_CHECK_FAIL = 'lexis_nexis_check_fail',
	COMPLIANCE_IN_REVIEW = 'compliance_in_review',
	COMPLIANCE_REJECTED = 'compliance_rejected',
	COMPLIANCE_APPROVED = 'compliance_approved',
	IN_REVIEW = 'in_review',
	DOCUSIGN_SENT = 'docusign_sent',
	DOCUSIGN_SIGNED = 'docusign_signed',
	CUSTODIAN_IN_PROGRESS = 'custodian_in_progress',
	CUSTODIAN_FAIL = 'custodian_fail',
	SOCURE_CHECK_OK = 'socure_check_ok',
	SOCURE_CHECK_FAIL = 'socure_check_fail',
	BACKGROUND_CHECK_SUCCESS = 'background_check_success',
	BACKGROUND_CHECK_FAIL = 'background_check_fail',
	CUSTODIAN_COMPLETE = 'custodian_complete',
	APPROVED = 'approved',
}

export default APPLICATION_STATUSES_ENUM;
