import { AsyncThunkAction } from '@reduxjs/toolkit';
import { Exactify } from '../../types';
import { useAsyncThunk, UseAsyncThunkOptions, UseAsyncThunkReturnType } from '../../hooks';
import { AsyncThunkConfig, ThunkArgMeta } from '../../redux';

interface AsyncThunkProps<
    Result,
    ThunkArg,
    ThunkApiConfig extends AsyncThunkConfig,
    Options extends UseAsyncThunkOptions<Result>,
> {
    action: AsyncThunkAction<Result, ThunkArg, ThunkApiConfig> & {
        type?: string;
        arg?: ThunkArg & ThunkArgMeta;
    };
    options?: Exactify<UseAsyncThunkOptions<Result>, Options>;
    children: (
        data: UseAsyncThunkReturnType<Result, Options>[0],
        status: UseAsyncThunkReturnType<Result, Options>[1],
        reload: UseAsyncThunkReturnType<Result, Options>[2],
    ) => React.ReactElement;
}

export const AsyncThunk = <
    Result,
    ThunkArg,
    ThunkApiConfig extends AsyncThunkConfig = {},
    Options extends UseAsyncThunkOptions<Result> = {},
>({
    action,
    options,
    children,
}: AsyncThunkProps<Result, ThunkArg, ThunkApiConfig, Options>) => {
    const [data, status, reload] = useAsyncThunk<Result, ThunkArg, ThunkApiConfig, Options>(action, options);
    return children(data, status, reload);
};
