import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { RootState } from '@app/redux/interface';

export const getApplicationsState = (state: RootState) => state.applications;

export const getApplicationsList = createDraftSafeSelector(
	getApplicationsState,
	applicationsState => applicationsState.list,
);

export const getApplicationDetails = createDraftSafeSelector(
	getApplicationsState,
	applicationsState => applicationsState.details,
);

export const getFormDetails = createDraftSafeSelector(
	getApplicationsState,
	applicationsState => applicationsState.form,
);
