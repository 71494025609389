import { Theme } from '@mui/material';
import { createTheme } from './createTheme';

export const defaultTheme = createTheme();

let currentTheme = defaultTheme;

export function getCurrentTheme() {
    return currentTheme;
}

export function changeTheme(theme: Theme): Theme {
    currentTheme = theme;
    return currentTheme;
}

export * from './darkTheme';
export * from './defaultTheme';
export * from './omniTheme';
export * from './nextGenTheme';
export * from './nextGenDarkTheme';
export { createTheme };
