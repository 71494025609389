import React, { useRef } from 'react';

import { showToast } from '@app/providers/ToastProvider';

import FILES_CONFIG, { FILE_TYPES } from '@components/fileManager/constants';
import convertBytesToHumanReadableFormat from '@components/fileManager/utils';

export interface FileManagerProps {
	type: FILE_TYPES;
	onChange: (file: File) => void;
	children({ handleOpenFileProvider }: { handleOpenFileProvider(): void }): JSX.Element;
}

function FileManager({ type, onChange, children }: FileManagerProps) {
	const inputFile = useRef<HTMLInputElement>(null);

	const fileInputConfig = FILES_CONFIG[type];

	const handleOpenFileProvider = () => {
		if (inputFile.current) {
			inputFile.current.click();
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newFile = e.target.files?.[0];
		const isValidFile = fileInputConfig.validator(newFile);

		if (newFile) {
			if (isValidFile) {
				onChange(newFile);
			} else {
				showToast.error(
					`File should be less then ${convertBytesToHumanReadableFormat(
						fileInputConfig.maxSize,
					)}. Allowed formats are ${fileInputConfig.acceptFormats.join(', ')}`,
				);
			}
		}

		e.target.value = '';
	};

	return (
		<>
			<input
				type="file"
				ref={inputFile}
				accept={fileInputConfig.acceptFormats.join(', ')}
				onChange={handleChange}
				style={{ display: 'none' }}
			/>

			{children({ handleOpenFileProvider })}
		</>
	);
}

export default FileManager;
