import { ApplicationFormSelectedQuestion } from '@pages/ApplicationForm/containers/ApplicationFormMain';

export function getYearOptions({ question }: { question: ApplicationFormSelectedQuestion }) {
	const years: number[] = [];
	let startDate;
	let endDate;

	if (['signatures-date', 'signatures-joint-date'].includes(question.system_identifier)) {
		startDate = new Date().getFullYear();
		endDate = startDate - 100;
	} else if (question.rule_set.includes('dob-')) {
		switch (question.rule_set) {
			case 'dob-18-to-100-years': {
				startDate = new Date().getFullYear() - Number(18);
				endDate = new Date().getFullYear() - Number(100);
				break;
			}
			case 'dob-0-to-18-years': {
				startDate = new Date().getFullYear() - Number(0);
				endDate = startDate - Number(18);
				break;
			}
			case 'dob-0-to-100-years': {
				startDate = new Date().getFullYear() - Number(0);
				endDate = startDate - Number(100);
				break;
			}
			case 'dob-0-to-21-years': {
				startDate = new Date().getFullYear() - Number(0);
				endDate = startDate - Number(21);
				break;
			}
			default: {
				startDate = new Date().getFullYear() + 20;
				endDate = startDate - 100;
			}
		}
	} else {
		startDate = new Date().getFullYear() + 20;
		endDate = startDate - 100;
	}

	while (startDate >= endDate) {
		years.push(startDate);
		startDate--;
	}

	return years.map(year => ({ id: `year-${year}`, value: year, label: year }));
}

export function getMonthOptions({ question, year }: { question: ApplicationFormSelectedQuestion; year: number }) {
	const MONTH_OPTIONS = [
		{ id: `month-${1}`, value: 1, label: 'January' },
		{ id: `month-${2}`, value: 2, label: 'February' },
		{ id: `month-${3}`, value: 3, label: 'March' },
		{ id: `month-${4}`, value: 4, label: 'April' },
		{ id: `month-${5}`, value: 5, label: 'May' },
		{ id: `month-${6}`, value: 6, label: 'June' },
		{ id: `month-${7}`, value: 7, label: 'July' },
		{ id: `month-${8}`, value: 8, label: 'August' },
		{ id: `month-${9}`, value: 9, label: 'September' },
		{ id: `month-${10}`, value: 10, label: 'October' },
		{ id: `month-${11}`, value: 11, label: 'November' },
		{ id: `month-${12}`, value: 12, label: 'December' },
	];

	if (!year) return [];

	if (['signatures-date', 'signatures-joint-date'].includes(question.system_identifier)) {
		return MONTH_OPTIONS;
	}

	if (question.rule_set.includes('dob-')) {
		if (new Date().getFullYear() === year) {
			return MONTH_OPTIONS.splice(0, new Date().getMonth() + 1);
		} else {
			return MONTH_OPTIONS;
		}
	}

	return MONTH_OPTIONS;
}

export function getDayOptions({
	question,
	year,
	month,
}: {
	question: ApplicationFormSelectedQuestion;
	year: number;
	month: number;
}) {
	if (!year || !month) return [];

	let sliceStartPosition = undefined;
	let sliceEndPosition = undefined;

	if (question.rule_set.includes('dob-') && new Date().getMonth() + 1 === month && new Date().getFullYear() === year) {
		sliceStartPosition = 0;
		sliceEndPosition = new Date().getDate();
	}

	return Array.from(Array(new Date(year, month, 0).getDate()).keys())
		.slice(sliceStartPosition, sliceEndPosition)
		.map(day => day + 1)
		.map(day => ({ id: `day-${day}`, value: day, label: day.toString() }));
}
