import React from 'react';

import ApplicationDetailsInterface from '@api/requests/applications/interfaces/ApplicationDetailsInterface.interface';

import { getApplicationFormProgress } from '@app/redux/applicationForm/applicationForm.selectors';
import { getApplicationDetails } from '@app/redux/applications/applications.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { Box, Grid, Typography } from '@mui/material';

import RoundChart from '@components/chart';
import CopyButton from '@components/copyButton';

import ApplicationFormPagesList from '@pages/ApplicationForm/containers/ApplicationFormNavigation/containers/ApplicationFormPagesList';
import {
	ApplicationFormNavigationBox,
	ApplicationFormNavigationDivider,
} from '@pages/ApplicationForm/containers/ApplicationFormNavigation/styles';
import { getApplicationAccountStatusLabel } from '@pages/Applications/containers/ApplicationAccountCardList/components/utils';

function ApplicationFormNavigation() {
	const applicationDetails = useAppSelector(getApplicationDetails) as ApplicationDetailsInterface;
	const applicationFormProgress = useAppSelector(getApplicationFormProgress);

	return (
		<ApplicationFormNavigationBox>
			<Grid container alignItems={'center'} justifyContent={'space-between'} px={2} pt={2}>
				<Box>
					<Typography variant={'body2'}>
						<b>Application form</b>
					</Typography>

					{applicationDetails.account_number &&
					getApplicationAccountStatusLabel(applicationDetails.status) === 'Approved' ? (
						<Grid container alignItems={'center'} mt={0.5}>
							<Typography color={'secondary'} variant={'caption'} mr={1}>
								Account number:
							</Typography>

							<Typography variant={'body2'} mr={1}>
								{applicationDetails.account_number}
							</Typography>

							<CopyButton copiedValue={applicationDetails.account_number.toString()} />
						</Grid>
					) : null}

					<Grid container alignItems={'center'} mt={0.5}>
						<Typography color={'secondary'} variant={'caption'} mr={1}>
							Application UID:
						</Typography>

						<Typography variant={'body2'} mr={1}>
							{applicationDetails.uid}
						</Typography>

						<CopyButton copiedValue={applicationDetails.uid} />
					</Grid>
				</Box>

				<Box sx={{ width: 64 }}>
					<RoundChart
						value={
							Math.round(
								(applicationFormProgress.values.total.answered / applicationFormProgress.values.total.required) * 1000,
							) / 10 || 0
						}
						withCaption={false}
					/>
				</Box>
			</Grid>

			<ApplicationFormNavigationDivider />

			<ApplicationFormPagesList />
		</ApplicationFormNavigationBox>
	);
}

export default ApplicationFormNavigation;
