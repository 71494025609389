import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import AccountClientRequests from '@api/requests';

import {
	ApplicationFormPage,
	ApplicationFormPartPage,
	ApplicationFormPartSection,
	ApplicationFormSection,
	ApplicationFormStateType,
} from './applicationForm.interfaces';

const initialState: ApplicationFormStateType = {
	id: 0,
	version: 0,
	application_id: 0,
	pages: [],
	sections: {},
	questions: {},
	identifier_questions: {
		account_type_id: 0,
		options_id: 0,
		customer_type_id: 0,
	},
	current: {
		page: { id: 0, label: '' },
		section: { id: 0, label: '' },
		questions_ids: [],
	},
};

export const { actions: applicationFormActions, reducer: applicationFormReducer } = createSlice({
	name: 'applicationForm',
	initialState,
	reducers: {
		selectApplicationFormPage(state, action: PayloadAction<ApplicationFormPage>) {
			state.current.page = {
				id: action.payload.id,
				label: action.payload.label,
			};
			state.current.section = {
				id: state.sections[action.payload.id][0].id,
				label: state.sections[action.payload.id][0].label,
			};
			state.current.questions_ids = Object.entries(state.questions)
				.filter(([, question]) => question.section_id === state.sections[action.payload.id][0].id)
				.map(([questionId]) => Number(questionId));
		},
		selectApplicationFormSection(state, action: PayloadAction<ApplicationFormSection>) {
			state.current.section = {
				id: action.payload.id,
				label: action.payload.label,
			};
			state.current.questions_ids = Object.entries(state.questions)
				.filter(([, question]) => question.section_id === action.payload.id)
				.map(([questionId]) => Number(questionId));
		},
		selectApplicationFormNextSection(
			state,
			action: PayloadAction<{
				page: ApplicationFormPartPage;
				section: ApplicationFormPartSection;
			}>,
		) {
			state.current.page = {
				id: action.payload.page.id,
				label: action.payload.page.label,
			};
			state.current.section = {
				id: action.payload.section.id,
				label: action.payload.section.label,
			};
			state.current.questions_ids = Object.entries(state.questions)
				.filter(([, question]) => question.section_id === action.payload.section.id)
				.map(([questionId]) => Number(questionId));
		},
		setApplicationForm(state, { payload }: PayloadAction<ApplicationFormStateType>) {
			state.id = payload.id;
			state.version = payload.version;
			state.application_id = payload.application_id;
			state.pages = payload.pages;
			state.sections = payload.sections;
			state.questions = payload.questions;
			state.identifier_questions = payload.identifier_questions;
			state.current = payload.current;
		},
	},
	extraReducers: builder =>
		builder
			.addCase(AccountClientRequests.Applications.saveTextAnswer.fulfilled, (state, { payload }) => {
				state.questions = {
					...state.questions,
					[payload.answer.question_id]: {
						...state.questions[payload.answer.question_id],
						value: payload.answer.data,
						errors: [],
					},
				};
			})
			.addCase(AccountClientRequests.Applications.saveTextAnswer.rejected, (state, { payload, meta }) => {
				const { response } = payload as AxiosError<{ messages: { data: string[] } }>;

				state.questions = {
					...state.questions,
					[meta.arg.question_id]: {
						...state.questions[meta.arg.question_id],
						errors: response?.data?.messages?.data || ['Something went wrong!'],
					},
				};
			})
			.addCase(AccountClientRequests.Applications.saveOptionAnswer.fulfilled, (state, { payload }) => {
				state.questions = {
					...state.questions,
					[payload.answer.question_id]: {
						...state.questions[payload.answer.question_id],
						options: state.questions[payload.answer.question_id].options.map(option => ({
							...option,
							is_checked: payload.answer.option_id === option.id,
						})),
						errors: [],
					},
				};
			})
			.addCase(AccountClientRequests.Applications.saveOptionAnswer.rejected, (state, { payload, meta }) => {
				const { response } = payload as AxiosError<{ messages: { data: string[] } }>;

				state.questions = {
					...state.questions,
					[meta.arg.question_id]: {
						...state.questions[meta.arg.question_id],
						errors: response?.data?.messages?.data || ['Something went wrong!'],
					},
				};
			})
			.addCase(AccountClientRequests.Applications.saveMultipleOptionAnswer.fulfilled, (state, { payload, meta }) => {
				state.questions = {
					...state.questions,
					[meta.arg.question_id]: {
						...state.questions[meta.arg.question_id],
						options: state.questions[meta.arg.question_id].options.map(option => ({
							...option,
							is_checked: payload.answers ? Boolean(payload.answers.find(({ option_id }) => option_id === option.id)): Boolean(payload.answer.option_id === option.id),
						})),
						errors: [],
					},
				};
			})
			.addCase(AccountClientRequests.Applications.saveMultipleOptionAnswer.rejected, (state, { payload, meta }) => {
				const { response } = payload as AxiosError<{ messages: { data: string[] } }>;

				state.questions = {
					...state.questions,
					[meta.arg.question_id]: {
						...state.questions[meta.arg.question_id],
						errors: response?.data?.messages?.data || ['Something went wrong!'],
					},
				};
			})
			.addCase(AccountClientRequests.Applications.saveFileAnswer.fulfilled, (state, { payload }) => {
				state.questions = {
					...state.questions,
					[payload.answer.question_id]: {
						...state.questions[payload.answer.question_id],
						value: payload.answer.document.name,
						errors: [],
					},
				};
			})
			.addCase(AccountClientRequests.Applications.saveFileAnswer.rejected, (state, { payload, meta }) => {
				const { response } = payload as AxiosError<{ messages: { data: string[] } }>;

				state.questions = {
					...state.questions,
					[meta.arg.question_id]: {
						...state.questions[meta.arg.question_id],
						errors: response?.data?.messages?.data || ['Something went wrong!'],
					},
				};
			})
			.addCase(AccountClientRequests.Applications.deleteAnswer.fulfilled, (state, { meta }) => {
				state.questions = {
					...state.questions,
					[meta.arg.question_id]: {
						...state.questions[meta.arg.question_id],
						value: '',
						options: state.questions[meta.arg.question_id].options.map(option => ({
							...option,
							is_checked: false,
						})),
						errors: [],
					},
				};
			})
			.addCase(AccountClientRequests.Applications.submitApplicationForm.rejected, (state, { payload }) => {
				const { response } = payload as AxiosError<{ messages: Record<number, string[]> }>;

				for (const questionId in response?.data.messages) {
					state.questions = {
						...state.questions,
						[Number(questionId)]: {
							...state.questions[Number(questionId)],
							errors: response?.data.messages[Number(questionId)] || ['Something went wrong!'],
						},
					};
				}
			})
			.addCase(AccountClientRequests.Auth.logout.fulfilled, state => {
				state.id = initialState.id;
				state.version = initialState.version;
				state.application_id = initialState.application_id;
				state.pages = initialState.pages;
				state.sections = initialState.sections;
				state.questions = initialState.questions;
				state.identifier_questions = initialState.identifier_questions;
				state.current = initialState.current;
			}),
});
