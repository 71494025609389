import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Formatter, FormatterProps } from 'mui-enhanced-table';
import React, { ReactNode } from 'react';

class CheckboxFormatterClass implements Formatter {
    getValueString(): string {
        throw new Error('Method not implemented.');
    }

    format({ value }: FormatterProps<any>): ReactNode {
        return value ? <CheckBox color="primary" /> : <CheckBoxOutlineBlank />;
    }
}

export const CheckboxFormatter = new CheckboxFormatterClass();
