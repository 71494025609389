import { FormTextField } from '@orbis-systems/orbis-react-toolkit';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Autocomplete, Box, Divider, Grid, IconButton, InputAdornment, Paper, Typography } from '@mui/material';

import Form from '@components/forms';
import useRegistrationForm from '@components/forms/registration/hooks';
import { RegistrationFormProps } from '@components/forms/registration/interfaces';
import SubmitButton from '@components/submitButton';

import * as environment from '@utils/environment';

function RegistrationForm({ initialValues, onSubmit }: RegistrationFormProps) {
	const {
		showPassword,
		showPasswordConfirmation,
		handleShowPassword,
		handleShowPasswordConfirmation,
		formik,
		phoneCodeOptions,
	} = useRegistrationForm({
		initialValues,
		onSubmit,
	});

	return (
		<Form formik={formik}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<FormTextField name={'first_name'} label={'First Name'} autoComplete="off" disabled={formik.isSubmitting} />
				</Grid>

				<Grid item xs={12}>
					<FormTextField name={'last_name'} label={'Last Name'} autoComplete="off" disabled={formik.isSubmitting} />
				</Grid>

				<Grid item xs={12}>
					<FormTextField
						name={'email'}
						label={'Email'}
						autoComplete="off"
						disabled={formik.isSubmitting}
						onChange={value => formik.setFieldValue('email', value.trim())}
					/>
				</Grid>

				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs={5}>
							<Autocomplete
								disablePortal
								options={phoneCodeOptions.map(({ isPriorityGroup, dial_code, country_name }) => ({
									isPriorityGroup,
									dial_code,
									country_name,
									label: dial_code + ' ' + country_name,
								}))}
								PaperComponent={props => <Paper {...props} sx={{ width: 350 }} />}
								disabled={formik.isSubmitting}
								value={{
									isPriorityGroup: false,
									dial_code: formik.values.phone?.code || '',
									country_name: formik.values.phone?.code || '',
									label: formik.values.phone?.code || '',
								}}
								onChange={(_, newValue) => formik.setFieldValue('phone.code', newValue?.dial_code)}
								renderInput={params => <FormTextField {...params} name="phone.code" label="Country Code" />}
								renderOption={(props, option) => {
									return (
										<>
											<Box key={props.id} component={'li'} {...props}>
												<Grid container>
													<Typography variant={'body2'} sx={{ width: 40 }} textAlign={'right'}>
														{option.dial_code}
													</Typography>

													<Typography variant={'body2'} ml={1}>
														{option.country_name}
													</Typography>
												</Grid>
											</Box>

											{option.isPriorityGroup ? <Divider style={{ width: '100%', margin: '5px 0' }} /> : null}
										</>
									);
								}}
							/>
						</Grid>

						<Grid item xs={7}>
							<FormTextField
								name={'phone.number'}
								label={'Phone Number'}
								autoComplete="off"
								value={formik.values.phone.number}
								onChange={(value: any) => {
									const intergersOnlyValue = value.replace(/[^0-9]/g, '');
									formik.setFieldValue('phone.number', intergersOnlyValue);
								}}
								disabled={formik.isSubmitting}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<FormTextField
						name={'password'}
						label={'Password'}
						type={showPassword ? 'text' : 'password'}
						autoComplete="off"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton aria-label="Toggle password visibility" onClick={handleShowPassword}>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
						disabled={formik.isSubmitting}
					/>
				</Grid>

				<Grid item xs={12}>
					<FormTextField
						name={'password_confirmation'}
						label={'Password Confirmation'}
						type={showPasswordConfirmation ? 'text' : 'password'}
						autoComplete="off"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton aria-label="Toggle password visibility" onClick={handleShowPasswordConfirmation}>
										{showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
						disabled={formik.isSubmitting}
					/>
				</Grid>

				<Grid item xs={12}>
					<ReCAPTCHA
						hl="en"
						sitekey={environment.env.RECAPTCHA_SITE_KEY}
						onChange={token => {
							formik.setFieldValue('recaptcha_token', token);
						}}
					/>
				</Grid>

				<Grid item xs={12}>
					<SubmitButton label={'Register'} disabled={formik.isSubmitting} />
				</Grid>
			</Grid>
		</Form>
	);
}

export default RegistrationForm;
