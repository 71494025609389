import { Box, styled } from '@mui/material';

export const StyledWelcomeSection = styled(Box)(({ theme }) => ({
	maxHeight: 400,
	overflowY: 'auto',
	padding: theme.spacing(2),
	[theme.breakpoints.down('sm')]: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
}));
