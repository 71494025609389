import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import configRequest from '@api/requests/configRequest';

import { showToast } from '@app/providers/ToastProvider';

export interface SendVerificationPhoneCodeData {
	type: 'sms' | 'voice';
}

export interface SendVerificationPhoneCodeResponse {
	phone_verification: {
		expires_at: string;
	};
}

const sendVerificationPhoneCode = configRequest<SendVerificationPhoneCodeResponse, SendVerificationPhoneCodeData>({
	requestPrefix: 'auth/sendVerificationPhoneCode',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.AUTH.VERIFICATION.PHONE.SEND(),
		method: HTTP_METHODS_ENUM.POST,
		data,
	}),
	onSuccess: () => showToast.success('The code has been sent to your phone.'),
});

export default sendVerificationPhoneCode;
