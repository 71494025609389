import React, { useState } from 'react';

import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';

import { Card, ClickAwayListener } from '@mui/material';

import { Animate, AnimatedPanel } from '@components/transition/panel';

import USER_PROFILE_VIEWS_MAP, {
	USER_PROFILE_STEPS_ENUM,
	UserProfileViewsFactoryComponentType,
} from './userProfileViewsFactory';

function UserProfile({
	currentUser,
	handleCloseUserProfile,
}: {
	currentUser: CurrentUserInterface;
	handleCloseUserProfile: () => void;
}) {
	const [currentStep, setCurrentStep] = useState<USER_PROFILE_STEPS_ENUM>(USER_PROFILE_STEPS_ENUM.INFO);

	const CurrentView = USER_PROFILE_VIEWS_MAP.get(currentStep) as UserProfileViewsFactoryComponentType;

	const handleClickAway = () => {
		if (currentStep !== 'user-profile-update') {
			handleCloseUserProfile();
		}
	};

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<Card sx={{ px: 2, py: 2.5 }}>
				<Animate>
					<AnimatedPanel id={currentStep} key={currentStep} animation={'slide-right'}>
						<CurrentView
							currentUser={currentUser}
							handleCloseUserProfile={handleCloseUserProfile}
							setCurrentStep={setCurrentStep}
						/>
					</AnimatedPanel>
				</Animate>
			</Card>
		</ClickAwayListener>
	);
}

export default UserProfile;
