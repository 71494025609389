import { boolean, mixed, number, object, string } from 'yup';
import { generateNamesObject } from '../../utils';
import { OrderType } from '../TradingForm/lookups';
import { CryptoOrderTransaction, TimeInForce } from './lookups';

export const cryptoFieldNames = generateNamesObject(
    'coin',
    'transaction',
    'orderType',
    'quantity',
    'marketTime',
    'stopPrice',
    'limitPrice',
    'tradeWithStablecoin',
);

export const cryptoTradingSchema = object({
    [cryptoFieldNames.coin]: string().required().label('Coin').default(''),
    [cryptoFieldNames.transaction]: mixed<CryptoOrderTransaction>()
        .label('Transaction')
        .default(CryptoOrderTransaction.Buy)
        .required(),
    [cryptoFieldNames.orderType]: mixed<OrderType>().label('Order Type').required().default(OrderType.Market),
    [cryptoFieldNames.quantity]: number().required().label('Quantity').min(0),
    [cryptoFieldNames.marketTime]: mixed<TimeInForce>()
        .label('Time In Force')
        .default(TimeInForce.FillOrKill)
        .required(),
    [cryptoFieldNames.stopPrice]: number()
        .label('Stop Price')
        .when(cryptoFieldNames.orderType, {
            is: (value: OrderType) => value === OrderType.Stop || value === OrderType.StopLimit,
            then: number().required(),
            otherwise: number(),
        }),
    [cryptoFieldNames.limitPrice]: number()
        .label('Limit Price')
        .when(cryptoFieldNames.orderType, {
            is: (value: OrderType) => value === OrderType.Limit || value === OrderType.StopLimit,
            then: number().required(),
            otherwise: number(),
        }),
    [cryptoFieldNames.tradeWithStablecoin]: boolean().required().default(true),
});

export const getInitialCryptoTradingFormValues = (values?: any) =>
    cryptoTradingSchema.cast(values ?? undefined, { stripUnknown: false });

export type CryptoTradingFormValues = ReturnType<typeof cryptoTradingSchema.getDefault>;
