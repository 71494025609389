import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import AssetsStateInterface from '@api/requests/assets/interfaces/AssetsStateInterface.interface';
import configRequest from '@api/requests/configRequest';

export type GetStatesListData = void;

export interface GetStatesListResponse {
	states: AssetsStateInterface[];
}

const getStatesList = configRequest<GetStatesListResponse, GetStatesListData>({
	requestPrefix: 'assets/getStatesList',
	reqConfigCreator: () => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.ASSETS.STATES.LIST(),
		method: HTTP_METHODS_ENUM.POST,
	}),
});

export default getStatesList;
