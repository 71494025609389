import { AsyncThunkAction } from '@reduxjs/toolkit';
import { DependencyList, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncActionStatus } from '../constants';
import { AsyncThunkConfig, selectAsyncActionStatus, ThunkArgMeta } from '../redux';
import { Exactify } from '../types/utils';
import { useInterval } from './useInterval';
import { usePersistState } from './usePersistState';

export interface UseAsyncThunkOptions<Result> {
    when?: boolean;
    deps?: DependencyList;
    interval?: number;
    keepPrevious?: boolean;
    persistKey?: string;
    defaultValue?: Result;
}

export type UseAsyncThunkReturnType<
    Result,
    Options,
    FinalResult = Options extends {
        defaultValue: Result;
    }
        ? Result
        : Result | undefined,
> = [FinalResult, AsyncActionStatus, () => Promise<FinalResult>, (reason?: string) => void];

export function useAsyncThunk<
    Result,
    ThunkArg,
    ThunkApiConfig extends AsyncThunkConfig = {},
    Options extends UseAsyncThunkOptions<Result> = {},
>(
    action: AsyncThunkAction<Result, ThunkArg, ThunkApiConfig> & {
        type?: string;
        arg?: ThunkArg & ThunkArgMeta;
    },
    options?: Exactify<UseAsyncThunkOptions<Result>, Options>,
): UseAsyncThunkReturnType<Result, Options> {
    if (!action.type) {
        throw new Error(
            'Action type not found. Make sure that the async thunk was created by `createAsyncThunk` from `orbis-react-toolkit` instead of `@reduxjs/toolkit`',
        );
    }

    const {
        when = true,
        deps = [],
        interval = 0,
        keepPrevious = !!options?.persistKey,
        persistKey,
        defaultValue,
    } = options || {};

    const dispatch = useDispatch();
    const [data, setData] = persistKey
        ? usePersistState<Result | undefined>(undefined, persistKey, undefined)
        : useState<Result | undefined>(defaultValue);
    const [time, setTime] = useState(Date.now());
    const status = useSelector((state: any) =>
        selectAsyncActionStatus(state, {
            type: action.type as string,
            extra: action.arg?._actionTypeExtra,
        }),
    );
    const wrappedPromiseRef = useRef<ReturnType<typeof action>>();

    const loadData = () => {
        if (when) {
            if (!keepPrevious) {
                setData(defaultValue);
            }

            wrappedPromiseRef.current?.abort();

            const wrappedPromise: ReturnType<typeof action> = dispatch(action as any);
            const promise = wrappedPromise.unwrap();
            promise
                .then((data) => {
                    setData(data);
                })
                .catch((error) => {
                    if (error.name !== 'AbortError') {
                        throw error;
                    }
                });

            wrappedPromiseRef.current = wrappedPromise;

            return promise;
        }

        return Promise.resolve(data);
    };

    useInterval(() => setTime(Date.now()), interval);
    useEffect(() => {
        loadData();
        return () => wrappedPromiseRef.current?.abort();
    }, [...deps, when, time, keepPrevious]);

    return [
        data,
        status,
        loadData,
        wrappedPromiseRef.current?.abort ??
            (() => {
                // no-op
            }),
    ] as UseAsyncThunkReturnType<Result, Options>;
}
