import APPLICATION_STATUSES_ENUM from '@api/requests/applications/enums/APPLICATION_STATUSES_ENUM.enum';
import ApplicationDetailsInterface from '@api/requests/applications/interfaces/ApplicationDetailsInterface.interface';

import { isEnableSSO } from '@utils/environment';
import * as environment from '@utils/environment';

export function isRedirectToNextGenSSO(applicationDetails: ApplicationDetailsInterface) {
	return (
		isEnableSSO() &&
		Boolean(applicationDetails.submitted_at) &&
		Boolean(applicationDetails.account_number) &&
		applicationDetails.status === APPLICATION_STATUSES_ENUM.APPROVED
	);
}

export function isRedirectToNextGenLogin(applicationDetails: ApplicationDetailsInterface) {
	return (
		environment.env.FIRM_ENVIRONMENT === 'axos-crypto' &&
		environment.env.BUILD_ENVIRONMENT === 'production' &&
		Boolean(applicationDetails.submitted_at) &&
		Boolean(applicationDetails.account_number) &&
		applicationDetails.status === APPLICATION_STATUSES_ENUM.APPROVED
	);
}
