import React from 'react';

import { FIRM_NAME_MAP } from '@app/layouts/UnauthorizedPageLayout/constants';
import UnauthorizedPageHeader from '@app/layouts/UnauthorizedPageLayout/header';
import { RootGrid, unauthorizedLayoutClasses } from '@app/layouts/UnauthorizedPageLayout/styles';

import { Box, Grid, Hidden, Typography } from '@mui/material';

import * as environment from '@utils/environment';

function UnauthorizedPageLayout({ children }: { children: React.ReactNode }) {
	return (
		<RootGrid container>
			<Hidden mdDown>
				<Grid item md={6} className={unauthorizedLayoutClasses.leftContainer}>
					{process.env.FIRM_ENVIRONMENT !== 'bosonic' && process.env.FIRM_ENVIRONMENT !== 'secondlane' && (
						<Box pl={10} sx={{ maxWidth: 600 }}>
							<Typography variant="h4" mt={3}>
								<b>From Vision to Reality</b>
							</Typography>

							<Typography variant="subtitle1" mt={4}>
								{`Ignite your future with ${FIRM_NAME_MAP.get(
									environment.env.FIRM_ENVIRONMENT,
								)}. Unlock your financial power with an investment account that realizes
							your goals.`}
							</Typography>
						</Box>
					)}
				</Grid>
			</Hidden>

			<Grid item xs={12} md={6} className={unauthorizedLayoutClasses.rightContainer}>
				<UnauthorizedPageHeader />

				<Box className={unauthorizedLayoutClasses.form}>{children}</Box>
			</Grid>
		</RootGrid>
	);
}

export default UnauthorizedPageLayout;
