import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import ApplicationInterface from '@api/requests/applications/interfaces/ApplicationInterface.interface';
import configRequest from '@api/requests/configRequest';

export interface CustodianCheckApplicationFormData {
	application_id: number;
}

export interface CustodianCheckApplicationFormResponse {
	application: ApplicationInterface & {
		current_custodian_request: { status: string };
	};
}

const custodianCheckApplicationForm = configRequest<
	CustodianCheckApplicationFormResponse,
	CustodianCheckApplicationFormData
>({
	requestPrefix: 'applications/custodianCheckApplicationForm',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.APPLICATION_FORM.CUSTODIAN_CHECK(),
		method: HTTP_METHODS_ENUM.POST,
		data: {
			...data,
			with: ['current_custodian_request', 'current_status'],
		},
	}),
});

export default custodianCheckApplicationForm;
