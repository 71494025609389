import { FormSelectOption } from '..';
import {
    OrderFillType,
    OrderMarketTime,
    OrderTransaction,
    OrderType,
    OrderValidity,
    PositionType,
    SecurityType,
    TradeDestination,
    TrailingStopType,
} from './lookups';

export const tradeDestinationFormSelectOptions: {
    singleAccount: FormSelectOption;
    averagePriceAccount: FormSelectOption;
    model: FormSelectOption;
} = {
    singleAccount: {
        name: 'Single Account',
        value: TradeDestination.SingleAccount,
    },
    averagePriceAccount: {
        name: 'Average Price Account',
        value: TradeDestination.AveragePriceAccount,
    },
    model: {
        name: 'Model',
        value: TradeDestination.Model,
    },
};

export const securityTypeFormSelectOptions: {
    equity: FormSelectOption;
    option: FormSelectOption;
    spread: FormSelectOption;
    mutualFund: FormSelectOption;
} = {
    equity: {
        name: 'Equity',
        value: SecurityType.Equity,
    },
    option: {
        name: 'Option',
        value: SecurityType.Option,
    },
    spread: {
        name: 'Spread',
        value: SecurityType.Spread,
    },
    mutualFund: {
        name: 'Mutual Fund',
        value: SecurityType.MutualFund,
    },
};

export const securityTypeOptions: {
    equity: FormSelectOption;
    option: FormSelectOption;
    spread: FormSelectOption;
    mutualFund: FormSelectOption;
} = {
    equity: {
        name: 'Equity',
        value: SecurityType.Equity,
    },
    option: {
        name: 'Option',
        value: SecurityType.Option,
    },
    spread: {
        name: 'Spread',
        value: SecurityType.Spread,
    },
    mutualFund: {
        name: 'Mutual Fund',
        value: SecurityType.MutualFund,
    },
};

export const transactionOptions: {
    buy: FormSelectOption;
    sell: FormSelectOption;
    shortSell: FormSelectOption;
    buyToCover: FormSelectOption;
} = {
    buy: {
        name: 'Buy',
        value: OrderTransaction.Buy,
    },
    sell: {
        name: 'Sell',
        value: OrderTransaction.Sell,
    },
    shortSell: {
        name: 'Short Sell',
        value: OrderTransaction.ShortSell,
    },
    buyToCover: {
        name: 'Buy to Cover',
        value: OrderTransaction.BuyToCover,
    },
};

export const mutualTransactionOptions: {
    buy: FormSelectOption;
    sell: FormSelectOption;
} = {
    buy: {
        name: 'Buy',
        value: OrderTransaction.Buy,
    },
    sell: {
        name: 'Redeem',
        value: OrderTransaction.Sell,
    },
};

export const optionTransactionOptions: {
    buyToOpen: FormSelectOption;
    buyToClose: FormSelectOption;
    sellToOpen: FormSelectOption;
    sellToClose: FormSelectOption;
} = {
    buyToOpen: {
        name: 'Buy to Open',
        value: OrderTransaction.BuyToOpen,
    },
    buyToClose: {
        name: 'Buy to Close',
        value: OrderTransaction.BuyToClose,
    },
    sellToOpen: {
        name: 'Sell to Open',
        value: OrderTransaction.SellToOpen,
    },
    sellToClose: {
        name: 'Sell to Close',
        value: OrderTransaction.SellToClose,
    },
};

export const spreadTransactionOptions: {
    open: FormSelectOption;
    close: FormSelectOption;
} = {
    open: {
        name: 'Open',
        value: OrderTransaction.Open,
    },
    close: {
        name: 'Close',
        value: OrderTransaction.Close,
    },
};

export const orderTypeOptions: {
    market: FormSelectOption;
    limit: FormSelectOption;
    stop: FormSelectOption;
    stopLimit: FormSelectOption;
    notional: FormSelectOption;
    trailingStop: FormSelectOption;
} = {
    market: {
        name: 'Market',
        value: OrderType.Market,
    },
    limit: {
        name: 'Limit',
        value: OrderType.Limit,
    },
    stop: {
        name: 'Stop',
        value: OrderType.Stop,
    },
    stopLimit: {
        name: 'Stop Limit',
        value: OrderType.StopLimit,
    },
    notional: {
        name: 'Notional',
        value: OrderType.Notional,
    },
    trailingStop: {
        name: 'Trailing Stop',
        value: OrderType.TrailingStop,
    },
};

export const spreadOrderTypeOptions: {
    market: FormSelectOption;
    even: FormSelectOption;
    debit: FormSelectOption;
    credit: FormSelectOption;
} = {
    market: {
        name: 'Market',
        value: OrderType.Market,
    },
    even: {
        name: 'Even',
        value: OrderType.Even,
    },
    debit: {
        name: 'Debit',
        value: OrderType.Debit,
    },
    credit: {
        name: 'Credit',
        value: OrderType.Credit,
    },
};

export const validityOptions: {
    day: FormSelectOption;
    gtc: FormSelectOption;
} = {
    day: {
        name: 'Good for Day',
        value: OrderValidity.GoodForDay,
    },
    gtc: {
        name: 'Good til canceled',
        value: OrderValidity.GoodTilCanceled,
    },
};

export const marketTimeOptions: {
    preMarket: FormSelectOption;
    coreMarket: FormSelectOption;
    postMarket: FormSelectOption;
    allMarket: FormSelectOption;
} = {
    preMarket: {
        name: 'Pre-market Hours',
        value: OrderMarketTime.PreMarket,
    },
    coreMarket: {
        name: 'Market Hours',
        value: OrderMarketTime.CoreMarket,
    },
    postMarket: {
        name: 'Post-market Hours',
        value: OrderMarketTime.PostMarket,
    },
    allMarket: {
        name: 'All Market Hours',
        value: OrderMarketTime.AllMarket,
    },
};

export const fillTypeOptions: {
    any: FormSelectOption;
    aon: FormSelectOption;
} = {
    any: {
        name: 'Any',
        value: OrderFillType.Any,
    },
    aon: {
        name: 'All or None',
        value: OrderFillType.AllOrNone,
    },
};

export const positionTypeOptions: {
    cash: FormSelectOption;
    margin: FormSelectOption;
    short: FormSelectOption;
} = {
    cash: {
        name: 'Cash',
        value: PositionType.Cash,
    },
    margin: {
        name: 'Margin',
        value: PositionType.Margin,
    },
    short: {
        name: 'Short',
        value: PositionType.Short,
    },
};

export const trailingStopPegTypeOptions: {
    dollar: FormSelectOption;
    percent: FormSelectOption;
} = {
    dollar: {
        name: 'Dollar',
        value: TrailingStopType.Dollar,
    },
    percent: {
        name: 'Percent',
        value: TrailingStopType.Percent,
    },
};
