import { createTheme as createMuiTheme, Theme, ThemeOptions } from '@mui/material';
import { isBoolean, merge } from 'lodash';
import { defaultThemeOptions, getCustomMixins } from './defaultTheme';

export function createTheme(isRtl?: boolean): Theme;
export function createTheme(themeOptions?: ThemeOptions, isRtl?: boolean): Theme;
export function createTheme(arg?: ThemeOptions | boolean, isRtl?: boolean): Theme {
    const theme = createMuiTheme({
        ...merge({}, defaultThemeOptions, isBoolean(arg) ? {} : arg),
        direction: (isBoolean(arg) ? arg : isRtl) ? 'rtl' : undefined,
    });

    theme.darkMode = theme.palette.mode === 'dark';
    theme.rtl = theme.direction === 'rtl';
    merge(theme.mixins, getCustomMixins(theme));
    return theme;
}
