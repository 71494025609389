import createRoutePath from '@api/endpoints/createEndpointPath';

import { getBranchRoutes } from '@utils/environment';

const RoutePaths = {
	AUTH: {
		LOGIN: createRoutePath('/:branchRoute/:repRoute/login'),
		FORGOT_PASSWORD_METHOD: createRoutePath('/:branchRoute/:repRoute/forgot-password-method'),
		FORGOT_PASSWORD_VIA_EMAIL: createRoutePath('/:branchRoute/:repRoute/forgot-password/email'),
		FORGOT_PASSWORD_VIA_PHONE: createRoutePath('/:branchRoute/:repRoute/forgot-password/sms'),
		FORGOT_PASSWORD_VIA_VOICE: createRoutePath('/:branchRoute/:repRoute/forgot-password/voice'),
		RESET_PASSWORD: createRoutePath('/:branchRoute/:repRoute/reset-password'),
		REGISTRATION: createRoutePath('/:branchRoute/:repRoute/registration'),
	},
	MAIN: {
		APPLICATIONS: createRoutePath('/:branchRoute/:repRoute/applications'),
		APPLICATION_FORM: createRoutePath('/:branchRoute/:repRoute/applications/forms'),
	},
};

export const UNAUTHORIZED_ROUTES = getBranchRoutes().reduce(
	(branchRoutes: string[], branchRoute) => [
		...branchRoutes,
		RoutePaths.AUTH.LOGIN({ branchRoute: branchRoute.branch_route, repRoute: branchRoute.rep_route }),
		RoutePaths.AUTH.FORGOT_PASSWORD_METHOD({
			branchRoute: branchRoute.branch_route,
			repRoute: branchRoute.rep_route,
		}),
		RoutePaths.AUTH.FORGOT_PASSWORD_VIA_EMAIL({
			branchRoute: branchRoute.branch_route,
			repRoute: branchRoute.rep_route,
		}),
		RoutePaths.AUTH.FORGOT_PASSWORD_VIA_PHONE({
			branchRoute: branchRoute.branch_route,
			repRoute: branchRoute.rep_route,
		}),
		RoutePaths.AUTH.FORGOT_PASSWORD_VIA_VOICE({
			branchRoute: branchRoute.branch_route,
			repRoute: branchRoute.rep_route,
		}),
		RoutePaths.AUTH.RESET_PASSWORD({
			branchRoute: branchRoute.branch_route,
			repRoute: branchRoute.rep_route,
		}),
		RoutePaths.AUTH.REGISTRATION({ branchRoute: branchRoute.branch_route, repRoute: branchRoute.rep_route }),
	],
	[],
);

export const AUTHORIZED_ROUTES = getBranchRoutes().reduce(
	(branchRoutes: string[], branchRoute) => [
		...branchRoutes,
		RoutePaths.MAIN.APPLICATIONS({ branchRoute: branchRoute.branch_route, repRoute: branchRoute.rep_route }),
		RoutePaths.MAIN.APPLICATION_FORM({
			branchRoute: branchRoute.branch_route,
			repRoute: branchRoute.rep_route,
		}),
	],
	[],
);

export default RoutePaths;
