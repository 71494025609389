import { createAction } from '@reduxjs/toolkit';
import { Optional } from 'utility-types';
import { AlertMessage, LoadingMessage, MessageId, ToastMessage } from './alertReducer';
import { ACTION_TYPE_PREFIX } from '../constants';

export const showLoader = createAction<Optional<LoadingMessage, 'id'> | string>(`${ACTION_TYPE_PREFIX}/ShowLoader`);

export const dismissLoader = createAction<MessageId>(`${ACTION_TYPE_PREFIX}/DismissLoader`);

export const showToast = createAction<Optional<ToastMessage, 'id'> | string>(`${ACTION_TYPE_PREFIX}/ShowToast`);

export const dismissToast = createAction<MessageId | undefined>(`${ACTION_TYPE_PREFIX}/DismissToast`);

export const removeToast = createAction<MessageId>(`${ACTION_TYPE_PREFIX}/RemoveToast`);

export const showAlertDialog = createAction<Optional<AlertMessage, 'id'> | string>(
    `${ACTION_TYPE_PREFIX}/ShowAlertDialog`,
);

export const dismissAlertDialog = createAction<MessageId | undefined>(`${ACTION_TYPE_PREFIX}/DismissAlertDialog`);

export const removeAlertDialog = createAction<MessageId | MessageId[]>(`${ACTION_TYPE_PREFIX}/RemoveAlertDialog`);

export const resetAlertState = createAction(`${ACTION_TYPE_PREFIX}/ResetAlertState`);
