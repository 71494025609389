import React from 'react';

import { Box, Grid, Hidden, Skeleton, Stack } from '@mui/material';

import { StyledApplicationFormSkeleton, applicationFormSkeletonClasses } from './styles';

function ApplicationFormSkeleton() {
	return (
		<StyledApplicationFormSkeleton container>
			<Hidden mdDown>
				<Grid item xs={12} md={4} lg={2.8} className={applicationFormSkeletonClasses.menuSection}>
					<Grid p={2}>
						<Skeleton width={'60%'} height={24} />
						<Skeleton width={'80%'} height={20} />

						<Grid item xs={12} mt={3} mb={1}>
							<Skeleton variant="rounded" height={60} />
						</Grid>

						<Grid item xs={12} columnSpacing={1}>
							<Stack direction={'column'}>
								<Stack direction={'row'} gap={1} alignItems={'center'}>
									<Skeleton height={24} width={40} variant="rounded" />
									<Skeleton height={40} width={'100%'} />
								</Stack>

								<Stack direction={'row'} gap={1} alignItems={'center'}>
									<Skeleton height={24} width={40} variant="rounded" />
									<Skeleton height={40} width={'100%'} />
								</Stack>

								<Stack direction={'row'} gap={1} alignItems={'center'}>
									<Skeleton height={24} width={40} variant="rounded" />
									<Skeleton height={40} width={'100%'} />
								</Stack>

								<Stack direction={'row'} gap={1} alignItems={'center'}>
									<Skeleton height={24} width={40} variant="rounded" />
									<Skeleton height={40} width={'100%'} />
								</Stack>

								<Stack direction={'row'} gap={1} alignItems={'center'} mb={3}>
									<Skeleton height={24} width={40} variant="rounded" />
									<Skeleton height={40} width={'100%'} />
								</Stack>
							</Stack>
						</Grid>

						<Grid item xs={12} mt={1} mb={2}>
							<Skeleton variant="rounded" height={60} />
						</Grid>

						<Grid item xs={12} mt={1} mb={2}>
							<Skeleton variant="rounded" height={60} />
						</Grid>

						<Grid item xs={12} mt={1} mb={1}>
							<Skeleton variant="rounded" height={60} />
						</Grid>
					</Grid>
				</Grid>
			</Hidden>

			<Grid item xs={12} md={8} lg={6} mt={1}>
				<Box px={3}>
					<Box mb={2}>
						<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
							<Skeleton height={40} width={200} />
							<Hidden smDown>
								<Stack direction={'row'} gap={1}>
									<Skeleton height={60} width={80} />
									<Skeleton height={60} width={100} />
								</Stack>
							</Hidden>
						</Stack>
					</Box>

					<Box p={2} className={applicationFormSkeletonClasses.detailsSection}>
						<Grid item xs={12}>
							<Skeleton width={'80%'} />
							<Skeleton width={200} height={60} />
						</Grid>

						<Grid item xs={12}>
							<Stack direction={'column'}>
								<Skeleton width={'80%'} height={40} />
								<Skeleton height={100} variant={'text'} />
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Stack direction={'column'}>
								<Skeleton width={'80%'} height={40} />
								<Skeleton width={200} height={60} />
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Stack direction={'column'}>
								<Skeleton width={'80%'} height={40} />
								<Skeleton width={200} height={60} />
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Stack direction={'column'}>
								<Skeleton width={'80%'} height={40} />
								<Skeleton height={100} variant={'text'} />
							</Stack>
						</Grid>
					</Box>
				</Box>
			</Grid>

			<Hidden lgDown>
				<Grid item lg={2.2}>
					<Box p={2}>
						<Skeleton width={'60%'} />
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
					</Box>
				</Grid>
			</Hidden>
		</StyledApplicationFormSkeleton>
	);
}

export default ApplicationFormSkeleton;
