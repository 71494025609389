import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik/dist/types';
import { useCallback } from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { LoginFormValues } from '@components/forms/login/interfaces';

function useLogin() {
	const { startRequest } = useTriggeredRequest(AccountClientRequests.Auth.login);

	const initialValues: LoginFormValues = { email: '', password: '', loginError: '' };

	const onSubmit = useCallback(
		async (values: LoginFormValues, formikHelpers: FormikHelpers<LoginFormValues>) => {
			try {
				await startRequest({
					email: values.email,
					password: values.password,
				});
			} catch (e) {
				const { response } = e as AxiosError<{ messages: string[] }>;

				formikHelpers.setErrors({
					loginError: response?.data?.messages?.join(' ') || 'Oops. Something went wrong.',
				});
			}
		},
		[startRequest],
	);

	return {
		initialValues,
		onSubmit,
	};
}

export default useLogin;
