import { Grid, styled } from '@mui/material';

export const ApplicationFormSectionGrid = styled(Grid)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
	padding: theme.spacing(1, 2, 1, 2),
	background: isSelected ? theme.palette.primary.light : '',
}));

export const ApplicationFormSectionStatus = styled('div')<{ status: string }>(({ status, theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 32,
	height: 24,
	borderRadius: 8,
	textAlign: 'center',
	letterSpacing: '0.04em',
	flexShrink: 0,
	marginRight: 16,
	fontSize: 14,
	lineHeight: '17px',
	background:
		(status === 'error' && theme.palette.error.main) ||
		(status === 'completed' && theme.palette.success.main) ||
		theme.palette.background.default,
}));
