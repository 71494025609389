import { formatDistance, isValid, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { format, utcToZonedTime } from 'date-fns-tz';
import { camelCase, isArray, isString, startCase } from 'lodash';
import { v4 } from 'uuid';
import { divide, formatPercentage } from './numberUtils';
import qs from 'qs';
import parse from 'html-react-parser';
import { getCurrentTheme } from '../themes';

export function getPublicPath() {
    return window.location.protocol + '//' + window.location.host;
}

export function resolveUrl(url: string) {
    return getPublicPath() + (url.startsWith('/') ? '' : '/') + url;
}

export function zeroPad(num: number, size: number): string {
    let string = num + '';

    while (string.length < size) {
        string = '0' + string;
    }

    return string;
}

export const getUuid = v4;

export function titleCase(value: string): string {
    return startCase(camelCase(value));
}

export function escapeHtml(content: string, headSpace = 0) {
    return '&nbsp;'.repeat(headSpace) + content.replace(/\n/g, `<br/>${' '.repeat(headSpace)}`).replace(/ /g, '&nbsp;');
}

export function parseOptionSymbol(optionSymbol: string) {
    try {
        // the length of an option symbol has 21 chars
        if (optionSymbol.length < 21) {
            return null;
        }

        const symbol = optionSymbol.substr(0, 6).trim();
        const dateString = optionSymbol.substr(6, 6);

        const date = new Date(
            Number('20' + dateString.substr(0, 2)),
            Number(dateString.substr(2, 2)) - 1,
            Number(dateString.substr(4, 2)),
        );
        const expiration = date;
        const call = optionSymbol[12] === 'C';
        const strike = divide(Number(optionSymbol.substr(13)), 1000);

        return {
            symbol,
            expiration,
            call,
            strike,
        };
    } catch {
        return null;
    }
}

export function formatDate(
    input: string | number | Date,
    {
        short = false,
        pattern: initialPattern,
        withTime = false,
        distance = false,
        timeZone = 'America/New_York',
        locale = enUS,
    }: {
        short?: boolean;
        pattern?: string;
        withTime?: boolean;
        distance?: boolean;
        timeZone?: string;
        locale?: Locale;
    } = {},
) {
    let pattern = withTime ? 'MMM dd, yyyy HH:mm:ss' : 'MMM dd, yyyy';
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (!input) {
        return '-';
    }

    if (short) {
        pattern = pattern.replace(', yyyy', '');
    }

    if (distance) {
        return formatDistance(
            new Date(isString(input) && isSafari && isValid(parseISO(input)) ? parseISO(input) : input),
            new Date(),
            {
                addSuffix: true,
            },
        );
    }

    return format(utcToZonedTime(new Date(input), timeZone), initialPattern || pattern, { timeZone, locale });
}

type FieldNames<Names extends string, Prefix extends string = ''> = {
    [Name in Names]: Prefix extends '' ? `${Name}` : `${Prefix}-${Name}`;
};
export function generateNamesObject<Names extends string>(name: Names, ...args: Names[]): FieldNames<Names>;
export function generateNamesObject<Names extends string, Prefix extends string = ''>(
    fields: readonly Names[],
    prefix?: Prefix,
): FieldNames<Names, Prefix>;
export function generateNamesObject<Names extends string, Prefix extends string = ''>(
    ...args: any
): FieldNames<Names, Prefix> {
    let names: Names[] = [];
    let prefix: Prefix | undefined;

    if (isArray(args[0])) {
        names = args[0];
        prefix = args[1];
    } else {
        names = args;
    }

    return names.reduce((result, name) => {
        result[name] = (prefix ? `${prefix}-${name}` : `${name}`) as any;
        return result;
    }, {} as FieldNames<Names, Prefix>);
}

export function getRouteWithParams(route: string, params: Record<string, string | number>): string {
    let newRoute = route;

    Object.keys(params).forEach((key) => {
        newRoute = newRoute.replace(`:${key}`, qs.stringify({ param: params[key] }).replace('param=', ''));
    });

    return newRoute;
}

export const getFraction = (() => {
    //https://github.com/yisibl/num2fraction/blob/master/index.js

    function almostEq(a: number, b: number) {
        return Math.abs(a - b) <= 9.5367432e-7;
    }

    function GCD(a: number, b: number): number {
        if (almostEq(b, 0)) {
            return a;
        }
        return GCD(b, a % b);
    }

    function findPrecision(n: number) {
        let e = 1;

        while (!almostEq(Math.round(n * e) / e, n)) {
            e *= 10;
        }

        return e;
    }

    function num2fraction(num: number | string) {
        if (num === 0 || num === '0') {
            return '0';
        }

        if (typeof num === 'string') {
            num = parseFloat(num);
        }

        const precision = findPrecision(num);
        const number = num * precision;
        const gcd = Math.abs(GCD(number, precision));

        const numerator = number / gcd;
        const denominator = precision / gcd;

        return Math.round(numerator) + ':' + Math.round(denominator);
    }

    return (value: number | string) => num2fraction(value);
})();

export function formatPhoneNumber(phoneNumber: string | undefined, specialChars: boolean) {
    if (phoneNumber === undefined) {
        return;
    }
    if (!specialChars) {
        // return only numbers
        return Number(phoneNumber.replace(/[^\w\s]/gi, ''));
    } else {
        //normalize string and remove all unnecessary characters
        phoneNumber = phoneNumber.replace(/[^\d]/g, '');

        //check if number length equals to 10
        if (phoneNumber.length === 10) {
            //reformat and return phoneNumber number
            return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        } else if (phoneNumber.length === 11) {
            return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
        }
        return null;
    }
}

export const sentenceCase = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
};

export const censor = (value: string, numChar: number, head: boolean) => {
    const regex = new RegExp(`${head ? '^' : ''}.{${numChar}}${!head ? '$' : ''}`);
    return value.replace(regex, '*'.repeat(numChar));
};

export const parseHtml = (articleBody: string, quotes?: { symbol: string; changePercent: number }[]) => {
    if (quotes) {
        return parse(
            articleBody.replace(/\((NYSE|Nasdaq|OTC): ([A-Z0-9]+)\)/gi, (match, exchange, symbol) => {
                const quote = quotes?.find((quote) => quote.symbol === symbol);

                if (!quote) {
                    return match;
                }

                return `(
                    <span data-symbol="${symbol}" style="color:${
                    quote.changePercent > 0
                        ? getCurrentTheme().palette.market.up
                        : getCurrentTheme().palette.market.down
                }
                    ;display: 'flex'; justify-content: 'center'; cursor: pointer;">
                    <a style="color:${getCurrentTheme().palette.primary.main};font-weight:700">${symbol}:&nbsp;</a>
                    ${formatPercentage(quote.changePercent, { withChange: true })}</span>
                )`;
            }),
        );
    } else {
        return parse(articleBody);
    }
};
