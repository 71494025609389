import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { PickersActionBar } from '@mui/x-date-pickers/PickersActionBar';
import React from 'react';

export type ClearableDatePickerProps<TInputDate, TDate> = Omit<DatePickerProps<TInputDate, TDate>, 'renderInput'> & {
    clearable?: boolean;
    dateFormat?: string;
    onClear?: () => void;
    error?: string | false;
    InputProps?: Partial<TextFieldProps>;
};

export const ClearableDatePicker = <TInputDate = number | Date, TDate = Date>({
    clearable,
    error,
    dateFormat = 'MMM dd, yyyy',
    onClear,
    InputProps,
    ...rest
}: ClearableDatePickerProps<TInputDate, TDate>) => {
    return (
        <DatePicker<TInputDate, TDate>
            disableMaskedInput
            inputFormat={dateFormat}
            componentsProps={{
                actionBar: {
                    actions: clearable ? ['clear'] : undefined,
                },
            }}
            components={{
                // FIXME: need to override default action bar because there is no way to use `onClear` callback
                ActionBar: (props) => {
                    return (
                        <PickersActionBar
                            {...props}
                            onClear={() => {
                                props.onClear();
                                onClear?.();
                            }}
                        />
                    );
                },
            }}
            renderInput={(props) => (
                <TextField
                    fullWidth
                    variant="outlined"
                    {...props}
                    {...InputProps}
                    error={!!error}
                    helperText={error ?? props.helperText}
                />
            )}
            {...rest}
        />
    );
};
