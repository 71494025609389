import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import THEME_ENUM from '@api/requests/assets/enums/THEME_ENUM.enum';
import configRequest from '@api/requests/configRequest';

export type SetCurrentThemeData = {
	value: THEME_ENUM;
};

export interface SetCurrentThemeResponse {
	setting: {
		key: 'theme';
		value: [THEME_ENUM];
	};
}

const setCurrentTheme = configRequest<SetCurrentThemeResponse, SetCurrentThemeData>({
	requestPrefix: 'assets/setCurrentTheme',
	reqConfigCreator: data => {
		const { value } = data as SetCurrentThemeData;

		return {
			url: ACCOUNT_CLIENT_API_ENDPOINTS.SETTINGS.SET(),
			method: HTTP_METHODS_ENUM.POST,
			data: {
				key: 'theme',
				value: [value],
			},
		};
	},
});

export default setCurrentTheme;
