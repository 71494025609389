import THEME_ENUM from '@api/requests/assets/enums/THEME_ENUM.enum';

import { Box, styled } from '@mui/material';

import { DESKTOP_LOGO_MAP, MOBILE_LOGO_MAP } from '@components/logo/constants';

export const AppLogo = styled(Box)<{ firm: string; mode: THEME_ENUM.DARK | THEME_ENUM.LIGHT }>(
	({ mode, firm, theme }) => ({
		backgroundImage: `url(${DESKTOP_LOGO_MAP.get(firm + '_' + mode)})`,
		backgroundPosition: 'center center',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		width: 120,
		height: 40,
		[theme.breakpoints.down('sm')]: {
			width: theme.spacing(6),
			height: theme.spacing(6),
			backgroundImage: `url(${MOBILE_LOGO_MAP.get(firm + '_' + mode)})`,
		},
	}),
);
