import React, { useRef } from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { showToast } from '@app/providers/ToastProvider';

function useTextFieldApplicationForm({
	applicationId,
	questionId,
	initialValue,
}: {
	applicationId: number;
	questionId: number;
	initialValue: string;
}) {
	const textFieldRef = useRef<HTMLInputElement | null>(null);

	const { isPending: isPendingSaveAnswer, startRequest: saveTextAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.saveTextAnswer,
	);

	const { isPending: isPendingDeleteAnswer, startRequest: deleteAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.deleteAnswer,
	);

	const handleSaveAnswer = async ({
		target: { value: answer },
	}: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
		if (answer !== initialValue) {
			try {
				await saveTextAnswer({
					application_id: applicationId,
					question_id: questionId,
					data: answer || '',
				});
			} catch (e) {
				showToast.error('Something went wrong!');
			}
		}
	};

	const handleDeleteAnswer = async (clearField: () => void) => {
		try {
			await deleteAnswer({
				application_id: applicationId,
				question_id: questionId,
			});

			if (textFieldRef.current) textFieldRef.current.value = '';
			clearField();
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	return {
		loading: isPendingSaveAnswer || isPendingDeleteAnswer,
		handleSaveAnswer,
		handleDeleteAnswer,
		textFieldRef,
	};
}

export default useTextFieldApplicationForm;
