import { useCallback } from 'react';
import { useHistory } from 'react-router';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';
import ApplicationApplicantInterface from '@api/requests/applications/interfaces/ApplicationApplicantInterface.interface';

import { showToast } from '@app/providers/ToastProvider';
import { store } from '@app/redux/store';
import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import useFetchApplicationForm from '@hooks/useFetchApplicationForm';

import * as environment from '@utils/environment';
import { isEnableSSO } from '@utils/environment';

function checkVerifyDocuments({
	applicantType,
	applicants,
}: {
	applicantType: string;
	applicants: ApplicationApplicantInterface[];
}) {
	return Boolean(
		applicants.filter(
			({ type, latest_background_check }) => type === applicantType && latest_background_check.status === 'success',
		).length,
	);
}

function useSubmitApplicationForm({
	applicationId,
	formId,
	formVersion,
	runBackground,
}: {
	applicationId: number;
	formId: number;
	formVersion?: number | null;
	runBackground: boolean;
}) {
	const history = useHistory();
	const generateRoutePath = useGenerateRoutePath();

	const { isPending: isSubmitting, startRequest: submitApplicationForm } = useTriggeredRequest(
		AccountClientRequests.Applications.submitApplicationForm,
	);

	const { isPending: isBackgroundCheck, startRequest: backgroundCheckApplicationForm } = useTriggeredRequest(
		AccountClientRequests.Applications.backgroundCheckApplicationForm,
	);

	const { isPending: isVerifyDocument, startRequest: verifyDocumentApplicationForm } = useTriggeredRequest(
		AccountClientRequests.Applications.verifyDocumentApplicationForm,
	);

	const { isPending: isCustodianCheck, startRequest: custodianCheckApplicationForm } = useTriggeredRequest(
		AccountClientRequests.Applications.custodianCheckApplicationForm,
	);

	const { fetchApplicationForm, isLoading: isCustodianCompleting } = useFetchApplicationForm();

	const handleSubmitApplicationForm = useCallback(async () => {
		try {
			await submitApplicationForm({ application_id: applicationId });

			showToast.success('Form has been submitted successfully');

			if (process.env.FIRM_ENVIRONMENT === 'bosonic' || process.env.FIRM_ENVIRONMENT === 'secondlane') {
				const token = store.getState().auth.tokens?.access_token;
				if (process.env.BUILD_ENVIRONMENT === 'production') {
					window.open(`https://onboarding.bosonic.digital/signing/login?id=${applicationId}&t=${token}`, '_blank');
				} else {
					window.open(
						`https://onboarding-qa.bosonicnetwork.com/signing/login?id=${applicationId}&t=${token}`,
						'_blank',
					);
				}
			}

			if (runBackground) {
				try {
					const {
						application: { applicants },
					} = await backgroundCheckApplicationForm({ application_id: applicationId });

					const backgroundCheckResults = await Promise.all(
						applicants
							.filter(
								({ latest_background_check }) =>
									latest_background_check.status !== 'success' &&
									latest_background_check.response?.check_status === 'DOCUMENTATION_REQUIRED',
							)
							.map(async applicant => {
								const res = await verifyDocumentApplicationForm({
									application_id: applicationId,
									applicant_type: applicant.type,
								});

								return checkVerifyDocuments({ applicantType: applicant.type, applicants: res.application.applicants });
							}),
					);

					const isBackgroundCheckPassed = backgroundCheckResults.every(Boolean);

					if (isBackgroundCheckPassed) showToast.success('Background check has been passed successfully');

					if (isBackgroundCheckPassed && environment.env.FIRM_ENVIRONMENT !== 'lightspeed') {
						const res = await custodianCheckApplicationForm({ application_id: applicationId });

						if (res.application.current_custodian_request.status === 'success' && isEnableSSO()) {
							await fetchApplicationForm({ applicationId, formId, formVersion });
						}
					}
				} catch (e) {
					//showToast.error('Background check failed.');
				}
			}

			history.push(generateRoutePath(RoutePaths.MAIN.APPLICATIONS));
		} catch (e) {
			showToast.error('Form contains errors that need to be fixed before the application can be submitted');
		}
	}, [
		applicationId,
		backgroundCheckApplicationForm,
		custodianCheckApplicationForm,
		fetchApplicationForm,
		formId,
		formVersion,
		generateRoutePath,
		history,
		submitApplicationForm,
		verifyDocumentApplicationForm,
	]);

	return {
		isSubmitting: isSubmitting || isBackgroundCheck || isVerifyDocument || isCustodianCheck || isCustodianCompleting,
		handleSubmitApplicationForm,
	};
}

export default useSubmitApplicationForm;
