import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import { unstable_useControlled as useControlled } from '@mui/utils';
import useEventCallback from '@mui/utils/useEventCallback';
import { useOpenState } from '../useOpenState';
import { useLocalizationContext, useUtils } from '../useUtils';
import { useValidation } from '../validation/useValidation';

/**
 * Props used to handle the value that are common to all pickers.
 */

/**
 * Props used to handle the value of non-static pickers.
 */

/**
 * Props used to handle the value of the pickers.
 */

/**
 * Props passed to `usePickerViews`.
 */

/**
 * Props passed to `usePickerLayoutProps`.
 */

/**
 * Manage the value lifecycle of all the pickers.
 */
export const usePickerValue = ({
  props,
  valueManager,
  wrapperVariant,
  validator
}) => {
  const {
    onAccept: onAcceptProp,
    onChange,
    value: inValue,
    defaultValue,
    closeOnSelect = wrapperVariant === 'desktop',
    selectedSections: selectedSectionsProp,
    onSelectedSectionsChange
  } = props;
  const utils = useUtils();
  const adapter = useLocalizationContext();
  const [value, setValue] = useControlled({
    controlled: inValue,
    default: defaultValue != null ? defaultValue : valueManager.emptyValue,
    name: 'usePickerValue',
    state: 'value'
  });
  const [selectedSections, setSelectedSections] = useControlled({
    controlled: selectedSectionsProp,
    default: null,
    name: 'usePickerValue',
    state: 'selectedSections'
  });
  const {
    isOpen,
    setIsOpen
  } = useOpenState(props);
  const [dateState, setDateState] = React.useState(() => ({
    committed: value,
    draft: value,
    resetFallback: value
  }));
  useValidation(_extends({}, props, {
    value
  }), validator, valueManager.isSameError, valueManager.defaultErrorState);
  const setDate = useEventCallback(params => {
    setDateState(prev => {
      switch (params.action) {
        case 'setAll':
        case 'acceptAndClose':
          {
            return {
              draft: params.value,
              committed: params.value,
              resetFallback: params.value
            };
          }
        case 'setCommitted':
          {
            return _extends({}, prev, {
              draft: params.value,
              committed: params.value
            });
          }
        case 'setDraft':
          {
            return _extends({}, prev, {
              draft: params.value
            });
          }
        default:
          {
            return prev;
          }
      }
    });
    if (!params.skipOnChangeCall && !valueManager.areValuesEqual(utils, dateState.committed, params.value)) {
      setValue(params.value);
      if (onChange) {
        const context = {
          validationError: params.contextFromField == null ? validator({
            adapter,
            value: params.value,
            props: _extends({}, props, {
              value: params.value
            })
          }) : params.contextFromField.validationError
        };
        onChange(params.value, context);
      }
    }
    if (params.action === 'acceptAndClose') {
      setIsOpen(false);
      if (onAcceptProp && !valueManager.areValuesEqual(utils, dateState.resetFallback, params.value)) {
        onAcceptProp(params.value);
      }
    }
  });
  React.useEffect(() => {
    if (isOpen) {
      // Update all dates in state to equal the current prop value
      setDate({
        action: 'setAll',
        value,
        skipOnChangeCall: true
      });
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  // Set the draft and committed date to equal the new prop value.
  if (!valueManager.areValuesEqual(utils, dateState.committed, value)) {
    setDate({
      action: 'setCommitted',
      value,
      skipOnChangeCall: true
    });
  }
  const handleClear = useEventCallback(() => {
    // Reset all date in state to the empty value and close picker.
    setDate({
      value: valueManager.emptyValue,
      action: 'acceptAndClose'
    });
  });
  const handleAccept = useEventCallback(() => {
    // Set all date in state to equal the current draft value and close picker.
    setDate({
      value: dateState.draft,
      action: 'acceptAndClose'
    });
  });
  const handleDismiss = useEventCallback(() => {
    // Set all dates in state to equal the last committed date.
    // e.g. Reset the state to the last committed value.
    setDate({
      value: dateState.committed,
      action: 'acceptAndClose'
    });
  });
  const handleCancel = useEventCallback(() => {
    // Set all dates in state to equal the last accepted date and close picker.
    // e.g. Reset the state to the last accepted value
    setDate({
      value: dateState.resetFallback,
      action: 'acceptAndClose'
    });
  });
  const handleSetToday = useEventCallback(() => {
    // Set all dates in state to equal today and close picker.
    setDate({
      value: valueManager.getTodayValue(utils),
      action: 'acceptAndClose'
    });
  });
  const handleOpen = useEventCallback(() => setIsOpen(true));
  const handleClose = useEventCallback(() => setIsOpen(false));
  const handleChange = useEventCallback((newDate, selectionState = 'partial') => {
    switch (selectionState) {
      case 'shallow':
        {
          // Update the `draft` state but do not fire `onChange`
          return setDate({
            action: 'setDraft',
            value: newDate,
            skipOnChangeCall: true
          });
        }
      case 'partial':
        {
          // Update the `draft` state and fire `onChange`
          return setDate({
            action: 'setDraft',
            value: newDate
          });
        }
      case 'finish':
        {
          if (closeOnSelect) {
            // Set all dates in state to equal the new date and close picker.
            return setDate({
              value: newDate,
              action: 'acceptAndClose'
            });
          }

          // Updates the `committed` state and fire `onChange`
          return setDate({
            value: newDate,
            action: 'setCommitted'
          });
        }
      default:
        {
          throw new Error('MUI: Invalid selectionState passed to `onDateChange`');
        }
    }
  });
  const handleChangeAndCommit = useEventCallback((newValue, contextFromField) => setDate({
    action: 'setCommitted',
    value: newValue,
    contextFromField
  }));
  const handleFieldSelectedSectionsChange = useEventCallback(newSelectedSections => {
    setSelectedSections(newSelectedSections);
    onSelectedSectionsChange == null ? void 0 : onSelectedSectionsChange(newSelectedSections);
  });
  const actions = {
    onClear: handleClear,
    onAccept: handleAccept,
    onDismiss: handleDismiss,
    onCancel: handleCancel,
    onSetToday: handleSetToday,
    onOpen: handleOpen,
    onClose: handleClose
  };
  const fieldResponse = {
    value: dateState.draft,
    onChange: handleChangeAndCommit,
    selectedSections,
    onSelectedSectionsChange: handleFieldSelectedSectionsChange
  };
  const viewValue = React.useMemo(() => valueManager.cleanValue(utils, dateState.draft), [utils, valueManager, dateState.draft]);
  const viewResponse = {
    value: viewValue,
    onChange: handleChange,
    onClose: handleClose,
    open: isOpen,
    onSelectedSectionsChange: handleFieldSelectedSectionsChange
  };
  const isValid = testedValue => {
    const error = validator({
      adapter,
      value: testedValue,
      props: _extends({}, props, {
        value: testedValue
      })
    });
    return !valueManager.hasError(error);
  };
  const layoutResponse = _extends({}, actions, {
    value: viewValue,
    onChange: handleChangeAndCommit,
    isValid
  });
  return {
    open: isOpen,
    fieldProps: fieldResponse,
    viewProps: viewResponse,
    layoutProps: layoutResponse,
    actions
  };
};