import {
    AsyncThunk,
    AsyncThunkOptions,
    AsyncThunkPayloadCreator,
    createAsyncThunk as createAsyncThunkToolkit,
    Dispatch,
    miniSerializeError,
    SerializedError,
} from '@reduxjs/toolkit';
import { isBoolean, merge } from 'lodash';
import { ACTION_TYPE_PREFIX, AsyncActionStatus } from '../constants';
import { getUuid } from '../utils';
import { dismissLoader, showAlertDialog, showLoader } from './alertActions';
import { LoadingMessage } from './alertReducer';
import { selectAsyncActionStatus } from './selectors';

export type ThunkArgMeta = {
    _actionTypeExtra?: string | number;
    _pendingMessage?: string | boolean;
    _alertIfError?: boolean;
};

export type AsyncThunkConfig = {
    state?: unknown;
    dispatch?: Dispatch;
    extra?: unknown;
    rejectValue?: unknown;
    serializedErrorType?: unknown;
    pendingMeta?: unknown;
    fulfilledMeta?: unknown;
    rejectedMeta?: unknown;
};

export type AsyncThunkArgs<Result, ThunkArg, ThunkApiConfig extends AsyncThunkConfig = {}> = {
    thunkArgMeta?: ThunkArgMeta;
    actionType: string;
    action: AsyncThunkPayloadCreator<Result, ThunkArg, ThunkApiConfig>;
};

export type CreateAsyncThunkReturnType<Result, ThunkArg, ThunkApiConfig extends AsyncThunkConfig> = AsyncThunk<
    Result,
    ThunkArg extends void
        ? void | ThunkArgMeta
        : ThunkArg extends Record<string, any>
        ? ThunkArg & ThunkArgMeta
        : ThunkArg | ({ _arg: ThunkArg } & ThunkArgMeta),
    ThunkApiConfig
>;

export function createAsyncThunk<Result, ThunkArg = void | ThunkArgMeta, ThunkApiConfig extends AsyncThunkConfig = {}>(
    { thunkArgMeta = {}, actionType, action }: AsyncThunkArgs<Result, ThunkArg, ThunkApiConfig>,
    options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig> & {
        // Extra configs
        onErrorAlert?: (error: SerializedError) => void;
    },
): CreateAsyncThunkReturnType<Result, ThunkArg, ThunkApiConfig> & {
    type: string;
    selectStatus: (state: any, extra?: string | number) => AsyncActionStatus;
} {
    const actionTypeWithPrefix = `${ACTION_TYPE_PREFIX}/${actionType}`;
    const asyncThunk = createAsyncThunkToolkit<Result, ThunkArg, ThunkApiConfig>(
        actionTypeWithPrefix,
        async (arg, thunkApi) => {
            const { _alertIfError, _pendingMessage } = merge({}, thunkArgMeta, arg);

            const pendingMessage: LoadingMessage | null = !_pendingMessage
                ? null
                : {
                      id: getUuid(),
                      content: isBoolean(_pendingMessage) ? 'Loading…' : _pendingMessage,
                  };

            if (pendingMessage) {
                thunkApi.dispatch(showLoader(pendingMessage));
            }

            try {
                const finalArg = arg !== undefined ? (arg as any)._arg ?? arg : arg;
                return (await Promise.resolve(action(finalArg, thunkApi))) as any;
            } catch (error) {
                if ((_alertIfError === undefined && !!pendingMessage) || _alertIfError) {
                    const serializedError = options?.serializeError?.(error) || miniSerializeError(error);
                    if (options?.onErrorAlert) {
                        options.onErrorAlert(serializedError);
                    } else {
                        thunkApi.dispatch(
                            showAlertDialog({
                                title: serializedError.name || '',
                                content: serializedError.message || '',
                            }),
                        );
                    }
                }

                // eslint-disable-next-line no-console
                // console.error(`Action error: ${actionType}\n`, error);
                return thunkApi.rejectWithValue(error as any, undefined as any);
            } finally {
                if (pendingMessage) {
                    thunkApi.dispatch(dismissLoader(pendingMessage.id));
                }
            }
        },
        options,
    );

    const asyncThunkWrapper = ((arg: ThunkArg) => {
        const asyncThunkAction = asyncThunk(arg);
        return Object.assign(asyncThunkAction, { arg, type: actionType });
    }) as unknown as CreateAsyncThunkReturnType<Result, ThunkArg, ThunkApiConfig>;

    return Object.assign(asyncThunkWrapper, asyncThunk, {
        type: actionType,
        selectStatus: (state: any, extra?: string | number) =>
            selectAsyncActionStatus(state, { type: actionTypeWithPrefix, extra }),
    });
}
