import { FormTextField } from '@orbis-systems/orbis-react-toolkit';
import { ErrorMessage } from 'formik';
import React from 'react';

import { ButtonBase, CircularProgress, Grid, Typography } from '@mui/material';

import Form from '@components/forms';
import SubmitButton from '@components/submitButton';

import { Counter } from '@pages/Auth/ForgotPasswordPage/containers/ResetPassword/styles';

import { USER_PROFILE_STEPS_ENUM } from '../../../containers/UserProfile/userProfileViewsFactory';
import useVerifyEmailForm from './hooks';
import { VerifyEmailFormProps } from './interfaces';
import { BackSubmitButton } from './styles';

function VerifyEmailForm({ initialValues, onSubmit, setCurrentStep }: VerifyEmailFormProps) {
	const { isSendingVerificationEmailCode, handleSendVerificationEmailCode, minutes, seconds, formik } =
		useVerifyEmailForm({
			initialValues,
			onSubmit,
		});

	return (
		<Form formik={formik}>
			<Grid container mb={1}>
				<Counter item xs={12} p={3}>
					{isSendingVerificationEmailCode ? (
						<CircularProgress size={42} />
					) : (
						<Typography variant="h4">
							<b>
								{minutes || seconds
									? `${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`
									: '00:00'}
							</b>
						</Typography>
					)}
				</Counter>

				<Grid item xs={12} textAlign={'right'}>
					<ButtonBase onClick={handleSendVerificationEmailCode} disabled={isSendingVerificationEmailCode}>
						<Typography variant="body2">
							<b>Resend code?</b>
						</Typography>
					</ButtonBase>
				</Grid>

				<Grid
					item
					xs={12}
					mt={2}
					sx={{
						color: theme => theme.palette.error.main,
						textAlign: 'center',
						fontSize: 14,
					}}
				>
					<ErrorMessage name="resendVerificationEmailCodeError" />
				</Grid>

				<Grid item xs={12} mt={4}>
					<FormTextField
						name={'code'}
						label={'Verification code'}
						autoComplete="off"
						disabled={formik.isSubmitting || isSendingVerificationEmailCode}
					/>
				</Grid>

				<Grid item xs={12} mt={4}>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<BackSubmitButton
								fullWidth
								type="button"
								variant="contained"
								color="secondary"
								onClick={() => setCurrentStep(USER_PROFILE_STEPS_ENUM.INFO)}
								disabled={formik.isSubmitting || isSendingVerificationEmailCode}
							>
								Back
							</BackSubmitButton>
						</Grid>

						<Grid item xs={6}>
							<SubmitButton label={'Verify'} disabled={formik.isSubmitting || isSendingVerificationEmailCode} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Form>
	);
}

export default VerifyEmailForm;
