import { FormInterface, FormQuestionInterface } from '@api/requests/forms/interfaces/FormInterface.interface';

function getFormQuestions(formDetails: FormInterface) {
	const questions: Record<number, FormQuestionInterface> = {};

	formDetails.pages.forEach(page => {
		page.sections.forEach(section => {
			section.questions.forEach(question => {
				questions[question.id] = question;
			});
		});
	});

	return questions;
}

export default getFormQuestions;
