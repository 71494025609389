import { Box, styled } from '@mui/material';

export const UploadedFileBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.dark,
	padding: theme.spacing(1, 2),
	borderRadius: 4,
	display: 'inline-block',
	fontSize: 12,
	fontWeight: 500,
}));
