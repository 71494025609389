import { useToggle } from '@orbis-systems/orbis-react-toolkit';
import React from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Button, ClickAwayListener, Drawer, Grid, Stack, Typography } from '@mui/material';

import ApplicationFormNavigation from '@pages/ApplicationForm/containers/ApplicationFormNavigation';

function ApplicationFormMobileAppBar() {
	const [navigationOpen, toggleNavigation] = useToggle(false);

	return (
		<React.Fragment>
			<Grid item xs={3}>
				<Button
					disableTouchRipple
					color="inherit"
					onClick={() => toggleNavigation(true)}
					sx={{ m: 2, p: 1, width: 200 }}
				>
					<MenuIcon color="inherit" />

					<Typography variant={'body1'} sx={{ textTransform: 'initial' }} ml={1}>
						<b>Application Sections</b>
					</Typography>
				</Button>
			</Grid>

			<Drawer
				open={navigationOpen}
				anchor="left"
				PaperProps={{
					sx: {
						top: '64px',
						backgroundImage: 'none',
						height: 'calc(100% - 64px)',
						width: { xs: '80%', sm: 'auto' },
					},
				}}
			>
				<ClickAwayListener onClickAway={() => toggleNavigation(false)}>
					<Stack sx={{ height: '100%' }}>
						<Grid item xs={12} md={4} lg={2.8} sx={{ minHeight: 'calc(100vh - 64px)' }}>
							<ApplicationFormNavigation />
						</Grid>
					</Stack>
				</ClickAwayListener>
			</Drawer>
		</React.Fragment>
	);
}

export default ApplicationFormMobileAppBar;
