import React from 'react';

import { getIsLoggedIn } from '@app/redux/auth/auth.selectors';
import { useAppSelector } from '@app/redux/hooks';
import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { AnimatedRedirect } from '@components/transition/route';

function OnlyAuthorizedRoute({ children }: { children: JSX.Element }) {
	const generateRoutePath = useGenerateRoutePath();
	const isLoggedIn = useAppSelector(getIsLoggedIn);

	return isLoggedIn ? children : <AnimatedRedirect to={generateRoutePath(RoutePaths.AUTH.LOGIN)} />;
}

export default OnlyAuthorizedRoute;
