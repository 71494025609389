import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import AssetsCountryInterface from '@api/requests/assets/interfaces/AssetsCountryInterface.interface';
import configRequest from '@api/requests/configRequest';

export type GetCountriesListData = void;

export interface GetCountriesListResponse {
	countries: AssetsCountryInterface[];
}

const getCountriesList = configRequest<GetCountriesListResponse, GetCountriesListData>({
	requestPrefix: 'assets/getCountriesList',
	reqConfigCreator: () => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.ASSETS.COUNTRIES.LIST(),
		method: HTTP_METHODS_ENUM.POST,
	}),
});

export default getCountriesList;
