import { cloneDeep } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { FormSelect, FormSelectOption, FormSelectProps, renderSelectOptions } from '../../FormControls';
import { fieldNames } from '../schema';
import { orderTypeOptions, spreadOrderTypeOptions } from '../selectOptions';
import { TradingFormContext } from '../TradingFormContext';

export type OrderTypeSelectProps = Partial<FormSelectProps>;

export const OrderTypeSelect: React.FunctionComponent<OrderTypeSelectProps> = (props) => {
    const {
        options: { allowFractional, allowTrailingStop },
        meta: { optionTrading, spreadTrading },
    } = useContext(TradingFormContext);
    const orderTypes = useMemo(() => {
        const result: Partial<typeof orderTypeOptions> = cloneDeep(orderTypeOptions);

        if (!allowFractional || optionTrading) {
            delete result.notional;
        }

        if (!allowTrailingStop) {
            delete result.trailingStop;
        }

        return Object.values(result) as FormSelectOption[];
    }, [optionTrading]);

    return (
        <FormSelect label="Order Type" size="small" {...props} name={fieldNames.orderType}>
            {renderSelectOptions(spreadTrading ? Object.values(spreadOrderTypeOptions) : orderTypes)}
        </FormSelect>
    );
};
