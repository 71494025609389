import { useFormikContext } from 'formik';
import React from 'react';
import { TradingFormValues } from '../schema';
import { FormNumberField, FormNumberFieldProps } from '../../FormControls';
import { MutualFundUnit } from '../lookups';
import { fieldNames } from '../schema';

export type MutualFundValueSelectProps = Partial<FormNumberFieldProps>;

export const MutualFundValueSelect: React.FunctionComponent<MutualFundValueSelectProps> = (props) => {
    const { values } = useFormikContext<TradingFormValues>();
    return (
        <FormNumberField
            label={values.mutualFundUnit === MutualFundUnit.Unit ? 'Quantity' : 'Value'}
            size="small"
            {...props}
            name={fieldNames.mutualFundValue}
        />
    );
};
