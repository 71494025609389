import { ThemeOptions } from '@mui/material';
import { createTheme } from './createTheme';

export const omniThemeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#1448C4',
        },
        neutral: {
            main: '#323F52',
            contrastText: '#fff',
        },
        secondary: {
            main: '#3D4056',
        },
        background: {
            default: '#F5F8FC',
        },
    },
};

export const omniTheme = createTheme(omniThemeOptions);
