import { Search } from '@mui/icons-material';
import { Box, styled, formHelperTextClasses, selectClasses } from '@mui/material';
import React, { ReactNode, useContext } from 'react';
import {
    FormControlGroup,
    FormControlGroupProps,
    FormTextField,
    FormTextFieldProps,
    formControlGroupClasses,
    FormSelectOption,
    FormSelectProps,
    FormSelect,
    renderSelectOptions,
    formSelectClasses,
} from '../../FormControls';
import { fieldNames } from '../schema';
import { TradingFormContext } from '../TradingFormContext';

export type SymbolSelectProps = {
    symbol?: string;
    isMutualFund: boolean;
    isValid: boolean;
    children?: ReactNode;
    formControlGroupProps?: Partial<FormControlGroupProps>;
    formTextFieldProps?: Partial<FormTextFieldProps>;
    formSelectProps?: Partial<FormSelectProps>;
    symbols?: FormSelectOption[];
};

const StyledFormControlGroup = styled(FormControlGroup)(({ theme }) => ({
    [`& .${formControlGroupClasses.fieldset}`]: { top: 0 },
    [`& .${formControlGroupClasses.legend}`]: { padding: 0 },
    [`& .${formSelectClasses.smallSelect}`]: {
        margin: theme.spacing(-1),
    },
    [`& .${formSelectClasses.error}`]: {
        display: 'none !important',
    },
}));

const StyledFormTextField = styled(FormTextField)(({ theme }) => ({
    [`& .${formHelperTextClasses.root}`]: {
        display: 'none',
    },
    '& svg': {
        margin: theme.spacing(0, 1, 0, 0),
        transform: theme.rtl ? 'scaleX(-1)' : undefined,
    },
}));

export const SymbolSelect: React.FunctionComponent<SymbolSelectProps> = ({
    symbol,
    isMutualFund,
    isValid,
    children,
    formTextFieldProps,
    formControlGroupProps,
    formSelectProps,
    symbols,
}) => {
    const {
        meta: { mutualFundTrading },
    } = useContext(TradingFormContext);
    return (
        <StyledFormControlGroup
            label={undefined}
            row
            outlined
            fullWidth
            {...formControlGroupProps}
            name={fieldNames.symbol}
            validate={(value) => {
                let error = '';

                if (mutualFundTrading && !isMutualFund) {
                    error = 'Not a mutual fund symbol';
                }

                if (!mutualFundTrading && isMutualFund) {
                    error = 'Not an equity symbol';
                }

                if (!isValid) {
                    error = 'Not a valid symbol';
                }

                return error || formControlGroupProps?.validate?.(value);
            }}
        >
            {symbols ? (
                <FormSelect
                    label=""
                    variant="standard"
                    size="small"
                    {...formSelectProps}
                    name={fieldNames.symbol}
                    displayEmpty
                    // placeholder="Select Asset…"
                    disableUnderline={true}
                >
                    {renderSelectOptions(symbols)}
                </FormSelect>
            ) : (
                <StyledFormTextField
                    variant="standard"
                    placeholder="Symbol"
                    allCaps
                    size="small"
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: <Search />,
                    }}
                    {...formTextFieldProps}
                    value={symbol ?? ''}
                    name={fieldNames.symbol}
                />
            )}
            <Box sx={{ height: '100%', width: '100%' }}>{children}</Box>
        </StyledFormControlGroup>
    );
};
