import React from 'react';
import { FormSelect, FormSelectOption, FormSelectProps, renderSelectOptions } from '../../FormControls';
import { cryptoFieldNames as fieldNames } from '../schema';

export type CoinSelectProps = Partial<FormSelectProps> & {
    availableCoins: FormSelectOption[];
};

export const CoinSelect: React.FunctionComponent<CoinSelectProps> = ({ availableCoins, ...formSelectProps }) => {
    return (
        <FormSelect label="Coin" size="small" {...formSelectProps} name={fieldNames.coin}>
            {renderSelectOptions(availableCoins)}
        </FormSelect>
    );
};
