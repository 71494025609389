import React from 'react';
import { FormSelect, FormSelectProps, renderSelectOptions } from '../../FormControls';
import { cryptoFieldNames as fieldNames } from '../schema';
import { cryptoTransactionOptions } from '../selectOptions';

export type TransactionSelectProps = Partial<FormSelectProps>;

export const TransactionSelect: React.FunctionComponent<TransactionSelectProps> = (props) => {
    return (
        <FormSelect size={'small'} label="Transaction" {...props} name={fieldNames.transaction}>
            {renderSelectOptions(Object.values(cryptoTransactionOptions))}
        </FormSelect>
    );
};
