import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import configRequest from '@api/requests/configRequest';

import { showToast } from '@app/providers/ToastProvider';

import ApplicationInterface from './interfaces/ApplicationInterface.interface';

export interface CreateApplicationData {
	branch_id: number;
	initiator_id: number;
	form_id: number;
}

export interface CreateApplicationResponse {
	application: ApplicationInterface;
}

const createApplication = configRequest<CreateApplicationResponse, CreateApplicationData>({
	requestPrefix: 'applications/create',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.APPLICATION_FORM.CREATE(),
		method: HTTP_METHODS_ENUM.POST,
		data: {
			...data,
			with: [
				'current_status',
				'timestamps',
				'form.translations',
				'documents.current_status.timestamps',
				'documents.answer.question.translations',
			],
		},
	}),
	onSuccess: () => {
		showToast.success('Application has been created successfully');
	},
});

export default createApplication;
