import React from 'react';
import { FormMoneyField, FormNumberFieldProps } from '../../FormControls';
import { cryptoFieldNames as fieldNames } from '../schema';

export type OrderLimitPriceSelectProps = Partial<FormNumberFieldProps>;

export const OrderLimitPriceSelect: React.FunctionComponent<OrderLimitPriceSelectProps> = (props) => {
    return (
        <FormMoneyField label="Limit Price" size="small" variant={'standard'} {...props} name={fieldNames.limitPrice} />
    );
};
