import React from 'react';

import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { AnimatedRedirect } from '@components/transition/route';

import RegistrationPage from '@pages/Auth/RegistrationPage';

import { isAllowUserRegistration } from '@utils/environment';

function RegistrationProtectedRoute() {
	const generateRoutePath = useGenerateRoutePath();

	return isAllowUserRegistration() ? (
		<RegistrationPage />
	) : (
		<AnimatedRedirect to={generateRoutePath(RoutePaths.AUTH.LOGIN)} />
	);
}

export default RegistrationProtectedRoute;
