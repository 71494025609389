import { useCallback } from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';
import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';

import { ChangePasswordFormValues } from '@components/forms/changePassword/interfaces';

import { USER_PROFILE_STEPS_ENUM } from '../userProfileViewsFactory';

function useChangePassword({
	setCurrentStep,
	currentUser,
}: {
	setCurrentStep(value: USER_PROFILE_STEPS_ENUM): void;
	currentUser: CurrentUserInterface;
}) {
	const { startRequest } = useTriggeredRequest(AccountClientRequests.Users.changePassword);

	const initialValues: ChangePasswordFormValues = {
		old_password: '',
		password: '',
		password_confirmation: '',
	};

	const onSubmit = useCallback(
		async (values: ChangePasswordFormValues) => {
			await startRequest({
				user_id: currentUser.id,
				old_password: values.old_password,
				password: values.password,
				password_confirmation: values.password_confirmation,
			});

			setCurrentStep(USER_PROFILE_STEPS_ENUM.INFO);
		},
		[currentUser.id, setCurrentStep, startRequest],
	);

	return {
		initialValues,
		onSubmit,
	};
}

export default useChangePassword;
