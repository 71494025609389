import { FormSelect, FormTextField } from '@orbis-systems/orbis-react-toolkit';
import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Grid, MenuItem, Typography } from '@mui/material';

import Form from '@components/forms';
import useCurrentUserForm from '@components/forms/currentUser/hooks';
import { CurrentUserFormProps } from '@components/forms/currentUser/interfaces';
import { StyledDivider } from '@components/forms/currentUser/styles';
import SubmitButton from '@components/submitButton';

import { USER_PROFILE_STEPS_ENUM } from '../../../containers/UserProfile/userProfileViewsFactory';
import { BackSubmitButton } from './styles';

function CurrentUserForm({ initialValues, onSubmit, currentUser, setCurrentStep }: CurrentUserFormProps) {
	const { phoneCodeOptions, formik } = useCurrentUserForm({ initialValues, onSubmit });

	return (
		<Form formik={formik}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant={'subtitle1'}>
						<b>Profile</b>
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<FormTextField name={'first_name'} label={'First name'} autoComplete="off" disabled={formik.isSubmitting} />
				</Grid>

				<Grid item xs={12}>
					<FormTextField name={'last_name'} label={'Last name'} autoComplete="off" disabled={formik.isSubmitting} />
				</Grid>

				<Grid item xs={12}>
					<StyledDivider />
				</Grid>

				<Grid item xs={12}>
					<Typography variant={'subtitle1'}>
						<b>Contact Details</b>
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<Box>
						<FormTextField
							name={'email'}
							label={'Email'}
							autoComplete="off"
							disabled={Boolean(currentUser.email_verified) || formik.isSubmitting}
						/>

						{currentUser.email_verified ? (
							<Grid container alignItems={'center'}>
								<CheckCircleIcon sx={{ mr: 1, fontSize: 14 }} color={'success'} />

								<Typography variant={'caption'} color="textSecondary">
									Email has been verified
								</Typography>
							</Grid>
						) : null}
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Grid container spacing={3}>
						<Grid item xs={5}>
							<FormSelect
								MenuProps={{
									PaperProps: {
										sx: { maxHeight: 300, width: 400 },
									},
									anchorOrigin: {
										vertical: 'bottom',
										horizontal: 'left',
									},
									transformOrigin: {
										vertical: 'top',
										horizontal: 'left',
									},
								}}
								renderValue={dial_code => dial_code}
								name={'phone.code'}
								label={'Code'}
								disabled={Boolean(currentUser.phone_verified) || formik.isSubmitting}
							>
								{phoneCodeOptions.map(({ dial_code, country_name }) => (
									<MenuItem key={dial_code + country_name} value={dial_code}>
										<Grid container>
											<Typography variant={'body2'} sx={{ width: 40 }} textAlign={'right'}>
												{dial_code}
											</Typography>

											<Typography variant={'body2'} ml={1}>
												{country_name}
											</Typography>
										</Grid>
									</MenuItem>
								))}
							</FormSelect>
						</Grid>

						<Grid item xs={7}>
							<FormTextField
								name={'phone.number'}
								label={'Phone number'}
								autoComplete="off"
								value={formik.values.phone.number}
								onChange={(value: any) => {
									const intergersOnlyValue = value.replace(/[^0-9]/g, '');
									formik.setFieldValue('phone.number', intergersOnlyValue);
								}}
								disabled={Boolean(currentUser.phone_verified) || formik.isSubmitting}
							/>
						</Grid>
					</Grid>

					{currentUser.phone_verified ? (
						<Grid container alignItems={'center'} mt={1}>
							<CheckCircleIcon sx={{ mr: 1, fontSize: 14 }} color={'success'} />

							<Typography variant={'caption'} color="textSecondary">
								Phone number has been verified
							</Typography>
						</Grid>
					) : null}
				</Grid>

				<Grid item xs={12}>
					<StyledDivider />
				</Grid>

				<Grid item xs={12}>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<BackSubmitButton
								fullWidth
								type="button"
								variant="contained"
								color="secondary"
								onClick={() => setCurrentStep(USER_PROFILE_STEPS_ENUM.INFO)}
								disabled={formik.isSubmitting}
							>
								Back
							</BackSubmitButton>
						</Grid>

						<Grid item xs={6}>
							<SubmitButton label={'Update'} disabled={!formik.dirty || formik.isSubmitting} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Form>
	);
}

export default CurrentUserForm;
