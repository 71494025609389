import { Box, CircularProgress, styled, Typography } from '@mui/material';
import React from 'react';
import { AlertDialog } from '../AlertDialog';

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '& > *:last-child': {
        marginLeft: theme.spacing(3),
    },
}));

type Props = {
    open: boolean;
    message?: string;
    size?: number;
};

export const ProgressDialog: React.FunctionComponent<Props> = ({ open, message = 'Loading…', size = 56 }) => {
    return (
        <AlertDialog noTitle noControl fullWidth maxWidth="xs" open={open} positiveButton={''}>
            <Container>
                <CircularProgress size={size} />
                {message && <Typography variant="subtitle1">{message}</Typography>}
            </Container>
        </AlertDialog>
    );
};
