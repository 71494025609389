import React from 'react';
import SignaturePad from 'react-signature-canvas';

import EditOffIcon from '@mui/icons-material/EditOff';
import { Box, Button, Grid } from '@mui/material';

import useUploadSignatureCanvas from '@pages/ApplicationForm/containers/ApplicationFormMain/components/SignatureFieldApplicationForm/components/UploadSignatureCanvas/hooks';
import { CanvasBox } from '@pages/ApplicationForm/containers/ApplicationFormMain/components/SignatureFieldApplicationForm/components/UploadSignatureCanvas/styles';

export interface UploadSignatureCanvasProps {
	disabled: boolean;
	handleSaveAnswer: (file: File) => Promise<void>;
}

function UploadSignatureCanvas({ disabled, handleSaveAnswer }: UploadSignatureCanvasProps) {
	const { canvasRef, canvasIsEmpty, handleClearCanvas, handleSaveCanvas } = useUploadSignatureCanvas({
		handleSaveAnswer,
	});

	return (
		<Box sx={{ width: 500 }}>
			<CanvasBox disabled={disabled}>
				<SignaturePad ref={canvasRef} />
			</CanvasBox>

			<Grid container justifyContent={'flex-end'} alignItems={'center'}>
				<Button
					disableElevation
					variant="contained"
					size="medium"
					color="secondary"
					startIcon={<EditOffIcon />}
					disabled={disabled || canvasIsEmpty}
					onClick={handleClearCanvas}
					sx={{ mr: 1, textTransform: 'capitalize' }}
				>
					<b>Clear</b>
				</Button>

				<Button
					disableElevation
					variant="contained"
					size="medium"
					color="primary"
					disabled={disabled || canvasIsEmpty}
					onClick={handleSaveCanvas}
					sx={{ textTransform: 'capitalize', width: 120 }}
				>
					<b>Sign</b>
				</Button>
			</Grid>
		</Box>
	);
}

export default UploadSignatureCanvas;
