import { number, object, string } from 'yup';
import { generateNamesObject } from '../../utils';

export const fieldNames = generateNamesObject('orderRef', 'quantity', 'stopPrice', 'limitPrice', 'value');

export const modifyOrderSchema = object({
    [fieldNames.orderRef]: string().required(),
    [fieldNames.quantity]: number().label('Quantity'),
    [fieldNames.stopPrice]: number().label('Stop Price'),
    [fieldNames.limitPrice]: number().label('Limit Price'),
    [fieldNames.value]: number().label('Value'),
});

export const getInitialModifyOrderFormValues = (values?: any) =>
    modifyOrderSchema.cast(values ?? undefined, { stripUnknown: false });

export type ModifyOrderFormValues = ReturnType<typeof modifyOrderSchema.getDefault>;
