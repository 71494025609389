import React from 'react';
import { OrderMarketTime, OrderTransaction, OrderType, OrderValidity } from '../TradingForm';
import { fieldNames } from './schema';

export interface Order {
    orderType: OrderType;
    validity: OrderValidity;
    marketTime: OrderMarketTime;
    symbol: string;
    transaction: OrderTransaction;
    ourRef: string;
    mutualFund: boolean;
    option: boolean;
    orderLegs?: Order[];
}

export type ModifyOrderFormOptions = {
    allowFractional: boolean;
};

export const defaultModifyOrderFormOptions: ModifyOrderFormOptions = {
    allowFractional: false,
};

export const getOrderMeta = (order: Order | null) => {
    const stopOrder = order?.orderType === OrderType.Stop || order?.orderType === OrderType.StopLimit;
    const limitOrder = order?.orderType === OrderType.Limit || order?.orderType === OrderType.StopLimit;
    const optionTrading = order?.option || false;
    const spreadTrading = optionTrading && (order?.orderLegs || []).length > 0;
    const mutualFundTrading = order?.mutualFund || false;
    const buying =
        order?.transaction === OrderTransaction.Buy ||
        order?.transaction === OrderTransaction.BuyToClose ||
        order?.transaction === OrderTransaction.BuyToCover ||
        order?.transaction === OrderTransaction.BuyToOpen;
    const selling =
        order?.transaction === OrderTransaction.Sell ||
        order?.transaction === OrderTransaction.SellToClose ||
        order?.transaction === OrderTransaction.SellToOpen ||
        order?.transaction === OrderTransaction.ShortSell;

    return {
        stopOrder,
        limitOrder,
        optionTrading,
        spreadTrading,
        mutualFundTrading,
        buying,
        selling,
    };
};

export type OrderMeta = ReturnType<typeof getOrderMeta>;

export const ModifyOrderFormContext = React.createContext({
    fieldNames,
    options: defaultModifyOrderFormOptions,
});
