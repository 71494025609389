import getCountriesList from './getCountriesList';
import getCurrentTheme from './getCurrentTheme';
import getDocumentTypeList from './getDocumentTypeList';
import getQuestionTypeList from './getQuestionTypeList';
import getRuleSets from './getRuleSets';
import getStatesList from './getStatesList';
import setCurrentTheme from './setCurrentTheme';

const AssetsRequests = {
	getCountriesList,
	getStatesList,
	getCurrentTheme,
	setCurrentTheme,
	getRuleSets,
	getDocumentTypeList,
	getQuestionTypeList,
};

export default AssetsRequests;
