import * as Yup from 'yup';

import AssetsCountryInterface from '@api/requests/assets/interfaces/AssetsCountryInterface.interface';

const registrationFormValidationScheme = (assetsCountries: AssetsCountryInterface[]) =>
	Yup.object().shape({
		first_name: Yup.string().required('This field is required.'),
		last_name: Yup.string().required('This field is required.'),
		email: Yup.string()
			.matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Email address is invalid.')
			.required('This field is required.'),
		password: Yup.string()
			.required('This field is required.')
			.min(8, 'Password must be at least 8 characters long.')
			.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, {
				message: 'Password must contain at least 1 lowercase character, 1 uppercase character and 1 number.',
			}),
		password_confirmation: Yup.string()
			.required('This field is required.')
			.min(8, 'Password must be at least 8 characters long.')
			.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, {
				message: 'Password must contain at least 1 lowercase character, 1 uppercase character and 1 number.',
			})
			.oneOf([Yup.ref('password')], "Passwords don't match."),
		phone: Yup.object().shape({
			code: Yup.string()
				.matches(/^[\d +]+$/, {
					message: 'Required.',
				})
				.test('oneOfRequired', 'Invalid code.', item => {
					if (!item) return true;

					return assetsCountries.map(({ dial_code }) => dial_code).includes(item);
				})
				.required('Required.'),
			number: Yup.string().required('This field is required.'),
		}),
		recaptcha_token: Yup.string().required(),
	});

export default registrationFormValidationScheme;
