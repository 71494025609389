import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import configRequest from '@api/requests/configRequest';

import { showToast } from '@app/providers/ToastProvider';

export interface DeleteAnswerData {
	application_id: number;
	question_id: number;
}

export type DeleteAnswerResponse = void;

const deleteAnswer = configRequest<DeleteAnswerResponse, DeleteAnswerData>({
	requestPrefix: 'applications/deleteAnswer',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.APPLICATION_FORM.DELETE_ANSWER(),
		method: HTTP_METHODS_ENUM.POST,
		data,
	}),
	onSuccess: () => {
		showToast.success('Answer has been cleared successfully');
	},
});

export default deleteAnswer;
