import { Formik, FormikConfig, FormikProps } from 'formik';
import React from 'react';
import { MutualFundValueSelect, OrderLimitPriceSelect, OrderStopPriceSelect, QuantitySelect } from './controls';
import { fieldNames, ModifyOrderFormValues, modifyOrderSchema } from './schema';
import {
    defaultModifyOrderFormOptions,
    getOrderMeta,
    ModifyOrderFormContext,
    ModifyOrderFormOptions,
    Order,
} from './ModifyOrderFormContext';

type ModifyOrderFormControls = {
    OrderStopPriceSelect: typeof OrderStopPriceSelect | null;
    OrderLimitPriceSelect: typeof OrderLimitPriceSelect | null;
    QuantitySelect: typeof QuantitySelect | null;
    MutualFundValueSelect: typeof MutualFundValueSelect | null;
};

export type ModifyOrderFormProps = Omit<FormikConfig<ModifyOrderFormValues>, 'children' | 'initialValues'> & {
    initialValues?: Partial<FormikConfig<ModifyOrderFormValues>['initialValues']>;
    options?: Partial<ModifyOrderFormOptions>;
    onSubmit: (values: ModifyOrderFormValues) => void;
    order: Order | null;
    children: (
        props: FormikProps<ModifyOrderFormValues> & {
            fieldNames: typeof fieldNames;
            controls: ModifyOrderFormControls;
            options: ModifyOrderFormOptions;
        },
    ) => React.ReactElement;
};

export const ModifyOrderForm: React.FunctionComponent<ModifyOrderFormProps> = ({
    options: formOptions,
    onSubmit,
    order,
    children,
    initialValues,
    ...formikConfig
}) => {
    return (
        <Formik
            {...formikConfig}
            initialValues={{
                ...modifyOrderSchema.getDefault(),
                ...initialValues,
            }}
            validationSchema={modifyOrderSchema}
            onSubmit={onSubmit}
        >
            {(formikProps) => {
                const meta = getOrderMeta(order);
                const { mutualFundTrading, stopOrder, limitOrder } = meta;

                const hasStopPriceSelect = !mutualFundTrading && stopOrder;
                const hasLimitPriceSelect = !mutualFundTrading && limitOrder;

                const options: ModifyOrderFormOptions = {
                    ...defaultModifyOrderFormOptions,
                    ...formOptions,
                };

                return (
                    <ModifyOrderFormContext.Provider
                        value={{
                            fieldNames,
                            options,
                        }}
                    >
                        {children({
                            ...formikProps,
                            fieldNames,
                            options,
                            controls: {
                                OrderStopPriceSelect: hasStopPriceSelect ? OrderStopPriceSelect : null,
                                OrderLimitPriceSelect: hasLimitPriceSelect ? OrderLimitPriceSelect : null,
                                QuantitySelect: !mutualFundTrading ? QuantitySelect : null,
                                MutualFundValueSelect: mutualFundTrading ? MutualFundValueSelect : null,
                            },
                        })}
                    </ModifyOrderFormContext.Provider>
                );
            }}
        </Formik>
    );
};
