import { TablePagination } from '@mui/material';
import { isFunction, orderBy, get, isNumber, isString, isArray } from 'lodash';
import { DataQuery, PaginatedData } from 'mui-enhanced-table';
import pluralize from 'pluralize';
import React from 'react';
import { SearchBoxProps, SearchBox } from '../SearchBox';
import { SearchResultsProps, SearchResults } from '../SearchResults';

type SortDirection = 'asc' | 'desc';

export type SearchProps<T = any> = {
    data?: readonly T[] | ((query: DataQuery) => Promise<PaginatedData<T>>);
    value?: string;
    itemName?: string;
    minSearchItems?: number;
    defaultSortBy?: string;
    defaultSortDirection?: SortDirection;
    matcher?: (item: T, searchText: string) => boolean;
    matchFields?: (keyof T)[];
    SearchResultProps?: Omit<SearchResultsProps, 'label'> & {
        label?: string | ((totalItems: number) => string);
    };
    children?: (props: {
        searchText: string;
        shouldHideSearchBox: boolean;
        results: readonly T[];
        onSearchChange: (value: string) => void;
        onSortChange: (sortBy: string, sortDirection: SortDirection) => void;
        SearchBox: React.ComponentType<Partial<SearchBoxProps>>;
        SearchResults: React.ComponentType<Partial<SearchResultsProps>>;
        SearchPagination: React.FunctionComponent;
    }) => React.ReactElement<HTMLElement>;
};

type State<T> = {
    data: T[];
    totalCount: number;
    page: number;
    searchText: string;
    sortBy: string;
    sortDirection: SortDirection;
    searchAmount: number;
};

const defaultMatcher = <T extends {}>(item: T, searchText: string, fields: (keyof T)[]) => {
    if (!searchText) {
        return true;
    }

    const loweredCaseSearchText = searchText.toLowerCase();
    return fields.some((field) => String(get(item, field, '')).toLowerCase().includes(loweredCaseSearchText));
};

export class Search<T extends {} = {}> extends React.Component<SearchProps<T>, State<T>> {
    state: State<T> = {
        data: [],
        totalCount: 0,
        page: 0,
        searchText: this.props.value ?? '',
        sortBy: this.props.defaultSortBy ?? '',
        sortDirection: this.props.defaultSortDirection ?? 'asc',
        searchAmount: 0,
    };

    totalMatchingItems = 0;

    handleSearchChange = (searchText: string) => {
        this.setState({
            searchText,
        });
    };

    handleSortChange = (sortBy: string, sortDirection: SortDirection) => {
        this.setState({
            sortBy,
            sortDirection,
        });
    };

    handlePageChange = (newPage: number) => {
        this.setState({ page: newPage });
        const propsData = this.props.data;
        if (propsData !== undefined) {
            if (isFunction(propsData)) {
                propsData({
                    pageNumber: newPage + 1,
                    pageSize: 10,
                    sortBy: this.state.sortBy,
                    sortDirection: this.state.sortDirection,
                    searchText: this.state.searchText,
                    filters: [],
                }).then((data) => this.setState({ data: data.items, totalCount: data.itemCount }));
            }
        }
    };

    componentDidMount() {
        this.handlePageChange(0);
    }

    SearchBox: React.FunctionComponent<Partial<SearchBoxProps>> = (props) => {
        return (
            <SearchBox
                value={this.state.searchText}
                onChange={(event) => this.handleSearchChange(event.target.value)}
                onClear={() => this.handleSearchChange('')}
                {...props}
            />
        );
    };

    SearchResults: React.FunctionComponent<Partial<SearchResultsProps>> = (props) => {
        const { searchText } = this.state;
        const label = this.props.SearchResultProps?.label;
        return (
            <SearchResults
                {...props}
                {...this.props.SearchResultProps}
                label={
                    isFunction(label)
                        ? label(this.totalMatchingItems)
                        : props.label ??
                          (searchText && !this.totalMatchingItems
                              ? `No matches found`
                              : `Showing ${pluralize(this.props.itemName || 'item', this.totalMatchingItems, true)}`)
                }
            />
        );
    };

    SearchPagination: React.FunctionComponent = () => {
        return (
            <TablePagination
                component="div"
                count={this.state.totalCount}
                page={this.state.page}
                onPageChange={(event, pageNumber) => this.handlePageChange(pageNumber)}
                rowsPerPageOptions={[]}
                rowsPerPage={10}
            />
        );
    };

    render() {
        const { data, value, minSearchItems = 7, matcher, matchFields, children } = this.props;
        const { searchText: internalSearchText, sortBy, sortDirection } = this.state;
        const searchText = value ?? internalSearchText;
        let displayData: readonly T[] = [];
        if (children && data) {
            if (matcher) {
                displayData = isArray(data)
                    ? data.filter((item) => matcher(item, searchText))
                    : this.state.data.filter((item) => matcher(item, searchText));
            } else if (matchFields) {
                displayData = isArray(data)
                    ? data.filter((item) => defaultMatcher(item, searchText, matchFields))
                    : this.state.data.filter((item) => defaultMatcher(item, searchText, matchFields));
            } else {
                displayData = isArray(data) ? data : this.state.data;
            }
        }

        displayData = orderBy(
            displayData,
            (item: T) => {
                const value = get(item, sortBy);

                if (isNumber(value)) {
                    return value;
                } else if (isString(value)) {
                    return value.toLowerCase();
                }

                return value;
            },
            sortDirection,
        );

        this.totalMatchingItems = displayData.length;

        return this.props.children?.({
            searchText,
            results: this.state.searchAmount === 0 ? displayData : displayData.slice(0, this.state.searchAmount),
            shouldHideSearchBox: (data && minSearchItems && data.length < minSearchItems) || false,
            onSearchChange: this.handleSearchChange,
            onSortChange: this.handleSortChange,
            SearchBox: this.SearchBox,
            SearchResults: this.SearchResults,
            SearchPagination: this.SearchPagination,
        });
    }
}
