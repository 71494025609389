export class BaseEnvConfig {
    protected getValue(key: string, defaultValue?: string) {
        const value = process.env[key];

        if (value === undefined) {
            if (defaultValue === undefined) {
                throw new Error(`Environment variable '${key}' is not defined`);
            }

            return defaultValue;
        }

        return value;
    }
}
