import { isString } from 'lodash';
import React, { useEffect } from 'react';
import { AlertDialog } from '../AlertDialog';
import { useToggle } from '../../hooks';

type CacheBusterProps = {
    disabled?: boolean;
    currentVersion: string;
    newVersion: string | (() => Promise<string>);
    children: React.ReactNode;
    action: 'reload' | 'dialog'
    reloadLimit?: number;
};

export const CacheBuster: React.FunctionComponent<CacheBusterProps> = ({
    newVersion,
    currentVersion,
    disabled,
    children,
    action,
    reloadLimit = 3
}) => {
    const url = document.location.toString();
    const d = new Date();
    const timeMS = d.getTime();
    const timestampedUrl = url + '?t=' + timeMS;
    const [dialogOpened, toggleDialog] = useToggle(false);
    const cacheBusterReloadCountKey = 'cacheBusterReloadCount'
    const reloadCount = parseInt(sessionStorage.getItem(cacheBusterReloadCountKey) ?? '0');

    useEffect(() => {
        async function checkForNewVersion() {
            if (!disabled) {
                const fetchedNewVersion = await (isString(newVersion) ? Promise.resolve(newVersion) : newVersion());
                if (fetchedNewVersion !== currentVersion) {
                    if (action === 'dialog') {
                        toggleDialog(true);
                    } else if (reloadCount < reloadLimit) {
                        sessionStorage.setItem(cacheBusterReloadCountKey, String(reloadCount + 1));
                        window.location.replace(timestampedUrl);
                    }
                }
            }
        }
        checkForNewVersion();
    }, []);



    return (
        <>
            {action === 'dialog' &&
                <AlertDialog noControl
                    open={dialogOpened}
                    maxWidth="md"
                    title={'New Version Available'}
                    messageHtml={'Would you like to load the new version?'}
                    positiveButton="Yes"
                    neutralButton="No"
                    neutralButtonColor="error"
                    onPositiveClick={() => {
                        window.location.replace(timestampedUrl);
                    }}
                    onNeutralClick={() => {
                        toggleDialog(false);
                    }}
                />
            }
            {children}
        </>
    )
};
