import { ButtonBase, Grid, alpha, styled } from '@mui/material';

export const Counter = styled(Grid)(({ theme }) => ({
	fontWeight: 500,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: alpha(theme.palette.primary.main, theme.darkMode ? 0.2 : 0.1),
}));

export const BackButton = styled(ButtonBase)(({ theme }) => ({
	marginBottom: theme.spacing(2),
	'& svg path': {
		fill: theme.palette.text.primary,
	},
}));
