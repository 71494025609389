import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import React from 'react';

export const showToast = {
	success: (message: string) => enqueueSnackbar({ variant: 'success', message: message }),
	error: (message: string) => enqueueSnackbar({ variant: 'error', message: message }),
	warning: (message: string) => enqueueSnackbar({ variant: 'warning', message: message }),
	info: (message: string) => enqueueSnackbar({ variant: 'info', message: message }),
};

function ToastProvider({ children }: { children: JSX.Element }) {
	return (
		<>
			<SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} />

			{children}
		</>
	);
}

export default ToastProvider;
