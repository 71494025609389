import { FormTextField } from '@orbis-systems/orbis-react-toolkit';
import { ErrorMessage } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';

import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ButtonBase, Grid, IconButton, InputAdornment, Typography } from '@mui/material';

import Form from '@components/forms';
import useLoginForm from '@components/forms/login/hooks';
import { LoginFormProps } from '@components/forms/login/interfaces';
import SubmitButton from '@components/submitButton';

import { isRetrievalOnly } from '@utils/environment';

function LoginForm({ initialValues, onSubmit }: LoginFormProps) {
	const history = useHistory();
	const generateRoutePath = useGenerateRoutePath();
	const { showPassword, handleShowPassword, formik } = useLoginForm({ initialValues, onSubmit });

	return (
		<Form formik={formik}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<FormTextField name={'email'} label={'Email'} autoComplete="off" disabled={formik.isSubmitting} />
				</Grid>

				<Grid item xs={12}>
					<FormTextField
						name={'password'}
						label={'Password'}
						type={showPassword ? 'text' : 'password'}
						autoComplete="off"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton aria-label="toggle password visibility" onClick={handleShowPassword}>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
						disabled={formik.isSubmitting}
					/>
				</Grid>

				<Grid item xs={12} textAlign={'right'}>
					<ButtonBase onClick={() => history.push(generateRoutePath(RoutePaths.AUTH.FORGOT_PASSWORD_METHOD))}>
						<Typography variant="body2" color={'primary'}>
							<b>{isRetrievalOnly() ? 'Need to create a password?' : 'Forgot password?'}</b>
						</Typography>
					</ButtonBase>
				</Grid>

				<Grid item xs={12}>
					<SubmitButton label={'Login'} disabled={formik.isSubmitting} />
				</Grid>

				<Grid
					item
					xs={12}
					sx={{
						color: theme => theme.palette.error.main,
						textAlign: 'center',
					}}
				>
					<ErrorMessage name="loginError" />
				</Grid>
			</Grid>
		</Form>
	);
}

export default LoginForm;
