import { saveState, selectPersistState } from '../redux';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

export function usePersistState<T>(
    data: T,
    persistKey: string,
    persistScope?: string,
): [T, Dispatch<SetStateAction<T>>] {
    const dispatch = useDispatch();
    const scope = persistScope;
    const state = useSelector((state: any) => {
        const persistState = selectPersistState(state, { scope, key: persistKey });

        if (!persistKey || persistState === undefined) {
            return data;
        }

        return persistState;
    }) as T;
    const dataRef = useRef(state);
    const [internalData, setInternalData] = useState(state);

    useEffect(() => {
        return () => {
            if (persistKey) {
                const data = dataRef.current;
                dispatch(
                    saveState({
                        scope,
                        persistKey: persistKey,
                        payload: cloneDeep(data),
                    }),
                );
            }
        };
    }, []);

    return [
        internalData,
        (data) => {
            setInternalData(data);
            dataRef.current = data instanceof Function ? data(internalData) : data;
        },
    ];
}
