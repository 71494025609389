import createEndpointPath from './createEndpointPath';

const ACCOUNT_CLIENT_API_ENDPOINTS = {
	ASSETS: {
		COUNTRIES: {
			LIST: createEndpointPath('countries/list'),
		},
		STATES: {
			LIST: createEndpointPath('states/list'),
		},
		QUESTION_TYPES: {
			LIST: createEndpointPath('question-types/list'),
		},
		RULE_SETS: {
			LIST: createEndpointPath('rule-sets/list'),
		},
		DOCUMENT_TYPES: {
			LIST: createEndpointPath('document-types/list'),
		},
	},
	AUTH: {
		LOGIN: createEndpointPath('auth/login'),
		REFRESH_TOKEN: createEndpointPath('auth/refresh'),
		LOGOUT: createEndpointPath('auth/logout'),
		RESET_PASSWORD: createEndpointPath('auth/reset'),
		RESET_PASSWORD_CODE: createEndpointPath('auth/forgot'),
		REGISTRATION: createEndpointPath('auth/register'),
		VERIFICATION: {
			PHONE: {
				SEND: createEndpointPath('auth/verifications/phone/send'),
				VERIFY: createEndpointPath('auth/verifications/phone/verify'),
			},
			EMAIL: {
				SEND: createEndpointPath('auth/verifications/email/send'),
				VERIFY: createEndpointPath('auth/verifications/email/verify'),
			},
		},
		GET_NEXT_GEN_TOKEN: createEndpointPath('auth/sso/generate-token'),
	},
	APPLICATIONS: {
		LIST: createEndpointPath('applications/list'),
		GET: createEndpointPath('applications/get'),
	},
	APPLICATION_FORM: {
		CREATE: createEndpointPath('applications/create'),
		ANSWER: createEndpointPath('applications/answer'),
		DELETE_ANSWER: createEndpointPath('applications/delete-answer'),
		SUBMIT: createEndpointPath('applications/submit'),
		BACKGROUND_CHECK: createEndpointPath('applications/background-check'),
		VERIFY_DOCUMENTS: createEndpointPath('applications/verify-documents'),
		CUSTODIAN_CHECK: createEndpointPath('applications/custodian/send'),
	},
	FORMS: {
		GET: createEndpointPath('forms/get'),
		LIST: createEndpointPath('forms/list'),
	},
	USERS: {
		GET: createEndpointPath('users/get'),
		UPDATE: createEndpointPath('users/update'),
	},
	SETTINGS: {
		GET: createEndpointPath('settings/get'),
		SET: createEndpointPath('settings/set'),
	},
};

export default ACCOUNT_CLIENT_API_ENDPOINTS;
