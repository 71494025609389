import React from 'react';
import { FormSelect, FormSelectProps, renderSelectOptions } from '../../FormControls';
import { cryptoFieldNames as fieldNames } from '../schema';
import { timeInForceOptions } from '../selectOptions';

export type TimeInForceSelectProps = Partial<FormSelectProps>;

export const TimeInForceSelect: React.FunctionComponent<TimeInForceSelectProps> = (props) => {
    return (
        <FormSelect label="Time In Force" size="small" {...props} name={fieldNames.marketTime}>
            {renderSelectOptions(Object.values(timeInForceOptions))}
        </FormSelect>
    );
};
