import { FormTextField } from '@orbis-systems/orbis-react-toolkit';
import React from 'react';

import { Autocomplete, Box, Divider, Grid, Paper, Typography } from '@mui/material';

import Form from '@components/forms';
import SubmitButton from '@components/submitButton';

import useResetPasswordMethodForm from './hooks';
import { ResetMethodFormProps } from './interfaces';

function ResetPasswordMethodForm({ initialValues, onSubmit, type }: ResetMethodFormProps) {
	const { formik, phoneCodeOptions } = useResetPasswordMethodForm({
		initialValues,
		onSubmit,
		type,
	});

	return (
		<Form formik={formik}>
			<Grid container spacing={3}>
				{type === 'email' ? (
					<Grid item xs={12}>
						<FormTextField name={'email'} label={'Email'} autoComplete="off" disabled={formik.isSubmitting} />
					</Grid>
				) : (
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={4}>
								<Autocomplete
									disablePortal
									options={phoneCodeOptions.map(({ isPriorityGroup, dial_code, country_name }) => ({
										isPriorityGroup,
										dial_code,
										country_name,
										label: dial_code + ' ' + country_name,
									}))}
									PaperComponent={props => <Paper {...props} sx={{ width: 350 }} />}
									disabled={formik.isSubmitting}
									value={{
										isPriorityGroup: false,
										dial_code: formik.values.phone?.code || '',
										country_name: formik.values.phone?.code || '',
										label: formik.values.phone?.code || '',
									}}
									onChange={(_, newValue) => formik.setFieldValue('phone.code', newValue?.dial_code)}
									renderInput={params => <FormTextField {...params} name="phone.code" label="Code" />}
									renderOption={(props, option) => {
										return (
											<>
												<Box key={props.id} component={'li'} {...props}>
													<Grid container>
														<Typography variant={'body2'} sx={{ width: 40 }} textAlign={'right'}>
															{option.dial_code}
														</Typography>

														<Typography variant={'body2'} ml={1}>
															{option.country_name}
														</Typography>
													</Grid>
												</Box>

												{option.isPriorityGroup ? <Divider style={{ width: '100%', margin: '5px 0' }} /> : null}
											</>
										);
									}}
								/>
							</Grid>

							<Grid item xs={8}>
								<FormTextField
									name={'phone.number'}
									label={'Phone number'}
									autoComplete="off"
									value={formik.values.phone.number}
									onChange={(value: any) => {
										const intergersOnlyValue = value.replace(/[^0-9]/g, '');
										formik.setFieldValue('phone.number', intergersOnlyValue);
									}}
									disabled={formik.isSubmitting}
								/>
							</Grid>
						</Grid>
					</Grid>
				)}

				<Grid item xs={12}>
					<SubmitButton label={'Send'} disabled={formik.isSubmitting} />
				</Grid>
			</Grid>
		</Form>
	);
}

export default ResetPasswordMethodForm;
