import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';
import configRequest from '@api/requests/configRequest';

import { showToast } from '@app/providers/ToastProvider';

export interface UserUpdateData {
	user_id: number;
	email: string;
	first_name: string;
	last_name: string;
	phone: string;
}

export type UserUpdateResponse = {
	user: CurrentUserInterface;
};

const update = configRequest<UserUpdateResponse, UserUpdateData>({
	requestPrefix: 'users/update',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.USERS.UPDATE(),
		method: HTTP_METHODS_ENUM.POST,
		data,
	}),
	onSuccess: () => showToast.success('User has been updated.'),
});

export default update;
