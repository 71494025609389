import { styled } from '@mui/material';

export const Caption = styled('text')(({ theme }) => ({
	fontSize: '6px',
	fontFamily: 'Roboto',
	fontWeight: 500,
	fill: theme.palette.secondary.main,
	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
}));

export const ChartValue = styled('text')(({ theme }) => ({
	fontSize: '14px',
	fontFamily: 'Roboto',
	fontWeight: 500,
	fill: theme.darkMode ? theme.palette.common.white : theme.palette.common.black,
	[theme.breakpoints.down('sm')]: {
		fontSize: '16px',
		transform: 'translate(2px, 5px)',
	},
}));
