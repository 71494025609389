import React from 'react';

import copyToClipboard from '@utils/copyToClipboard';

import CopyImage from 'jsx:@assets/svgs/copy.svg';

import { CopyIcon } from './styles';

function CopyButton({ copiedValue }: { copiedValue: string }) {
	return (
		<CopyIcon
			onClick={e => {
				e.preventDefault();
				e.stopPropagation();
				copyToClipboard(copiedValue);
			}}
		>
			<CopyImage />
		</CopyIcon>
	);
}

export default CopyButton;
