import React from 'react';

import { Box, Grid, Hidden } from '@mui/material';

import Loader from '@components/loader';

import ApplicationFormMobileAppBar from '@pages/ApplicationForm/components/ApplicationFormMobileAppBar';
import ApplicationFormSkeleton from '@pages/ApplicationForm/components/ApplicationFormSkeleton';
import ApplicationFormHints from '@pages/ApplicationForm/containers/ApplicationFormHints';
import ApplicationFormMain from '@pages/ApplicationForm/containers/ApplicationFormMain';
import ApplicationFormNavigation from '@pages/ApplicationForm/containers/ApplicationFormNavigation';
import useApplicationForm from '@pages/ApplicationForm/hooks';

function ApplicationFormPage() {
	const { isLoading } = useApplicationForm();

	if (isLoading) {
		return (
			<Grid container sx={{ height: '100vh' }}>
				<Loader loaderFallBackComponent={ApplicationFormSkeleton} />
			</Grid>
		);
	}

	return (
		<Grid container>
			<Hidden mdUp>
				<ApplicationFormMobileAppBar />
			</Hidden>

			<Hidden mdDown>
				<Grid item xs={12} md={4} lg={2.8} sx={{ minHeight: 'calc(100vh - 64px)' }}>
					<ApplicationFormNavigation />
				</Grid>
			</Hidden>

			<Grid item xs={12} md={8} lg={6} mt={{ md: 3 }}>
				<Box px={3}>
					<ApplicationFormMain />
				</Box>
			</Grid>

			<Hidden lgDown>
				<Grid item lg={3.2}>
					<ApplicationFormHints />
				</Grid>
			</Hidden>
		</Grid>
	);
}

export default ApplicationFormPage;
