import moment from 'moment';
import React from 'react';

import TimelineDot from '@mui/lab/TimelineDot';
import { Grid, Stack, Typography } from '@mui/material';

import CopyButton from '@components/copyButton';

import { ApplicationAccountCardProps } from '@pages/Applications/containers/ApplicationAccountCardList';
import {
	getApplicationAccountStatusIcon,
	getApplicationAccountStatusLabel,
} from '@pages/Applications/containers/ApplicationAccountCardList/components/utils';

import ApplicationIcon from 'jsx:@assets/svgs/docs.svg';

import { StyledApplicationCard, applicationCardClasses } from './styles';

function ApplicationCard({ date, status, uid, isActive, handleSelectCard, form }: ApplicationAccountCardProps) {
	return (
		<StyledApplicationCard
			variant="outlined"
			active={isActive}
			className={
				getApplicationAccountStatusLabel(status) === 'Under review' ? applicationCardClasses.underReview : undefined
			}
			onClick={handleSelectCard}
		>
			<Typography variant={'caption'} className={applicationCardClasses.formTitle}>
				{form}
			</Typography>
			<Grid container justifyContent={'space-between'} alignItems={'center'}>
				<Grid item>
					<Stack alignItems={'center'} direction={'row'} className={applicationCardClasses.titleContainer}>
						<ApplicationIcon />

						<Typography variant={'caption'} ml={1.5} className={applicationCardClasses.accountTitle}>
							Application
						</Typography>
					</Stack>
				</Grid>

				<Grid item>
					<Stack alignItems={'center'} direction={'row'}>
						<TimelineDot color={getApplicationAccountStatusIcon(status)} />

						<Typography variant={'caption'} ml={1} className={applicationCardClasses.accountStatus}>
							{getApplicationAccountStatusLabel(status)}
						</Typography>
					</Stack>
				</Grid>
			</Grid>

			<Typography variant={'h5'} mb={1} mr={8}>
				<b style={{ marginRight: '8px' }}>{uid}</b>

				<CopyButton copiedValue={uid} />
			</Typography>

			<Typography variant={'caption'} className={applicationCardClasses.date}>
				<b>Updated: </b>
				{moment(date).format('MMM Do, YYYY [at] h:mm A')}
			</Typography>
		</StyledApplicationCard>
	);
}

export default ApplicationCard;
