import axios from 'axios';

import { store } from '@app/redux/store';

import * as environment from '@utils/environment';

const httpClient = axios.create({
	baseURL: environment.env.API_URL,
});

httpClient.interceptors.request.use(async config => {
	const accessToken = store.getState().auth.tokens?.access_token;
	const branchEnvironment = store.getState().auth.branchEnvironment.branch;

	if (config.headers && accessToken) {
		return {
			...config,
			headers: {
				...config?.headers,
				Authorization: `Bearer ${accessToken}`,
			},
		};
	}

	return {
		...config,
		headers: {
			...config?.headers,
			branch: branchEnvironment,
		},
	};
});

export default httpClient;
