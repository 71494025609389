import React from 'react';

import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';

import CurrentUserForm from '@components/forms/currentUser';

import { USER_PROFILE_STEPS_ENUM } from '../userProfileViewsFactory';
import useUserProfileUpdate from './hooks';

function UserProfileUpdate({
	currentUser,
	setCurrentStep,
}: {
	currentUser: CurrentUserInterface;
	setCurrentStep: (value: USER_PROFILE_STEPS_ENUM) => void;
}) {
	const { initialValues, onSubmit } = useUserProfileUpdate({ currentUser, setCurrentStep });

	return (
		<CurrentUserForm
			currentUser={currentUser}
			setCurrentStep={setCurrentStep}
			onSubmit={onSubmit}
			initialValues={initialValues}
		/>
	);
}

export default UserProfileUpdate;
