import AssetsDocumentTypeInterface from '@api/requests/assets/interfaces/AssetsDocumentTypeInterface.interface';
import AssetsQuestionTypeInterface from '@api/requests/assets/interfaces/AssetsQuestionTypeInterface.interface';
import AssetsRuleSetInterface from '@api/requests/assets/interfaces/AssetsRuleSetInterface.interface';
import { FormQuestionInterface } from '@api/requests/forms/interfaces/FormInterface.interface';

import {
	APPLICATION_FORM_QUESTION_TYPES_ENUM,
	ApplicationFormAnswer,
	ApplicationFormQuestion,
} from '@app/redux/applicationForm/applicationForm.interfaces';

const isQuestionVisible = (value?: number) => value === undefined || value === 0;

function buildApplicationFormQuestions({
	formQuestions,
	formAssets,
	applicationFormAnswers,
	page_id,
	section_id,
	currentUserId,
}: {
	formQuestions: FormQuestionInterface[];
	formAssets: {
		question_types: Record<number, AssetsQuestionTypeInterface>;
		document_types: Record<number, AssetsDocumentTypeInterface>;
		rule_sets: Record<number, AssetsRuleSetInterface>;
	};
	applicationFormAnswers: Record<number, ApplicationFormAnswer>;
	page_id: number;
	section_id: number;
	currentUserId: number;
}): { ids: number[]; questions: ApplicationFormQuestion[] } {
	const ids: number[] = [];

	const questions: ApplicationFormQuestion[] = [];

	formQuestions.forEach(question => {
		const question_type = formAssets.question_types[question.type_id].name;

		const is_value =
			question_type === APPLICATION_FORM_QUESTION_TYPES_ENUM.NUMBER ||
			question_type === APPLICATION_FORM_QUESTION_TYPES_ENUM.COUNTRY ||
			question_type === APPLICATION_FORM_QUESTION_TYPES_ENUM.EMAIL ||
			question_type === APPLICATION_FORM_QUESTION_TYPES_ENUM.STATE ||
			question_type === APPLICATION_FORM_QUESTION_TYPES_ENUM.DATE ||
			question_type === APPLICATION_FORM_QUESTION_TYPES_ENUM.TEXTAREA ||
			question_type === APPLICATION_FORM_QUESTION_TYPES_ENUM.TEXT ||
			question_type === APPLICATION_FORM_QUESTION_TYPES_ENUM.FILE;

		const has_options =
			question_type === APPLICATION_FORM_QUESTION_TYPES_ENUM.AGREEMENT ||
			question_type === APPLICATION_FORM_QUESTION_TYPES_ENUM.SELECT ||
			question_type === APPLICATION_FORM_QUESTION_TYPES_ENUM.RADIO ||
			question_type === APPLICATION_FORM_QUESTION_TYPES_ENUM.CHECKBOX;

		const is_file = question_type === APPLICATION_FORM_QUESTION_TYPES_ENUM.FILE;

		if (isQuestionVisible(question.is_hidden)) {
			ids.push(question.id);

			questions.push({
				id: question.id,
				type: formAssets.question_types[question.type_id].name,
				value: is_value ? applicationFormAnswers[question.id]?.value || '' : '',
				is_optional: question.is_optional !== 0,
				rule_set: formAssets.rule_sets[question.rule_set_id].name,
				is_encrypted: question.is_encrypted !== 0,
				position: question.position,
				is_locked: applicationFormAnswers[question.id]?.data.locked || false,
				is_self_answered: applicationFormAnswers[question.id]
					? currentUserId === applicationFormAnswers[question.id].answered_by
					: true,
				locked_for_applicants: question.locked_for_applicants,
				locked_for_applicants_if_answered_by_non_applicant: question.locked_for_applicants_if_answered_by_non_applicant,
				page_id,
				section_id,
				description: question.description?.translations?.[0]?.data || '',
				document_type:
					question.document_type_id === null
						? null
						: formAssets.document_types[question.document_type_id]
						? formAssets.document_types[question.document_type_id].name
						: null,
				system_identifier: question.system_identifier,
				multiple_answers: question.has_multiple_answers !== 0,
				label: question.translations[0].data,
				errors: is_file
					? applicationFormAnswers[question.id]?.data.declined
						? [applicationFormAnswers[question.id]?.data.reason]
						: []
					: [],
				show_errors: is_file ? applicationFormAnswers[question.id]?.data.declined || false : false,
				dependencies: question.parent_questions.map(parent => {
					return {
						question_id: parent.parent_question_id,
						option_id: parent.parent_option_id,
						is_hidden: true,
					};
				}),
				options: has_options
					? question.options.map(option => {
							return {
								id: option.id,
								label: option.translations[0].data,
								position: option.position,
								is_checked: applicationFormAnswers[question.id]?.options.includes(option.id) || false,
								system_identifier: option.system_identifier,
								dependencies: option.parent_options.map(parent => {
									return {
										question_id: parent.parent_question_id,
										option_id: parent.parent_option_id,
										is_hidden: true,
									};
								}),
							};
					  })
					: [],
			});
		}
	});

	return {
		ids,
		questions,
	};
}

export default buildApplicationFormQuestions;
