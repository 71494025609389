import React, { useEffect } from 'react';

import useFetch from '@api/hooks/useFetch';
import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { getIsLoggedIn } from '@app/redux/auth/auth.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { Grid } from '@mui/material';

import Loader from '@components/loader';

function AssetsProvider({ children }: { children: JSX.Element }) {
	const isLoggedIn = useAppSelector(getIsLoggedIn);

	const { loading: isLoadingCountriesList } = useFetch(AccountClientRequests.Assets.getCountriesList, [], undefined);

	const { loading: isLoadingStatesList } = useFetch(AccountClientRequests.Assets.getStatesList, [], undefined);

	const { startRequest: handleFetchCurrentTheme, isPending: isLoadingCurrentTheme } = useTriggeredRequest(
		AccountClientRequests.Assets.getCurrentTheme,
	);

	const { startRequest: handleFetchRuleSets, isPending: isLoadingRuleSets } = useTriggeredRequest(
		AccountClientRequests.Assets.getRuleSets,
	);

	const { startRequest: handleFetchDocumentTypeList, isPending: isLoadingDocumentTypeList } = useTriggeredRequest(
		AccountClientRequests.Assets.getDocumentTypeList,
	);

	const { startRequest: handleFetchQuestionTypeList, isPending: isLoadingQuestionTypeList } = useTriggeredRequest(
		AccountClientRequests.Assets.getQuestionTypeList,
	);

	useEffect(() => {
		if (isLoggedIn) {
			handleFetchCurrentTheme();
			handleFetchRuleSets();
			handleFetchDocumentTypeList();
			handleFetchQuestionTypeList();
		}
	}, [
		isLoggedIn,
		handleFetchCurrentTheme,
		handleFetchRuleSets,
		handleFetchDocumentTypeList,
		handleFetchQuestionTypeList,
	]);

	return isLoadingCountriesList ||
		isLoadingStatesList ||
		isLoadingRuleSets ||
		isLoadingDocumentTypeList ||
		isLoadingQuestionTypeList ||
		isLoadingCurrentTheme ? (
		<Grid container alignItems={'center'} justifyContent={'center'} sx={{ height: '100vh' }}>
			<Loader />
		</Grid>
	) : (
		children
	);
}

export default AssetsProvider;
