import React from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';
import THEME_ENUM from '@api/requests/assets/enums/THEME_ENUM.enum';
import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';

import { getCurrentTheme } from '@app/redux/assets/assets.selectors';
import { isVerificationAccount } from '@app/redux/auth/auth.selectors';
import { useAppSelector } from '@app/redux/hooks';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import { Box, Button, ButtonBase, CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material';

import * as environment from '@utils/environment';

import LogoutIcon from 'jsx:@assets/svgs/logout.svg';
import MoonIcon from 'jsx:@assets/svgs/moon.svg';
import SunIcon from 'jsx:@assets/svgs/sun.svg';

import { USER_PROFILE_STEPS_ENUM } from '../userProfileViewsFactory';
import { StyledDivider, UserProfileInfoContainer, userProfileInfoClasses } from './styles';

function UserProfileInfo({
	currentUser,
	handleCloseUserProfile,
	setCurrentStep,
}: {
	currentUser: CurrentUserInterface;
	handleCloseUserProfile: () => void;
	setCurrentStep: (value: USER_PROFILE_STEPS_ENUM) => void;
}) {
	const currentTheme = useAppSelector(getCurrentTheme);
	const { isVerificationPhoneRequired, isPhoneVerified, isVerificationEmailRequired, isEmailVerified } =
		useAppSelector(isVerificationAccount);

	const { startRequest: handleUserLogout, isPending: isLoggingOut } = useTriggeredRequest(
		AccountClientRequests.Auth.logout,
	);

	const { startRequest: handleChangeTheme } = useTriggeredRequest(AccountClientRequests.Assets.setCurrentTheme);

	return (
		<UserProfileInfoContainer>
			<Grid container alignItems={'center'} justifyContent={'space-between'} sx={{ mb: 2.5 }}>
				<Typography variant={'subtitle1'}>
					<b>Profile</b>
				</Typography>

				<Box>
					<Button
						disableElevation
						variant="contained"
						size="small"
						color="secondary"
						onClick={() => setCurrentStep(USER_PROFILE_STEPS_ENUM.UPDATE)}
						sx={{ mr: 2.5, textTransform: 'capitalize' }}
					>
						<b>Edit</b>
					</Button>

					<ButtonBase onClick={handleCloseUserProfile}>
						<CloseIcon />
					</ButtonBase>
				</Box>
			</Grid>

			<Stack spacing={2}>
				<Box>
					<Typography color="textSecondary">First Name</Typography>

					<Typography className={userProfileInfoClasses.value}>{currentUser.first_name}</Typography>
				</Box>

				<Box>
					<Typography color="textSecondary">Last Name</Typography>

					<Typography className={userProfileInfoClasses.value}>{currentUser.last_name}</Typography>
				</Box>

				<Typography variant={'subtitle1'}>
					<b>Contact Details</b>
				</Typography>

				{currentUser.email ? (
					<Box>
						<Typography color="textSecondary">Email</Typography>

						<Typography className={userProfileInfoClasses.value}>{currentUser.email}</Typography>

						{isVerificationEmailRequired && !isEmailVerified ? (
							<ButtonBase sx={{ width: '100%' }} onClick={() => setCurrentStep(USER_PROFILE_STEPS_ENUM.VERIFY_EMAIL)}>
								<Grid container alignItems={'center'} justifyContent={'space-between'}>
									<Box display={'flex'} alignItems={'center'}>
										<InfoIcon color={'error'} sx={{ fontSize: 14 }} />

										<Typography variant={'caption'} color="textSecondary" ml={1}>
											Email needs to be verified
										</Typography>
									</Box>

									<ArrowForwardIosIcon sx={{ fontSize: 12 }} />
								</Grid>
							</ButtonBase>
						) : null}

						{isEmailVerified ? (
							<Grid container alignItems={'center'}>
								<CheckCircleIcon color={'success'} sx={{ fontSize: 14 }} />

								<Typography variant={'caption'} color="textSecondary" ml={1}>
									Email has been verified
								</Typography>
							</Grid>
						) : null}
					</Box>
				) : null}

				{currentUser.phone ? (
					<Box>
						<Typography color="textSecondary">Phone</Typography>

						<Typography className={userProfileInfoClasses.value}>{currentUser.phone}</Typography>

						{isVerificationPhoneRequired && !isPhoneVerified ? (
							<ButtonBase
								sx={{ width: '100%' }}
								onClick={() => setCurrentStep(USER_PROFILE_STEPS_ENUM.VERIFY_PHONE_METHOD)}
							>
								<Grid container alignItems={'center'} justifyContent={'space-between'}>
									<Box display={'flex'} alignItems={'center'}>
										<InfoIcon color={'error'} sx={{ fontSize: 14 }} />

										<Typography variant={'caption'} color="textSecondary" ml={1}>
											Phone number needs to be verified
										</Typography>
									</Box>

									<ArrowForwardIosIcon sx={{ fontSize: 12 }} />
								</Grid>
							</ButtonBase>
						) : null}

						{isPhoneVerified ? (
							<Grid container alignItems={'center'}>
								<CheckCircleIcon color={'success'} sx={{ fontSize: 14 }} />

								<Typography variant={'caption'} color="textSecondary" ml={1}>
									Phone number has been verified
								</Typography>
							</Grid>
						) : null}
					</Box>
				) : null}

				<StyledDivider />

				<ButtonBase onClick={() => setCurrentStep(USER_PROFILE_STEPS_ENUM.CHANGE_PASSWORD)}>
					<Grid container alignItems={'center'} justifyContent={'space-between'}>
						<Box display={'flex'} alignItems={'center'}>
							<LockIcon sx={{ fontSize: 16 }} />

							<Typography color="textSecondary" ml={1}>
								Change Password
							</Typography>
						</Box>

						<ArrowForwardIosIcon sx={{ fontSize: 12 }} />
					</Grid>
				</ButtonBase>

				<Grid
					container
					alignItems={'center'}
					justifyContent={'space-between'}
					className={userProfileInfoClasses.settingsPanel}
				>
					<Typography className={userProfileInfoClasses.version}>
						Ver. 1.0 Build <span>{environment.env.RIA_APP_BUILD_NUMBER}</span>
					</Typography>

					<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
						<Stack mr={1.5} alignItems={'center'}>
							{currentTheme === THEME_ENUM.DARK ? (
								<SunIcon onClick={() => handleChangeTheme({ value: THEME_ENUM.LIGHT })} />
							) : (
								<MoonIcon onClick={() => handleChangeTheme({ value: THEME_ENUM.DARK })} />
							)}
						</Stack>

						<Divider orientation="vertical" flexItem sx={{ borderColor: '#47507C' }} />

						{isLoggingOut ? (
							<Box ml={1.5}>
								<CircularProgress size={16} />
							</Box>
						) : (
							<ButtonBase>
								<Stack
									onClick={() => handleUserLogout()}
									direction={'row'}
									alignItems={'center'}
									className={userProfileInfoClasses.logout}
									ml={1.5}
								>
									<Typography variant={'caption'} mr={1}>
										Log out
									</Typography>

									<LogoutIcon />
								</Stack>
							</ButtonBase>
						)}
					</Stack>
				</Grid>
			</Stack>
		</UserProfileInfoContainer>
	);
}

export default UserProfileInfo;
