import React from 'react';
import { OrderTransaction, OrderType, PositionType, SecurityType, TradeDestination } from './lookups';
import { fieldNames, getInitialTradingFormValues, TradingFormValues } from './schema';

export type TradingFormOptions = {
    allowMutualFundTrading: boolean;
    allowOptionTrading: boolean;
    allowFractional: boolean;
    allowTrailingStop: boolean;
    marginPositions: boolean;
    cashPositions: boolean;
};

export const defaultTradingFormOptions: TradingFormOptions = {
    allowMutualFundTrading: false,
    allowOptionTrading: false,
    allowFractional: false,
    allowTrailingStop: false,
    marginPositions: false,
    cashPositions: true,
};

export const getTradingFormMeta = (values: TradingFormValues) => {
    const equityTrading = values.securityType === SecurityType.Equity;
    const spreadTrading = values.securityType === SecurityType.Spread;
    const optionTrading = spreadTrading || values.securityType === SecurityType.Option;
    const mutualFundTrading = values.securityType === SecurityType.MutualFund;
    const accountTrading = values.tradeDestination === TradeDestination.SingleAccount;
    const inventoryTrading = values.tradeDestination === TradeDestination.AveragePriceAccount;
    const modelTrading = values.tradeDestination === TradeDestination.Model;
    const buying =
        values.transaction === OrderTransaction.Buy ||
        values.transaction === OrderTransaction.BuyToClose ||
        values.transaction === OrderTransaction.BuyToCover ||
        values.transaction === OrderTransaction.BuyToOpen;
    const selling =
        values.transaction === OrderTransaction.Sell ||
        values.transaction === OrderTransaction.SellToClose ||
        values.transaction === OrderTransaction.SellToOpen ||
        values.transaction === OrderTransaction.ShortSell;
    const shorting = values.transaction === OrderTransaction.ShortSell;
    const limitOrder = values.orderType === OrderType.Limit || values.orderType === OrderType.StopLimit;
    const stopOrder = values.orderType === OrderType.Stop || values.orderType === OrderType.StopLimit;
    const notionalOrder = values.orderType === OrderType.Notional;
    const trailingStopOrder = values.orderType === OrderType.TrailingStop;

    return {
        equityTrading,
        spreadTrading,
        optionTrading,
        mutualFundTrading,
        accountTrading,
        inventoryTrading,
        modelTrading,
        buying,
        selling,
        shorting,
        limitOrder,
        stopOrder,
        notionalOrder,
        trailingStopOrder,
    };
};

export type TradingFormMeta = ReturnType<typeof getTradingFormMeta>;

export const TradingFormContext = React.createContext({
    fieldNames,
    meta: getTradingFormMeta(getInitialTradingFormValues()),
    options: defaultTradingFormOptions,
});
