import {
    Box,
    ClickAwayListener,
    FormControl,
    FormControlProps,
    FormGroup,
    FormGroupProps,
    FormHelperText,
    FormLabel,
    styled,
    useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { Field, FieldProps } from 'formik';
import { get, isString } from 'lodash';
import React, { useState } from 'react';
import { FieldConfigProps } from '.';
import { generateNamesObject } from '../../utils/stringUtils';

export const formControlGroupClasses = generateNamesObject(
    'outlined',
    'fieldset',
    'legend',
    'outlinedFocused',
    'outlinedError',
    'outlinedErrorLabel',
);

const Container = styled(Box)(({ theme }) => ({
    [`& .${formControlGroupClasses.outlined}`]: {
        padding: '10.5px 14px',
        position: 'relative',
        minHeight: 40,
        zIndex: 1,
        '&:hover $fieldset': {
            borderColor: 'inherit',
        },
    },
    [`& .${formControlGroupClasses.fieldset}`]: {
        top: -8,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 0,
        padding: '0 8px',
        position: 'absolute',
        borderRadius: '4px',
        pointerEvents: 'none',
        border: `1px solid ${theme.palette.action.disabled}`,
    },
    [`& .${formControlGroupClasses.legend}`]: {
        fontSize: '0.9em',
        paddingLeft: 5,
        paddingRight: 5,
        pointerEvents: 'none',
    },
    [`& .${formControlGroupClasses.outlinedFocused}`]: {
        color: theme.palette.primary.main,
        borderColor: `${theme.palette.primary.main} !important`,
        borderWidth: 2,
    },
    [`& .${formControlGroupClasses.outlinedError}`]: {
        color: theme.palette.error.main,
        borderColor: `${theme.palette.error.main} !important`,
    },
    [`& .${formControlGroupClasses.outlinedErrorLabel}`]: {
        margin: '4px 14px 0 14px',
    },
}));

export type FormControlGroupProps = FieldConfigProps & {
    name: string;
    labelGutter?: boolean;
    label?: string;
    row?: boolean;
    outlined?: boolean;
    touched?: boolean;
    formGroupProps?: Partial<FormGroupProps>;
};

export const FormControlGroup: React.FunctionComponent<FormControlGroupProps & Partial<FormControlProps>> = ({
    children,
    name,
    labelGutter,
    label,
    row,
    outlined,
    validate,
    touched: controlledTouched,
    formGroupProps,
    ...rest
}) => {
    const theme = useTheme();
    const [focused, setFocused] = useState(false);

    return (
        <Field name={name} validate={validate}>
            {({ form: { touched, errors, setFieldTouched } }: FieldProps) => {
                const error = (controlledTouched ?? get(touched, name)) && (get(errors, name) as string);
                return (
                    <ClickAwayListener
                        onClickAway={() => {
                            if (focused) {
                                setFocused(false);
                                if (isString(error)) {
                                    setFieldTouched(name, true);
                                }
                            }
                        }}
                    >
                        <Container>
                            <FormControl {...rest} error={!!error} focused={focused} onClick={() => setFocused(true)}>
                                {!outlined && label && (
                                    <FormLabel
                                        component="legend"
                                        className={clsx({
                                            [formControlGroupClasses.outlinedFocused]: outlined && focused,
                                            [formControlGroupClasses.outlinedError]: outlined && error,
                                        })}
                                        style={{
                                            marginBottom: labelGutter ? theme.spacing(1) : undefined,
                                        }}
                                    >
                                        {label}
                                    </FormLabel>
                                )}

                                <Box
                                    className={clsx({
                                        [formControlGroupClasses.outlined]: outlined,
                                    })}
                                >
                                    <FormGroup row={row} {...formGroupProps}>
                                        {children}
                                    </FormGroup>

                                    {outlined && (
                                        <FormControl
                                            component="fieldset"
                                            error={!!error}
                                            focused={focused}
                                            className={clsx(formControlGroupClasses.fieldset, {
                                                [formControlGroupClasses.outlinedFocused]: outlined && focused,
                                                [formControlGroupClasses.outlinedError]: outlined && error,
                                            })}
                                        >
                                            <FormLabel
                                                component="legend"
                                                className={formControlGroupClasses.legend}
                                                focused={focused}
                                            >
                                                {label}
                                            </FormLabel>
                                        </FormControl>
                                    )}
                                </Box>

                                {!!error && isString(error) && (
                                    <FormHelperText
                                        className={clsx({
                                            [formControlGroupClasses.outlinedErrorLabel]: outlined && error,
                                        })}
                                    >
                                        {error}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Container>
                    </ClickAwayListener>
                );
            }}
        </Field>
    );
};
