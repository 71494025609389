import { removeToast, MessageId, selectAlert } from '../../redux';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

type ToastHandlerProps = {};

export const ToastHandler: React.FunctionComponent<ToastHandlerProps> = () => {
    const displayedRef = useRef<MessageId[]>([]);
    const dispatch = useDispatch();
    const toasts = useSelector((state) => selectAlert(state)).toasts;

    const storeDisplayed = (id: MessageId) => {
        displayedRef.current = displayedRef.current.concat(id);
    };

    const removeDisplayed = (id: MessageId) => {
        displayedRef.current = displayedRef.current.filter((key) => id !== key);
    };

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        toasts.forEach(({ id, content, options = {}, dismissed = false }) => {
            if (dismissed) {
                closeSnackbar(id);
                return;
            }

            // Do nothing if toast is already displayed
            if (displayedRef.current.includes(id)) {
                return;
            }

            // Display toast using notistack package
            enqueueSnackbar(content, {
                key: id,
                ...options,
                onClose: (event, reason, key) => {
                    if (options.onClose) {
                        options.onClose(event, reason, key);
                    }
                },
                onExited: (event, key) => {
                    dispatch(removeToast(key));
                    removeDisplayed(key);
                },
            });

            // Keep track of toast that we've displayed
            storeDisplayed(id);
        });
    }, [toasts]);

    return null;
};
