import React, { useEffect, useState } from 'react';

import ApplicationDetailsInterface from '@api/requests/applications/interfaces/ApplicationDetailsInterface.interface';

import { Button, Grid, Hidden, Stack, Typography } from '@mui/material';

import RoundChart from '@components/chart';

import { getApplicationAccountStatusLabel } from '@pages/Applications/containers/ApplicationAccountCardList/components/utils';
import {
	isRedirectToNextGenLogin,
	isRedirectToNextGenSSO,
} from '@pages/Applications/containers/ApplicationAccountDetails/components/InfoSection/utils';

import DownloadApplicationButton from './containers/DownloadApplicationPdfButton';
import NextGenRedirect from './containers/NextGenRedirect';
import { StyledAccountSection } from './styles';

function InfoSection({
	applicationDetails,
	progressPercentage,
	applicationStatusRefresh,
}: {
	applicationDetails: ApplicationDetailsInterface;
	progressPercentage: number;
	applicationStatusRefresh: () => void;
}) {
	const [seconds, updateSeconds] = useState(0);
	let timer: any = undefined;
	let timout: any = undefined;

	const updateTimer = () => {
		updateSeconds(prevSeconds => prevSeconds + 1);
	};

	useEffect(() => {
		if (getApplicationAccountStatusLabel(applicationDetails.status) != 'Approved') {
			timer = setInterval(updateTimer, 1000);
			timout = setTimeout(applicationStatusRefresh, 60000);
			return () => {
				if (timer) {
					clearInterval(timer);
				}
				if (timout) {
					clearTimeout(timout);
				}
			};
		}
	}, []);

	return (
		<StyledAccountSection>
			<Grid container>
				<Grid item xs={9}>
					<Typography variant={'body1'}>
						<b>Account Details</b>
					</Typography>

					{getApplicationAccountStatusLabel(applicationDetails.status) != 'Approved' && (
						<>
							<Typography variant={'body2'} sx={{ marginTop: '10px' }}>
								Last Refresh: <b>{`${seconds} seconds ago.`}</b>
							</Typography>

							<Button
								variant="contained"
								color="secondary"
								onClick={applicationStatusRefresh}
								sx={{ textTransform: 'initial', width: 195, height: 32, marginTop: '5px' }}
							>
								<Typography variant={'caption'} ml={1}>
									<b>Refresh Application Status </b>
								</Typography>
							</Button>
						</>
					)}

					{applicationDetails.account_number ? (
						<Grid container alignItems={'baseline'}>
							<Typography color="textSecondary" variant={'caption'} mr={0.5}>
								Account number:
							</Typography>

							<Typography variant={'body2'}>{applicationDetails.account_number}</Typography>
						</Grid>
					) : null}

					<Hidden smUp>
						<Stack direction={'column'} alignItems={{ xs: 'flex-start', sm: 'center' }} mt={1}>
							<Typography color="textSecondary" sx={{ fontSize: 10 }}>
								Application UID:
							</Typography>

							<Typography variant={'caption'}> {applicationDetails.uid || 'N/A'}</Typography>
						</Stack>
					</Hidden>
				</Grid>

				<Grid item xs={3} sm={12} mt={{ sm: 4 }} px={{ sm: 3, lg: 6 }} mb={{ sm: 2 }}>
					<RoundChart
						value={progressPercentage || 0}
						withCaption
						status={getApplicationAccountStatusLabel(applicationDetails.status)}
					/>
				</Grid>
			</Grid>

			<Hidden smDown>
				<Grid item xs={12}>
					<Stack direction={'column'} alignItems={{ xs: 'flex-start', sm: 'center' }}>
						<Typography color="textSecondary" sx={{ fontSize: 10 }}>
							Application UID:
						</Typography>

						<Typography variant={'caption'}> {applicationDetails.uid || 'N/A'}</Typography>
					</Stack>
				</Grid>
			</Hidden>

			{applicationDetails.account_number &&
			getApplicationAccountStatusLabel(applicationDetails.status) === 'Approved' ? (
				<Grid container justifyContent={'center'} mt={4} spacing={1}>
					<Grid item>
						<DownloadApplicationButton application={applicationDetails} />
					</Grid>

					{process.env.FIRM_ENVIRONMENT === 'york' &&
						getApplicationAccountStatusLabel(applicationDetails.status) === 'Approved' && (
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										if (process.env.DEPLOYMENT_ENVIRONMENT === 'production') {
											window.open('https://sdt.axosclearing.com/login', '_blank');
										} else {
											window.open('https://axos-ng-uat.orbisfn.io', '_blank');
										}
									}}
									sx={{ textTransform: 'initial', width: 200, height: 32 }}
								>
									<Typography variant={'caption'} ml={1}>
										<b>Launch Trading Platform</b>
									</Typography>
								</Button>
							</Grid>
						)}

					{isRedirectToNextGenSSO(applicationDetails) || isRedirectToNextGenLogin(applicationDetails) ? (
						<Grid item>
							<NextGenRedirect
								isRedirectToNextGenSSO={isRedirectToNextGenSSO(applicationDetails)}
								isRedirectToNextGenLogin={isRedirectToNextGenLogin(applicationDetails)}
							/>
						</Grid>
					) : null}
				</Grid>
			) : null}
		</StyledAccountSection>
	);
}

export default InfoSection;
