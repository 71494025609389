import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AsyncActionStatus } from '../constants/lookups';
import { ACTION_TYPE_PREFIX } from '../constants';
import { AnyAction } from 'redux';
import { ThunkArgMeta } from './asyncActions';

export type AsyncState = Record<string, AsyncActionStatus>;

const DEFAULT_STATE: AsyncState = {};

function isAsyncAction(action: AnyAction): action is PayloadAction<void, string, { arg?: any }> {
    return (
        action.type?.startsWith(ACTION_TYPE_PREFIX) &&
        (action.type?.endsWith(`/${AsyncActionStatus.Pending}`) ||
            action.type?.endsWith(`/${AsyncActionStatus.Fulfilled}`) ||
            action.type?.endsWith(`/${AsyncActionStatus.Rejected}`))
    );
}

function isPendingAction(action: PayloadAction) {
    return action.type.endsWith(`/${AsyncActionStatus.Pending}`);
}

function isFulfilledAction(action: PayloadAction) {
    return action.type.endsWith(`/${AsyncActionStatus.Fulfilled}`);
}

function getActionType(action: PayloadAction<void, string, { arg?: ThunkArgMeta }>) {
    /* 
    Action type patterns: 
      - `prefix/actionType/pending`
      - `prefix/actionType/fulfilled`
      - `prefix/actionType/rejected`
    */
    let actionType = action.type.slice(action.type.indexOf('/') + 1, action.type.lastIndexOf('/'));
    const extra = action.meta?.arg?._actionTypeExtra;

    if (extra) {
        actionType += `/${extra}`;
    }

    return actionType;
}

export const asyncReducer = createReducer(DEFAULT_STATE, (builder) => {
    builder.addMatcher(isAsyncAction, (state, action) => {
        state[getActionType(action)] = isPendingAction(action)
            ? AsyncActionStatus.Pending
            : isFulfilledAction(action)
            ? AsyncActionStatus.Fulfilled
            : AsyncActionStatus.Rejected;
    });
});
