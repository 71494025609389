import { Typography, styled, Box, Skeleton, skeletonClasses } from '@mui/material';
import React, { useState } from 'react';
import { generateNamesObject } from '../../utils/stringUtils';

export const imageWithLoaderClasses = generateNamesObject('root', 'image', 'title');

const Image = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    '&:hover': {
        '> h6': {
            color: theme.palette.primary.main,
        },
    },
    [`& .${imageWithLoaderClasses.image}`]: {
        borderRadius: 6,
        objectFit: 'cover',
        height: '100%',
        width: '100%'
    },
    [`& .${imageWithLoaderClasses.title}`]: {
        fontSize: '16px',
        marginTop: theme.spacing(1),
    },
    [`& .${skeletonClasses.root}`]: {
        borderRadius: 6,
    },
}));

type Props = {
    image: string;
    height?: string | number;
    width?: string | number;
    onClick?: () => void;
    title?: string;
};

export const ImageWithLoader: React.FunctionComponent<Props> = ({ image, height, width, onClick, title }) => {
    const [showImage, setShowImage] = useState<boolean>(false);
    return (
        <Image className={imageWithLoaderClasses.root} onClick={onClick} sx={{ cursor: onClick ? 'pointer' : 'auto' }}>
            <img
                className={imageWithLoaderClasses.image}
                src={image}
                onLoad={() => setShowImage(true)}
                style={showImage ? { display: 'block', height: height, width: width } : { display: 'none' }}
            />
            <Box sx={showImage ? { display: 'none' } : { height: height, width: width }}>
                <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
            </Box>
            {title && (
                <Typography className={imageWithLoaderClasses.title} variant="h6">
                    {title}
                </Typography>
            )}
        </Image>
    );
};
