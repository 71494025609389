import { Box, styled } from '@mui/material';

export const CanvasBox = styled(Box)<{ disabled: boolean }>(({ theme, disabled }) => ({
	width: '500px',
	height: '300px',
	margin: '15px 0',
	cursor: disabled ? 'none' : 'pointer',
	opacity: disabled ? 0.5 : 1,

	'& > canvas': {
		width: '100%',
		height: '100%',
		borderRadius: '5px',
		background: theme.darkMode ? theme.palette.secondary.light : 'white',
		border: theme.darkMode ? '' : `1px solid ${theme.palette.divider}`,
	},
}));
