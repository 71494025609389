import { nextGenDarkThemeOptions, nextGenThemeOptions } from '@orbis-systems/orbis-react-toolkit';

import { ThemeOptions } from '@mui/material';

import * as environment from '@utils/environment';

const defaultDark = nextGenDarkThemeOptions;
const defaultLight = nextGenThemeOptions;

const themes: { [key: string]: { dark: ThemeOptions; light: ThemeOptions } } = {
	viewtrade: { dark: defaultDark, light: defaultLight },
	abm: { dark: defaultDark, light: defaultLight },
	viking: { dark: defaultDark, light: defaultLight },
	'axos-crypto': { dark: defaultDark, light: defaultLight },
	'invertir-online': { dark: defaultDark, light: defaultLight },
	'apex-brokerage-services': { dark: defaultDark, light: defaultLight },
	watchdog: { dark: defaultDark, light: defaultLight },
	lightspeed: { dark: defaultDark, light: defaultLight },
	'summit-securities': { dark: defaultDark, light: defaultLight },
	adcap: { dark: defaultDark, light: defaultLight },
	upstox: { dark: defaultDark, light: defaultLight },
	bosonic: { dark: defaultDark, light: defaultLight },
	york: { dark: defaultDark, light: defaultLight },
	secondlane: {
		dark: {
			palette: {
				...defaultDark.palette!,
				primary: {
					main: '#7745EC',
					dark: '#774DBE',
					light: '#B898EF',
				},
			},
		},
		light: {
			palette: {
				...defaultLight.palette!,
				primary: {
					main: '#4D1AE4',
					dark: '#340CA5',
					light: '#7745EC',
				},
			},
		},
	},
};

export const getFirmTheme = (): { dark: ThemeOptions; light: ThemeOptions } => {
	return themes[environment.env.FIRM_ENVIRONMENT!];
};
