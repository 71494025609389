import { ApplicationAnswerInterface } from '@api/requests/applications/interfaces/ApplicationDetailsInterface.interface';
import AssetsDocumentTypeInterface from '@api/requests/assets/interfaces/AssetsDocumentTypeInterface.interface';
import AssetsQuestionTypeInterface from '@api/requests/assets/interfaces/AssetsQuestionTypeInterface.interface';
import AssetsRuleSetInterface from '@api/requests/assets/interfaces/AssetsRuleSetInterface.interface';

import { ApplicationFormSection, ApplicationFormSections } from '@app/redux/applicationForm/applicationForm.interfaces';

export interface QuestionAndAnswerItem {
	id: number;
	type: string;
	sectionId: number;
	label: string;
	value: string;
}

export interface SectionWithQuestionsAndAnswers {
	parentPageId: number;
	sectionId: number;
	sectionLabel: string;
	questionsAndAnswersList: QuestionAndAnswerItem[];
}

function getAnswerValueBaseOnQuestionType(
	answer: ApplicationAnswerInterface,
	formAssets: {
		question_types: Record<number, AssetsQuestionTypeInterface>;
		document_types: Record<number, AssetsDocumentTypeInterface>;
		rule_sets: Record<number, AssetsRuleSetInterface>;
	},
) {
	switch (formAssets.question_types[answer.question.type_id].name) {
		case 'text':
		case 'textarea':
		case 'number':
		case 'email':
		case 'date':
		case 'state':
		case 'country':
			return answer.data;
		case 'select':
		case 'agreement':
		case 'radio':
		case 'checkbox':
			return answer.option?.translations[0]?.data || '';
		default:
			return answer.data;
	}
}

export function createListOfAllAnswers(
	answers: ApplicationAnswerInterface[],
	formAssets: {
		question_types: Record<number, AssetsQuestionTypeInterface>;
		document_types: Record<number, AssetsDocumentTypeInterface>;
		rule_sets: Record<number, AssetsRuleSetInterface>;
	},
	sections: ApplicationFormSections,
) {
	const pureAnswers = answers
		.filter(e => e.question)
		.map(e => ({
			id: e.id,
			type: formAssets.question_types[e.question.type_id].name,
			sectionId: e.question.section_id,
			label: e.question.translations[0].data,
			value: getAnswerValueBaseOnQuestionType(e, formAssets),
		}));

	const sectionsList = Object.values(sections).reduce(
		(prev, next) => prev.concat(next),
		[],
	) as ApplicationFormSection[];

	return sectionsList
		.map(e => {
			const sectionLabel = e.label;
			const sectionId = e.id;
			const parentPageId = e.page_id;
			const questionsAndAnswersList: QuestionAndAnswerItem[] = pureAnswers.filter(answer => e.id === answer.sectionId);

			return { sectionLabel, sectionId, parentPageId, questionsAndAnswersList };
		})
		.filter(e => Boolean(e.questionsAndAnswersList.length));
}
