import { generateNamesObject } from '@orbis-systems/orbis-react-toolkit';

import { Card, styled } from '@mui/material';

export const applicationCardClasses = generateNamesObject(
	['accountTitle', 'accountStatus', 'date', 'underReview', 'titleContainer', 'formTitle'],
	'ApplicationCard',
);

export const StyledApplicationCard = styled(Card)<{ active: boolean }>(({ theme, active }) => ({
	height: '100%',
	borderRadius: 6,
	padding: theme.spacing(3),
	paddingTop: 13,
	backgroundColor: active ? (theme.darkMode ? '#4F451A' : '#FCF7E6') : theme.darkMode ? '#312F1F' : '#FCF7E6',
	border: active ? `2px solid ${theme.palette.warning.dark}` : `1px solid ${theme.darkMode ? '#4F451A' : '#F8EBBF'}`,
	'&:hover': {
		cursor: 'pointer',
	},
	[`& .${applicationCardClasses.accountTitle}`]: {
		color: theme.palette.warning.dark,
		fontWeight: 500,
	},
	[`& .${applicationCardClasses.formTitle}`]: {
		color: theme.palette.text,
		fontWeight: 500,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: '100%',
		display: 'block',
	},
	[`& .${applicationCardClasses.accountStatus}`]: {
		fontWeight: 500,
		color: theme.palette.warning.dark,
	},
	[`& .${applicationCardClasses.date}`]: {
		color: theme.palette.warning.dark,
		opacity: 0.7,
	},
	[`&.${applicationCardClasses.underReview}`]: {
		backgroundColor: active
			? theme.darkMode
				? theme.palette.analystRatings.failBorder
				: '#FFEAF0'
			: theme.darkMode
			? theme.palette.analystRatings.fail
			: theme.palette.common.white,
		border: active
			? `2px solid ${theme.palette.error.dark}`
			: `1px solid ${theme.darkMode ? theme.palette.analystRatings.failBorder : '#FFCADA'}`,

		[`& .${applicationCardClasses.accountTitle}`]: {
			color: theme.palette.error.main,
		},
		[`& .${applicationCardClasses.accountStatus}`]: {
			color: theme.palette.error.main,
		},
		[`& .${applicationCardClasses.date}`]: {
			color: theme.darkMode ? theme.palette.error.main : theme.palette.error.light,
			opacity: 1,
		},
		[`& .${applicationCardClasses.titleContainer} svg path`]: {
			fill: theme.palette.error.main,
		},
	},
}));
