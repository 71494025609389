import * as Yup from 'yup';

const resetPasswordFormValidationScheme = Yup.object().shape({
	password: Yup.string()
		.required('This field is required.')
		.min(8, 'Password must be at least 8 characters long.')
		.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, {
			message: 'Password must contain at least 1 lowercase character, 1 uppercase character and 1 number.',
		}),
	password_confirmation: Yup.string()
		.required('This field is required.')
		.min(8, 'Password must be at least 8 characters long.')
		.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, {
			message: 'Password must contain at least 1 lowercase character, 1 uppercase character and 1 number.',
		})
		.oneOf([Yup.ref('password')], "Passwords don't match."),
	code: Yup.string()
		.min(6, 'The verification code must be at least 6 characters long.')
		.max(6, 'The verification code must contain no more than 6 characters.')
		.required('This field is required.'),
});

export default resetPasswordFormValidationScheme;
