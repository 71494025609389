import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { formatNumber, multiply } from '../../../utils';
import { FormMoneyField, FormNumberFieldProps } from '../../FormControls';
import { fieldNames, TradingFormValues } from '../schema';
import { getTradingQuantity } from '../utils';

export type OrderValueSelectProps = Partial<FormNumberFieldProps> & { lastPrice?: number };

export const OrderValueSelect: React.FunctionComponent<OrderValueSelectProps> = ({ lastPrice, ...rest }) => {
    const { values, setFieldValue, isSubmitting } = useFormikContext<TradingFormValues>();

    useEffect(() => {
        if (!values?.symbol) {
            setFieldValue(fieldNames.orderValue, 0);
        } else if (values?.symbol) {
            setFieldValue(
                fieldNames.orderValue,
                formatNumber(multiply(values.quantity ?? 0, lastPrice ?? 0), { decimalPlaces: 2 }),
            );
        }
    }, [values?.symbol, lastPrice]);

    return (
        <FormMoneyField
            label="Order Value"
            disabled={!lastPrice || isSubmitting}
            size="small"
            min={0}
            {...rest}
            name={fieldNames.orderValue}
            onChange={(value) => {
                if (lastPrice) {
                    setFieldValue(
                        fieldNames.quantity,
                        Math.floor(getTradingQuantity(values, Number(value) || 0, lastPrice)),
                    );
                }
            }}
        />
    );
};
