import { generateNamesObject } from '@orbis-systems/orbis-react-toolkit';

import { ButtonBase, Popper, styled } from '@mui/material';

export const contactsPopperClasses = generateNamesObject(['item'], 'ContactsPopper');

export const ContactsPopper = styled(Popper)(({ theme }) => ({
	bottom: '96px!important',
	top: 'unset!important',
	left: 'unset!important',
	right: '24px!important',
	backgroundColor: theme.palette.background.paper,
	borderRadius: 6,
	width: '352px',
	border: `1px solid ${theme.palette.divider}`,
	zIndex: theme.zIndex.drawer,
	[theme.breakpoints.down('sm')]: {
		right: '10px!important',
	},
	[`& .${contactsPopperClasses.item}`]: {
		backgroundColor: theme.palette.divider,
		padding: theme.spacing(1, 2),
		borderRadius: 4,
	},
}));

export const ContactsButton = styled(ButtonBase)(({ theme }) => ({
	position: 'fixed',
	bottom: 24,
	right: 24,
	backgroundColor: theme.palette.success.main,
	borderRadius: '50%',
	width: theme.spacing(8),
	height: theme.spacing(8),
	boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
	border: `1px solid ${theme.palette.secondary.light}`,
	zIndex: theme.zIndex.drawer,
	[theme.breakpoints.down('sm')]: {
		right: 10,
	},
}));
