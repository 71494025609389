import React from 'react';
import { OrderType } from '../TradingForm/lookups';
import { CryptoOrderTransaction } from './lookups';
import { CryptoTradingFormValues, cryptoFieldNames, getInitialCryptoTradingFormValues } from './schema';

export const getCryptoTradingFormMeta = (values: CryptoTradingFormValues) => {
    const buying = values.transaction === CryptoOrderTransaction.Buy;
    const selling = values.transaction === CryptoOrderTransaction.Sell;
    const limitOrder = values.orderType === OrderType.Limit || values.orderType === OrderType.StopLimit;
    const stopOrder = values.orderType === OrderType.Stop || values.orderType === OrderType.StopLimit;
    const marketOrder = values.orderType === OrderType.Market;

    return {
        buying,
        selling,
        limitOrder,
        stopOrder,
        marketOrder,
    };
};

export type CryptoTradingFormMeta = ReturnType<typeof getCryptoTradingFormMeta>;

export const CryptoTradingFormContext = React.createContext({
    fieldNames: cryptoFieldNames,
    meta: getCryptoTradingFormMeta(getInitialCryptoTradingFormValues()),
});
