import { useEffect, useRef, useState } from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { showToast } from '@app/providers/ToastProvider';

function useAgreementFieldApplicationForm({
	applicationId,
	questionId,
}: {
	applicationId: number;
	questionId: number;
}) {
	const [isReadAgreement, setReadAgreement] = useState(false);
	const agreementTextFieldRef = useRef<HTMLDivElement | null>(null);

	const { isPending: isPendingSaveAnswer, startRequest: saveAgreementAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.saveOptionAnswer,
	);

	const { isPending: isPendingDeleteAnswer, startRequest: deleteAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.deleteAnswer,
	);

	const handleSaveAnswer = async (optionId: number) => {
		try {
			await saveAgreementAnswer({
				application_id: applicationId,
				question_id: questionId,
				data: optionId,
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	const handleDeleteAnswer = async () => {
		try {
			await deleteAnswer({
				application_id: applicationId,
				question_id: questionId,
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	const handleScrollAgreementText = () => {
		if (agreementTextFieldRef.current) {
			const isBottomAgreement =
				agreementTextFieldRef.current.scrollHeight - 10 <=
				agreementTextFieldRef.current.scrollTop + agreementTextFieldRef.current.clientHeight;

			setReadAgreement(isBottomAgreement);
		}
	};

	useEffect(() => {
		if (agreementTextFieldRef.current) {
			const isBottomAgreement =
				agreementTextFieldRef.current.scrollHeight - 10 <=
				agreementTextFieldRef.current.scrollTop + agreementTextFieldRef.current.clientHeight;

			setReadAgreement(isBottomAgreement);
		}
	}, []);

	return {
		loading: isPendingSaveAnswer || isPendingDeleteAnswer,
		handleSaveAnswer,
		handleDeleteAnswer,
		agreementTextFieldRef,
		handleScrollAgreementText,
		isReadAgreement,
	};
}

export default useAgreementFieldApplicationForm;
