import { Document, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';

import ApplicationDetailsInterface from '@api/requests/applications/interfaces/ApplicationDetailsInterface.interface';
import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';

import {
	QuestionAndAnswerItem,
	SectionWithQuestionsAndAnswers,
} from '../../containers/DownloadApplicationPdfButton/utils';
import styles from './styles';

const getPageCounterText = (current: number, total: number) => `Page ${current} of ${total}`;

const PdfFormDataDocument = ({
	application,
	user,
	filledFormList,
}: {
	application: ApplicationDetailsInterface;
	user: CurrentUserInterface;
	filledFormList: SectionWithQuestionsAndAnswers[];
}) => {
	const userFullName = `${user.first_name} ${user.last_name}`;
	const uidLabel = `Account #${application.uid}`;
	const createdAtLabel = `Account Opened Date ${application.timestamps.created_at}`;

	const renderHeader = () => (
		<View style={styles.documentHeader}>
			<Text style={styles.documentBaseTextInfo}>{userFullName}</Text>
			<Text style={styles.documentBaseTextInfo}>{uidLabel}</Text>
			<Text style={styles.documentBaseTextInfo}>{createdAtLabel}</Text>
		</View>
	);

	const renderSectionLabel = (sectionLabel: string) => (
		<View style={styles.sectionHeader}>
			<View style={styles.sectionMark} />
			<Text style={styles.sectionLabel}>{sectionLabel}</Text>
		</View>
	);

	const renderPageCounter = () => (
		<Text
			fixed
			style={styles.pageCounter}
			render={({ pageNumber, totalPages }) => getPageCounterText(pageNumber, totalPages)}
		/>
	);

	return (
		<Document>
			<Page style={styles.page} wrap>
				{renderHeader()}
				{filledFormList.map(section => {
					const { sectionLabel, questionsAndAnswersList } = section;

					return (
						<View key={sectionLabel} style={styles.section}>
							{renderSectionLabel(sectionLabel)}
							{questionsAndAnswersList.map(item => (
								<View key={item.id} style={styles.qaItem}>
									<Text style={styles.questionLabel}>{item.label}</Text>
									<Text style={styles.answerLabel}>{getAnswerLabelBaseOnType(item)}</Text>
								</View>
							))}
						</View>
					);
				})}
				{renderPageCounter()}
			</Page>
		</Document>
	);
};

function getAnswerLabelBaseOnType(questionAndAnswer: QuestionAndAnswerItem) {
	switch (questionAndAnswer.type) {
		case 'file':
			return 'File uploaded';
		default:
			return questionAndAnswer.value;
	}
}

export default PdfFormDataDocument;
