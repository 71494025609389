import { alpha, darken } from '@mui/material';
import { getCurrentTheme } from '../themes';

export class ColorGenerator {
    private colorMap: Record<string, string> = {};
    private colorLevelMap: Record<string, number> = {};

    constructor(private baseColors: string[]) {
        if (baseColors.length === 0) {
            throw new Error('There are no base colors');
        }
    }

    getColorByLevel = (color: string, level: number) => {
        let newColor = color;

        while (level > 0) {
            newColor = darken(color, 0.2);
            level--;
        }

        return newColor;
    };

    getNextColor = (scope?: string | number, reset = false) => {
        const key = String(scope);

        if (reset || !this.colorLevelMap.hasOwnProperty(key)) {
            this.colorLevelMap[key] = 0;
        } else {
            this.colorLevelMap[key]++;
        }

        const color = this.baseColors[this.colorLevelMap[key] % this.baseColors.length];
        const level = Math.floor(this.colorLevelMap[key] / this.baseColors.length);

        return this.getColorByLevel(color, level);
    };

    getColorByKey = (key: string | number, scope = '') => {
        const keyWithScope = `${key}:${scope}`;
        if (!this.colorMap.hasOwnProperty(keyWithScope)) {
            this.colorMap[keyWithScope] = this.getNextColor(scope);
        }

        return this.colorMap[keyWithScope];
    };
}

export const defaultColorGenerator = new ColorGenerator([
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#9e9e9e',
    '#607d8b',
]);

export function getContrastTextColor(backgroundColor: string) {
    if (!backgroundColor) {
        return getCurrentTheme().palette.text.primary;
    }

    return getCurrentTheme().palette.getContrastText(alpha(backgroundColor, 1));
}

export function getTreemapColor(
    current: number,
    min: number,
    max: number,
    colors?: {
        downMin: string;
        downMax: string;
        upMin: string;
        upMax: string;
    },
) {
    let a = '';
    let b = '';
    let amount = 0.0;
    const { downMin = '#e08684', downMax = '#ce3f3b', upMin = '#759d81', upMax = '#4a7e5a' } = colors || {};

    if (!current || current === 0.0) {
        return '#a9a9a9';
    }

    if (current < 0.0) {
        a = downMin;
        b = downMax;
        amount = current / min;
    } else {
        a = upMin;
        b = upMax;
        amount = current / max;
    }

    const ah = parseInt(a.replace(/#/g, ''), 16);
    const ar = ah >> 16;
    const ag = (ah >> 8) & 0xff;
    const ab = ah & 0xff;
    const bh = parseInt(b.replace(/#/g, ''), 16);
    const br = bh >> 16;
    const bg = (bh >> 8) & 0xff;
    const bb = bh & 0xff;
    const rr = ar + amount * (br - ar);
    const rg = ag + amount * (bg - ag);
    const rb = ab + amount * (bb - ab);

    const color = '#' + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1);
    return color;
}
