import { Theme, ThemeOptions } from '@mui/material';
import { CustomMixins } from '@mui/material/styles/createMixins';

export const defaultThemeOptions: ThemeOptions = {
    palette: {
        common: {
            grey: '#cacaca',
            red: '#e54949',
            pink: '#ff6384',
            green: '#12CB92',
            blue: '#4869b7',
            orange: '#ffce56',
            purple: '#9c53ae',
            teal: '#6ec7cd',
            gold: '#E9983E',
            lightGray: '#f9f9f9',
            lightBlue: '#36a2eb',
            lightGreen: '#00bfac',
            darkGold: '#ffa800',
            darkBlue: '#1345BE',
            darkGreen: '#0c996d',
        },
        market: {
            up: '#12CB92',
            down: '#FD4E4E',
        },
        consensus: {
            strongSell: '#c01111',
            sell: '#f02b2b',
            hold: '#8b8b8b',
            buy: '#12CB92',
            strongBuy: '#00996a',
        },
        neutral: {
            main: ' #323F52',
            contrastText: '#fff',
        },
    },
    transitions: {
        allEase: 'all ease .2s',
    },
    // shadows: Array(25).fill('none') as Shadows,
    components: {},
};

export const getCustomMixins = (theme: Theme): CustomMixins => ({
    textNoWrap: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    clickable: {
        cursor: 'pointer',
        color: theme.palette.info.main,
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        zIndex: 1000,
        transition: theme.transitions.allEase,
        backgroundColor: theme.palette.action.hover,
        pointerEvents: 'none',
    },
});
