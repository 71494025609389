import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { showToast } from '@app/providers/ToastProvider';
import { getAssetsStates } from '@app/redux/assets/assets.selectors';
import { useAppSelector } from '@app/redux/hooks';

function useStateFieldApplicationForm({ applicationId, questionId }: { applicationId: number; questionId: number }) {
	const states = useAppSelector(getAssetsStates);

	const { isPending: isPendingSaveAnswer, startRequest: saveStateAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.saveTextAnswer,
	);

	const { isPending: isPendingDeleteAnswer, startRequest: deleteAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.deleteAnswer,
	);

	const handleSaveAnswer = async (state: string) => {
		try {
			await saveStateAnswer({
				application_id: applicationId,
				question_id: questionId,
				data: state,
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	const handleDeleteAnswer = async () => {
		try {
			await deleteAnswer({
				application_id: applicationId,
				question_id: questionId,
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	return {
		options: states.map(state => ({
			id: `application-${applicationId}-question-${questionId}-state-${state.id}`,
			value: state.abbreviation,
			label: state.name,
		})),
		loading: isPendingSaveAnswer || isPendingDeleteAnswer,
		handleSaveAnswer,
		handleDeleteAnswer,
	};
}

export default useStateFieldApplicationForm;
