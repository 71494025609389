import React, { useEffect } from 'react';

import { authActions } from '@app/redux/auth/auth.reducer';
import { useAppDispatch } from '@app/redux/hooks';

import { ButtonBase, Grid, Typography } from '@mui/material';

import ArrowIcon from 'jsx:@assets/svgs/arrowRight.svg';
import MicrophoneIcon from 'jsx:@assets/svgs/microphone.svg';
import PhoneIcon from 'jsx:@assets/svgs/phone.svg';

import { USER_PROFILE_STEPS_ENUM } from '../userProfileViewsFactory';
import { ActionButton, VerifyPhoneMethodContainer, verifyPhoneMethodClasses } from './styles';

function VerifyPhoneMethod({ setCurrentStep }: { setCurrentStep: (value: USER_PROFILE_STEPS_ENUM) => void }) {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(authActions.setVerifyPhoneType(undefined));
	}, [dispatch]);

	return (
		<VerifyPhoneMethodContainer container direction="column" alignItems="center" justifyContent="center">
			<Typography variant="body1" mb={2}>
				<b>Please select method to verify your phone number.</b>
			</Typography>

			<Grid container>
				<Grid item xs={12} mb={2}>
					<ButtonBase
						className={verifyPhoneMethodClasses.item}
						onClick={() => {
							dispatch(authActions.setVerifyPhoneType('voice'));
							setCurrentStep(USER_PROFILE_STEPS_ENUM.VERIFY_PHONE);
						}}
					>
						<Grid item display={'flex'} alignItems={'center'} justifyContent={'center'}>
							<MicrophoneIcon />

							<Typography variant="body2" className={verifyPhoneMethodClasses.label}>
								<b>Voice</b>
							</Typography>
						</Grid>

						<ArrowIcon />
					</ButtonBase>
				</Grid>

				<Grid item xs={12} mb={2}>
					<ButtonBase
						className={verifyPhoneMethodClasses.item}
						onClick={() => {
							dispatch(authActions.setVerifyPhoneType('sms'));
							setCurrentStep(USER_PROFILE_STEPS_ENUM.VERIFY_PHONE);
						}}
					>
						<Grid item display={'flex'} alignItems={'center'} justifyContent={'center'}>
							<PhoneIcon />

							<Typography variant="body2" className={verifyPhoneMethodClasses.label}>
								<b>SMS</b>
							</Typography>
						</Grid>

						<ArrowIcon />
					</ButtonBase>
				</Grid>
			</Grid>

			<ActionButton
				fullWidth
				type="button"
				variant="contained"
				color="secondary"
				onClick={() => setCurrentStep(USER_PROFILE_STEPS_ENUM.INFO)}
			>
				Back
			</ActionButton>
		</VerifyPhoneMethodContainer>
	);
}

export default VerifyPhoneMethod;
