import { generateNamesObject } from '@orbis-systems/orbis-react-toolkit';

import { Button, Grid, alpha, styled } from '@mui/material';

export const verifyPhoneMethodClasses = generateNamesObject(['item', 'label'], 'VerifyPhoneMethodContainer');

export const VerifyPhoneMethodContainer = styled(Grid)(({ theme }) => ({
	[`& .${verifyPhoneMethodClasses.item}`]: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(2, 1.5),
		width: '100%',
		borderRadius: 6,
		background: theme.palette.background.paper,
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: alpha(theme.palette.primary.main, theme.darkMode ? 0.2 : 0.1),
		},
		'& svg path': {
			fill: theme.palette.text.primary,
		},
	},
	[`& .${verifyPhoneMethodClasses.label}`]: {
		display: 'flex',
		marginLeft: theme.spacing(2),
		fontSize: 12,
	},
}));

export const ActionButton = styled(Button)(({ theme }) => ({
	padding: theme.spacing(1, 3),
	fontSize: 16,
	fontWeight: 700,
	textTransform: 'capitalize',
	borderRadius: 4,
	height: 50,
}));
