import * as Yup from 'yup';

const verifyEmailFormValidationScheme = Yup.object().shape({
	code: Yup.string()
		.min(6, 'The verification code must be at least 6 characters long.')
		.max(6, 'The verification code must contain no more than 6 characters.')
		.required('This field is required.'),
});

export default verifyEmailFormValidationScheme;
