import React from 'react';

import VerifyPhoneForm from '@components/forms/verifyPhone';

import { USER_PROFILE_STEPS_ENUM } from '../userProfileViewsFactory';
import useVerifyPhone from './hooks';

function VerifyPhone({ setCurrentStep }: { setCurrentStep: (value: USER_PROFILE_STEPS_ENUM) => void }) {
	const { initialValues, onSubmit } = useVerifyPhone({
		setCurrentStep,
	});

	return <VerifyPhoneForm setCurrentStep={setCurrentStep} onSubmit={onSubmit} initialValues={initialValues} />;
}

export default VerifyPhone;
