import moment from 'moment';
import { useTimer } from 'react-timer-hook';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { getAccessTokenExpiresAt } from '@app/redux/auth/auth.selectors';
import { useAppSelector } from '@app/redux/hooks';

function useRefreshToken() {
	const accessTokenExpiresAt = useAppSelector(getAccessTokenExpiresAt) as string;

	const { startRequest: handleRefreshToken } = useTriggeredRequest(AccountClientRequests.Auth.refreshToken);
	const { startRequest: handleLogout } = useTriggeredRequest(AccountClientRequests.Auth.logout);

	const { restart } = useTimer({
		expiryTimestamp: moment(accessTokenExpiresAt).subtract(5, 'minutes').toDate(),
		onExpire: async () => {
			try {
				const res = await handleRefreshToken();

				restart(moment(res.refresh.token.expires_at).subtract(5, 'minutes').toDate());
			} catch (e) {
				await handleLogout();
			}
		},
	});
}

export default useRefreshToken;
