import getNextGenToken from './getNextGenToken';
import login from './login';
import logout from './logout';
import refreshToken from './refreshToken';
import registration from './registration';
import resetPassword from './resetPassword';
import sendResetPasswordCode from './sendResetPasswordCode';

const AuthRequests = {
	login,
	refreshToken,
	logout,
	registration,
	sendResetPasswordCode,
	resetPassword,
	getNextGenToken,
};

export default AuthRequests;
