import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { showToast } from '@app/providers/ToastProvider';
import { getApplicationDetails } from '@app/redux/applications/applications.selectors';
import { useAppSelector } from '@app/redux/hooks';
import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import useFetchApplicationForm from '@hooks/useFetchApplicationForm';

function useApplicationForm() {
	const history = useHistory();
	const generateRoutePath = useGenerateRoutePath();

	const applicationDetails = useAppSelector(getApplicationDetails);

	const { fetchApplicationForm, isLoading } = useFetchApplicationForm(() => {
		showToast.error('Something went wrong!');
		history.push(generateRoutePath(RoutePaths.MAIN.APPLICATIONS));
	});

	useEffect(() => {
		if (!applicationDetails?.id) {
			showToast.error('Something went wrong!');
			history.push(generateRoutePath(RoutePaths.MAIN.APPLICATIONS));
		} else {
			fetchApplicationForm({
				applicationId: applicationDetails.id,
				formId: applicationDetails.form_id,
				formVersion: applicationDetails.form_version,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [applicationDetails?.id, applicationDetails?.form_id, applicationDetails?.form_version]);

	return {
		isLoading,
		history,
	};
}

export default useApplicationForm;
