import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { useTimer } from 'react-timer-hook';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { getCodeExpiring, getResetPasswordDetails } from '@app/redux/auth/auth.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { ResetPasswordFormProps, ResetPasswordFormValues } from './interfaces';
import resetPasswordFormValidationScheme from './validationScheme';

function useResetPasswordForm({ initialValues, onSubmit }: Pick<ResetPasswordFormProps, 'initialValues' | 'onSubmit'>) {
	const resetPasswordDetails = useAppSelector(getResetPasswordDetails);
	const codeExpiring = useAppSelector(getCodeExpiring);

	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

	const handleShowPassword = () => setShowPassword(!showPassword);
	const handleShowPasswordConfirmation = () => setShowPasswordConfirmation(!showPasswordConfirmation);

	const { seconds, minutes, restart } = useTimer({
		expiryTimestamp: new Date(codeExpiring || '00:00'),
	});

	const { startRequest } = useTriggeredRequest(AccountClientRequests.Auth.sendResetPasswordCode);

	const formik = useFormik<ResetPasswordFormValues>({
		initialValues,
		validationSchema: resetPasswordFormValidationScheme,
		onSubmit,
	});

	const handleResendCode = useCallback(async () => {
		try {
			formik.setFieldError('resendCodeError', '');

			const response = await startRequest({
				email: resetPasswordDetails.email,
				phone: resetPasswordDetails.phone || undefined,
				channel_type: resetPasswordDetails.channel_type == 'email' ? undefined : resetPasswordDetails.channel_type,
				via: resetPasswordDetails.via,
			});

			restart(new Date(response.password_reset.expires_at || '00:00'));
		} catch (e) {
			const { response } = e as AxiosError<{
				messages: string[];
			}>;

			formik.setFieldError('resendCodeError', response?.data?.messages?.join(' ') || 'Oops. Something went wrong.');
		}
	}, [
		formik,
		resetPasswordDetails.channel_type,
		resetPasswordDetails.email,
		resetPasswordDetails.phone,
		resetPasswordDetails.via,
		restart,
		startRequest,
	]);

	return {
		showPassword,
		showPasswordConfirmation,
		handleShowPassword,
		handleShowPasswordConfirmation,
		handleResendCode,
		minutes,
		seconds,
		formik,
	};
}

export default useResetPasswordForm;
