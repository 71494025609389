import React, { useState, useEffect } from 'react';
import { Pagination as MuiPagination, PaginationProps, paginationClasses, styled } from '@mui/material';

const StyledMuiPagination = styled(MuiPagination)(({ theme }) => ({
    width: '100%',
    margin: theme.spacing(3),
    [`& .${paginationClasses.ul}`]: {
        justifyContent: 'center',
    },
}));

type Props<T = any> = Omit<PaginationProps, 'renderItem'> & {
    data: readonly T[];
    itemsPerPage?: number;
    renderItem: (item: T) => React.ReactNode;
};

export const Pagination: <T>(props: Props<T>) => React.ReactElement = ({
    data,
    itemsPerPage = 10,
    renderItem,
    ...rest
}) => {
    const [currentPage, setCurrentPage] = useState(0);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    useEffect(() => {
        setCurrentPage(Math.max(0, Math.min(totalPages - 1, currentPage)));
    }, [itemsPerPage, totalPages]);

    return (
        <>
            {data
                .slice(currentPage * itemsPerPage, currentPage * itemsPerPage + itemsPerPage)
                .map((item) => renderItem(item))}

            {totalPages > 1 && (
                <StyledMuiPagination
                    showFirstButton
                    showLastButton
                    color="primary"
                    count={totalPages}
                    page={currentPage + 1}
                    onChange={(event, page) => setCurrentPage(page - 1)}
                    {...rest}
                />
            )}
        </>
    );
};
