import { styled } from '@mui/material';

import Arrow from 'jsx:@assets/svgs/arrowLeft.svg';
import More from 'jsx:@assets/svgs/more.svg';

export const ArrowIcon = styled(Arrow)(({ theme }) => ({
	path: {
		fill: theme.palette.text.primary,
	},
}));

export const ShowMoreIcon = styled(More)(({ theme }) => ({
	rect: {
		fill: theme.palette.primary.main,
	},
}));
