import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import ApplicationApplicantInterface from '@api/requests/applications/interfaces/ApplicationApplicantInterface.interface';
import ApplicationInterface from '@api/requests/applications/interfaces/ApplicationInterface.interface';
import configRequest from '@api/requests/configRequest';

export interface VerifyDocumentApplicationFormData {
	application_id: number;
	applicant_type: string;
}

export interface VerifyDocumentApplicationFormResponse {
	application: ApplicationInterface & {
		applicants: ApplicationApplicantInterface[];
	};
}

const verifyDocumentApplicationForm = configRequest<
	VerifyDocumentApplicationFormResponse,
	VerifyDocumentApplicationFormData
>({
	requestPrefix: 'applications/verifyDocumentApplicationForm',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.APPLICATION_FORM.VERIFY_DOCUMENTS(),
		method: HTTP_METHODS_ENUM.POST,
		data: {
			...data,
			with: ['applicants.latest_background_check', 'current_status'],
		},
	}),
});

export default verifyDocumentApplicationForm;
