import React from 'react';

import APPLICATION_DOCUMENT_STATUSES_ENUM from '@api/requests/applications/enums/APPLICATION_DOCUMENT_STATUSES_ENUM.enum';
import APPLICATION_STATUSES_ENUM from '@api/requests/applications/enums/APPLICATION_STATUSES_ENUM.enum';

import DeclinedIcon from 'jsx:@assets/svgs/alert.svg';
import CashIcon from 'jsx:@assets/svgs/cash.svg';
import SuccessIcon from 'jsx:@assets/svgs/cloud.svg';
import CryptoIcon from 'jsx:@assets/svgs/crypto.svg';
import MarginIcon from 'jsx:@assets/svgs/margin.svg';
import PendingIcon from 'jsx:@assets/svgs/pending.svg';

export function getApplicationAccountStatusLabel(applicationAccountStatus: APPLICATION_STATUSES_ENUM) {
	switch (applicationAccountStatus) {
		case APPLICATION_STATUSES_ENUM.CREATED:
		case APPLICATION_STATUSES_ENUM.IN_PROGRESS:
			return 'In progress';

		case APPLICATION_STATUSES_ENUM.SUBMITTED:
		case APPLICATION_STATUSES_ENUM.LEXIS_NEXIS_CHECK_OK:
		case APPLICATION_STATUSES_ENUM.LEXIS_NEXIS_CHECK_FAIL:
		case APPLICATION_STATUSES_ENUM.COMPLIANCE_IN_REVIEW:
		case APPLICATION_STATUSES_ENUM.COMPLIANCE_REJECTED:
		case APPLICATION_STATUSES_ENUM.COMPLIANCE_APPROVED:
		case APPLICATION_STATUSES_ENUM.IN_REVIEW:
		case APPLICATION_STATUSES_ENUM.DOCUSIGN_SENT:
		case APPLICATION_STATUSES_ENUM.DOCUSIGN_SIGNED:
		case APPLICATION_STATUSES_ENUM.CUSTODIAN_IN_PROGRESS:
		case APPLICATION_STATUSES_ENUM.CUSTODIAN_FAIL:
		case APPLICATION_STATUSES_ENUM.SOCURE_CHECK_FAIL:
		case APPLICATION_STATUSES_ENUM.SOCURE_CHECK_OK:
		case APPLICATION_STATUSES_ENUM.BACKGROUND_CHECK_FAIL:
		case APPLICATION_STATUSES_ENUM.BACKGROUND_CHECK_SUCCESS:
			return 'Under review';

		case APPLICATION_STATUSES_ENUM.CUSTODIAN_COMPLETE:
		case APPLICATION_STATUSES_ENUM.APPROVED:
			return 'Approved';

		default:
			return 'Under review';
	}
}

export function getApplicationAccountStatusIcon(applicationAccountStatus: APPLICATION_STATUSES_ENUM) {
	switch (applicationAccountStatus) {
		case APPLICATION_STATUSES_ENUM.CREATED:
		case APPLICATION_STATUSES_ENUM.IN_PROGRESS:
			return 'warning';

		case APPLICATION_STATUSES_ENUM.LEXIS_NEXIS_CHECK_FAIL:
		case APPLICATION_STATUSES_ENUM.COMPLIANCE_REJECTED:
		case APPLICATION_STATUSES_ENUM.CUSTODIAN_FAIL:
		case APPLICATION_STATUSES_ENUM.SOCURE_CHECK_FAIL:
		case APPLICATION_STATUSES_ENUM.BACKGROUND_CHECK_FAIL:
		case APPLICATION_STATUSES_ENUM.SUBMITTED:
		case APPLICATION_STATUSES_ENUM.LEXIS_NEXIS_CHECK_OK:
		case APPLICATION_STATUSES_ENUM.COMPLIANCE_IN_REVIEW:
		case APPLICATION_STATUSES_ENUM.COMPLIANCE_APPROVED:
		case APPLICATION_STATUSES_ENUM.IN_REVIEW:
		case APPLICATION_STATUSES_ENUM.DOCUSIGN_SENT:
		case APPLICATION_STATUSES_ENUM.DOCUSIGN_SIGNED:
		case APPLICATION_STATUSES_ENUM.CUSTODIAN_IN_PROGRESS:
		case APPLICATION_STATUSES_ENUM.SOCURE_CHECK_OK:
		case APPLICATION_STATUSES_ENUM.BACKGROUND_CHECK_SUCCESS:
			return 'error';

		case APPLICATION_STATUSES_ENUM.CUSTODIAN_COMPLETE:
		case APPLICATION_STATUSES_ENUM.APPROVED:
			return 'primary';

		default:
			return 'error';
	}
}

export function getApplicationAccountDocumentIcon(
	applicationAccountDocumentStatus: APPLICATION_DOCUMENT_STATUSES_ENUM,
) {
	switch (applicationAccountDocumentStatus) {
		case APPLICATION_DOCUMENT_STATUSES_ENUM.PENDING:
		case APPLICATION_DOCUMENT_STATUSES_ENUM.UPLOADED:
		case APPLICATION_DOCUMENT_STATUSES_ENUM.APPROVED:
			return <SuccessIcon />;

		case APPLICATION_DOCUMENT_STATUSES_ENUM.DECLINED:
			return <DeclinedIcon />;

		default:
			return <PendingIcon />;
	}
}

export function getApplicationAccountTypeIcon(accountType: string) {
	switch (accountType) {
		case 'Cash':
			return <CashIcon />;

		case 'Margin':
			return <MarginIcon />;

		case 'Crypto':
			return <CryptoIcon />;

		default:
			return null;
	}
}
