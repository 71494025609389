import { ApplicationAnswerInterface } from '@api/requests/applications/interfaces/ApplicationDetailsInterface.interface';
import AssetsDocumentTypeInterface from '@api/requests/assets/interfaces/AssetsDocumentTypeInterface.interface';
import AssetsQuestionTypeInterface from '@api/requests/assets/interfaces/AssetsQuestionTypeInterface.interface';
import AssetsRuleSetInterface from '@api/requests/assets/interfaces/AssetsRuleSetInterface.interface';
import { FormQuestionInterface } from '@api/requests/forms/interfaces/FormInterface.interface';

import {
	APPLICATION_FORM_QUESTION_TYPES_ENUM,
	ApplicationFormAnswer,
} from '@app/redux/applicationForm/applicationForm.interfaces';

function buildApplicationFormAnswers({
	applicationAnswers,
	formAssets,
	formQuestions,
}: {
	applicationAnswers: ApplicationAnswerInterface[];
	formAssets: {
		question_types: Record<number, AssetsQuestionTypeInterface>;
		document_types: Record<number, AssetsDocumentTypeInterface>;
		rule_sets: Record<number, AssetsRuleSetInterface>;
	};
	formQuestions: Record<number, FormQuestionInterface>;
}): Record<number, ApplicationFormAnswer> {
	const answers: Record<number, ApplicationFormAnswer> = {};

	applicationAnswers.forEach(answer => {
		let question_id: number | null = null;
		let question_type_id: number | null = null;

		if (
			answer.question_id &&
			// eslint-disable-next-line no-prototype-builtins
			formQuestions.hasOwnProperty(answer.question_id)
		) {
			question_id = formQuestions[answer.question_id].id;
			question_type_id = formQuestions[answer.question_id].type_id;
		}

		if (question_id && question_type_id) {
			switch (formAssets.question_types[question_type_id].name) {
				case APPLICATION_FORM_QUESTION_TYPES_ENUM.NUMBER:
				case APPLICATION_FORM_QUESTION_TYPES_ENUM.COUNTRY:
				case APPLICATION_FORM_QUESTION_TYPES_ENUM.EMAIL:
				case APPLICATION_FORM_QUESTION_TYPES_ENUM.STATE:
				case APPLICATION_FORM_QUESTION_TYPES_ENUM.DATE:
				case APPLICATION_FORM_QUESTION_TYPES_ENUM.TEXTAREA:
				case APPLICATION_FORM_QUESTION_TYPES_ENUM.TEXT: {
					answers[question_id] = {
						answered_by: answer.answered_by,
						value: answer.data,
						options: [],
						data: { locked: false, reason: '', declined: false },
					};
					break;
				}

				case APPLICATION_FORM_QUESTION_TYPES_ENUM.AGREEMENT:
				case APPLICATION_FORM_QUESTION_TYPES_ENUM.SELECT:
				case APPLICATION_FORM_QUESTION_TYPES_ENUM.RADIO:
				case APPLICATION_FORM_QUESTION_TYPES_ENUM.CHECKBOX: {
					if (!answers[question_id]) {
						answers[question_id] = {
							answered_by: answer.answered_by,
							value: '',
							options: [answer.option_id],
							data: {
								locked: false,
								reason: '',
								declined: false,
							},
						};
					} else {
						answers[question_id].options.push(answer.option_id);
					}
					break;
				}

				case APPLICATION_FORM_QUESTION_TYPES_ENUM.FILE: {
					answers[question_id] = {
						answered_by: answer.answered_by,
						value: answer.document?.name || '',
						options: [],
						data: {
							locked: answer.document?.current_status.status !== 'declined',
							reason: answer.document?.current_status.reason || 'Document has been declined, must be re-uploaded.',
							declined: answer.document?.current_status.status === 'declined',
						},
					};
					break;
				}

				default: {
					answers[question_id] = {
						answered_by: answer.answered_by,
						value: '',
						options: [],
						data: { locked: false, reason: '', declined: false },
					};
					break;
				}
			}
		}
	});

	return answers;
}

export default buildApplicationFormAnswers;
