import createRoutePath from '@api/endpoints/createEndpointPath';

import { getBranchEnvironment } from '@app/redux/auth/auth.selectors';
import { useAppSelector } from '@app/redux/hooks';

function useGenerateRoutePath() {
	const branchEnvironment = useAppSelector(getBranchEnvironment);

	return (rawPath: ReturnType<typeof createRoutePath>) =>
		rawPath({ branchRoute: branchEnvironment.branch_route, repRoute: branchEnvironment.rep_route });
}

export default useGenerateRoutePath;
