import * as React from 'react';

import { CircularProgress, Typography } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import { LabelButton } from './styles';

interface Option {
	id: number;
	label: string;
}

interface IDropButtonProps {
	label: string;
	options: Option[];
	onSelect: (option: Option) => void;
	isLoading?: boolean;
	disabled?: boolean;
}

const DropButton: React.FC<IDropButtonProps> = ({ label, options, onSelect, isLoading, disabled }) => {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const handleMenuItemClick = (option: Option) => {
		onSelect(option);
		setOpen(false);
	};

	const handleToggle = () => {
		if (disabled) {
			return;
		}
		if (options.length === 1) {
			onSelect(options[0]);
			return;
		}
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}
		setOpen(false);
	};

	return (
		<div style={{ position: 'relative' }}>
			<ButtonGroup
				variant="contained"
				ref={anchorRef}
				aria-label="Button group with a nested menu"
				onClick={handleToggle}
				sx={{ boxShadow: isLoading ? 'none' : '0 0 30px 0 rgba(0,0,0,0.15)', width: '300px' }}
				disabled={disabled}
			>
				{isLoading && <CircularProgress size={25} style={{ margin: 'auto' }} />}
				{!isLoading && <LabelButton size={'large'}>{label}</LabelButton>}
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1,
					width: '100%',
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{options.map(option => (
										<MenuItem
											key={option.id}
											disabled={false}
											selected={false}
											onClick={() => handleMenuItemClick(option)}
										>
											<Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
												{option.label}
											</Typography>
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
};

export default DropButton;
