import React from 'react';

import { ApplicationFormPage as ApplicationFormPageInterface } from '@app/redux/applicationForm/applicationForm.interfaces';
import { applicationFormActions } from '@app/redux/applicationForm/applicationForm.reducer';
import { useAppDispatch } from '@app/redux/hooks';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';

import ApplicationFormSectionsList from '@pages/ApplicationForm/containers/ApplicationFormNavigation/containers/ApplicationFormSectionsList';
import { ApplicationFormNavigationDivider } from '@pages/ApplicationForm/containers/ApplicationFormNavigation/styles';

import { ApplicationFormAccordion, ApplicationFormPageStatus } from './styles';

export interface ApplicationFormPageProps {
	isSelected: boolean;
	applicationFormPage: ApplicationFormPageInterface;
	applicationFormPageStatus: 'error' | 'completed' | 'pending';
}

function ApplicationFormPage({ isSelected, applicationFormPage, applicationFormPageStatus }: ApplicationFormPageProps) {
	const dispatch = useAppDispatch();

	const handleChangeCurrentPage = () => dispatch(applicationFormActions.selectApplicationFormPage(applicationFormPage));

	return (
		<>
			<ApplicationFormAccordion expanded={isSelected} onChange={handleChangeCurrentPage}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
					<Grid container px={2}>
						<Grid item xs={12}>
							<Typography variant={'body2'}>
								<b>{applicationFormPage.label}</b>
							</Typography>
						</Grid>

						<Grid item xs={12} display={'flex'} alignItems={'center'}>
							<ApplicationFormPageStatus status={applicationFormPageStatus} />

							<Typography variant={'caption'} color={'secondary'}>
								{(applicationFormPageStatus === 'error' && 'Contains Errors') ||
									(applicationFormPageStatus === 'completed' && 'Completed') ||
									'Remaining Questions'}
							</Typography>
						</Grid>
					</Grid>
				</AccordionSummary>

				<AccordionDetails>
					<>
						<ApplicationFormNavigationDivider />

						<ApplicationFormSectionsList applicationFormPageId={applicationFormPage.id} />
					</>
				</AccordionDetails>
			</ApplicationFormAccordion>
		</>
	);
}

export default ApplicationFormPage;
