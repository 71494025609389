import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import AssetsQuestionTypeInterface from '@api/requests/assets/interfaces/AssetsQuestionTypeInterface.interface';
import configRequest from '@api/requests/configRequest';

export type GetQuestionTypeListData = void;

export interface GetQuestionTypeListResponse {
	question_types: AssetsQuestionTypeInterface[];
}

const getQuestionTypeList = configRequest<GetQuestionTypeListResponse, GetQuestionTypeListData>({
	requestPrefix: 'assets/getQuestionTypeList',
	reqConfigCreator: () => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.ASSETS.QUESTION_TYPES.LIST(),
		method: HTTP_METHODS_ENUM.POST,
	}),
});

export default getQuestionTypeList;
