import { ButtonProps } from '@mui/material';
import { createReducer } from '@reduxjs/toolkit';
import { isArray, isString } from 'lodash';
import { OptionsObject } from 'notistack';
import { getUuid } from '../utils';
import {
    dismissAlertDialog,
    dismissLoader,
    dismissToast,
    removeAlertDialog,
    removeToast,
    resetAlertState,
    showAlertDialog,
    showLoader,
    showToast,
} from './alertActions';

export type MessageId = string | number;

export interface LoadingMessage {
    id: MessageId;
    content: string;
}

export interface ToastMessage {
    id: MessageId;
    content: string;
    dismissed?: boolean;
    options?: OptionsObject;
}

export interface AlertMessage {
    id: MessageId;
    content: string;
    dismissed?: boolean;
    title?: string;
    positiveButton?: string;
    negativeButton?: string;
    neutralButton?: string;
    onClose?: (event: React.MouseEvent, id: MessageId) => void;
    onPositiveClick?: (event: React.MouseEvent, id: MessageId) => void;
    onNegativeClick?: (event: React.MouseEvent, id: MessageId) => void;
    onNeutralClick?: (event: React.MouseEvent, id: MessageId) => void;
    positiveButtonColor?: ButtonProps['color'];
    negativeButtonColor?: ButtonProps['color'];
    neutralButtonColor?: ButtonProps['color'];
}

const DEFAULT_STATE: {
    toasts: ToastMessage[];
    loadings: LoadingMessage[];
    alerts: AlertMessage[];
} = {
    toasts: [],
    loadings: [],
    alerts: [],
};

export type AlertState = typeof DEFAULT_STATE;

export const alertReducer = createReducer(DEFAULT_STATE, (builder) => {
    builder.addCase(resetAlertState, () => DEFAULT_STATE);

    builder.addCase(showToast, (state, action) => {
        const id = getUuid();
        const message: LoadingMessage = isString(action.payload)
            ? {
                  id,
                  content: action.payload,
              }
            : {
                  id,
                  ...action.payload,
              };

        state.toasts.push(message);
    });

    builder.addCase(dismissToast, (state, action) => {
        state.toasts.forEach((snackbar) => {
            if (!action.payload || action.payload === snackbar.id) {
                snackbar.dismissed = true;
            }
        });
    });

    builder.addCase(removeToast, (state, action) => {
        state.toasts = state.toasts.filter((item) => item.id !== action.payload);
    });

    builder.addCase(showLoader, (state, action) => {
        const id = getUuid();
        const message: LoadingMessage = isString(action.payload)
            ? {
                  id,
                  content: action.payload,
              }
            : {
                  id,
                  ...action.payload,
              };

        state.loadings.push(message);
    });

    builder.addCase(dismissLoader, (state, action) => {
        state.loadings = state.loadings.filter((item) => item.id !== action.payload);
    });

    builder.addCase(showAlertDialog, (state, action) => {
        const id = getUuid();
        const message: AlertMessage = isString(action.payload)
            ? {
                  id,
                  content: action.payload,
              }
            : {
                  id,
                  ...action.payload,
              };

        state.alerts.push(message);
    });

    builder.addCase(dismissAlertDialog, (state, action) => {
        state.alerts.forEach((alert) => {
            if (!action.payload || action.payload === alert.id) {
                alert.dismissed = true;
            }
        });
    });

    builder.addCase(removeAlertDialog, (state, action) => {
        const payload = action.payload;
        state.alerts = state.alerts.filter((item) =>
            isArray(payload) ? !payload.includes(item.id) : item.id !== payload,
        );
    });
});
