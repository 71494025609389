import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import configRequest from '@api/requests/configRequest';

import ApplicationInterface from './interfaces/ApplicationInterface.interface';

export type GetApplicationsData = void;

export interface GetApplicationsResponse {
	applications: ApplicationInterface[];
}

const getApplications = configRequest<GetApplicationsResponse, GetApplicationsData>({
	requestPrefix: 'applications/list',
	reqConfigCreator: () => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.APPLICATIONS.LIST(),
		method: HTTP_METHODS_ENUM.POST,
		data: {
			with: ['current_status', 'timestamps', 'documents.current_status.timestamps', 'form.translations'],
			order_by: { created_at: { direction: 'asc', priority: 1 } },
		},
	}),
});

export default getApplications;
