import { motion } from 'framer-motion';
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { AnimatedRedirectProps, AnimatedRouteProps } from './interfaces';

const Fade: React.FC = ({ children }) => (
	<motion.div
		exit={{ opacity: 0 }}
		initial={{ opacity: 0 }}
		animate={{ opacity: 1 }}
		transition={{
			duration: 0.25,
		}}
		style={{ height: '100%' }}
	>
		{children}
	</motion.div>
);

const animations = {
	fade: Fade,
};

export const AnimatedRoute: React.FC<AnimatedRouteProps> = ({ children, exact = false, path, animation }) => {
	const Animation = animations[animation];
	return (
		<Route exact={exact} path={path}>
			<Animation>{children}</Animation>
		</Route>
	);
};

export const AnimatedRedirect: React.FC<AnimatedRedirectProps> = ({ to }) => {
	return (
		<motion.div exit="undefined">
			<Redirect to={to} />
		</motion.div>
	);
};

export const AnimatedSwitch: React.FC = ({ children }) => {
	const location = useLocation();

	return (
		<Switch location={location} key={location.pathname}>
			{children}
		</Switch>
	);
};
