import { Select, SelectProps, styled } from '@mui/material';
import React from 'react';

const StyledSelect = styled(Select)(({ theme }) => ({
    ['& > div']: {
        paddingTop: theme.spacing(1),
    },
}));

export const FilledSelectNoLabel = (props: SelectProps) => (
    <StyledSelect {...props} displayEmpty disableUnderline variant="filled" />
);
