import {
	APPLICATION_FORM_QUESTION_TYPES_ENUM,
	ApplicationFormDependency,
	ApplicationFormOption,
	ApplicationFormQuestions,
	ApplicationFormStateType,
} from '../../applicationForm.interfaces';
import {
	ApplicationFormErrorsInterface,
	ApplicationFormNavigationInterface,
	ApplicationFormProgressInterface,
} from './interfaces';

function isApplicationFormOptionChecked(options: ApplicationFormOption[], id: number) {
	for (let i = 0; i < options.length; i++) {
		if (options[i].id === id && options[i].is_checked) {
			return true;
		}
	}

	return false;
}

export function isApplicationFormQuestionVisible(
	dependencies: ApplicationFormDependency[],
	questions: ApplicationFormQuestions,
) {
	if (!dependencies || dependencies.length === 0) {
		return true;
	}

	const option_answers: boolean[] = [];

	const questions_answers: boolean[] = [];

	dependencies.forEach(dependency => {
		questions_answers.push(isApplicationFormQuestionVisible(questions[dependency.question_id].dependencies, questions));

		questions[dependency.question_id].options.forEach(option => {
			if (option.id === dependency.option_id) {
				const is_checked = isApplicationFormOptionChecked(questions[dependency.question_id].options, option.id);

				option_answers.push(
					isApplicationFormQuestionVisible(option.dependencies, questions) &&
						(dependency.is_hidden ? is_checked : !is_checked),
				);
			}
		});
	});

	return option_answers.every(answer => answer) && questions_answers.every(answer => answer);
}

function buildApplicationFormProgress(applicationFormState: ApplicationFormStateType) {
	const values: ApplicationFormProgressInterface = {
		sections: {},
		pages: {},
		total: { required: 0, answered: 0, visible: 0 },
	};

	const errors: ApplicationFormErrorsInterface = { sections: {}, pages: {}, total: 0 };

	const navigation: ApplicationFormNavigationInterface = {};

	Object.keys(applicationFormState.questions).forEach(key => {
		const question = applicationFormState.questions[parseInt(key)];

		const has_options =
			question.type === APPLICATION_FORM_QUESTION_TYPES_ENUM.AGREEMENT ||
			question.type === APPLICATION_FORM_QUESTION_TYPES_ENUM.SELECT ||
			question.type === APPLICATION_FORM_QUESTION_TYPES_ENUM.RADIO ||
			question.type === APPLICATION_FORM_QUESTION_TYPES_ENUM.CHECKBOX;

		if (values.sections[question.section_id] === undefined)
			values.sections[question.section_id] = {
				required: 0,
				answered: 0,
				visible: 0,
			};

		if (values.pages[question.page_id] === undefined)
			values.pages[question.page_id] = {
				required: 0,
				answered: 0,
				visible: 0,
			};

		if (errors.sections[question.section_id] === undefined) errors.sections[question.section_id] = 0;

		if (errors.pages[question.page_id] === undefined) errors.pages[question.page_id] = 0;

		if (isApplicationFormQuestionVisible(question.dependencies, applicationFormState.questions)) {
			values.pages[question.page_id].visible++;
			values.sections[question.section_id].visible++;

			if (!question.is_optional) {
				values.pages[question.page_id].required++;
				values.sections[question.section_id].required++;
				values.total.required++;

				if (question.errors.length === 0) {
					if (has_options) {
						for (let i = 0; i < question.options.length; i++) {
							if (question.options[i].is_checked) {
								values.pages[question.page_id].answered++;
								values.sections[question.section_id].answered++;
								values.total.answered++;
								break;
							}
						}
					} else {
						if (question.value !== '') {
							values.pages[question.page_id].answered++;
							values.sections[question.section_id].answered++;
							values.total.answered++;
						}
					}
				} else {
					errors.sections[question.section_id]++;
					errors.pages[question.page_id]++;
					errors.total++;
				}
			}
		}
	});

	const visible_pages = applicationFormState.pages.filter(page => values.pages[page.id].visible > 0);

	visible_pages.forEach((page, page_index) => {
		const visible_sections = applicationFormState.sections[page.id].filter(
			section => values.sections[section.id].visible > 0,
		);

		visible_sections.forEach((section, section_index) => {
			const last_section = visible_sections.length === section_index + 1;
			const last_page = visible_pages.length === page_index + 1;
			let label = 'Next Section';
			let submit = false;
			let next: {
				section: { id: number; label: string; questions: number[] };
				page: { id: number; label: string };
			} = {
				section: { id: 0, label: '', questions: [] },
				page: { id: 0, label: '' },
			};

			if (last_section) {
				if (last_page) {
					submit = true;
				} else {
					const next_page = visible_pages[page_index + 1];

					const next_section = applicationFormState.sections[next_page.id].filter(
						section => values.sections[section.id].visible > 0,
					)[0];

					label = 'Next Page';

					next = {
						section: {
							id: next_section.id,
							label: next_section.label,
							questions: next_section.questions_ids,
						},
						page: { id: next_page.id, label: next_page.label },
					};
				}
			} else {
				const next_section = visible_sections[section_index + 1];

				next = {
					section: {
						id: next_section.id,
						label: next_section.label,
						questions: next_section.questions_ids,
					},
					page: { id: page.id, label: page.label },
				};
			}

			navigation[section.id] = {
				label,
				submit,
				next,
			};
		});
	});

	return {
		values,
		errors,
		navigation,
	};
}

export default buildApplicationFormProgress;
