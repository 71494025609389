import React from 'react';
import Helmet from 'react-helmet';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import ThemeProvider from '@app/providers/ThemeProvider';
import ToastProvider from '@app/providers/ToastProvider';
import { persist, store } from '@app/redux/store';
import AppRoutes from '@app/routes';

import { FAVICON_MAP } from '@components/logo/constants';

import { capitalize } from '@utils/capitalize';
import * as environment from '@utils/environment';

function App() {
	return (
		<React.StrictMode>
			<Helmet>
				<title>{capitalize(environment.env.FIRM_ENVIRONMENT)} Onboarding</title>
				<link rel="icon" type="image/png" href={FAVICON_MAP.get(environment.env.FIRM_ENVIRONMENT)} sizes="16x16" />
			</Helmet>

			<ReduxProvider store={store}>
				<BrowserRouter>
					<PersistGate persistor={persist}>
						<ThemeProvider>
							<ToastProvider>
								<AppRoutes />
							</ToastProvider>
						</ThemeProvider>
					</PersistGate>
				</BrowserRouter>
			</ReduxProvider>
		</React.StrictMode>
	);
}

export default App;
