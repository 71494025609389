import ApplicationsRequests from './applications';
import AssetsRequests from './assets';
import AuthRequests from './auth';
import FormsRequests from './forms';
import UsersRequests from './users';

const AccountClientRequests = {
	Assets: AssetsRequests,
	Auth: AuthRequests,
	Users: UsersRequests,
	Applications: ApplicationsRequests,
	Forms: FormsRequests,
};

export default AccountClientRequests;
