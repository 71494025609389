import React from 'react';
import { FormSelect, FormSelectProps, renderSelectOptions } from '../../FormControls';
import { fillTypeOptions } from '../selectOptions';
import { fieldNames } from '../schema';

export type FillTypeSelectProps = Partial<FormSelectProps>;

export const FillTypeSelect: React.FunctionComponent<FillTypeSelectProps> = (props) => {
    return (
        <FormSelect label="Fill Type" size="small" {...props} name={fieldNames.fillType}>
            {renderSelectOptions(Object.values(fillTypeOptions))}
        </FormSelect>
    );
};
