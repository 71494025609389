import { validateDocumentImageFile, validateDocumentPdfFile } from '@components/fileManager/validators';

export type FILE_TYPES = 'document-pdf' | 'document-image';

export interface FILE_CONFIG {
	acceptFormats: string[];
	maxSize: number;
	validator: (file?: File) => boolean;
}

const FILES_CONFIG: Record<FILE_TYPES, FILE_CONFIG> = {
	'document-pdf': {
		acceptFormats: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'],
		maxSize: 10485760,
		validator: validateDocumentPdfFile,
	},
	'document-image': {
		acceptFormats: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'],
		maxSize: 10485760,
		validator: validateDocumentImageFile,
	},
};

export default FILES_CONFIG;
