import { createSelector } from '@reduxjs/toolkit';
import { ACTION_TYPE_PREFIX, AsyncActionStatus } from '../constants';
import { AlertState } from './alertReducer';
import { AsyncState } from './asyncReducer';
import { PersistState } from './persistStateReducer';

export const selectAlert = (state: any) => {
    const alertState = state._ortkAlert;

    if (!alertState) {
        throw new Error(
            'Alert state not found. Did you include `alertReducer` as `_ortkAlert` when creating root reducer?',
        );
    }

    return alertState as AlertState;
};

export const selectPersistState = createSelector(
    (state: any) => {
        const persistState = state._ortkPersistState;

        if (!persistState) {
            throw new Error(
                'Persist state not found. Did you include `persistStateReducer` as `_ortkPersistState` when creating root reducer?',
            );
        }

        return persistState as PersistState;
    },
    (
        state: any,
        props: {
            key: string;
            scope?: string;
        },
    ) => props,
    (persistState, { key, scope }) => {
        const persistedState = (persistState as PersistState)[scope || 'noscope']?.[key || ''];
        return persistedState;
    },
);

export const selectAsyncActionStatus = createSelector(
    (state: any) => {
        const asyncState = state._ortkAsync;

        if (!asyncState) {
            throw new Error(
                'Async state not found. Did you include `asyncReducer` as `_ortkAsync` when creating root reducer?',
            );
        }

        return asyncState as AsyncState;
    },
    (
        state: any,
        props: {
            type: string;
            extra?: string | number;
        },
    ) => props,
    (asyncState, { type, extra }) => {
        if (extra) {
            type += `/${extra}`;
        }

        return (asyncState as AsyncState)[type.replace(`${ACTION_TYPE_PREFIX}/`, '')] || AsyncActionStatus.Idle;
    },
);

export const selectCombinedAsyncActionStatus = (state: any, statusSelectors: ((state: any) => AsyncActionStatus)[]) => {
    if (statusSelectors.length === 0) {
        return AsyncActionStatus.Idle;
    }

    const statuses = statusSelectors.map((selector) => selector(state));

    for (const status of statuses) {
        if (status === AsyncActionStatus.Rejected || status === AsyncActionStatus.Pending) {
            return status;
        }
    }

    return statuses[0];
};
