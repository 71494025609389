import { Box, styled } from '@mui/material';

export const AgreementBox = styled(Box)(({ theme }) => ({
	margin: theme.spacing(0, 0, 2, 0),
	borderRadius: 4,
	padding: theme.spacing(2),
	background: theme.darkMode ? theme.palette.secondary.light : 'white',
	border: theme.darkMode ? '' : `1px solid ${theme.palette.divider}`,
}));

export const AgreementText = styled(Box)(() => ({
	fontSize: 14,
	lineHeight: 2,
	maxHeight: 250,
	overflowY: 'auto',
	whiteSpace: 'pre-wrap',
}));
