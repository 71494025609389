import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { showToast } from '@app/providers/ToastProvider';

function useRadioGroupFieldApplicationForm({
	applicationId,
	questionId,
}: {
	applicationId: number;
	questionId: number;
}) {
	const { isPending: isPendingSaveAnswer, startRequest: saveRadioGroupAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.saveOptionAnswer,
	);

	const { isPending: isPendingDeleteAnswer, startRequest: deleteAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.deleteAnswer,
	);

	const handleSaveAnswer = async (optionId: number) => {
		try {
			await saveRadioGroupAnswer({
				application_id: applicationId,
				question_id: questionId,
				data: optionId,
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	const handleDeleteAnswer = async () => {
		try {
			await deleteAnswer({
				application_id: applicationId,
				question_id: questionId,
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	return {
		loading: isPendingSaveAnswer || isPendingDeleteAnswer,
		handleSaveAnswer,
		handleDeleteAnswer,
	};
}

export default useRadioGroupFieldApplicationForm;
