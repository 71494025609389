import React from 'react';
import { useLocation } from 'react-router';

import { getCurrentUser } from '@app/redux/auth/auth.selectors';
import { useAppSelector } from '@app/redux/hooks';
import RoutePaths from '@app/routes/paths';

import { AnimatedRedirect } from '@components/transition/route';

import { getBranchRoutes } from '@utils/environment';

function MatchCurrentBranchRoute({ children }: { children: JSX.Element }) {
	const { pathname } = useLocation();

	const currentUser = useAppSelector(getCurrentUser);

	const params = pathname.split('/').filter(param => param !== '');
	const branchRouteParams = params[0];
	const repRouteParam = params[1];
	const branchEnvironment = getBranchRoutes().find(
		route => route.branch_route === branchRouteParams && route.rep_route === repRouteParam,
	);

	const checkIfBranchExists = (id: number): boolean => {
		if (currentUser && currentUser.branches) {
			for (let i = 0; i < currentUser.branches.length; i++) {
				if (currentUser.branches[i].id === id) {
					return true;
				}
			}
		}
		return false;
	};

	const userBranchEnvironment = getBranchRoutes().find(route => checkIfBranchExists(route.branch_id));

	return userBranchEnvironment?.branch_id === branchEnvironment?.branch_id ? (
		children
	) : (
		<AnimatedRedirect
			to={RoutePaths.MAIN.APPLICATIONS({
				branchRoute: userBranchEnvironment?.branch_route,
				repRoute: userBranchEnvironment?.rep_route,
			})}
		/>
	);
}

export default MatchCurrentBranchRoute;
