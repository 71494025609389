import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import { NUM_BOOL_TYPE } from '@api/interfaces/SharedInterfaces.interface';
import configRequest from '@api/requests/configRequest';

import { showToast } from '@app/providers/ToastProvider';

export interface SaveTextAnswerData {
	application_id: number;
	question_id: number;
	data: string;
}

export interface SaveTextAnswerResponse {
	answer: {
		answered_by: number;
		data: string;
		deleted_at: string | null;
		id: number;
		is_encrypted_with_application_key: NUM_BOOL_TYPE | null;
		is_encrypted_with_public_key: NUM_BOOL_TYPE | null;
		option_id: null;
		question_id: number;
	};
}

const saveTextAnswer = configRequest<SaveTextAnswerResponse, SaveTextAnswerData>({
	requestPrefix: 'applications/saveTextAnswer',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.APPLICATION_FORM.ANSWER(),
		method: HTTP_METHODS_ENUM.POST,
		data,
	}),
	onSuccess: () => {
		showToast.success('Answer has been saved successfully');
	},
});

export default saveTextAnswer;
