function downloadDocuments(fileStream: BlobPart, fileName: string, contentType: string) {
	const link = document.createElement('a');
	const file = new Blob([fileStream], { type: contentType });
	link.download = fileName;
	link.href = URL.createObjectURL(file);
	link.click();
	link.remove();
}

export default downloadDocuments;
