import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import THEME_ENUM from '@api/requests/assets/enums/THEME_ENUM.enum';

import { getCurrentTheme } from '@app/redux/assets/assets.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { Caption, ChartValue } from '@components/chart/styles';

function RoundChart({ withCaption = false, value, status }: { value: number; withCaption?: boolean; status?: string }) {
	const currentTheme = useAppSelector(getCurrentTheme);

	return (
		<PieChart
			data={[{ value, key: 1, color: value === 100 ? '#12B065' : '#E5B000' }]}
			reveal={value}
			lineWidth={24}
			background={currentTheme === THEME_ENUM.LIGHT ? '#E3EAED' : '#293859'}
			lengthAngle={360}
			animate
			label={({ x, y, dx, dy, dataEntry }) => (
				<>
					<ChartValue x={x} y={withCaption ? y - 5 : y} dx={dx} dy={dy} dominantBaseline="central" textAnchor="middle">
						{`${dataEntry.value}%`}
					</ChartValue>
					{withCaption ? (
						<Caption x={x} y={y + 10} dx={dx} dy={dy} dominantBaseline="central" textAnchor="middle">
							{status}
						</Caption>
					) : null}
				</>
			)}
			labelPosition={0}
		/>
	);
}

export default RoundChart;
