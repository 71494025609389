import React from 'react';

import { CircularProgress } from '@mui/material';

function Loader({
	loaderFallBackComponent: LoaderFallBackComponent = () => <CircularProgress size={50} />,
}: {
	loaderFallBackComponent?: () => JSX.Element;
}) {
	return <LoaderFallBackComponent />;
}

export default Loader;
