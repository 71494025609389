import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import configRequest from '@api/requests/configRequest';

import { showToast } from '@app/providers/ToastProvider';

export type SendVerificationEmailCodeData = void;

export interface SendVerificationEmailCodeResponse {
	email_verification: {
		expires_at: string;
	};
}

const sendVerificationEmailCode = configRequest<SendVerificationEmailCodeResponse, SendVerificationEmailCodeData>({
	requestPrefix: 'auth/sendVerificationEmailCode',
	reqConfigCreator: () => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.AUTH.VERIFICATION.EMAIL.SEND(),
		method: HTTP_METHODS_ENUM.POST,
	}),
	onSuccess: () => showToast.success('The code has been sent to your email.'),
});

export default sendVerificationEmailCode;
