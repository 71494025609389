import moment from 'moment';
import { useState } from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { showToast } from '@app/providers/ToastProvider';

import { ApplicationFormSelectedQuestion } from '@pages/ApplicationForm/containers/ApplicationFormMain';
import {
	getDayOptions,
	getMonthOptions,
	getYearOptions,
} from '@pages/ApplicationForm/containers/ApplicationFormMain/components/DateFieldApplicationForm/utils';

function useDateFieldApplicationForm({
	applicationId,
	question,
}: {
	applicationId: number;
	question: ApplicationFormSelectedQuestion;
}) {
	const [yearValue, setYearValue] = useState<number>(
		question.value && !question.is_encrypted ? Number((question.value as string).split('/')[2]) : 0,
	);
	const [monthValue, setMonthValue] = useState<number>(
		question.value && !question.is_encrypted ? Number((question.value as string).split('/')[0]) : 0,
	);
	const [dayValue, setDayValue] = useState<number>(
		question.value && !question.is_encrypted ? Number((question.value as string).split('/')[1]) : 0,
	);

	const { isPending: isPendingSaveAnswer, startRequest: saveDateAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.saveTextAnswer,
	);

	const { isPending: isPendingDeleteAnswer, startRequest: deleteAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.deleteAnswer,
	);

	const handleSaveAnswer = async (year: number, month: number, day: number) => {
		try {
			await saveDateAnswer({
				application_id: applicationId,
				question_id: question.id,
				data: moment(month + '/' + day + '/' + year).format('MM/DD/YYYY'),
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	const handleDeleteAnswer = async () => {
		try {
			await deleteAnswer({
				application_id: applicationId,
				question_id: question.id,
			});

			setYearValue(0);
			setMonthValue(0);
			setDayValue(0);
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	const handleChangeYear = (year: number) => {
		setYearValue(year);
		setMonthValue(0);
		setDayValue(0);
	};

	const handleChangeMonth = (month: number) => {
		setMonthValue(month);
		setDayValue(0);
	};

	const handleChangeDay = (day: number) => {
		setDayValue(day);
	};

	return {
		loading: isPendingSaveAnswer || isPendingDeleteAnswer,
		handleSaveAnswer,
		handleDeleteAnswer,
		yearValue,
		monthValue,
		dayValue,
		handleChangeYear,
		handleChangeMonth,
		handleChangeDay,
		yearOptions: getYearOptions({ question }),
		monthOptions: getMonthOptions({ question, year: yearValue }),
		dayOptions: getDayOptions({ question, year: yearValue, month: monthValue }),
	};
}

export default useDateFieldApplicationForm;
