import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import configRequest from '@api/requests/configRequest';

import { showToast } from '@app/providers/ToastProvider';

export interface VerifyEmailData {
	code: string;
}

export type VerifyEmailResponse = void;

const verifyEmail = configRequest<VerifyEmailResponse, VerifyEmailData>({
	requestPrefix: 'auth/verifyEmail',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.AUTH.VERIFICATION.EMAIL.VERIFY(),
		method: HTTP_METHODS_ENUM.POST,
		data,
	}),
	onSuccess: () => showToast.success('Email has been verified successfully.'),
});

export default verifyEmail;
