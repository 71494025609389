import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import configRequest from '@api/requests/configRequest';
import { FormInterface } from '@api/requests/forms/interfaces/FormInterface.interface';

export interface GetFormData {
	id: number;
	version?: number | null;
}

export interface GetFormResponse {
	form: FormInterface;
}

const getFormDetails = configRequest<GetFormResponse, GetFormData>({
	requestPrefix: 'forms/getFormDetails',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.FORMS.GET(),
		method: HTTP_METHODS_ENUM.POST,
		data: {
			...data,
			version: data?.version || undefined,
			with: [
				'pages.translations',
				'pages.sections.translations',
				'pages.sections.questions.translations',
				'pages.sections.questions.parent_questions.parent_question',
				'pages.sections.questions.parent_questions.parent_option',
				'pages.sections.questions.options.parent_options.parent_option',
				'pages.sections.questions.options.translations',
				'pages.sections.questions.description.translations',
			],
		},
	}),
});

export default getFormDetails;
