import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { FormNumberField, FormNumberFieldProps } from '../../FormControls';
import { fieldNames, TradingFormValues } from '../schema';
import { TradingFormContext } from '../TradingFormContext';
import { getTradingTotal } from '../utils';

export type QuantitySelectProps = Partial<FormNumberFieldProps> & { lastPrice?: number };

export const QuantitySelect: React.FunctionComponent<QuantitySelectProps> = ({ lastPrice, ...rest }) => {
    const { values, setFieldValue } = useFormikContext<TradingFormValues>();
    const {
        options: { allowFractional },
        meta: { accountTrading, buying },
    } = useContext(TradingFormContext);

    return (
        <FormNumberField
            label="Quantity"
            size="small"
            {...rest}
            name={fieldNames.quantity}
            validate={(value) => {
                let error = '';

                if ((!accountTrading || !allowFractional) && value < Math.floor(value)) {
                    error = 'Fractional is not allowed';
                }

                if (buying && value < Math.floor(value)) {
                    error = 'Fractional buying transaction is not supported. Please change to notional order.';
                }

                return error || rest.validate?.(value);
            }}
            onChange={(value) => {
                if (value) {
                    setFieldValue(
                        fieldNames.orderValue,
                        Math.floor(getTradingTotal(values, value || 0, lastPrice || 0)),
                    );
                }
            }}
        />
    );
};
