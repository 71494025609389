import { ThemeOptions } from '@mui/material';
import { createTheme } from './createTheme';

export const nextGenDarkThemeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#7489D8',
            dark: '#415BBA',
            light: '#48557D',
        },
        secondary: {
            main: '#8087AC',
            dark: '#656C93',
            light: '#47507C',
        },
        error: {
            main: '#FF2B6C',
            dark: '#E1255F',
            light: '#8E2647',
        },
        warning: {
            main: '#E5B000',
            dark: '#E1A200',
            light: '#F2D780',
        },
        success: {
            main: '#18C673',
            dark: '#12B065',
            light: '#1B734A',
        },
        info: {
            main: '#54A1E5',
        },
        neutral: {
            main: '#323F52',
            contrastText: '#fff',
        },
        background: {
            default: '#0E1625',
            paper: '#16243A',
        },
        common: {
            blue: '#54A1E5',
            green: '#0EAF4E',
            red: '#D13434',
            yellow: '#E5B000',
            pink: '#9969F5',
            darkBlue: '#1E2485',
            orange: '#E07B1D',
        },
        market: {
            up: '#18C673',
            down: '#FF2B6C',
        },
        consensus: {
            strongSell: '#FF2B6C',
            sell: '#FF2B6C',
            hold: '#54A1E5',
            buy: '#18C673',
            strongBuy: '#18C673',
        },
        accountClassification: {
            cash: '#E5B000',
            margin: '#18C673',
            crypto: '#E07B1D',
        },
        divider: '#293859',
        text: {
            primary: '#FFFFFF',
            secondary: '#8087AC',
        },
        action: {
            active: '#8087AC',
            hover: '#293859',
            disabled: '#293859',
            activatedOpacity: 0.5,
        },
        analystRatings: {
            success: '#1D312A',
            fail: '#342229',
            successBorder: '#1C4A36',
            failBorder: '#562334',
            topPercentageBadge: '#1D2A43',
        },
    },
    shape: {
        borderRadius: 6,
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    border: '1px solid #293859',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#47507C',
                    color: '#FFFFFF',
                },
                arrow: {
                    color: '#47507C',
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#293859',
                },
            },
        },
    },
};

export const nextGenDarkTheme = createTheme(nextGenDarkThemeOptions);
