import { Box, Typography, useTheme, alpha } from '@mui/material';
import React from 'react';
import { FormControlGroup, FormControlGroupProps } from '../../FormControls';
import { IconButtonWithTooltip } from '../../IconButtonWithTooltip';
import { ExitToApp as ToggleOptionTableIcon } from '@mui/icons-material';
import { formatDate, formatMoney } from '../../../utils';

export type OptionLeg = {
    call: boolean;
    strikePrice: number;
    expirationDate: string;
    lastPrice: number;
    bid: number;
    ask: number;
    symbol: string;
    underlyingSymbol?: string;
};

export type OptionLegQuoteProps = {
    quote: OptionLeg;
};

export const OptionLegQuote: React.FunctionComponent<OptionLegQuoteProps> = ({ quote }) => {
    const theme = useTheme();
    const backgroundColor = quote.call ? theme.palette.primary.main : theme.palette.common.red;

    return (
        <Typography>
            {`${quote.strikePrice}`}
            <span
                style={{
                    backgroundColor,
                    color: theme.palette.getContrastText(backgroundColor),
                    padding: 4,
                    margin: 4,
                    borderRadius: 4,
                    fontSize: 12,
                    verticalAlign: 'middle',
                }}
            >
                {quote.call ? 'Call' : 'Put'}
            </span>
            {formatDate(quote.expirationDate)}
            <br />
            <Typography variant="caption">
                {formatMoney(quote?.lastPrice)}&nbsp;&nbsp;&nbsp;&nbsp;
                {formatMoney(quote?.bid)} / {formatMoney(quote?.ask)}
            </Typography>
        </Typography>
    );
};

export type OptionSelectButtonProps = {
    onSelect: () => void;
    active: boolean;
};

export const OptionLegButton: React.FunctionComponent<OptionSelectButtonProps> = ({ active, onSelect }) => {
    const theme = useTheme();

    return (
        <Box sx={{ right: 6, top: 6, position: 'absolute' }}>
            <IconButtonWithTooltip
                tooltip="Toggle Option Chain Table"
                size="small"
                icon={<ToggleOptionTableIcon />}
                sx={{
                    color: active ? theme.palette.text.primary : alpha(theme.palette.text.secondary, 0.4),
                }}
                onClick={onSelect}
            />
        </Box>
    );
};

export type OptionLegSelectProps = Partial<FormControlGroupProps> & {
    label: string;
    name: string;
    touched: boolean;
    quote?: OptionLeg;
    active: boolean;
    onSelect: () => void;
};

export const OptionLegSelect: React.FunctionComponent<OptionLegSelectProps> = ({
    label,
    name,
    touched,
    quote,
    active,
    onSelect,
    ...formControlGroupProps
}) => {
    return (
        <FormControlGroup
            label={label}
            row
            outlined
            fullWidth
            touched={!!touched}
            {...formControlGroupProps}
            name={name}
            validate={(value) => {
                let error = '';
                if (!value) {
                    error = 'Please select option';
                }
                return error || formControlGroupProps.validate?.(value);
            }}
        >
            <OptionLegButton onSelect={onSelect} active={active} />

            {quote ? <OptionLegQuote quote={quote} /> : <Typography>Select option</Typography>}
        </FormControlGroup>
    );
};
