import React from 'react';
import { FormSelect, FormSelectProps, renderSelectOptions } from '../../FormControls';
import { validityOptions } from '../selectOptions';
import { fieldNames } from '../schema';

export type ValiditySelectProps = Partial<FormSelectProps>;

export const ValiditySelect: React.FunctionComponent<ValiditySelectProps> = (props) => {
    return (
        <FormSelect label="Validity" size="small" {...props} name={fieldNames.validity}>
            {renderSelectOptions(Object.values(validityOptions))}
        </FormSelect>
    );
};
