import * as Yup from 'yup';

const changePasswordFormValidationScheme = Yup.object().shape({
	old_password: Yup.string()
		.required('This field is required.')
		.min(8, 'Password must be at least 8 characters long.')
		.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, {
			message: 'Password must contain at least 1 lowercase character, 1 uppercase character and 1 number.',
		}),
	password: Yup.string()
		.required('This field is required.')
		.min(8, 'Password must be at least 8 characters long.')
		.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, {
			message: 'Password must contain at least 1 lowercase character, 1 uppercase character and 1 number.',
		}),
	password_confirmation: Yup.string()
		.required('This field is required.')
		.min(8, 'Password must be at least 8 characters long.')
		.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, {
			message: 'Password must contain at least 1 lowercase character, 1 uppercase character and 1 number.',
		})
		.oneOf([Yup.ref('password')], "Passwords don't match."),
});

export default changePasswordFormValidationScheme;
