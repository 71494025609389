import { useState } from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { showToast } from '@app/providers/ToastProvider';

export enum SIGNATURE_FIELD_TYPE_ENUM {
	TYPE,
	DRAW,
	UPLOAD,
}

function useSignatureFieldApplicationForm({
	applicationId,
	questionId,
}: {
	applicationId: number;
	questionId: number;
}) {
	const [signatureType, setSignatureType] = useState<SIGNATURE_FIELD_TYPE_ENUM>(SIGNATURE_FIELD_TYPE_ENUM.TYPE);

	const { isPending: isPendingSaveAnswer, startRequest: saveFileAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.saveFileAnswer,
	);

	const { isPending: isPendingDeleteAnswer, startRequest: deleteAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.deleteAnswer,
	);

	const handleSaveAnswer = async (file: File) => {
		try {
			await saveFileAnswer({
				application_id: applicationId,
				question_id: questionId,
				file,
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	const handleDeleteAnswer = async () => {
		try {
			await deleteAnswer({
				application_id: applicationId,
				question_id: questionId,
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	return {
		loading: isPendingSaveAnswer || isPendingDeleteAnswer,
		handleSaveAnswer,
		handleDeleteAnswer,
		signatureType,
		setSignatureType,
	};
}

export default useSignatureFieldApplicationForm;
