import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import configRequest from '@api/requests/configRequest';

import ApplicationDetailsInterface from './interfaces/ApplicationDetailsInterface.interface';

export interface GetApplicationData {
	application_id: number;
}

export interface GetApplicationResponse {
	application: ApplicationDetailsInterface;
}

const getApplicationDetails = configRequest<GetApplicationResponse, GetApplicationData>({
	requestPrefix: 'applications/getApplicationDetails',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.APPLICATIONS.GET(),
		method: HTTP_METHODS_ENUM.POST,
		data: {
			...data,
			with: [
				'answers.option.translations',
				'answers.question.translations',
				'answers.document.current_status.timestamps',
				'current_status',
				'form',
				'statuses.timestamps',
				'documents.current_status.timestamps',
				'documents.answer.question.translations',
				'branch.services.settings',
				'background_checks',
				'timestamps',
				'payment',
			],
		},
	}),
});

export default getApplicationDetails;
