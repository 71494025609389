import { useFormik } from 'formik';

import { getAssetsCountries } from '@app/redux/assets/assets.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { CurrentUserFormProps, CurrentUserFormValues } from './interfaces';
import currentUserFormValidationScheme from './validationScheme';

function useCurrentUserForm({ initialValues, onSubmit }: Pick<CurrentUserFormProps, 'initialValues' | 'onSubmit'>) {
	const assetsCountries = useAppSelector(getAssetsCountries);

	const formik = useFormik<CurrentUserFormValues>({
		initialValues,
		validationSchema: currentUserFormValidationScheme,
		onSubmit,
	});

	return {
		phoneCodeOptions: assetsCountries.map(country => ({
			dial_code: country.dial_code,
			country_name: country.name,
		})),
		formik,
	};
}

export default useCurrentUserForm;
