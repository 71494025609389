import React, { useMemo } from 'react';
import { FormSelect, FormSelectOption, FormSelectProps, renderSelectOptions } from '../../FormControls';
import { positionTypeOptions } from '../selectOptions';
import { fieldNames } from '../schema';
import { cloneDeep } from 'lodash';

export type PositionTypeSelectProps = Partial<FormSelectProps> & {
    isMarginAccount?: boolean;
};

export const PositionTypeSelect: React.FunctionComponent<PositionTypeSelectProps> = ({
    isMarginAccount = false,
    ...rest
}) => {
    const positionTypes = useMemo(() => {
        const result: Partial<typeof positionTypeOptions> = cloneDeep(positionTypeOptions);

        if (!isMarginAccount) {
            delete result.margin;
            delete result.short;
        }

        return Object.values(result) as FormSelectOption[];
    }, [isMarginAccount]);

    return (
        <FormSelect label="Position Type" size="small" {...rest} name={fieldNames.positionType}>
            {renderSelectOptions(Object.values(positionTypes))}
        </FormSelect>
    );
};
