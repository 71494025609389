import {
    Button,
    ButtonProps,
    Checkbox,
    CheckboxProps,
    FormControlLabel,
    FormControlLabelProps,
    Radio,
    RadioProps,
    styled,
    Switch,
    SwitchProps,
} from '@mui/material';
import clsx from 'clsx';
import { Field, FieldProps } from 'formik';
import _ from 'lodash';
import React from 'react';
import { FieldConfigProps } from '.';
import { generateNamesObject } from '../../utils/stringUtils';

export const formButtonClasses = generateNamesObject('button', 'selectedButton');

export type FormButtonProps = FieldConfigProps & Partial<ButtonProps>;

const StyledButton = styled(Button)(({ theme }) => ({
    [`& .${formButtonClasses.button}`]: {
        color: theme.palette.text.secondary,
    },
    [`& .${formButtonClasses.selectedButton}`]: {
        color: `${theme.palette.text.primary} !important`,
    },
}));

export const FormButton: React.FunctionComponent<FormButtonProps> = ({
    name,
    validate,
    children,
    value,
    onClick,
    ...rest
}) => {
    return (
        <Field name={name} validate={validate}>
            {({ form: { setFieldValue, values } }: FieldProps) => {
                const selected = _.get(values, name) === value;
                return (
                    <StyledButton
                        color="primary"
                        {...rest}
                        className={clsx(rest.className, formButtonClasses.button, {
                            [formButtonClasses.selectedButton]: selected,
                        })}
                        value={value}
                        variant={selected ? 'contained' : 'outlined'}
                        onClick={(event) => {
                            setFieldValue(name, value);
                            onClick?.(event);
                        }}
                    >
                        {children}
                    </StyledButton>
                );
            }}
        </Field>
    );
};

type FormControlLabelButtonProps = FieldConfigProps &
    Partial<FormControlLabelProps> &
    Pick<FormControlLabelProps, 'label'>;

export type FormRadioButtonProps = FormControlLabelButtonProps & {
    radioProps?: RadioProps;
};

export const FormRadioButton: React.FunctionComponent<FormRadioButtonProps> = ({
    name,
    validate,
    value,
    radioProps,
    ...rest
}) => {
    return (
        <Field name={name} validate={validate}>
            {({ form: { setFieldValue, values } }: FieldProps) => {
                const selected = _.get(values, name) === value;
                return (
                    <FormControlLabel
                        {...rest}
                        control={<Radio {...radioProps} />}
                        checked={selected}
                        onChange={(event, checked) => {
                            if (checked) {
                                setFieldValue(name, value);
                            }
                            rest.onChange?.(event, checked);
                        }}
                        value={value}
                    />
                );
            }}
        </Field>
    );
};

export type FormCheckboxButtonProps = FormControlLabelButtonProps & {
    checkboxProps?: CheckboxProps;
};

export const FormCheckboxButton: React.FunctionComponent<FormCheckboxButtonProps> = ({
    name,
    validate,
    value,
    checkboxProps,
    ...rest
}) => {
    return (
        <Field name={name} validate={validate}>
            {({ form: { setFieldValue, values } }: FieldProps) => {
                const selected = _.get(values, name) ?? [];

                if (!_.isArray(selected)) {
                    throw new Error('Values of checked checkboxes must be stored in an array');
                }

                return (
                    <FormControlLabel
                        {...rest}
                        control={<Checkbox {...checkboxProps} />}
                        checked={selected.includes(value)}
                        onChange={(event, checked) => {
                            setFieldValue(name, _.xor(selected, [value]));
                            rest.onChange?.(event, checked);
                        }}
                        value={value}
                    />
                );
            }}
        </Field>
    );
};

export type FormSwitchButtonProps = FormControlLabelButtonProps & {
    switchProps?: SwitchProps;
};

export const FormSwitchButton: React.FunctionComponent<FormSwitchButtonProps> = ({
    name,
    validate,
    switchProps,
    ...rest
}) => {
    return (
        <Field name={name} validate={validate}>
            {({ form: { setFieldValue, values } }: FieldProps) => {
                const selected = _.get(values, name) === true;
                return (
                    <FormControlLabel
                        {...rest}
                        control={<Switch {...switchProps} />}
                        checked={selected}
                        onChange={(event, checked) => {
                            setFieldValue(name, checked);
                            rest.onChange?.(event, checked);
                        }}
                    />
                );
            }}
        </Field>
    );
};
