import { OrtkProviders, changeTheme } from '@orbis-systems/orbis-react-toolkit';
import React, { useMemo } from 'react';

import cache from '@app/cache';
import { getCurrentTheme } from '@app/redux/assets/assets.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { createTheme } from '../../themes';

function ThemeProvider({ children }: { children: JSX.Element }) {
	const currentTheme = useAppSelector(getCurrentTheme);

	const ortkTheme = useMemo(() => changeTheme(createTheme(currentTheme === 'dark', false)), [currentTheme]);

	cache.applicationTheme.set(currentTheme);

	return <OrtkProviders theme={ortkTheme}>{children}</OrtkProviders>;
}

export default ThemeProvider;
