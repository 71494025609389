import { Box, Divider, styled } from '@mui/material';

export const ApplicationFormNavigationDivider = styled(Divider)(({ theme }) => ({
	borderWidth: 1,
	borderColor: theme.palette.divider,
	margin: theme.spacing(2, 0, 2, 0),
}));

export const ApplicationFormNavigationBox = styled(Box)(({ theme }) => ({
	background: theme.palette.background.paper,
	height: '100%',
}));
