import React from 'react';

import { Typography } from '@mui/material';

import { StyledSubmitButton } from './styles';

function SubmitButton({ label, disabled }: { label: string; disabled?: boolean }) {
	return (
		<StyledSubmitButton fullWidth type="submit" variant="contained" color="primary" disabled={disabled}>
			<Typography variant={'subtitle1'}>
				<b>{label}</b>
			</Typography>
		</StyledSubmitButton>
	);
}

export default SubmitButton;
