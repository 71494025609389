import { Box, styled } from '@mui/material';

export const UnauthorizedHeader = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginTop: theme.spacing(4),
	padding: theme.spacing(0, 8, 0, 4),
	[theme.breakpoints.down('lg')]: {
		padding: theme.spacing(0, 4, 0, 4),
	},
	[theme.breakpoints.down('md')]: {
		padding: theme.spacing(0, 2, 0, 2),
	},
}));
