import { miniSerializeError, SerializedError } from '@reduxjs/toolkit';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { isBoolean } from 'lodash';
import { getUuid } from '../utils';
import { dismissLoader, showAlertDialog, showLoader } from './alertActions';
import { LoadingMessage } from './alertReducer';

export type CreateQueryFnOptions = {
    alertIfError?: boolean;
    pendingMessage?: boolean | string;
    onErrorAlert?: (error: SerializedError) => void;
    serializeError?: (error: any) => SerializedError;
};

type ExtraArgs = {
    _alertIfError?: boolean;
    _pendingMessage?: boolean | string;
};

type ArgsWithExtra<Args> = Args extends void
    ? void | ExtraArgs
    : Args extends Record<string, any>
    ? Args & ExtraArgs
    : Args | ({ _arg: Args } & ExtraArgs);

export const createQueryFn = <Args = void, Result = unknown, Error = unknown, ExtraOptions = {}>(
    queryFn: (args: Args, queryApi: BaseQueryApi, extraOptions: ExtraOptions) => MaybePromise<Result>,
    options?: CreateQueryFnOptions,
): BaseQueryFn<ArgsWithExtra<Args>, Result, Error, ExtraOptions> => {
    return async (args, queryApi, extraOptions) => {
        let alertIfError: boolean | undefined = options?.alertIfError;
        let pendingMessage: boolean | string | undefined = options?.pendingMessage;

        if (args !== undefined) {
            alertIfError = (args as any)._alertIfError ?? alertIfError;
            pendingMessage = (args as any)._pendingMessage ?? pendingMessage;
        }

        const loadingMessage: LoadingMessage | null = !pendingMessage
            ? null
            : {
                  id: getUuid(),
                  content: isBoolean(pendingMessage) ? 'Loading…' : pendingMessage,
              };

        try {
            if (loadingMessage) {
                queryApi.dispatch(showLoader(loadingMessage));
            }

            const finalArgs = args !== undefined ? (args as any)._arg ?? args : args;
            const data = await queryFn(finalArgs, queryApi, extraOptions);

            return {
                data: (data || null) as Result,
            };
        } catch (error) {
            if ((alertIfError === undefined && !!loadingMessage) || alertIfError) {
                const { serializeError, onErrorAlert } = options || {};

                const serializedError = serializeError?.(error) || miniSerializeError(error);
                if (onErrorAlert) {
                    onErrorAlert(serializedError);
                } else {
                    queryApi.dispatch(
                        showAlertDialog({
                            title: serializedError.name || '',
                            content: serializedError.message || '',
                        }),
                    );
                }
            }

            return {
                error: error as Error,
            };
        } finally {
            if (loadingMessage) {
                queryApi.dispatch(dismissLoader(loadingMessage.id));
            }
        }
    };
};
