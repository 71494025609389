import { createSlice } from '@reduxjs/toolkit';

import AccountClientRequests from '@api/requests';

import { ApplicationsStateType } from './applications.interfaces';

const initialState: ApplicationsStateType = {
	list: [],
	details: undefined,
	form: undefined,
};

export const { actions: applicationsActions, reducer: applicationsReducer } = createSlice({
	name: 'applications',
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(AccountClientRequests.Applications.getApplicationDetails.fulfilled, (state, { payload }) => {
				state.details = payload.application;
			})
			.addCase(AccountClientRequests.Forms.getFormDetails.fulfilled, (state, { payload }) => {
				state.form = payload.form;
			})
			.addCase(AccountClientRequests.Applications.createApplication.fulfilled, (state, { payload }) => {
				state.list = [...state.list, payload.application].sort((applicationLeft, applicationRight) => {
					if (applicationLeft.account_number === null) return 1;
					if (applicationRight.account_number === null) return -1;
					return 0;
				});
			})
			.addCase(AccountClientRequests.Applications.getApplications.fulfilled, (state, { payload }) => {
				state.list = payload.applications.sort((applicationLeft, applicationRight) => {
					if (applicationLeft.account_number === null) return 1;
					if (applicationRight.account_number === null) return -1;
					return 0;
				});
			})
			.addCase(AccountClientRequests.Applications.saveFileAnswer.fulfilled, (state, { payload, meta }) => {
				state.list = state.list.map(application =>
					application.id === meta.arg.application_id
						? {
								...application,
								documents: application.documents.map(document =>
									document.id === payload.answer.document.id
										? {
												...document,
												current_status: payload.answer.document.current_status,
										  }
										: document,
								),
						  }
						: application,
				);

				if (state.details) {
					state.details.documents = state.details.documents.map(document =>
						document.id === payload.answer.document.id
							? {
									...document,
									current_status: payload.answer.document.current_status,
							  }
							: document,
					);
				}
			})
			.addCase(AccountClientRequests.Applications.submitApplicationForm.fulfilled, (state, { payload }) => {
				state.list = state.list.map(application =>
					application.id === payload.application.id
						? {
								...application,
								account_number: payload.application.account_number,
								submitted_at: payload.application.submitted_at,
								current_status: payload.application.current_status,
						  }
						: application,
				);

				if (state.details) {
					state.details.account_number = payload.application.account_number;
					state.details.submitted_at = payload.application.submitted_at;
					state.details.current_status = payload.application.current_status;
				}
			})
			.addCase(AccountClientRequests.Applications.backgroundCheckApplicationForm.fulfilled, (state, { payload }) => {
				state.list = state.list.map(application =>
					application.id === payload.application.id
						? {
								...application,
								account_number: payload.application.account_number,
								submitted_at: payload.application.submitted_at,
								current_status: payload.application.current_status,
						  }
						: application,
				);

				if (state.details) {
					state.details.account_number = payload.application.account_number;
					state.details.submitted_at = payload.application.submitted_at;
					state.details.current_status = payload.application.current_status;
				}
			})
			.addCase(AccountClientRequests.Applications.verifyDocumentApplicationForm.fulfilled, (state, { payload }) => {
				state.list = state.list.map(application =>
					application.id === payload.application.id
						? {
								...application,
								account_number: payload.application.account_number,
								submitted_at: payload.application.submitted_at,
								current_status: payload.application.current_status,
						  }
						: application,
				);

				if (state.details) {
					state.details.account_number = payload.application.account_number;
					state.details.submitted_at = payload.application.submitted_at;
					state.details.current_status = payload.application.current_status;
				}
			})
			.addCase(AccountClientRequests.Applications.custodianCheckApplicationForm.fulfilled, (state, { payload }) => {
				state.list = state.list.map(application =>
					application.id === payload.application.id
						? {
								...application,
								account_number: payload.application.account_number,
								submitted_at: payload.application.submitted_at,
								current_status: payload.application.current_status,
						  }
						: application,
				);

				if (state.details) {
					state.details.account_number = payload.application.account_number;
					state.details.submitted_at = payload.application.submitted_at;
					state.details.current_status = payload.application.current_status;
				}
			})
			.addCase(AccountClientRequests.Auth.logout.fulfilled, state => {
				state.list = initialState.list;
				state.details = initialState.details;
				state.form = initialState.form;
			}),
});
