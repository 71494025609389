import { useEffect, useRef, useState } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';

import { showToast } from '@app/providers/ToastProvider';

function useUploadSignatureCanvas({ handleSaveAnswer }: { handleSaveAnswer: (file: File) => Promise<void> }) {
	const canvasRef = useRef<ReactSignatureCanvas>(null);
	const [canvasIsEmpty, setCanvasIsEmpty] = useState(true);

	const handleClearCanvas = () => {
		if (canvasRef && canvasRef?.current) {
			canvasRef.current.clear();
		}
	};

	const handleSaveCanvas = () => {
		if (canvasRef && canvasRef?.current) {
			if (canvasRef.current.isEmpty()) {
				showToast.error('You should draw something.');
			} else {
				canvasRef.current.getTrimmedCanvas().toBlob(blob => {
					if (blob) {
						handleSaveAnswer(new File([blob], 'signature.png'));
					}
				});
			}
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			if (canvasRef && canvasRef?.current) {
				setCanvasIsEmpty(canvasRef.current.isEmpty());
			}
		}, 500);
		return () => clearInterval(interval);
	}, []);

	return {
		canvasRef,
		canvasIsEmpty,
		handleClearCanvas,
		handleSaveCanvas,
	};
}

export default useUploadSignatureCanvas;
