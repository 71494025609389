import React from 'react';

import { FIRM_NAME_MAP } from '@app/layouts/UnauthorizedPageLayout/constants';
import { store } from '@app/redux/store';

import { Grid, Typography } from '@mui/material';
import { Button } from '@mui/material';

import * as environment from '@utils/environment';

const WELCOME_LETTERS_MAP = new Map<string, (props: { firm: string }) => JSX.Element>([
	[
		'axos-crypto',
		() => (
			<Grid container>
				<Grid item xs={12} mb={1}>
					<Typography variant={'h6'}>Welcome to Axos Digital Assets!</Typography>
				</Grid>

				<Grid item xs={12} mb={2}>
					<Typography variant={'body2'}>
						Below, you will find information and links to be able to access your account, along with the account funding
						instructions.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Please verify your account information in the Axos Digital Assets platform, by performing the following
						actions:
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Visit{' '}
						<a href={'https://trading.axosdigitalassets.com'} target={'_blank'} rel="noreferrer">
							trading.axosdigitalassets.com
						</a>
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Enter the user id and password provided via SMS to your cell phone number.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						**Remember that the passwords are case sensitive, and please input exactly as given. For the best user
						experience, please use Google Chrome, Firefox or Edge as your browser. Also, please immediately change the
						initial password as soon as you login to the website.**
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>To send funds to your Axos Digital Assets account, please:</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Log in to your account.</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Initiate the transfer of funds from your bank account.</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Once the account is funded, your dollars will be converted into Axos Coins – these are stablecoins issued by
						Axos Digital Assets. You may begin to purchase digital assets through the platform.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Please note, when communicating with Axos Digital Assets, you must use the e-mail of record. E-mail
						communications from third parties will be rejected.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						If you have any questions, please feel free to contact Axos Digital Asset Support at{' '}
						<a href="mailto: support@axosdigitalassets.com">support@axosdigitalassets.com</a>
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Please refer to the user agreement for the terms and conditions that govern your account with Axos Digital
						Assets.
					</Typography>
				</Grid>
			</Grid>
		),
	],
	[
		'abm',
		({ firm }) => (
			<Grid container>
				<Grid item xs={12} mb={1}>
					<Typography variant={'h6'}>Welcome letter</Typography>
				</Grid>

				<Grid item xs={12} mb={2}>
					<Typography variant={'caption'} color={'textSecondary'}>
						***Please make sure to read and keep the information below for future reference as you will need it to be
						able to access your account***
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Dear Client,</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Welcome to ViewTrade Securities.</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Below, you will find all the information and links to be able to access your account along with the funding
						instructions.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						To see all your account information in the Apex Clearing platform (our clearing firm) do the following:
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Please visit{' '}
						<a href={'https://public-apps.apexclearing.com/session/#/login'} target={'_blank'} rel="noreferrer">
							public-apps.apexclearing.com
						</a>{' '}
						and just following the directions on how to create a user ID.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						For USA clients, you will need to enter your social security number.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						For foreign clients, please make sure to use the address that you entered on the first line of your
						application, otherwise you would not be able to continue.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>To be able to trade in your account please do the following:</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Please visit{' '}
						{firm === 'abm' ? (
							<a href={'https://abm-wt-secure.viewtrade.com'} target={'_blank'} rel="noreferrer">
								abm-wt-secure.viewtrade.com
							</a>
						) : (
							<a href={'https://webtrade.orbisfn.com'} target={'_blank'} rel="noreferrer">
								webtrade.orbisfn.com
							</a>
						)}{' '}
						and use the user id and password that was just provided via SMS to the phone number you entered during your
						registration. Remember that the passwords are case sensitive, please Input exactly as given.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						**Please use GOOGLE CHROME, FIREFOX or EDGE as your browser for this web site***
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Make sure to change the provided password as soon as you enter the websites for your own security.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Once the account is funded you may begin to trade. To fund your account, you will find the wiring
						instructions below:
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Apex address</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>350 N St. Paul Street</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Suite 1300</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Dallas, TX 75201</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Bank: BMO Harris Bank</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Address: 111 W Monroe St Chicago, IL 60603</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>ABA: 071000288</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>SWIFT: HATRUS44</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Account Name: Apex Clearing</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Account Number: 3713286</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>For Further Credit: Customer Name (your name)</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Further Account: Your APEX account number - Please be sure to include your Apex account number under FFC
						(for further credit). If you do not reference your account number, the funds will not be credited to your
						account.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Please note, when communicating with ViewTrade Securities, you must use the e-mail of record. E-mail
						communications from third parties will be rejected.
					</Typography>
				</Grid>
			</Grid>
		),
	],
	[
		'viewtrade',
		({ firm }) => (
			<Grid container>
				<Grid item xs={12} mb={1}>
					<Typography variant={'h6'}>Welcome letter</Typography>
				</Grid>

				<Grid item xs={12} mb={2}>
					<Typography variant={'caption'} color={'textSecondary'}>
						***Please make sure to read and keep the information below for future reference as you will need it to be
						able to access your account***
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Dear Client,</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Welcome to ViewTrade Securities.</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Below, you will find all the information and links to be able to access your account along with the funding
						instructions.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						To see all your account information in the Apex Clearing platform (our clearing firm) do the following:
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Please visit{' '}
						<a href={'https://public-apps.apexclearing.com/session/#/login'} target={'_blank'} rel="noreferrer">
							public-apps.apexclearing.com
						</a>{' '}
						and just following the directions on how to create a user ID.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						For USA clients, you will need to enter your social security number.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						For foreign clients, please make sure to use the address that you entered on the first line of your
						application, otherwise you would not be able to continue.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>To be able to trade in your account please do the following:</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Please visit{' '}
						{firm === 'abm' ? (
							<a href={'https://abm-wt-secure.viewtrade.com'} target={'_blank'} rel="noreferrer">
								abm-wt-secure.viewtrade.com
							</a>
						) : (
							<a href={'https://webtrade.orbisfn.com'} target={'_blank'} rel="noreferrer">
								webtrade.orbisfn.com
							</a>
						)}{' '}
						and use the user id and password that was just provided via SMS to the phone number you entered during your
						registration. Remember that the passwords are case sensitive, please Input exactly as given.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						**Please use GOOGLE CHROME, FIREFOX or EDGE as your browser for this web site***
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Make sure to change the provided password as soon as you enter the websites for your own security.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Once the account is funded you may begin to trade. To fund your account, you will find the wiring
						instructions below:
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Apex address</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>350 N St. Paul Street</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Suite 1300</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Dallas, TX 75201</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Bank: BMO Harris Bank</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Address: 111 W Monroe St Chicago, IL 60603</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>ABA: 071000288</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>SWIFT: HATRUS44</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Account Name: Apex Clearing</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>Account Number: 3713286</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>For Further Credit: Customer Name (your name)</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Further Account: Your APEX account number - Please be sure to include your Apex account number under FFC
						(for further credit). If you do not reference your account number, the funds will not be credited to your
						account.
					</Typography>
				</Grid>

				<Grid item xs={12} mb={1}>
					<Typography variant={'body2'}>
						Please note, when communicating with ViewTrade Securities, you must use the e-mail of record. E-mail
						communications from third parties will be rejected.
					</Typography>
				</Grid>
			</Grid>
		),
	],
]);

function WelcomeLetter({ firm, application_id }: { firm: string; application_id: number }) {
	const SpecificLetter = WELCOME_LETTERS_MAP.get(firm);

	if (SpecificLetter) return <SpecificLetter firm={firm} />;

	return (
		<Grid container>
			<Grid item xs={12} mb={1}>
				<Typography variant={'h6'}>Welcome to Onboarding!</Typography>
			</Grid>
			{(process.env.FIRM_ENVIRONMENT === 'bosonic' || process.env.FIRM_ENVIRONMENT === 'secondlane') && (
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							const token = store.getState().auth.tokens?.access_token;
							if (process.env.BUILD_ENVIRONMENT === 'production') {
								window.open(
									`https://onboarding.bosonic.digital/signing/login?id=${application_id}&t=${token}`,
									'_blank',
								);
							} else {
								window.open(
									`https://onboarding-qa.bosonicnetwork.com/signing/login?id=${application_id}&t=${token}`,
									'_blank',
								);
							}
						}}
						sx={{ textTransform: 'initial', width: 200, height: 32 }}
					>
						<Typography variant={'caption'} ml={1}>
							<b style={{ whiteSpace: 'nowrap' }}>
								{' '}
								{`Finalize ${FIRM_NAME_MAP.get(environment.env.FIRM_ENVIRONMENT)} Account`}
							</b>
						</Typography>
					</Button>
				</Grid>
			)}
		</Grid>
	);
}

export default WelcomeLetter;
