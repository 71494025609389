import FileSaver from 'file-saver';
import { isArray, mergeWith } from 'lodash';

export function convertBlobToBase64(blob: Blob) {
    return new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(btoa(fileReader.result as string));
        fileReader.onerror = (event) => reject(event);
        fileReader.readAsBinaryString(blob);
    });
}

export function getBackgroundPreference() {
    return localStorage.getItem('background') || ' ';
}

export function setBackgroundPreference(background: string) {
    return localStorage.setItem('background', background);
}

// https://github.com/darkskyapp/string-hash
export function getHashFromString(str = ''): string {
    let hash = 5381;
    let i = str.length;

    while (i) {
        hash = (hash * 33) ^ str.charCodeAt(--i);
    }

    /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
     * integers. Since we want the results to be always positive, convert the
     * signed int to an unsigned by doing an unsigned bitshift. */
    return (hash >>> 0).toString(16);
}

export function getKeyByArguments(...args: any[]) {
    return args.join(':');
}

export function isPresent<T>(t: T | undefined | null | void): t is T {
    return t !== undefined && t !== null;
}

export function pickRandom<T>(array: T[]) {
    return array[Math.floor(Math.random() * array.length)];
}

export function reorder<T = any>(input: T[], sourceIndex: number, destinationIndex: number): T[] {
    const output = [...input];
    const item = output[sourceIndex];
    output.splice(sourceIndex, 1);
    output.splice(destinationIndex, 0, item);
    return output;
}

export function mergeOverwriteArray(obj: any, src: any) {
    return mergeWith(obj, src, (objValue, srcValue) => {
        if (isArray(srcValue)) {
            return srcValue;
        }

        return;
    });
}

export function saveToCsv(content: string[][], filename: string) {
    const csvData = content.map((row) => row.join(',')).join('\n');
    const bom = '\uFEFF';
    const blob = new Blob([bom + csvData], {
        type: 'text/plain;charset=utf-8',
    });

    FileSaver.saveAs(blob, filename);
}
