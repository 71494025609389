import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import configRequest from '@api/requests/configRequest';

import { showToast } from '@app/providers/ToastProvider';

export interface RegistrationData {
	email: string;
	first_name: string;
	last_name: string;
	password: string;
	password_confirmation: string;
	phone: string;
	recaptcha_token: string;
}

export type RegistrationResponse = void;

const registration = configRequest<RegistrationResponse, RegistrationData>({
	requestPrefix: 'auth/registration',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.AUTH.REGISTRATION(),
		method: HTTP_METHODS_ENUM.POST,
		data,
	}),
	onSuccess: () => showToast.success('User has been registered.'),
});

export default registration;
