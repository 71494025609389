import { AlertMessage, dismissAlertDialog, selectAlert } from '../../redux';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertDialog } from '../AlertDialog/AlertDialog';

export type AlertDialogHandlerComponentProps = { alert: AlertMessage };

export type AlertDialogHandlerProps = {
    render?: React.FunctionComponent<AlertDialogHandlerComponentProps>;
};

export const AlertDialogHandler: React.FunctionComponent<AlertDialogHandlerProps> = ({
    render: Component = DefaultAlertDialog,
}) => {
    const dispatch = useDispatch();
    const alerts = useSelector((state) => selectAlert(state)).alerts;

    return (
        <>
            {alerts.map((alert) => {
                return (
                    <Component
                        key={alert.id}
                        alert={{
                            ...alert,
                            onClose:
                                alert.onClose ??
                                ((event, id) => {
                                    if (alert.onClose) {
                                        alert.onClose(event, id);
                                    } else {
                                        dispatch(dismissAlertDialog(id));
                                    }
                                }),
                        }}
                    />
                );
            })}
        </>
    );
};

export const DefaultAlertDialog: React.FunctionComponent<AlertDialogHandlerComponentProps> = ({ alert }) => {
    const {
        id,
        dismissed,
        content,
        title,
        positiveButton,
        negativeButton,
        neutralButton,
        onClose,
        onPositiveClick,
        onNegativeClick,
        onNeutralClick,
    } = alert;

    return (
        <AlertDialog
            key={id}
            open={!dismissed}
            messageHtml={content}
            title={title}
            maxWidth="md"
            onClose={(event) => onClose?.(event, id)}
            positiveButton={positiveButton}
            negativeButton={negativeButton}
            neutralButton={neutralButton}
            onPositiveClick={(event) => {
                if (onPositiveClick) {
                    onPositiveClick(event, id);
                } else if (!negativeButton && !neutralButton) {
                    onClose?.(event, id);
                }
            }}
            onNegativeClick={(event) => {
                if (onNegativeClick) {
                    onNegativeClick(event, id);
                } else {
                    onClose?.(event, id);
                }
            }}
            onNeutralClick={(event) => onNeutralClick?.(event, id)}
        />
    );
};
