import React from 'react';
import { FormControlGroup, FormControlGroupProps, FormRadioButton, FormRadioButtonProps } from '../../FormControls';
import { MutualFundUnit } from '../lookups';
import { fieldNames } from '../schema';

export type MutualFundUnitSelectProps = {
    formControlGroupProps?: Partial<FormControlGroupProps>;
    formRadioButtonProps?: Partial<FormRadioButtonProps>;
};

export const MutualFundUnitSelect: React.FunctionComponent<MutualFundUnitSelectProps> = ({
    formControlGroupProps,
    formRadioButtonProps,
}) => {
    return (
        <FormControlGroup
            row
            fullWidth
            outlined
            variant="outlined"
            label="Unit"
            {...formControlGroupProps}
            name={fieldNames.mutualFundUnit}
        >
            <FormRadioButton
                label="Unit"
                {...formRadioButtonProps}
                name={fieldNames.mutualFundUnit}
                value={MutualFundUnit.Unit}
            />
            <FormRadioButton
                label="Dollar"
                {...formRadioButtonProps}
                name={fieldNames.mutualFundUnit}
                value={MutualFundUnit.Dollar}
            />
        </FormControlGroup>
    );
};
