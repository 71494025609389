import { cloneDeep } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { FormSelect, FormSelectOption, FormSelectProps, renderSelectOptions } from '../../FormControls';
import { fieldNames } from '../schema';
import { securityTypeOptions } from '../selectOptions';
import { TradingFormContext } from '../TradingFormContext';

export type SecurityTypeSelectProps = Partial<FormSelectProps>;

export const SecurityTypeSelect: React.FunctionComponent<SecurityTypeSelectProps> = (props) => {
    const {
        options: { allowOptionTrading, allowMutualFundTrading },
        meta: { optionTrading, mutualFundTrading },
    } = useContext(TradingFormContext);
    const securityTypes = useMemo(() => {
        const result: Partial<typeof securityTypeOptions> = cloneDeep(securityTypeOptions);

        if (!allowOptionTrading) {
            delete result.option;
            delete result.spread;
        }

        if (!allowMutualFundTrading) {
            delete result.mutualFund;
        }

        return Object.values(result) as FormSelectOption[];
    }, [allowOptionTrading, allowMutualFundTrading]);

    return (
        <FormSelect
            size={'small'}
            label="Security Type"
            {...props}
            name={fieldNames.securityType}
            validate={(value) => {
                let error = '';

                if (optionTrading && !allowOptionTrading) {
                    error = 'This account cannot trade option';
                }

                if (mutualFundTrading && !allowMutualFundTrading) {
                    error = 'This account cannot trade mutual fund';
                }

                return error || props.validate?.(value);
            }}
        >
            {renderSelectOptions(securityTypes)}
        </FormSelect>
    );
};
