import { ApplicationFormOption, ApplicationFormStateType } from '@app/redux/applicationForm/applicationForm.interfaces';
import { ApplicationFormIdentifierQuestionsAnswers } from '@app/redux/applicationForm/utils/buildApplicationFormIdentifierQuestionsAnswers/interfaces';

function getApplicationFormIdentifierQuestionAnswer(options: ApplicationFormOption[]) {
	let answer = options ? 'Not filled out yet' : 'Does not exist';

	if (Array.isArray(options)) {
		for (let i = 0; i < options.length; i++) {
			if (options[i].is_checked) {
				answer = options[i].label;
				break;
			}
		}
	}

	return answer;
}

function buildApplicationFormIdentifierQuestionsAnswers(
	applicationFormState: ApplicationFormStateType,
): ApplicationFormIdentifierQuestionsAnswers {
	return {
		account_type: getApplicationFormIdentifierQuestionAnswer(
			applicationFormState.questions[applicationFormState.identifier_questions.account_type_id]?.options,
		),
		options: getApplicationFormIdentifierQuestionAnswer(
			applicationFormState.questions[applicationFormState.identifier_questions.options_id]?.options,
		),
		customer_type: getApplicationFormIdentifierQuestionAnswer(
			applicationFormState.questions[applicationFormState.identifier_questions.customer_type_id]?.options,
		),
	};
}

export default buildApplicationFormIdentifierQuestionsAnswers;
