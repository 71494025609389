import { Formik, FormikConfig, FormikProps } from 'formik';
import React from 'react';
import {
    CoinSelect,
    OrderLimitPriceSelect,
    OrderStopPriceSelect,
    OrderTypeSelect,
    QuantitySelect,
    QuantitySwitch,
    TimeInForceSelect,
    TransactionSelect,
} from './controls';
import { CryptoTradingFormContext, CryptoTradingFormMeta, getCryptoTradingFormMeta } from './CryptoTradingFormContext';
import { cryptoFieldNames as fieldNames, CryptoTradingFormValues, cryptoTradingSchema } from './schema';

type CryptoTradingFormControls = {
    CoinSelect: typeof CoinSelect;
    QuantitySelect: typeof QuantitySelect;
    OrderTypeSelect: typeof OrderTypeSelect;
    OrderStopPriceSelect: typeof OrderStopPriceSelect | null;
    OrderLimitPriceSelect: typeof OrderLimitPriceSelect | null;
    TimeInForceSelect: typeof TimeInForceSelect | null;
    TransactionSelect: typeof TransactionSelect;
    QuantitySwitch: typeof QuantitySwitch;
};

export type CryptoTradingFormProps = Omit<FormikConfig<CryptoTradingFormValues>, 'children' | 'initialValues'> & {
    initialValues?: Partial<FormikConfig<CryptoTradingFormValues>['initialValues']>;
    onSubmit: (values: CryptoTradingFormValues) => void;
    children: (
        props: FormikProps<CryptoTradingFormValues> & {
            fieldNames: typeof fieldNames;
            controls: CryptoTradingFormControls;
            meta: CryptoTradingFormMeta;
        },
    ) => React.ReactElement;
};

export const CryptoTradingForm: React.FunctionComponent<CryptoTradingFormProps> = ({
    onSubmit,
    children,
    initialValues,
    ...formikConfig
}) => {
    return (
        <Formik
            {...formikConfig}
            initialValues={{
                ...cryptoTradingSchema.getDefault(),
                ...initialValues,
            }}
            validationSchema={cryptoTradingSchema}
            onSubmit={onSubmit}
        >
            {(formikProps) => {
                const { values } = formikProps;
                const meta = getCryptoTradingFormMeta(values);

                return (
                    <CryptoTradingFormContext.Provider
                        value={{
                            fieldNames,
                            meta,
                        }}
                    >
                        {children({
                            ...formikProps,
                            fieldNames,
                            meta,
                            controls: {
                                TransactionSelect,
                                OrderTypeSelect,
                                CoinSelect,
                                OrderStopPriceSelect: meta.stopOrder ? OrderStopPriceSelect : null,
                                OrderLimitPriceSelect: meta.limitOrder ? OrderLimitPriceSelect : null,
                                QuantitySelect: QuantitySelect,
                                TimeInForceSelect: TimeInForceSelect,
                                QuantitySwitch: QuantitySwitch,
                            },
                        })}
                    </CryptoTradingFormContext.Provider>
                );
            }}
        </Formik>
    );
};
