import { Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import React, { ErrorInfo } from 'react';
import { escapeHtml, getUuid } from '../../utils';
import { AlertDialog } from '../AlertDialog';

export interface ErrorBoundaryProps {
    children: React.ReactNode;
    useDialog?: boolean;
    showStackTrace?: boolean;
    onErrorMessage?: (error: Error, errorInfo: ErrorInfo, errorId: string) => string;
    onCatch?: (error: Error, errorInfo: ErrorInfo) => string;
}

interface State {
    error: Error | null;
    errorInfo: ErrorInfo | null;
    errorId: string;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
    state: State = {
        error: null,
        errorInfo: null,
        errorId: '',
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const errorId = this.props.onCatch?.(error, errorInfo) || getUuid();

        this.setState({
            error,
            errorInfo,
            errorId,
        });
    }

    reloadComponent = () => {
        this.setState({
            error: null,
            errorInfo: null,
            errorId: '',
        });
    };

    reloadApp = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        return;
    };

    render() {
        const { error, errorInfo, errorId } = this.state;

        const { useDialog, showStackTrace, onErrorMessage } = this.props;

        if (error && errorInfo) {
            let htmlMessage =
                onErrorMessage?.(error, errorInfo, errorId) ||
                `It looks like we are having some internal issues. Please try again by clicking on <b>Retry</b> or refresh the app. Error Id: ${errorId}.<br>`;

            if (showStackTrace) {
                const stackTrace = error.stack ? `<code>${escapeHtml(error.stack)}</code>` : error.message ?? '';
                if (stackTrace) {
                    htmlMessage += `<br>${stackTrace}`;
                }
            }

            if (!useDialog) {
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader title="Application Error" />
                                <CardContent style={{ paddingTop: 0 }}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textSecondary"
                                        dangerouslySetInnerHTML={{ __html: htmlMessage }}
                                    />
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'flex-end' }}>
                                    <Button onClick={this.reloadApp} color="error">
                                        Reload
                                    </Button>
                                    <Button onClick={this.reloadComponent}>Retry</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                );
            }

            return (
                <AlertDialog
                    noControl
                    open={true}
                    maxWidth="md"
                    title={'Application Error'}
                    messageHtml={htmlMessage}
                    positiveButton="Retry"
                    neutralButton="Reload"
                    neutralButtonColor="error"
                    onPositiveClick={this.reloadComponent}
                    onNeutralClick={this.reloadApp}
                />
            );
        }

        return this.props.children;
    }
}
