import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik/dist/types';
import { useCallback } from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { VerifyPhoneFormValues } from '@components/forms/verifyPhone/interfaces';

import { USER_PROFILE_STEPS_ENUM } from '../userProfileViewsFactory';

function useVerifyPhone({ setCurrentStep }: { setCurrentStep(value: USER_PROFILE_STEPS_ENUM): void }) {
	const { startRequest: verifyPhone } = useTriggeredRequest(AccountClientRequests.Users.verifyPhone);

	const initialValues: VerifyPhoneFormValues = {
		code: '',
	};

	const onSubmit = useCallback(
		async (values: VerifyPhoneFormValues, formikHelpers: FormikHelpers<VerifyPhoneFormValues>) => {
			try {
				await verifyPhone({
					code: values.code,
				});

				setCurrentStep(USER_PROFILE_STEPS_ENUM.INFO);
			} catch (e) {
				const { response } = e as AxiosError<{
					messages: { code: string[] } | string[];
				}>;

				formikHelpers.setErrors({
					code: Array.isArray(response?.data?.messages)
						? response?.data?.messages?.join(' ') || 'Oops. Something went wrong.'
						: response?.data?.messages?.code?.join(' ') || 'Oops. Something went wrong.',
				});
			}
		},
		[setCurrentStep, verifyPhone],
	);

	return {
		initialValues,
		onSubmit,
	};
}

export default useVerifyPhone;
