import React from 'react';

import { ApplicationFormSection as ApplicationFormSectionInterface } from '@app/redux/applicationForm/applicationForm.interfaces';
import { applicationFormActions } from '@app/redux/applicationForm/applicationForm.reducer';
import { useAppDispatch } from '@app/redux/hooks';

import { ButtonBase, Typography } from '@mui/material';

import { ApplicationFormSectionGrid, ApplicationFormSectionStatus } from './styles';

export interface ApplicationFormSectionProps {
	isSelected: boolean;
	applicationFormSection: ApplicationFormSectionInterface;
	applicationFormSectionPosition: number;
	applicationFormSectionStatus: 'error' | 'completed' | 'pending';
}

function ApplicationFormSection({
	isSelected,
	applicationFormSection,
	applicationFormSectionPosition,
	applicationFormSectionStatus,
}: ApplicationFormSectionProps) {
	const dispatch = useAppDispatch();

	const handleChangeCurrentSection = () => {
		dispatch(applicationFormActions.selectApplicationFormSection(applicationFormSection));
		resetPageScroll();
	};

	const resetPageScroll = () => {
		document.documentElement.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<ButtonBase sx={{ width: '100%' }} onClick={handleChangeCurrentSection}>
			<ApplicationFormSectionGrid container alignItems={'center'} isSelected={isSelected}>
				<ApplicationFormSectionStatus status={applicationFormSectionStatus}>
					{applicationFormSectionPosition}
				</ApplicationFormSectionStatus>

				<Typography variant={'body2'}>{applicationFormSection.label}</Typography>
			</ApplicationFormSectionGrid>
		</ButtonBase>
	);
}

export default ApplicationFormSection;
