import { generateNamesObject } from '@orbis-systems/orbis-react-toolkit';

import { Grid, styled } from '@mui/material';

export const detailSkeletonClasses = generateNamesObject(['detailsSection'], 'DetailsSkeleton');

export const StyledAccountDetailsSkeleton = styled(Grid)(({ theme }) => ({
	background: theme.palette.background.paper,
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: 6,
	[theme.breakpoints.down('xl')]: {
		'>div': {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
	},
	[`& .${detailSkeletonClasses.detailsSection}`]: {
		borderLeft: `1px solid ${theme.palette.divider}`,
		borderRight: `1px solid ${theme.palette.divider}`,
	},
}));
