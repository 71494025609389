import { Card, CardContent, Typography, styled, Box, useTheme, cardContentClasses } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import React from 'react';
import { generateNamesObject } from '../../utils/stringUtils';

export const infoCardClasses = generateNamesObject('headline', 'caption', 'headlineContainer', 'border');

const StyledCard = styled(Card)(({ theme }) => ({
    position: 'relative',
    background: theme.palette.background.paper,
    boxShadow: 'none',
    [`& .${cardContentClasses.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        '&:last-child': {
            padding: theme.spacing(3),
        },
    },
    [`& .${infoCardClasses.headline}`]: {
        display: 'flex',
        ...theme.mixins.textNoWrap,
        fontWeight: 500,
        lineHeight: '1rem',
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    [`& .${infoCardClasses.caption}`]: {
        ...theme.mixins.textNoWrap,
        fontSize: 24,
        fontWeight: 600,
        lineHeight: '1.5rem',
        color: theme.palette.text.primary,
    },
    [`& .${infoCardClasses.headlineContainer}`]: {
        '& svg': {
            left: '0 !important',
            width: '16px',
            marginRight: theme.spacing(1.5),
            fill: theme.palette.primary.main,
            marginTop: '-4px',
        },
    },
    [`& .${infoCardClasses.border}`]: {
        height: `calc(100% - ${theme.spacing(2)})`,
        width: 4,
        borderRadius: 99,
        position: 'absolute',
        top: theme.spacing(1),
        left: 0,
    },
}));

type Props = {
    headline: React.ReactNode;
    caption?: React.ReactNode;
    headlineTypographyVariant?: Variant;
    borderColor?: string;
    border?: boolean;
    background?: string;
    height?: number;
    onClick?: () => void;
};

export const InfoCard: React.FunctionComponent<Props> = ({
    headline,
    caption,
    headlineTypographyVariant,
    border,
    height,
    background,
    borderColor,
    onClick,
}) => {
    return (
        <StyledCard
            style={{
                height: height || 112,
                background: background ?? '',
                cursor: onClick ? 'pointer' : 'auto',
            }}
            onClick={onClick}
        >
            <CardContent>
                {' '}
                <span
                    className={infoCardClasses.border}
                    style={{ backgroundColor: border ? borderColor ?? useTheme().palette.primary.main : '' }}
                ></span>
                <Box className={infoCardClasses.headlineContainer}>
                    <Typography
                        component={'div'}
                        variant={headlineTypographyVariant ? headlineTypographyVariant : undefined}
                        className={infoCardClasses.headline}
                    >
                        {headline}
                    </Typography>
                </Box>
                <Typography component={'div'} variant="subtitle2" className={infoCardClasses.caption}>
                    {caption}
                </Typography>
            </CardContent>
        </StyledCard>
    );
};
