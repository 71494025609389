import React from 'react';

import VerifyEmailForm from '@components/forms/verifyEmail';

import { USER_PROFILE_STEPS_ENUM } from '../userProfileViewsFactory';
import useVerifyEmail from './hooks';

function VerifyEmail({ setCurrentStep }: { setCurrentStep: (value: USER_PROFILE_STEPS_ENUM) => void }) {
	const { initialValues, onSubmit } = useVerifyEmail({
		setCurrentStep,
	});

	return <VerifyEmailForm setCurrentStep={setCurrentStep} onSubmit={onSubmit} initialValues={initialValues} />;
}

export default VerifyEmail;
