export interface BranchEnvironmentVariable {
	branch: string;
	branch_id: number;
	branch_route: string;
	rep_id: number;
	rep_route: string;
}

export interface EnvVariables {
	API_URL: string;
	BUILD_ENVIRONMENT: 'development' | 'production';
	FIRM_ENVIRONMENT: string;
	BRANCH_ROUTES: string;
	DEFAULT_BRANCH: string;
	RECAPTCHA_SITE_KEY: string;
	ALLOW_USER_CREATION: string;
	ALLOW_APPLICATION_CREATION: string;
	ENABLE_SSO: string;
	SSO_ENVIRONMENT_URL: string;
	RETRIEVAL_ONLY: string;
	SUPPORT_ACCOUNT_RELATED: string;
	SUPPORT_FAX: string;
	SUPPORT_PHONE_NUMBER: string;
	SUPPORT_ADDRESS: string;
	RIA_APP_BUILD_NUMBER: string;
}

export const env: EnvVariables = {
	API_URL: process.env.API_URL as string,
	BUILD_ENVIRONMENT: process.env.BUILD_ENVIRONMENT as 'development' | 'production',
	FIRM_ENVIRONMENT: process.env.FIRM_ENVIRONMENT as string,
	BRANCH_ROUTES: process.env.BRANCH_ROUTES as string,
	DEFAULT_BRANCH: process.env.DEFAULT_BRANCH as string,
	RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY as string,
	ALLOW_USER_CREATION: process.env.ALLOW_USER_CREATION as string,
	ALLOW_APPLICATION_CREATION: process.env.ALLOW_APPLICATION_CREATION as string,
	ENABLE_SSO: process.env.ALLOW_APPLICATION_CREATION as string,
	SSO_ENVIRONMENT_URL: process.env.SSO_ENVIRONMENT_URL as string,
	RETRIEVAL_ONLY: process.env.RETRIEVAL_ONLY as string,
	SUPPORT_ACCOUNT_RELATED: process.env.SUPPORT_ACCOUNT_RELATED as string,
	SUPPORT_FAX: process.env.SUPPORT_FAX as string,
	SUPPORT_PHONE_NUMBER: process.env.SUPPORT_PHONE_NUMBER as string,
	SUPPORT_ADDRESS: process.env.SUPPORT_ADDRESS as string,
	RIA_APP_BUILD_NUMBER: process.env.RIA_APP_BUILD_NUMBER as string,
};

export const FALLBACK_BRANCH_ENVIRONMENT = {
	branch: 'viewtrade-vtdt-retail',
	branch_id: 1,
	branch_route: 'vt',
	rep_id: 3,
	rep_route: 'retail',
};

export const getDefaultBranch = (): BranchEnvironmentVariable => {
	if (!process.env.DEFAULT_BRANCH) {
		return FALLBACK_BRANCH_ENVIRONMENT;
	}

	try {
		const defaultBranch = JSON.parse(process.env.DEFAULT_BRANCH.replace(/\\/g, '') as string);
		return {
			branch: defaultBranch.branch,
			branch_id: Number(defaultBranch.branch_id),
			branch_route: defaultBranch.branch_route,
			rep_id: Number(defaultBranch.rep_id),
			rep_route: defaultBranch.rep_route,
		};
	} catch (e) {
		return FALLBACK_BRANCH_ENVIRONMENT;
	}
};

export const getBranchRoutes = (): BranchEnvironmentVariable[] => {
	if (!process.env.BRANCH_ROUTES) {
		return [getDefaultBranch()];
	}

	try {
		const branchRoutes = JSON.parse(process.env.BRANCH_ROUTES.replace(/\\/g, '') as string);

		return branchRoutes.map(
			(branchRoute: {
				branch: string;
				branch_id: string;
				branch_route: string;
				rep_id: string;
				rep_route: string;
			}) => ({
				branch: branchRoute.branch,
				branch_id: Number(branchRoute.branch_id),
				branch_route: branchRoute.branch_route,
				rep_id: Number(branchRoute.rep_id),
				rep_route: branchRoute.rep_route,
			}),
		);
	} catch (e) {
		return [FALLBACK_BRANCH_ENVIRONMENT];
	}
};

export const isRetrievalOnly = (): boolean => {
	return process.env.RETRIEVAL_ONLY === 'true';
};

export const isAllowUserRegistration = (): boolean => {
	return process.env.ALLOW_USER_CREATION === 'true';
};

export const isAllowApplicationCreation = (): boolean => {
	return process.env.ALLOW_APPLICATION_CREATION === 'true';
};

export const isEnableSSO = (): boolean => {
	return process.env.ENABLE_SSO === 'true';
};

export const isDevelopment = (): boolean => {
	return process.env.BUILD_ENVIRONMENT === 'development';
};

export const isProduction = (): boolean => {
	return process.env.BUILD_ENVIRONMENT === 'production';
};
