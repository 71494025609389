import { SerializedError } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { isString } from 'lodash';
import { escapeHtml } from './stringUtils';

export class AxiosErrorSerializer {
    constructor(protected error: AxiosError<any>) {
        this.getCode = this.getCode.bind(this);
        this.getName = this.getName.bind(this);
        this.getDebugData = this.getDebugData.bind(this);
        this.getMessage = this.getMessage.bind(this);
        this.getSerializedMessage = this.getSerializedMessage.bind(this);
        this.serialize = this.serialize.bind(this);
    }

    getCode() {
        return String(this.error.code);
    }

    getName() {
        const { status, statusText } = this.error.response || {};
        return `${status || this.error.name}${statusText ? ` - ${statusText}` : ''}`;
    }

    getDebugData() {
        const {
            data: responseData,
            config: { url, method, data: requestData },
        } = this.error.response || {
            data: {},
            status: '',
            statusText: '',
            config: {
                url: '',
                method: '',
                data: null,
            },
        };

        return {
            id: responseData.id,
            method,
            url,
            data: requestData,
        };
    }

    getMessage() {
        const { data } = this.error.response || {};
        return `${data.error ?? data.message ?? this.error.message}`;
    }

    getSerializedMessage(message: string, debugData?: ReturnType<AxiosErrorSerializer['getDebugData']>) {
        const { id, method, url, data } = debugData || {};
        const debugString = `
            ${id ? `<br/>Id: ${id}` : ''}
            ${url ? `<br/>Endpoint: ${method?.toUpperCase()} ${url}` : ''}
            ${isString(data) ? `<br/>Payload: <br/>${escapeHtml(JSON.stringify(JSON.parse(data), null, 4), 4)}` : ''}
        `.trim();

        return `${message}${
            !debugData
                ? ''
                : `
                    <br/>
                    <code>
                        ${debugString}
                    </code>
                `
        }`.trim();
    }

    serialize(debug = false): SerializedError {
        return {
            name: this.getName(),
            code: this.getCode(),
            stack: this.error.stack,
            message: this.getSerializedMessage(this.getMessage(), debug ? this.getDebugData() : undefined),
        };
    }
}

export class OrbisErrorSerializer extends AxiosErrorSerializer {
    override getCode() {
        return (this.error as any).orbisErrorCode || super.getCode();
    }

    override getMessage() {
        return (this.error as any).orbisErrorMessage || super.getMessage();
    }
}
