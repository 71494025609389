import {
    Autocomplete,
    autocompleteClasses,
    AutocompleteProps,
    Box,
    styled,
    TextField,
    Typography,
} from '@mui/material';
import { BaseFilter } from 'mui-enhanced-table';
import React from 'react';
import { FilterProps } from '..';
import { generateNamesObject } from '../../../utils/stringUtils';

export const autocompleteFilterClasses = generateNamesObject('accountsFilter', 'input', 'listItem');

const Container = styled(Box)(() => ({
    width: '100%',
    [`& .${autocompleteFilterClasses.accountsFilter}`]: {
        marginTop: 8,
        marginBottom: 8,
    },
    [`& .${autocompleteFilterClasses.input}`]: {
        minWidth: 376,
    },
    [`& .${autocompleteClasses.option}`]: {
        padding: 0,
    },
    [`& .${autocompleteFilterClasses.listItem}`]: {
        padding: '0 16px',
    },
}));

export type FilterOption<T> = {
    id: string;
    label: string;
    data: T;
};

export type AutocompleteFilterProps<
    T,
    Multiple extends boolean | undefined = true,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
> = FilterProps<T> & {
    label?: string;
    options: FilterOption<T>[];
    multiple?: Multiple;
    freeSolo?: FreeSolo;
    disableClearable?: DisableClearable;
    isMatch: (item: T, selectedIds: string[]) => boolean;
    onChange?: (selectedIds: string[]) => void;
    autoCompleteProps?: Omit<
        Partial<AutocompleteProps<FilterOption<T>, Multiple, DisableClearable, FreeSolo>>,
        'ref' | 'classes'
    >;
};

type State = {
    selectedIds: string[];
};

export class AutocompleteFilter<T = any> extends BaseFilter<AutocompleteFilterProps<T>, State, T> {
    state: State = {
        selectedIds: [],
    };

    componentDidMount = () => {
        this.props.onChange?.(this.state.selectedIds);
    };

    updateFilter = () => {
        const { data, onChange, onUpdateFilter, isMatch } = this.props;

        const selectedIds = this.state.selectedIds;

        onChange?.(selectedIds);
        onUpdateFilter(
            selectedIds.length > 0
                ? data.filter((item) => isMatch(item.data, selectedIds)).map((item) => item.id)
                : null,
        );
    };

    render() {
        const { label, options, autoCompleteProps, name } = this.props;

        return (
            <Container>
                <Typography variant="overline">{name}</Typography>
                <Autocomplete
                    multiple
                    openOnFocus
                    filterSelectedOptions
                    size="small"
                    defaultValue={[]}
                    className={autocompleteFilterClasses.accountsFilter}
                    options={options}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth={false}
                            label={label}
                            className={autocompleteFilterClasses.input}
                        />
                    )}
                    isOptionEqualToValue={(option) => this.state.selectedIds.includes(option.id)}
                    onChange={(event, options) => {
                        this.setState(
                            {
                                selectedIds: options.map((item) => item.id),
                            },
                            this.updateFilter,
                        );
                    }}
                    {...autoCompleteProps}
                />
            </Container>
        );
    }
}
