import { ClearAll } from '@mui/icons-material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import {
    alpha,
    Box,
    Checkbox,
    Chip,
    chipClasses,
    ClickAwayListener,
    IconButton,
    ListItemText,
    MenuItem,
    Popper,
    styled,
    Tooltip,
    Typography,
} from '@mui/material';
import { get, xor } from 'lodash';
import { BaseFilter } from 'mui-enhanced-table';
import React from 'react';
import { TableRow } from '..';
import { getCurrentTheme } from '../../../themes';
import { getContrastTextColor } from '../../../utils/colorUtils';
import { generateNamesObject } from '../../../utils/stringUtils';

export const tagFilterClasses = generateNamesObject(
    'filters',
    'chip',
    'button',
    'icon',
    'clearButton',
    'popper',
    'menuItem',
);

const Container = styled(Box)(({ theme }) => ({
    marginRight: theme.spacing(3),
    position: 'relative',
    marginBottom: theme.spacing(2),
    display: 'flex',
    [`& .${tagFilterClasses.filters}`]: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minWidth: '170px',
    },
    [`& .${chipClasses.root}`]: {
        cursor: 'pointer',
    },
    [`& .${tagFilterClasses.chip}`]: {
        marginRight: 8,
        marginBottom: 8,
        minWidth: '140px',
        width: '75%',
        justifyContent: 'space-between',
    },
    [`& .${tagFilterClasses.icon}`]: {
        background: theme.palette.action.selected,
        color: 'unset !important',
        borderRadius: '16px',
        minWidth: 32,
        width: 'auto',
        height: 32,
        paddingLeft: 8,
        paddingRight: 8,
        marginRight: 0,
        lineHeight: '32px',
        fontSize: '1rem',
        textAlign: 'center',
        '&:hover': {
            color: 'unset',
        },
        display: 'flex',
        alignItems: 'center',
        pointerEvents: 'none',
        margin: '0 !important',
    },
    [`& .${tagFilterClasses.clearButton}`]: {
        padding: 4,
        marginLeft: theme.spacing(1),
    },
    [`& .${tagFilterClasses.popper}`]: {
        zIndex: 1500,
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.background.paper}`,
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.spacing(1),
        overflow: 'auto',
        maxHeight: 250,
    },
    [`& .${tagFilterClasses.menuItem}`]: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 4,
        paddingBottom: 4,
    },
    [`& .${tagFilterClasses.button}`]: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.action.hover,
        borderRadius: theme.shape.borderRadius,
        '& p': {
            fontSize: 12,
            fontWeight: theme.typography.fontWeightBold,
        },
    },
}));

type Props = {
    name?: string;
    defaultLabel?: string;
    formatLabel?: (label: string) => string;
    formatColor?: (label: string) => string;
    variant?: 'chip' | 'button';
};

type State = {
    filters: string[];
    anchorEl: any | null;
};

export class TagFilter extends BaseFilter<Props, State> {
    state: State = {
        filters: [] as string[],
        anchorEl: null,
    };

    toggleFilter = (filter: string) => {
        this.setState(
            {
                filters: xor(this.state.filters, [filter]),
            },
            this.updateFilter,
        );
    };

    tagReducer = (result: { [key: string]: number }, item: TableRow) => {
        const { filterBy, defaultLabel = '' } = this.props;

        const label = get(item.data, (filterBy as string) ?? '', defaultLabel);

        if (!label) {
            return result;
        }

        if (!result.hasOwnProperty(label)) {
            result[label] = 0;
        }

        result[label]++;
        return result;
    };

    resetFilters = () =>
        this.setState(
            {
                filters: [],
            },
            this.updateFilter,
        );

    updateFilter = () => {
        const { filterBy, data, onUpdateFilter, defaultLabel = '' } = this.props;

        const filters = this.state.filters;

        onUpdateFilter(
            filters.length
                ? data
                      .filter((item) => filters.includes(get(item.data, filterBy as string, defaultLabel)))
                      .map((item) => item.id)
                : null,
            filters,
        );
    };

    render() {
        const { data, formatLabel, formatColor, name, variant = 'chip' } = this.props;

        const { filters } = this.state;

        const theme = getCurrentTheme();
        const tags = data.reduce(this.tagReducer, {});
        const labels = Object.keys(tags);

        if (!Object.keys(tags).length) {
            return null;
        }

        const handleClick = (target: EventTarget | null) => {
            this.setState({
                anchorEl: this.state.anchorEl ? null : target,
            });
        };

        const open = Boolean(this.state.anchorEl);
        const defaultColor = theme.palette.primary.main;

        return (
            <Container>
                {labels.length > 0 &&
                    (variant === 'button' ? (
                        <IconButton
                            disableRipple
                            color="inherit"
                            size="large"
                            onClick={(event) => handleClick(event.currentTarget)}
                            className={tagFilterClasses.button}
                        >
                            <Typography>{name}</Typography>
                            {!open ? <KeyboardArrowDownOutlinedIcon /> : <KeyboardArrowUpOutlinedIcon />}
                        </IconButton>
                    ) : (
                        <Box onClick={(event) => handleClick(event.currentTarget)}>
                            <Chip
                                onDelete={() => null}
                                label={name}
                                style={{
                                    color: getContrastTextColor(formatColor?.(name ?? '') ?? defaultColor),
                                    backgroundColor:
                                        filters.length > 0
                                            ? formatColor?.(name ?? '') || defaultColor
                                            : alpha(formatColor?.(name ?? '') ?? defaultColor, 0.5),
                                }}
                                deleteIcon={
                                    <span className={tagFilterClasses.icon}>
                                        {!open ? <KeyboardArrowDownOutlinedIcon /> : <KeyboardArrowUpOutlinedIcon />}
                                    </span>
                                }
                            />
                        </Box>
                    ))}
                {filters.length > 0 && (
                    <Tooltip title={`Clear ${name}`} placement="bottom">
                        <IconButton onClick={this.resetFilters} className={tagFilterClasses.clearButton}>
                            <ClearAll />
                        </IconButton>
                    </Tooltip>
                )}

                <Popper
                    open={open}
                    anchorEl={this.state.anchorEl}
                    className={tagFilterClasses.popper}
                    placement={'bottom-start'}
                    disablePortal
                >
                    <ClickAwayListener onClickAway={(event) => handleClick(event.currentTarget)}>
                        <Box className={tagFilterClasses.filters}>
                            {labels.map((label) => {
                                const onClick = () => this.toggleFilter(label);
                                const formattedLabel = formatLabel?.(label) || label;

                                return (
                                    <Tooltip title={formattedLabel} key={label}>
                                        <Box onClick={onClick}>
                                            <MenuItem key={label} value={label} className={tagFilterClasses.menuItem}>
                                                <Checkbox checked={filters.includes(label)} onClick={onClick} />
                                                <ListItemText primary={formattedLabel} />
                                            </MenuItem>
                                        </Box>
                                    </Tooltip>
                                );
                            })}
                        </Box>
                    </ClickAwayListener>
                </Popper>
            </Container>
        );
    }
}
