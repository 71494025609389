export enum PositionType {
    Cash = 1,
    Margin = 2,
    Short = 3,
}

export enum MutualFundUnit {
    Unit,
    Dollar,
}

export enum MutualFundCapitalGains {
    Reinvest = 'Reinvest',
    PayInCash = 'PayInCash',
}

export enum MutualFundDividends {
    Reinvest = 'Reinvest',
    PayInCash = 'PayInCash',
}

export enum SecurityType {
    Equity,
    Option,
    Spread,
    MutualFund,
    Bond,
}

export enum OrderType {
    Market = 'MARKET',
    Limit = 'LIMIT',
    Stop = 'STOP',
    StopLimit = 'STOP_LIMIT',
    Notional = 'Notional',
    Even = 'Even',
    Credit = 'Credit',
    Debit = 'Debit',
    Spread = 'Spread',
    TrailingStop = 'TRAILING_STOP',
}

export enum TrailingStopType {
    Dollar = '$',
    Percent = '%',
}

export enum TradeDestination {
    SingleAccount,
    AveragePriceAccount,
    Model,
}

export enum OrderTransaction {
    Buy = 'BUY',
    BuyToOpen = 'BUY_TO_OPEN',
    BuyToClose = 'BUY_TO_CLOSE',
    BuyToCover = 'BUY_TO_COVER',
    Sell = 'SELL',
    SellToOpen = 'SELL_TO_OPEN',
    SellToClose = 'SELL_TO_CLOSE',
    ShortSell = 'SHORT_SELL',
    Open = 'OPEN',
    Close = 'CLOSE',
}

export enum OrderValidity {
    GoodForDay = 'DAY',
    GoodTilCanceled = 'GTC',
}

export enum OrderMarketTime {
    PreMarket = 'PRE_MARKET',
    CoreMarket = 'CORE_MARKET',
    PostMarket = 'POST_MARKET',
    AllMarket = 'ALL_MARKET',
    PreCoreMarket = 'PRE_CORE',
    PostCoreMarket = 'POST_CORE',
}

export enum OrderFillType {
    Any = 'ANY',
    AllOrNone = 'AON',
}
