import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import ApplicationInterface from '@api/requests/applications/interfaces/ApplicationInterface.interface';
import configRequest from '@api/requests/configRequest';

export interface SubmitApplicationFormData {
	application_id: number;
}

export interface SubmitApplicationFormResponse {
	application: ApplicationInterface;
}

const submitApplicationForm = configRequest<SubmitApplicationFormResponse, SubmitApplicationFormData>({
	requestPrefix: 'applications/submitApplicationForm',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.APPLICATION_FORM.SUBMIT(),
		method: HTTP_METHODS_ENUM.POST,
		data: {
			...data,
			with: ['current_status', 'statuses.timestamps'],
		},
	}),
});

export default submitApplicationForm;
