import { cloneDeep } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { FormSelect, FormSelectOption, FormSelectProps, renderSelectOptions } from '../../FormControls';
import { fieldNames } from '../schema';
import { tradeDestinationFormSelectOptions } from '../selectOptions';
import { TradingFormContext } from '../TradingFormContext';

export type TradeDestinationSelectProps = Partial<FormSelectProps>;

export const TradeDestinationSelect: React.FunctionComponent<TradeDestinationSelectProps> = (props) => {
    const {
        meta: { mutualFundTrading },
    } = useContext(TradingFormContext);
    const tradeDestination = useMemo(() => {
        const result: Partial<typeof tradeDestinationFormSelectOptions> = cloneDeep(tradeDestinationFormSelectOptions);
        if (mutualFundTrading) {
            delete result.averagePriceAccount;
            delete result.model;
        }

        return Object.values(result) as FormSelectOption[];
    }, [mutualFundTrading]);

    return (
        <FormSelect label="Trade Destination" {...props} name={fieldNames.tradeDestination}>
            {renderSelectOptions(tradeDestination)}
        </FormSelect>
    );
};
