import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useCallback, useEffect } from 'react';
import { useTimer } from 'react-timer-hook';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { showToast } from '@app/providers/ToastProvider';
import { getVerificationPhoneCodeExpiring, getVerificationPhoneType } from '@app/redux/auth/auth.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { VerifyPhoneFormProps, VerifyPhoneFormValues } from './interfaces';
import verifyPhoneFormValidationScheme from './validationScheme';

function useVerifyPhoneForm({ initialValues, onSubmit }: Pick<VerifyPhoneFormProps, 'initialValues' | 'onSubmit'>) {
	const codeExpiring = useAppSelector(getVerificationPhoneCodeExpiring);
	const verificationPhoneType = useAppSelector(getVerificationPhoneType);

	const { seconds, minutes, restart } = useTimer({
		expiryTimestamp: new Date(codeExpiring || '00:00'),
	});

	const { startRequest: sendVerificationPhoneCode, isPending: isSendingVerificationPhoneCode } = useTriggeredRequest(
		AccountClientRequests.Users.sendVerificationPhoneCode,
	);

	const formik = useFormik<VerifyPhoneFormValues>({
		initialValues,
		validationSchema: verifyPhoneFormValidationScheme,
		onSubmit,
	});

	const handleSendVerificationPhoneCode = useCallback(async () => {
		try {
			const response = await sendVerificationPhoneCode({ type: verificationPhoneType as 'sms' | 'voice' });

			restart(new Date(response.phone_verification.expires_at || '00:00'));
		} catch (e) {
			const { response } = e as AxiosError<{
				messages: string[];
			}>;

			showToast.error(response?.data?.messages?.join(' ') || 'Oops. Something went wrong.');
		}
	}, [restart, sendVerificationPhoneCode, verificationPhoneType]);

	useEffect(() => {
		handleSendVerificationPhoneCode();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		isSendingVerificationPhoneCode,
		handleSendVerificationPhoneCode,
		minutes,
		seconds,
		formik,
	};
}

export default useVerifyPhoneForm;
