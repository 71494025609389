import { Accordion, styled } from '@mui/material';

export const ApplicationFormPageStatus = styled('div')<{ status: string }>(({ status, theme }) => ({
	width: 8,
	height: 8,
	borderRadius: '50%',
	marginBottom: 1,
	marginRight: 5,
	background:
		(status === 'error' && theme.palette.error.main) ||
		(status === 'completed' && theme.palette.success.main) ||
		theme.palette.warning.main,
}));

export const ApplicationFormAccordion = styled(Accordion)(({ theme }) => ({
	backgroundImage: 'none',
	boxShadow: 'none',
	'.MuiAccordionSummary-root': {
		padding: theme.spacing(0, 1, 0, 0),
		minHeight: '0!important',
		'.Mui-expanded': {
			margin: 0,
		},
	},
	'.MuiAccordionSummary-content': {
		margin: 0,
	},
	'.MuiAccordionDetails-root': {
		padding: 0,
	},
}));
