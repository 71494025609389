import React from 'react';
import { FormSelect, FormSelectProps, renderSelectOptions } from '../../FormControls';
import { marketTimeOptions } from '../selectOptions';
import { fieldNames } from '../schema';

export type MarketTimeSelectProps = Partial<FormSelectProps>;

export const MarketTimeSelect: React.FunctionComponent<MarketTimeSelectProps> = (props) => {
    return (
        <FormSelect label="Market Time" size="small" {...props} name={fieldNames.marketTime}>
            {renderSelectOptions(Object.values(marketTimeOptions))}
        </FormSelect>
    );
};
