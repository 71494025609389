import { removeScopedStates, saveState } from './persistStateActions';
import { createReducer } from '@reduxjs/toolkit';
import { resetPersistState } from './persistStateActions';

export type PersistState = Record<string, any>;

const DEFAULT_STATE: PersistState = {};

export const persistStateReducer = createReducer(DEFAULT_STATE, (builder) => {
    builder.addCase(resetPersistState, () => DEFAULT_STATE);

    builder.addCase(saveState, (state, { payload: { scope = 'undefined', persistKey, payload } }) => {
        state[scope] = {
            ...state[scope],
            [persistKey]: payload,
        };
    });

    builder.addCase(removeScopedStates, (state, action) => {
        delete state[action.payload];
    });
});
