import React from 'react';

import LoginForm from '@components/forms/login';

import useLogin from './hooks';

function Login() {
	const { initialValues, onSubmit } = useLogin();

	return <LoginForm initialValues={initialValues} onSubmit={onSubmit} />;
}

export default Login;
