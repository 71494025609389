import { ApplicationAnswerInterface } from '@api/requests/applications/interfaces/ApplicationDetailsInterface.interface';
import AssetsDocumentTypeInterface from '@api/requests/assets/interfaces/AssetsDocumentTypeInterface.interface';
import AssetsQuestionTypeInterface from '@api/requests/assets/interfaces/AssetsQuestionTypeInterface.interface';
import AssetsRuleSetInterface from '@api/requests/assets/interfaces/AssetsRuleSetInterface.interface';
import { FormInterface, FormPageInterface } from '@api/requests/forms/interfaces/FormInterface.interface';

import { ApplicationFormStateType } from '@app/redux/applicationForm/applicationForm.interfaces';
import assignIdentifierQuestionId from '@app/redux/applicationForm/utils/buildApplicationForm/assignIdentifierQuestionId.utils';
import buildApplicationFormAnswers from '@app/redux/applicationForm/utils/buildApplicationForm/buildApplicationFormAnswers.utils';
import buildApplicationFormQuestions from '@app/redux/applicationForm/utils/buildApplicationForm/buildApplicationFormQuestions.utils';
import getFormQuestions from '@app/redux/applicationForm/utils/buildApplicationForm/getFormQuestions.utils';

function buildApplicationForm({
	formDetails,
	formAssets,
	applicationId,
	applicationAnswers,
	currentUserId,
}: {
	formDetails: FormInterface;
	formAssets: {
		question_types: Record<number, AssetsQuestionTypeInterface>;
		document_types: Record<number, AssetsDocumentTypeInterface>;
		rule_sets: Record<number, AssetsRuleSetInterface>;
	};
	applicationId: number;
	applicationAnswers: ApplicationAnswerInterface[];
	currentUserId: number;
}): ApplicationFormStateType {
	const applicationFormAnswers = buildApplicationFormAnswers({
		applicationAnswers,
		formAssets,
		formQuestions: getFormQuestions(formDetails),
	});

	const form: ApplicationFormStateType = {
		id: formDetails.id,
		version: formDetails.version,
		application_id: applicationId,
		pages: [],
		questions: {},
		sections: {},
		identifier_questions: {
			account_type_id: 0,
			options_id: 0,
			customer_type_id: 0,
		},
		current: {
			page: { id: 0, label: '' },
			section: { id: 0, label: '' },
			questions_ids: [],
		},
	};

	formDetails.pages.forEach((page: FormPageInterface) => {
		if (page.position === 1) {
			form.current.page = {
				id: page.id,
				label: page.translations[0].data,
			};
		}

		form.pages.push({
			id: page.id,
			position: page.position,
			label: page.translations[0].data,
			number_of_sections: page.sections.length,
		});

		form.sections[page.id] = [];

		page.sections.forEach(section => {
			if (section.questions.length > 0) {
				const builder = buildApplicationFormQuestions({
					formQuestions: section.questions,
					formAssets,
					applicationFormAnswers,
					page_id: page.id,
					section_id: section.id,
					currentUserId,
				});

				if (page.position === 1 && section.position === 1) {
					form.current.section = {
						id: section.id,
						label: section.translations[0].data,
					};
					form.current.questions_ids = builder.ids;
				}

				form.sections[page.id].push({
					id: section.id,
					position: section.position,
					page_id: page.id,
					label: section.translations[0].data,
					number_of_questions: section.questions.length,
					questions_ids: builder.ids,
				});

				builder.questions.forEach(question => {
					assignIdentifierQuestionId({
						applicationFormQuestion: question,
						applicationFormIdentifierQuestions: form.identifier_questions,
					});

					form.questions[question.id] = {
						...question,
					};
				});
			}
		});

		if (form.sections[page.id].length === 0) {
			delete form.sections[page.id];
		}
	});

	return form;
}

export default buildApplicationForm;
