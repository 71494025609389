import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik/dist/types';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { getResetPasswordDetails } from '@app/redux/auth/auth.selectors';
import { useAppSelector } from '@app/redux/hooks';
import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { ResetPasswordFormValues } from '@components/forms/resetPassword/interfaces';

function useResetPasswordForm() {
	const history = useHistory();
	const generateRoutePath = useGenerateRoutePath();

	const { startRequest } = useTriggeredRequest(AccountClientRequests.Auth.resetPassword);

	const resetPasswordDetails = useAppSelector(getResetPasswordDetails);

	const initialValues: ResetPasswordFormValues = {
		password: '',
		password_confirmation: '',
		code: '',
		resetPasswordError: '',
		resendCodeError: '',
	};

	const onSubmit = useCallback(
		async (values: ResetPasswordFormValues, formikHelpers: FormikHelpers<ResetPasswordFormValues>) => {
			try {
				await startRequest({
					password: values.password,
					password_confirmation: values.password_confirmation,
					code: values.code,
					phone: resetPasswordDetails.phone || undefined,
					email: resetPasswordDetails.email || undefined,
					via: resetPasswordDetails.via,
				});

				history.push(generateRoutePath(RoutePaths.AUTH.LOGIN));
			} catch (e) {
				const { response } = e as AxiosError<{
					messages: { code: string[] } | string[];
				}>;

				formikHelpers.setErrors({
					[Array.isArray(response?.data?.messages) ? 'resetPasswordError' : 'code']: Array.isArray(
						response?.data?.messages,
					)
						? response?.data?.messages?.join(' ') || 'Oops. Something went wrong.'
						: response?.data?.messages?.code?.join(' ') || 'Oops. Something went wrong.',
				});
			}
		},
		[
			startRequest,
			resetPasswordDetails.phone,
			resetPasswordDetails.email,
			resetPasswordDetails.via,
			history,
			generateRoutePath,
		],
	);

	return {
		initialValues,
		onSubmit,
	};
}

export default useResetPasswordForm;
