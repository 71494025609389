import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { showToast } from '@app/providers/ToastProvider';

function useCheckboxGroupFieldApplicationForm({
	applicationId,
	questionId,
}: {
	applicationId: number;
	questionId: number;
}) {
	const { isPending: isPendingSaveAnswer, startRequest: saveCheckboxGroupAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.saveMultipleOptionAnswer,
	);

	const { isPending: isPendingDeleteAnswer, startRequest: deleteAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.deleteAnswer,
	);

	const handleSaveAnswer = async (optionIds: number[], hasMultipleAnswers: boolean) => {
		try {
			await saveCheckboxGroupAnswer({
				application_id: applicationId,
				question_id: questionId,
				data: hasMultipleAnswers ? optionIds.map(optionId => ({ data: optionId })) : optionIds[0],
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	const handleDeleteAnswer = async () => {
		try {
			await deleteAnswer({
				application_id: applicationId,
				question_id: questionId,
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	return {
		loading: isPendingSaveAnswer || isPendingDeleteAnswer,
		handleSaveAnswer,
		handleDeleteAnswer,
	};
}

export default useCheckboxGroupFieldApplicationForm;
