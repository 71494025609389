import { Box, Button, FormControl, MenuItem, styled, Typography } from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import React from 'react';
import { generateNamesObject } from '../../utils';
import { FilledSelectNoLabel } from '../FilledSelectNoLabel';

export const searchResultClasses = generateNamesObject(['root', 'label'], 'SearchResults');
interface Option {
    value: number | string;
    name?: string;
    disabled?: boolean;
}

const Container = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
        display: 'inline-flex',
        '&:first-child': {
            flexGrow: 1,
        },
    },

    ['& button']: {
        marginLeft: 16,
        height: 39,
    },
}));

export type SearchResultsProps = {
    label?: string;
    selectLabel?: string;
    selectOptions?: Option[];
    defaultSelectValue?: unknown;
    buttonLabel?: string;
    buttonIcon?: React.ReactElement;
    onButtonClick?: () => void;
    onSelectChange?: SelectInputProps['onChange'];
};

export const SearchResults: React.FunctionComponent<SearchResultsProps> = ({
    label,
    selectLabel,
    selectOptions,
    defaultSelectValue,
    buttonLabel,
    buttonIcon,
    onButtonClick,
    onSelectChange,
}) => {
    return (
        <Container className={searchResultClasses.root}>
            <Typography variant="h6" className={searchResultClasses.label}>
                {label}
            </Typography>

            {selectOptions && (
                <>
                    <Typography>{selectLabel}&nbsp;&nbsp;&nbsp;</Typography>

                    <FormControl>
                        <FilledSelectNoLabel
                            defaultValue={defaultSelectValue || selectOptions[0].value}
                            onChange={onSelectChange}
                        >
                            {selectOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </FilledSelectNoLabel>
                    </FormControl>

                    {buttonLabel && (
                        <Button variant="contained" color="primary" onClick={onButtonClick}>
                            {buttonIcon}
                            &nbsp;{buttonLabel}
                        </Button>
                    )}
                </>
            )}
        </Container>
    );
};
