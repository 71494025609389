import React from 'react';

import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';

import ChangePasswordForm from '@components/forms/changePassword';

import { USER_PROFILE_STEPS_ENUM } from '../userProfileViewsFactory';
import useChangePassword from './hooks';

function ChangePassword({
	currentUser,
	setCurrentStep,
}: {
	currentUser: CurrentUserInterface;
	setCurrentStep: (value: USER_PROFILE_STEPS_ENUM) => void;
}) {
	const { initialValues, onSubmit } = useChangePassword({ setCurrentStep, currentUser });

	return <ChangePasswordForm setCurrentStep={setCurrentStep} onSubmit={onSubmit} initialValues={initialValues} />;
}

export default ChangePassword;
