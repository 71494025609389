import { FormTextField } from '@orbis-systems/orbis-react-toolkit';
import React from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, Typography } from '@mui/material';

import Form from '@components/forms';
import useChangePasswordForm from '@components/forms/changePassword/hooks';
import { ChangePasswordFormProps } from '@components/forms/changePassword/interfaces';
import { BackSubmitButton } from '@components/forms/currentUser/styles';
import SubmitButton from '@components/submitButton';

import { USER_PROFILE_STEPS_ENUM } from '../../../containers/UserProfile/userProfileViewsFactory';

function ChangePasswordForm({ initialValues, onSubmit, setCurrentStep }: ChangePasswordFormProps) {
	const {
		showOldPassword,
		showPassword,
		showPasswordConfirmation,
		handleShowOldPassword,
		handleShowPassword,
		handleShowPasswordConfirmation,
		formik,
	} = useChangePasswordForm({ initialValues, onSubmit });

	return (
		<Form formik={formik}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant={'subtitle1'}>
						<b>Change password</b>
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<FormTextField
						name={'old_password'}
						label={'Current password'}
						type={showOldPassword ? 'text' : 'password'}
						autoComplete="off"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton aria-label="toggle password visibility" onClick={handleShowOldPassword}>
										{showOldPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
						disabled={formik.isSubmitting}
					/>
				</Grid>

				<Grid item xs={12}>
					<FormTextField
						name={'password'}
						label={'New password'}
						type={showPassword ? 'text' : 'password'}
						autoComplete="off"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton aria-label="toggle password visibility" onClick={handleShowPassword}>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
						disabled={formik.isSubmitting}
					/>
				</Grid>

				<Grid item xs={12}>
					<FormTextField
						name={'password_confirmation'}
						label={'Confirm new password'}
						type={showPasswordConfirmation ? 'text' : 'password'}
						autoComplete="off"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton aria-label="toggle password visibility" onClick={handleShowPasswordConfirmation}>
										{showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
						disabled={formik.isSubmitting}
					/>
				</Grid>

				<Grid item xs={12}>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<BackSubmitButton
								fullWidth
								type="button"
								variant="contained"
								color="secondary"
								onClick={() => setCurrentStep(USER_PROFILE_STEPS_ENUM.INFO)}
								disabled={formik.isSubmitting}
							>
								Back
							</BackSubmitButton>
						</Grid>

						<Grid item xs={6}>
							<SubmitButton label={'Update'} disabled={formik.isSubmitting} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Form>
	);
}

export default ChangePasswordForm;
