import React from 'react';

import { FIRM_NAME_MAP } from '@app/layouts/UnauthorizedPageLayout/constants';

import { Grid, Typography } from '@mui/material';

import Login from '@pages/Auth/LoginPage/containers/Login';

import * as environment from '@utils/environment';

function LoginPage() {
	return (
		<Grid container direction="column" alignItems="center" justifyContent="center">
			<Typography variant="body1" mb={1} fontWeight={500}>
				{`Welcome to ${FIRM_NAME_MAP.get(environment.env.FIRM_ENVIRONMENT)} Onboarding`}
			</Typography>

			<Typography variant="body2" color={'secondary'} mb={8}>
				Please enter credentials to login
			</Typography>

			<Login />
		</Grid>
	);
}

export default LoginPage;
