const VALIDATION_PROP_NAMES = ['disablePast', 'disableFuture', 'minDate', 'maxDate', 'minTime', 'maxTime', 'minDateTime', 'maxDateTime', 'shouldDisableDate', 'shouldDisableMonth', 'shouldDisableYear', 'shouldDisableClock', 'shouldDisableTime', 'minuteStep'];
/**
 * Extract the validation props for the props received by a component.
 * Limit the risk of forgetting some of them and reduce the bundle size.
 */
export const extractValidationProps = props => VALIDATION_PROP_NAMES.reduce((extractedProps, propName) => {
  if (props.hasOwnProperty(propName)) {
    extractedProps[propName] = props[propName];
  }
  return extractedProps;
}, {});