import {
    FormControl,
    FormControlProps,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
    styled,
    inputLabelClasses,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { get } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { FieldConfigProps } from '.';
import { generateNamesObject } from '../../utils/stringUtils';

export const formSelectClasses = generateNamesObject('smallSelect', 'error', 'smallInputLabel');

export interface FormSelectOption {
    value: number | string;
    name?: string;
    disabled?: boolean;
}

export type FormSelectProps = FieldConfigProps &
    Partial<Omit<SelectProps, 'onChange'>> & {
        children: React.ReactElement<{
            value: any;
            disabled?: boolean;
        }>[];
        label?: string;
        size?: 'small' | 'medium';
        formControlProps?: FormControlProps;
        onChange?: (value: any) => void;
    };

const StyledFormControl = styled(FormControl)(({ variant }) => ({
    [`& .${formSelectClasses.smallSelect}`]: {
        '& > div:first-of-type': {
            padding: '10.5px 14px !important',
        },
    },
    [`& .${formSelectClasses.smallInputLabel}`]: {
        transform: variant === 'outlined' ? 'translate(14px, 11px) scale(1) !important' : undefined,
    },
    [`& .${inputLabelClasses.shrink}`]: {
        transform: variant === 'outlined' ? 'translate(14px, -9px) scale(0.75) !important' : undefined,
    },
    [`& .${formSelectClasses.error}`]: {
        display: 'block',
    },
}));

export const FormSelect: React.FunctionComponent<FormSelectProps> = ({
    name,
    validate,
    label,
    size = 'medium',
    children,
    fullWidth = true,
    formControlProps,
    ...rest
}) => {
    const inputLabel = useRef<HTMLLabelElement>(null);

    return (
        <Field name={name} validate={validate}>
            {({ field, form: { setFieldValue, touched, errors, initialValues } }: FieldProps) => {
                const error = get(touched, name) && (get(errors, name) as string);

                useEffect(() => {
                    const values = children.filter((child) => !child.props.disabled).map((child) => child.props.value);
                    if (field.value && !values.includes(field.value)) {
                        const newValues = get(initialValues, name);
                        setFieldValue(name, values.includes(newValues) ? newValues : '');
                    }
                }, [children]);

                return (
                    <StyledFormControl fullWidth={fullWidth} variant="outlined" {...formControlProps} error={!!error}>
                        <InputLabel
                            ref={inputLabel}
                            className={size === 'small' ? formSelectClasses.smallInputLabel : undefined}
                        >
                            {label}
                        </InputLabel>
                        <Select
                            className={size === 'small' ? formSelectClasses.smallSelect : undefined}
                            {...rest}
                            {...field}
                            label={label}
                            onChange={(event) => {
                                setFieldValue(name, event.target.value);
                                rest.onChange?.(event.target.value as string);
                            }}
                            value={field.value ?? ''}
                        >
                            {children}
                        </Select>
                        {!!error && <FormHelperText className={formSelectClasses.error}>{error}</FormHelperText>}
                    </StyledFormControl>
                );
            }}
        </Field>
    );
};

const renderOptionItem = (item: FormSelectOption) => {
    if (!(item.hasOwnProperty('name') && item.hasOwnProperty('value'))) {
        throw new Error('`Option` must have `name` and `value`');
    }

    return (
        <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
            {item.name}
        </MenuItem>
    );
};

export const renderSelectOptions = (options: FormSelectOption[]) => options.map(renderOptionItem);
