import { generateNamesObject } from '@orbis-systems/orbis-react-toolkit';

import THEME_ENUM from '@api/requests/assets/enums/THEME_ENUM.enum';

import { Grid, alpha, styled } from '@mui/material';

import { MOBILE_LOGO_MAP } from '@components/logo/constants';
import { AppLogo } from '@components/logo/styles';

export const authorizedLayoutClasses = generateNamesObject(
	['appBar', 'account', 'controls', 'userProfilePopper'],
	'AuthorizedLayout',
);

export const RootGrid = styled(Grid)(({ theme }) => ({
	minHeight: '100vh',
	[`& .${authorizedLayoutClasses.appBar}`]: {
		background: theme.palette.background.paper,
		zIndex: theme.zIndex.drawer + 1,
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	[`& .${authorizedLayoutClasses.account}`]: {
		position: 'relative',
		padding: theme.spacing(1),
		margin: theme.spacing(1),
		gap: theme.spacing(1),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		minWidth: 'fit-content',
		height: 48,
		borderRadius: 6,
		border: `1px solid ${theme.palette.divider}`,
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: alpha(theme.palette.primary.main, theme.darkMode ? 0.2 : 0.1),
		},
	},
	[`& .${authorizedLayoutClasses.userProfilePopper}`]: {
		top: '70px!important',
		left: 'calc(100% - 336px)!important',
		backgroundColor: theme.palette.divider,
		borderRadius: 6,
		width: 328,
		border: `1px solid ${theme.palette.secondary.light}`,
		zIndex: theme.zIndex.drawer,
	},
}));

export const AuthorizedLogo = styled(AppLogo)<{ firm: string; mode: THEME_ENUM.DARK | THEME_ENUM.LIGHT }>(
	({ mode, firm, theme }) => ({
		width: theme.spacing(6),
		height: theme.spacing(6),
		backgroundImage: `url(${MOBILE_LOGO_MAP.get(firm + '_' + mode)})`,
		margin: theme.spacing(1, 2),
	}),
);
