import { connect as connectFormik, FormikProps } from 'formik';
import { omitBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { selectPersistState, saveState, ConnectProps } from '../../redux';

type PersistFormProps = {
    persistScope?: string;
    persistKey: string;
    children?: (formRestored: boolean) => any;
};

type State = {
    formRestored: boolean;
};

export const PersistFormContext = React.createContext<string>('');

class PersistForm extends React.Component<
    PersistFormProps & ConnectProps<typeof mapStateToProps, typeof mapDispatchToProps> & { formik: FormikProps<any> },
    State
> {
    static contextType = PersistFormContext;

    state: State = {
        formRestored: false,
    };

    componentWillUnmount() {
        const { saveState, persistScope, persistKey, formik } = this.props;

        saveState({
            scope: persistScope || this.context,
            persistKey,
            payload: omitBy(formik, (item: any) => item instanceof Function),
        });
    }

    componentDidMount() {
        const { state, formik, persistScope, persistKey } = this.props;
        const persistState = selectPersistState(state, { scope: persistScope || this.context, key: persistKey });

        if (persistState) {
            formik.setFormikState(persistState);
        }

        this.setState({
            formRestored: true,
        });
    }

    render() {
        return this.props.children?.(this.state.formRestored) || null;
    }
}

const mapStateToProps = (state: any) => ({ state });

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveState: bindActionCreators(saveState, dispatch),
});

const ConnectedPersistForm = connect(mapStateToProps, mapDispatchToProps)(PersistForm);

const ConnectedFormikPersistForm = connectFormik<PersistFormProps, any>(ConnectedPersistForm);

export { ConnectedFormikPersistForm as PersistForm };
