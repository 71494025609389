import React, { useCallback } from 'react';

import { ApplicationFormPage as ApplicationFormPageInterface } from '@app/redux/applicationForm/applicationForm.interfaces';
import {
	getApplicationFormPages,
	getApplicationFormProgress,
	getApplicationFormSelectedPage,
} from '@app/redux/applicationForm/applicationForm.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { Box } from '@mui/material';

import ApplicationFormPage from '@pages/ApplicationForm/containers/ApplicationFormNavigation/components/ApplicationFormPage';
import { ApplicationFormNavigationDivider } from '@pages/ApplicationForm/containers/ApplicationFormNavigation/styles';

function ApplicationFormPagesList() {
	const applicationFormProgress = useAppSelector(getApplicationFormProgress);
	const applicationFormSelectedPage = useAppSelector(getApplicationFormSelectedPage);
	const applicationFormPages = useAppSelector(getApplicationFormPages).filter(
		applicationFormPage => applicationFormProgress.values.pages[applicationFormPage.id].visible > 0,
	);

	const getApplicationFormPageStatus = useCallback(
		(applicationFormPage: ApplicationFormPageInterface) =>
			(applicationFormProgress.errors.pages[applicationFormPage.id] > 0 && 'error') ||
			(applicationFormProgress.values.pages[applicationFormPage.id].answered ===
				applicationFormProgress.values.pages[applicationFormPage.id].required &&
				'completed') ||
			'pending',
		[applicationFormProgress.errors.pages, applicationFormProgress.values.pages],
	);

	return (
		<>
			{applicationFormPages.map(applicationFormPage => (
				<Box key={applicationFormPage.id}>
					<ApplicationFormPage
						isSelected={applicationFormSelectedPage.id === applicationFormPage.id}
						applicationFormPage={applicationFormPage}
						applicationFormPageStatus={getApplicationFormPageStatus(applicationFormPage)}
					/>

					<ApplicationFormNavigationDivider />
				</Box>
			))}
		</>
	);
}

export default ApplicationFormPagesList;
