import { ButtonBase, alpha, styled } from '@mui/material';

export const VerificationItem = styled(ButtonBase)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	padding: theme.spacing(2, 1.5),
	width: '100%',
	borderRadius: 6,
	background: theme.palette.background.paper,
	'&:hover': {
		cursor: 'pointer',
		backgroundColor: alpha(theme.palette.primary.main, theme.darkMode ? 0.2 : 0.1),
	},
	'& svg path': {
		fill: theme.palette.text.primary,
	},
}));
