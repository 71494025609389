import { ThemeOptions } from '@mui/material';
import { createTheme } from './createTheme';

export const darkThemeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#7088DE',
        },
        neutral: {
            main: '#323F52',
            contrastText: '#fff',
        },
        background: {
            default: '#0B1726',
            paper: '#13243C',
        },
        secondary: {
            main: '#192F4D',
        },
        common: {
            blue: '#3D4C83',
            green: '#12CB92',
            red: '#FD4E4E',
        },
    },
};

export const darkTheme = createTheme(darkThemeOptions);
