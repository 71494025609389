import React, { useEffect } from 'react';

import { showToast } from '@app/providers/ToastProvider';
import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { AnimatedRedirect } from '@components/transition/route';

import { isRetrievalOnly } from '@utils/environment';

function NotRetrievedRoute({ children }: { children: JSX.Element }) {
	const generateRoutePath = useGenerateRoutePath();

	useEffect(() => {
		if (isRetrievalOnly()) {
			showToast.error('User can only retrieve application.');
		}
	}, []);

	if (isRetrievalOnly()) {
		return <AnimatedRedirect to={generateRoutePath(RoutePaths.MAIN.APPLICATIONS)} />;
	}

	return children;
}

export default NotRetrievedRoute;
