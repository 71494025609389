import { useCallback } from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { showToast } from '@app/providers/ToastProvider';

import * as environment from '@utils/environment';

function useNextGenRedirect() {
	const { isPending, startRequest } = useTriggeredRequest(AccountClientRequests.Auth.getNextGenToken);

	const handleRedirectToNextGenSSO = useCallback(async () => {
		try {
			const {
				sso_token: { token: nextGenToken },
			} = await startRequest();

			window.location.replace(`${environment.env.SSO_ENVIRONMENT_URL}/login/sso?token=${nextGenToken}`);
		} catch (e) {
			showToast.error('Could not launch Crypto Trading');
		}
	}, [startRequest]);

	const handleRedirectToNextGenLogin = useCallback(() => {
		window.location.replace('https://trading.axosdigitalassets.com/login');
	}, []);

	return {
		handleRedirectToNextGenSSO,
		handleRedirectToNextGenLogin,
		loading: isPending,
	};
}

export default useNextGenRedirect;
