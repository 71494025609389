import { parsePhoneNumberFromString } from 'libphonenumber-js';

const getNational = (phone: string): string => {
	const phone_number = parsePhoneNumberFromString(phone);
	if (phone_number) {
		return phone_number.nationalNumber.toString();
	}
	return '';
};

const getDialCode = (phone: string): string => {
	const phone_number = parsePhoneNumberFromString(phone);
	if (phone_number) {
		return `+${phone_number.countryCallingCode}`;
	}
	return '+1';
};

export { getNational, getDialCode };
