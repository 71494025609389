import { createDraftSafeSelector } from '@reduxjs/toolkit';

import buildApplicationFormIdentifierQuestionsAnswers from '@app/redux/applicationForm/utils/buildApplicationFormIdentifierQuestionsAnswers/buildApplicationFormIdentifierQuestionsAnswers.utils';
import buildApplicationFormProgress, {
	isApplicationFormQuestionVisible,
} from '@app/redux/applicationForm/utils/buildApplicationFormProgress/buildApplicationFormProgress.utils';
import { RootState } from '@app/redux/interface';

import { ApplicationFormSelectedQuestion } from '@pages/ApplicationForm/containers/ApplicationFormMain';

export const getApplicationFormState = (state: RootState) => state.applicationForm;

export const getApplicationFormPages = createDraftSafeSelector(
	getApplicationFormState,
	applicationFormState => applicationFormState.pages,
);

export const getApplicationFormSelectedPage = createDraftSafeSelector(
	getApplicationFormState,
	applicationFormState => applicationFormState.current.page,
);

export const getApplicationFormSections = createDraftSafeSelector(
	getApplicationFormState,
	applicationFormState => applicationFormState.sections,
);

export const getApplicationFormSelectedSection = createDraftSafeSelector(
	getApplicationFormState,
	applicationFormState => applicationFormState.current.section,
);

export const getApplicationFormProgress = createDraftSafeSelector(
	getApplicationFormState,
	buildApplicationFormProgress,
);

export const getApplicationFormIdentifierQuestionsAnswers = createDraftSafeSelector(
	getApplicationFormState,
	buildApplicationFormIdentifierQuestionsAnswers,
);

export const getApplicationFormSelectedQuestions = createDraftSafeSelector(
	getApplicationFormState,
	(applicationFormState): ApplicationFormSelectedQuestion[] => {
		return applicationFormState.current.questions_ids.map(question_id => {
			const question = applicationFormState.questions[question_id];

			return {
				...question,
				is_visible: isApplicationFormQuestionVisible(question.dependencies, applicationFormState.questions),
				options: question.options.map(option => ({
					...option,
					is_visible: isApplicationFormQuestionVisible(option.dependencies, applicationFormState.questions),
				})),
			};
		});
	},
);
