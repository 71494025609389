import { createAction, createSlice } from '@reduxjs/toolkit';

import AccountClientRequests from '@api/requests';
import THEME_ENUM from '@api/requests/assets/enums/THEME_ENUM.enum';
import { GetCountriesListResponse } from '@api/requests/assets/getCountriesList';
import { GetDocumentTypeListResponse } from '@api/requests/assets/getDocumentTypeList';
import { GetQuestionTypeListResponse } from '@api/requests/assets/getQuestionTypeList';
import { GetRuleSetsResponse } from '@api/requests/assets/getRuleSets';
import { GetStatesListResponse } from '@api/requests/assets/getStatesList';

import { AssetsStateType } from './assets.interfaces';

const initialState: AssetsStateType = {
	currentTheme: undefined,
	countries: [],
	states: [],
	documentTypes: {},
	questionTypes: {},
	ruleSets: {},
};

const getCountriesFulfilled = createAction<GetCountriesListResponse>('assets/getCountriesList/fulfilled');
const getStatesFulfilled = createAction<GetStatesListResponse>('assets/getStatesList/fulfilled');
const getDocumentTypeListsFulfilled = createAction<GetDocumentTypeListResponse>('assets/getDocumentTypeList/fulfilled');
const getQuestionTypeListsFulfilled = createAction<GetQuestionTypeListResponse>('assets/getQuestionTypeList/fulfilled');
const getRuleSetsFulfilled = createAction<GetRuleSetsResponse>('assets/getRuleSets/fulfilled');

export const { actions: assetsActions, reducer: assetsReducer } = createSlice({
	name: 'assets',
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(getCountriesFulfilled, (state, { payload }) => {
				state.countries = payload.countries.filter(({ dial_code }) => dial_code);
			})
			.addCase(getStatesFulfilled, (state, { payload }) => {
				state.states = payload.states;
			})
			.addCase(getDocumentTypeListsFulfilled, (state, { payload }) => {
				state.documentTypes = payload.document_types.reduce((res, curr) => {
					return { ...res, [curr.id]: curr };
				}, {});
			})
			.addCase(getQuestionTypeListsFulfilled, (state, { payload }) => {
				state.questionTypes = payload.question_types.reduce((res, curr) => {
					return { ...res, [curr.id]: curr };
				}, {});
			})
			.addCase(getRuleSetsFulfilled, (state, { payload }) => {
				state.ruleSets = payload.rule_sets.reduce((res, curr) => {
					return { ...res, [curr.id]: curr };
				}, {});
			})
			.addCase(AccountClientRequests.Assets.getCurrentTheme.fulfilled, (state, { payload }) => {
				state.currentTheme = payload.setting.value?.[0] || THEME_ENUM.LIGHT;
			})
			.addCase(AccountClientRequests.Assets.setCurrentTheme.fulfilled, (state, { payload }) => {
				state.currentTheme = payload.setting.value?.[0] || THEME_ENUM.LIGHT;
			}),
});
