import { generateNamesObject } from '@orbis-systems/orbis-react-toolkit';

import { Grid, styled } from '@mui/material';

import bosonicBackground from '@assets/images/bosonic-background.png';
import defaultBackgroundLight from '@assets/images/login-background-light.png';
import defaultBackground from '@assets/images/login-background.png';
import secondLaneBackground from '@assets/images/secondlane-background.png';

const backgrounds: { [key: string]: any } = {
	viewtrade: { dark: defaultBackground, light: defaultBackgroundLight },
	abm: { dark: defaultBackground, light: defaultBackgroundLight },
	viking: { dark: defaultBackground, light: defaultBackgroundLight },
	'axos-crypto': { dark: defaultBackground, light: defaultBackgroundLight },
	'invertir-online': { dark: defaultBackground, light: defaultBackgroundLight },
	'apex-brokerage-services': { dark: defaultBackground, light: defaultBackgroundLight },
	watchdog: { dark: defaultBackground, light: defaultBackgroundLight },
	lightspeed: { dark: defaultBackground, light: defaultBackgroundLight },
	'summit-securities': { dark: defaultBackground, light: defaultBackgroundLight },
	adcap: { dark: defaultBackground, light: defaultBackgroundLight },
	upstox: { dark: defaultBackground, light: defaultBackgroundLight },
	bosonic: { dark: bosonicBackground, light: bosonicBackground },
	york: { dark: defaultBackground, light: defaultBackgroundLight },
	secondlane: { dark: secondLaneBackground, light: secondLaneBackground },
};

export const unauthorizedLayoutClasses = generateNamesObject(
	['leftContainer', 'rightContainer', 'form'],
	'UnauthorizedLayout',
);

export const RootGrid = styled(Grid)(({ theme }) => ({
	height: '100vh',

	[`& .${unauthorizedLayoutClasses.leftContainer}`]: {
		color: theme.palette.common.white,
		display: 'flex',
		alignItems: 'center',
		backgroundImage: theme.darkMode
			? `url(${backgrounds[process.env.FIRM_ENVIRONMENT!].dark})`
			: `url(${backgrounds[process.env.FIRM_ENVIRONMENT!].light})`,
		backgroundPosition: 'center center',
		backgroundSize: 'cover',
	},
	[`& .${unauthorizedLayoutClasses.rightContainer}`]: {
		display: 'flex',
		position: 'relative',
		maxHeight: '100vh',
		overflow: 'auto',
		paddingTop: theme.spacing(18),
		paddingBottom: theme.spacing(3),
		[theme.breakpoints.down('lg')]: {
			paddingTop: theme.spacing(12),
		},
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(8),
		},
		[`& .${unauthorizedLayoutClasses.form}`]: {
			width: 348,
			margin: 'auto',
		},
	},
}));
