import backgroundCheckApplicationForm from './backgroundCheckApplicationForm';
import createApplication from './createApplication';
import custodianCheckApplicationForm from './custodianCheckApplicationForm';
import deleteAnswer from './deleteAnswer';
import getApplicationDetails from './getApplicationDetails';
import getApplications from './getApplications';
import saveFileAnswer from './saveFileAnswer';
import saveMultipleOptionAnswer from './saveMultipleOptionAnswer';
import saveOptionAnswer from './saveOptionAnswer';
import saveTextAnswer from './saveTextAnswer';
import submitApplicationForm from './submitApplicationForm';
import verifyDocumentApplicationForm from './verifyDocumentApplicationForm';

const ApplicationsRequests = {
	getApplicationDetails,
	getApplications,
	createApplication,
	saveTextAnswer,
	saveOptionAnswer,
	saveMultipleOptionAnswer,
	saveFileAnswer,
	deleteAnswer,
	submitApplicationForm,
	backgroundCheckApplicationForm,
	verifyDocumentApplicationForm,
	custodianCheckApplicationForm,
};

export default ApplicationsRequests;
