import React from 'react';

import DropButton from '@components/dropButton';

import useCreateApplication from '@pages/Applications/containers/CreateApplication/hooks';

function CreateApplication() {
	const { forms, disabled, loading, handleCreateApplication } = useCreateApplication();

	return (
		<DropButton
			label={'Start New Application'}
			options={forms}
			onSelect={option => handleCreateApplication(option.id)}
			isLoading={loading}
			disabled={disabled}
		/>
	);
}

export default CreateApplication;
