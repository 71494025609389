import React, { useContext } from 'react';
import { FormNumberField, FormNumberFieldProps } from '../../FormControls';
import { fieldNames } from '../schema';
import { ModifyOrderFormContext } from '../ModifyOrderFormContext';

export type QuantitySelectProps = Partial<FormNumberFieldProps>;

export const QuantitySelect: React.FunctionComponent<QuantitySelectProps> = (props) => {
    const {
        options: { allowFractional },
    } = useContext(ModifyOrderFormContext);

    return (
        <FormNumberField
            label="Quantity"
            size="small"
            {...props}
            name={fieldNames.quantity}
            validate={(value) => {
                let error = '';

                if (!allowFractional && value < Math.floor(value)) {
                    error = 'Fractional is not allowed';
                }

                return error || props.validate?.(value);
            }}
        />
    );
};
