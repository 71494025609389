import moment from 'moment';
import React from 'react';

import APPLICATION_STATUSES_ENUM from '@api/requests/applications/enums/APPLICATION_STATUSES_ENUM.enum';

import TimelineDot from '@mui/lab/TimelineDot';
import { Grid, Stack, Typography } from '@mui/material';

import CopyButton from '@components/copyButton';

import { ApplicationAccountCardProps } from '@pages/Applications/containers/ApplicationAccountCardList';
import {
	getApplicationAccountStatusIcon,
	getApplicationAccountStatusLabel,
} from '@pages/Applications/containers/ApplicationAccountCardList/components/utils';

import AccountIcon from 'jsx:@assets/svgs/wallet.svg';

import { StyledAccountCard, accountCardClasses } from './styles';

function AccountCard({ date, status, uid, isActive, handleSelectCard, form }: ApplicationAccountCardProps) {
	const accountIsUpdating = status === APPLICATION_STATUSES_ENUM.IN_PROGRESS;

	return (
		<StyledAccountCard
			variant="outlined"
			active={isActive}
			className={accountIsUpdating ? accountCardClasses.updatingAccount : undefined}
			onClick={handleSelectCard}
		>
			<Typography variant={'caption'} className={accountCardClasses.formTitle}>
				{form}
			</Typography>
			<Grid container justifyContent={'space-between'} alignItems={'center'}>
				<Grid item>
					<Stack alignItems={'center'} direction={'row'} className={accountCardClasses.titleContainer}>
						<AccountIcon />

						<Typography variant={'caption'} ml={1.5} className={accountCardClasses.accountTitle}>
							Account
						</Typography>
					</Stack>
				</Grid>

				<Grid item>
					<Stack alignItems={'center'} direction={'row'}>
						<TimelineDot color={accountIsUpdating ? 'secondary' : getApplicationAccountStatusIcon(status)} />

						<Typography variant={'caption'} ml={1} className={accountCardClasses.accountStatus}>
							{accountIsUpdating ? 'Updating' : getApplicationAccountStatusLabel(status)}
						</Typography>
					</Stack>
				</Grid>
			</Grid>

			<Typography variant={'h5'} mb={1}>
				<b style={{ marginRight: '8px' }}>{uid}</b>

				<CopyButton copiedValue={uid} />
			</Typography>

			<Typography variant={'caption'} className={accountCardClasses.date}>
				<b>Updated: </b>
				{moment(date).format('MMM Do, YYYY [at] h:mm A')}
			</Typography>
		</StyledAccountCard>
	);
}

export default AccountCard;
