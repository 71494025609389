import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React, { useMemo } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { defaultTheme } from '../themes';

export interface OrbisComponentsProvidersProps {
    children?: React.ReactNode;
    theme?: Theme;
}

export function OrtkProviders(props: OrbisComponentsProvidersProps) {
    const { theme = defaultTheme, children } = props;
    const cacheRtl = useMemo(() => {
        return createCache({
            key: 'muirtl',
            stylisPlugins: [rtlPlugin],
        });
    }, []);

    const jsx = (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider maxSnack={4} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    {children}
                </SnackbarProvider>
            </ThemeProvider>
        </LocalizationProvider>
    );

    if (theme.rtl && cacheRtl) {
        return (
            <CacheProvider value={cacheRtl}>
                <div dir="rtl">{jsx}</div>
            </CacheProvider>
        );
    }

    return jsx;
}
