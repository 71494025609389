import { useFormik } from 'formik';
import { useState } from 'react';

import { LoginFormProps, LoginFormValues } from './interfaces';
import loginFormValidationScheme from './validationScheme';

function useLoginForm({ initialValues, onSubmit }: Pick<LoginFormProps, 'initialValues' | 'onSubmit'>) {
	const [showPassword, setShowPassword] = useState(false);

	const handleShowPassword = () => setShowPassword(!showPassword);

	const formik = useFormik<LoginFormValues>({
		initialValues,
		validationSchema: loginFormValidationScheme,
		onSubmit,
	});

	return { showPassword, handleShowPassword, formik };
}

export default useLoginForm;
