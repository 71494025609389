import { Box, styled, Typography } from '@mui/material';
import { isAfter, isBefore, isSameDay, sub } from 'date-fns';
import { get, isFunction } from 'lodash';
import { BaseFilter } from 'mui-enhanced-table';
import React from 'react';
import { TableRow } from '..';
import { generateNamesObject } from '../../../utils/stringUtils';
import { ClearableDatePicker } from '../../ClearableDatePicker';

export const dateRangeFilterClasses = generateNamesObject('container', 'datePicker', 'datePickerInput', 'pickers');

const Container = styled(Box)(({ theme }) => ({
    width: '100%',
    [`& .${dateRangeFilterClasses.datePicker}`]: {
        marginTop: 8,
        marginRight: 16,
        '& > div': {
            paddingRight: 8,
        },
        width: 160,
    },
    [`& .${dateRangeFilterClasses.pickers}`]: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
    },
}));

export type PickerValueType = Date | number | null;

type Props = {
    fromDate?: PickerValueType;
    toDate?: PickerValueType;
    dateFormat?: string;
    onChange?: (fromDate: PickerValueType, toDate: PickerValueType) => void;
};

type State = {
    fromDate: PickerValueType;
    toDate: PickerValueType;
};

export class DateRangeFilter extends BaseFilter<Props, State> {
    state: State = {
        fromDate: this.props.fromDate ? new Date(this.props.fromDate) : null,
        toDate: this.props.toDate ? new Date(this.props.toDate) : null,
    };

    componentDidMount = () => {
        this.props.onChange?.(this.state.fromDate, this.state.toDate);
    };

    handleFromDateChange = (date: PickerValueType) => {
        if (date) {
            this.setState({ fromDate: date }, this.updateFilter);
        }
    };

    handleToDateChange = (date: PickerValueType) => {
        if (date) {
            this.setState({ toDate: date }, this.updateFilter);
        }
    };

    filterData = (item: TableRow) => {
        let match = true;
        const filterBy = this.props.filterBy;
        const date = new Date(get(item.data, isFunction(filterBy) ? filterBy(item) : filterBy || ''));
        const { fromDate, toDate } = this.state;

        if (fromDate) {
            match = match && (isSameDay(date, fromDate) || isAfter(date, fromDate));
        }

        if (toDate) {
            match = match && (isSameDay(date, toDate) || isBefore(date, toDate));
        }

        return match;
    };

    updateFilter = () => {
        const { data, onChange, onUpdateFilter } = this.props;

        const { fromDate, toDate } = this.state;

        onChange?.(fromDate, toDate);
        onUpdateFilter(data.filter(this.filterData).map((item) => item.id));
    };

    render() {
        const { name, dateFormat = 'MMM dd, yyyy' } = this.props;
        const { fromDate, toDate } = this.state;

        return (
            <Container>
                <Typography variant="overline">{name}</Typography>
                <Box className={dateRangeFilterClasses.pickers}>
                    <ClearableDatePicker
                        disableMaskedInput
                        disableFuture
                        clearable
                        label="From"
                        inputFormat={dateFormat}
                        className={dateRangeFilterClasses.datePicker}
                        value={fromDate}
                        shouldDisableDate={(date) =>
                            (date && toDate && isAfter(date, toDate)) || (date && isAfter(date, Date.now())) || false
                        }
                        onChange={this.handleFromDateChange}
                        onClear={() =>
                            this.setState({
                                fromDate: null,
                            })
                        }
                        InputProps={{
                            size: 'small',
                        }}
                    />

                    <ClearableDatePicker
                        disableMaskedInput
                        disableFuture
                        clearable
                        label="To"
                        inputFormat={dateFormat}
                        className={dateRangeFilterClasses.datePicker}
                        value={toDate}
                        shouldDisableDate={(date) =>
                            (date && fromDate && isBefore(date, sub(fromDate, { days: 1 }))) || false
                        }
                        onChange={this.handleToDateChange}
                        onClear={() =>
                            this.setState({
                                toDate: null,
                            })
                        }
                        InputProps={{
                            size: 'small',
                        }}
                    />
                </Box>
            </Container>
        );
    }
}
