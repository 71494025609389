import { createTheme as createThemeToolkit } from '@orbis-systems/orbis-react-toolkit';
import { cloneDeep, merge } from 'lodash';
import { DeepPartial } from 'utility-types';

import { ThemeOptions } from '@mui/material';

import { getFirmTheme } from './getTheme';

declare module '@mui/material/styles/createTheme' {
	interface Dimensions {
		navigationWidth: number;
		sidebarWidth: number;
		drawerWidth: number;
		slideOutWidth: number;
	}

	interface Theme {
		dimensions: Dimensions;
	}

	interface ThemeOptions {
		dimensions?: Dimensions;
	}
}

const themeOptions: DeepPartial<ThemeOptions> = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: ({ theme }) => ({
					'&.Mui-disabled': {
						color: theme.darkMode ? '#999999' : '#FFFFFF',
					},
				}),
			},
		},
	},
	dimensions: {
		navigationWidth: 72,
		sidebarWidth: 364,
		drawerWidth: 268,
		slideOutWidth: 330,
	},
};

export const createTheme = (isDarkMode = false, rtl = false) => {
	const { dark, light } = getFirmTheme();
	return createThemeToolkit(
		isDarkMode ? merge(cloneDeep(dark), themeOptions) : merge(cloneDeep(light), themeOptions),
		rtl,
	);
};
