import { ScrollSlider, useToggle } from '@orbis-systems/orbis-react-toolkit';
import React from 'react';

import APPLICATION_STATUSES_ENUM from '@api/requests/applications/enums/APPLICATION_STATUSES_ENUM.enum';

import { getApplicationDetails, getApplicationsList } from '@app/redux/applications/applications.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { ButtonBase, Grid, IconButton, Stack, Typography } from '@mui/material';

import branch from '@hocs/branch';

import AccountCard from '@pages/Applications/containers/ApplicationAccountCardList/components/AccountCard';
import ApplicationCard from '@pages/Applications/containers/ApplicationAccountCardList/components/ApplicationCard';

import { ArrowIcon, ShowMoreIcon } from './styles';

export interface ApplicationAccountCardProps {
	account_number?: number;
	date: string;
	status: APPLICATION_STATUSES_ENUM;
	uid: string;
	isActive: boolean;
	handleSelectCard(): void;
	form: string;
}

const ApplicationAccountCard = branch<ApplicationAccountCardProps & JSX.IntrinsicAttributes>({
	factoryFunction: ({ account_number }) => Boolean(account_number),
	RightView: AccountCard,
})(ApplicationCard);

function ApplicationAccountCardList({
	handleSelectCard,
}: {
	handleSelectCard({
		applicationId,
		formId,
	}: {
		applicationId: number;
		formId: number;
		formVersion?: number | null;
	}): void;
}) {
	const [isFullView, toggleFullView] = useToggle(false);

	const applications = useAppSelector(getApplicationsList);
	const applicationDetails = useAppSelector(getApplicationDetails);

	return (
		<React.Fragment>
			{!isFullView ? (
				<ScrollSlider fullWidth childWidth={282} childHeight={150} space={16} noStretch>
					{applications.slice(0, 3).map(application => (
						<ApplicationAccountCard
							key={application.id}
							account_number={application.account_number}
							uid={application.uid || 'N/A'}
							status={application.status}
							date={application.timestamps.updated_at}
							isActive={applicationDetails?.id === application.id}
							form={application.form.translations[0].data}
							handleSelectCard={() =>
								handleSelectCard({
									applicationId: application.id,
									formId: application.form_id,
									formVersion: application.form_version,
								})
							}
						/>
					))}

					{applications.length > 3 ? (
						<ButtonBase sx={{ height: '100%', width: '100%' }} onClick={() => toggleFullView(true)}>
							<Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
								<IconButton aria-label="Show more">
									<ShowMoreIcon />
								</IconButton>

								<Typography variant={'caption'} color={'primary'}>
									<b>Show more ({applications.length - 3})</b>
								</Typography>
							</Stack>
						</ButtonBase>
					) : null}
				</ScrollSlider>
			) : (
				<Grid container>
					<Grid item xs={12} mb={3}>
						<ButtonBase onClick={() => toggleFullView(false)}>
							<Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
								<ArrowIcon />

								<Typography variant={'caption'} ml={1}>
									<b>Show less</b>
								</Typography>
							</Stack>
						</ButtonBase>
					</Grid>

					<Grid item xs={12} sx={{ maxHeight: 350, overflowY: 'auto' }}>
						<Grid container rowSpacing={2} columnSpacing={2}>
							{applications.map(application => (
								<Grid item xs={12} sm={6} md={4} lg={3} key={application.id}>
									<ApplicationAccountCard
										account_number={application.account_number}
										uid={application.uid || 'N/A'}
										status={application.status}
										date={application.timestamps.updated_at}
										isActive={applicationDetails?.id === application.id}
										form={application.form.translations[0].data}
										handleSelectCard={() =>
											handleSelectCard({ applicationId: application.id, formId: application.form_id })
										}
									/>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			)}
		</React.Fragment>
	);
}

export default ApplicationAccountCardList;
