import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { RootState } from '@app/redux/interface';

export const getAuthState = (state: RootState) => state.auth;

export const getIsLoggedIn = createDraftSafeSelector(getAuthState, authState => authState.isLoggedIn);

export const getBranchEnvironment = createDraftSafeSelector(getAuthState, authState => authState.branchEnvironment);

export const getCurrentUser = createDraftSafeSelector(getAuthState, authState => authState.currentUser);

export const isVerificationAccount = createDraftSafeSelector(getAuthState, authState => {
	const isVerificationPhoneRequired = Boolean(authState.currentUser?.branches?.[0]?.require_phone_verification);
	const isVerificationEmailRequired = Boolean(authState.currentUser?.branches?.[0]?.require_email_verification);
	const isPhoneVerified = Boolean(authState.currentUser?.phone_verified);
	const isEmailVerified = Boolean(authState.currentUser?.email_verified);

	return {
		needVerifyAccount:
			(isVerificationPhoneRequired && !isPhoneVerified) || (isVerificationEmailRequired && !isEmailVerified),
		isVerificationPhoneRequired,
		isPhoneVerified,
		isVerificationEmailRequired,
		isEmailVerified,
	};
});

export const getResetPasswordDetails = createDraftSafeSelector(getAuthState, authState => authState.resetPassword);

export const getCodeExpiring = createDraftSafeSelector(getAuthState, authState => authState.resetPassword.expires_at);

export const getVerificationEmailCodeExpiring = createDraftSafeSelector(
	getAuthState,
	authState => authState.verifyEmail.expires_at,
);

export const getVerificationPhoneCodeExpiring = createDraftSafeSelector(
	getAuthState,
	authState => authState.verifyPhone.expires_at,
);

export const getVerificationPhoneType = createDraftSafeSelector(getAuthState, authState => authState.verifyPhone.type);

export const getAccessTokenExpiresAt = createDraftSafeSelector(getAuthState, authState => authState.tokens?.expires_at);
