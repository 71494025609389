import THEME_ENUM from '@api/requests/assets/enums/THEME_ENUM.enum';

export enum LOCAL_STORAGE_KEYS {
	APPLICATION_THEME = 'applicationTheme',
}

const getLocalStorageItem = (key: LOCAL_STORAGE_KEYS) => window.localStorage.getItem(key) || '';

const setLocalStorageItem = (key: LOCAL_STORAGE_KEYS, value: string) => window.localStorage.setItem(key, value);

const cache = {
	applicationTheme: {
		get(): THEME_ENUM {
			return (getLocalStorageItem(LOCAL_STORAGE_KEYS.APPLICATION_THEME) as THEME_ENUM) || THEME_ENUM.LIGHT;
		},

		set(value: THEME_ENUM) {
			setLocalStorageItem(LOCAL_STORAGE_KEYS.APPLICATION_THEME, value);
		},
	},
};

export default cache;
