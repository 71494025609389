import React from 'react';
import { Switch } from 'react-router-dom';

import AuthorizedPageLayout from '@app/layouts/AuthorizedPageLayout';
import UnauthorizedPageLayout from '@app/layouts/UnauthorizedPageLayout';
import AssetsProvider from '@app/providers/AssetsProvider';
import MatchCurrentBranchRoute from '@app/routes/components/MatchCurrentBranchRoute';
import NotRetrievedRoute from '@app/routes/components/NotRetrievedRoute';
import OnlyAuthorizedRoute from '@app/routes/components/OnlyAuthorizedRoute';
import OnlyUnauthorizedRoute from '@app/routes/components/OnlyUnauthorizedRoute';
import RegistrationProtectedRoute from '@app/routes/components/RegistrationProtectedRoute';
import RoutePaths, { AUTHORIZED_ROUTES, UNAUTHORIZED_ROUTES } from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { AnimatedRedirect, AnimatedRoute, AnimatedSwitch } from '@components/transition/route';

import useBranchEnvironment from '@hooks/useBranchEnvironment';

import ApplicationFormPage from '@pages/ApplicationForm';
import ApplicationsPage from '@pages/Applications';
import ForgotPasswordPage from '@pages/Auth/ForgotPasswordPage';
import ResetPasswordPage from '@pages/Auth/ForgotPasswordPage/containers/ResetPassword';
import ResetPasswordMethod from '@pages/Auth/ForgotPasswordPage/containers/RessetPasswordMethod';
import LoginPage from '@pages/Auth/LoginPage';

import RefreshToken from '../../containers/RefreshToken';

function AppRoutes() {
	useBranchEnvironment();

	const generateRoutePath = useGenerateRoutePath();

	return (
		<AssetsProvider>
			<Switch>
				<AnimatedRoute exact path={UNAUTHORIZED_ROUTES} animation={'fade'}>
					<OnlyUnauthorizedRoute>
						<UnauthorizedPageLayout>
							<AnimatedSwitch>
								<AnimatedRoute animation={'fade'} path={RoutePaths.AUTH.REGISTRATION()}>
									<RegistrationProtectedRoute />
								</AnimatedRoute>

								<AnimatedRoute animation={'fade'} path={RoutePaths.AUTH.LOGIN()}>
									<LoginPage />
								</AnimatedRoute>

								<AnimatedRoute animation={'fade'} path={RoutePaths.AUTH.FORGOT_PASSWORD_METHOD()}>
									<ForgotPasswordPage />
								</AnimatedRoute>

								<AnimatedRoute animation={'fade'} path={RoutePaths.AUTH.FORGOT_PASSWORD_VIA_EMAIL()}>
									<ResetPasswordMethod />
								</AnimatedRoute>

								<AnimatedRoute animation={'fade'} path={RoutePaths.AUTH.FORGOT_PASSWORD_VIA_PHONE()}>
									<ResetPasswordMethod />
								</AnimatedRoute>

								<AnimatedRoute animation={'fade'} path={RoutePaths.AUTH.FORGOT_PASSWORD_VIA_VOICE()}>
									<ResetPasswordMethod />
								</AnimatedRoute>

								<AnimatedRoute animation={'fade'} path={RoutePaths.AUTH.RESET_PASSWORD()}>
									<ResetPasswordPage />
								</AnimatedRoute>

								<AnimatedRedirect to={generateRoutePath(RoutePaths.AUTH.LOGIN)} />
							</AnimatedSwitch>
						</UnauthorizedPageLayout>
					</OnlyUnauthorizedRoute>
				</AnimatedRoute>

				<AnimatedRoute animation={'fade'} exact path={AUTHORIZED_ROUTES}>
					<OnlyAuthorizedRoute>
						<AuthorizedPageLayout>
							<RefreshToken />
							<AnimatedSwitch>
								<AnimatedRoute animation={'fade'} exact path={RoutePaths.MAIN.APPLICATIONS()}>
									<MatchCurrentBranchRoute>
										<ApplicationsPage />
									</MatchCurrentBranchRoute>
								</AnimatedRoute>

								<AnimatedRoute animation={'fade'} exact path={RoutePaths.MAIN.APPLICATION_FORM()}>
									<NotRetrievedRoute>
										<MatchCurrentBranchRoute>
											<ApplicationFormPage />
										</MatchCurrentBranchRoute>
									</NotRetrievedRoute>
								</AnimatedRoute>
							</AnimatedSwitch>
						</AuthorizedPageLayout>
					</OnlyAuthorizedRoute>
				</AnimatedRoute>

				<AnimatedRedirect to={generateRoutePath(RoutePaths.AUTH.LOGIN)} />
			</Switch>
		</AssetsProvider>
	);
}

export default AppRoutes;
