import * as React from 'react';
import { useLocalizationContext } from '../useUtils';
import { applyDefaultDate } from '../../utils/date-utils';
export const validateDate = ({
  props,
  value,
  adapter
}) => {
  if (value === null) {
    return null;
  }
  const now = adapter.utils.date();
  const minDate = applyDefaultDate(adapter.utils, props.minDate, adapter.defaultDates.minDate);
  const maxDate = applyDefaultDate(adapter.utils, props.maxDate, adapter.defaultDates.maxDate);
  switch (true) {
    case !adapter.utils.isValid(value):
      return 'invalidDate';
    case Boolean(props.shouldDisableDate && props.shouldDisableDate(value)):
      return 'shouldDisableDate';
    case Boolean(props.shouldDisableMonth && props.shouldDisableMonth(value)):
      return 'shouldDisableMonth';
    case Boolean(props.shouldDisableYear && props.shouldDisableYear(value)):
      return 'shouldDisableYear';
    case Boolean(props.disableFuture && adapter.utils.isAfterDay(value, now)):
      return 'disableFuture';
    case Boolean(props.disablePast && adapter.utils.isBeforeDay(value, now)):
      return 'disablePast';
    case Boolean(minDate && adapter.utils.isBeforeDay(value, minDate)):
      return 'minDate';
    case Boolean(maxDate && adapter.utils.isAfterDay(value, maxDate)):
      return 'maxDate';
    default:
      return null;
  }
};
export const useIsDateDisabled = ({
  shouldDisableDate,
  shouldDisableMonth,
  shouldDisableYear,
  minDate,
  maxDate,
  disableFuture,
  disablePast
}) => {
  const adapter = useLocalizationContext();
  return React.useCallback(day => validateDate({
    adapter,
    value: day,
    props: {
      shouldDisableDate,
      shouldDisableMonth,
      shouldDisableYear,
      minDate,
      maxDate,
      disableFuture,
      disablePast
    }
  }) !== null, [adapter, shouldDisableDate, shouldDisableMonth, shouldDisableYear, minDate, maxDate, disableFuture, disablePast]);
};