import React from 'react';
import { useHistory } from 'react-router';

import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { Grid, Stack, Typography } from '@mui/material';

import { BackButton } from '@pages/Auth/ForgotPasswordPage/containers/ResetPassword/styles';

import ArrowIcon from 'jsx:@assets/svgs/arrowLeft.svg';

import ContactForm from './containers';

function ResetPasswordMethod() {
	const history = useHistory();
	const generateRoutePath = useGenerateRoutePath();

	return (
		<Grid container>
			<BackButton onClick={() => history.push(generateRoutePath(RoutePaths.AUTH.FORGOT_PASSWORD_METHOD))}>
				<Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
					<ArrowIcon />

					<Typography variant="body2" ml={1}>
						<b>Back to method</b>
					</Typography>
				</Stack>
			</BackButton>

			<Typography variant="body1" mb={2} mt={2}>
				<b>Please enter the contact details associated with your account.</b>
			</Typography>

			<ContactForm />
		</Grid>
	);
}

export default ResetPasswordMethod;
