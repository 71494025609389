import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import CurrentSessionInterface from '@api/requests/auth/interfaces/CurrentSessionInterface.interface';
import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';
import configRequest from '@api/requests/configRequest';

export interface LoginData {
	email: string;
	password: string;
}

export interface LoginResponse {
	login: {
		token: CurrentSessionInterface;
		user: CurrentUserInterface;
	};
}

const login = configRequest<LoginResponse, LoginData>({
	requestPrefix: 'auth/login',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.AUTH.LOGIN(),
		method: HTTP_METHODS_ENUM.POST,
		data: { ...data, with: ['user.role', 'user.branches.forms.translations', 'user.branches.initiators'] },
	}),
});

export default login;
