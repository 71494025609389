import { Box, Typography, styled } from '@mui/material';

export const ApplicationFormMainBox = styled(Box)(({ theme }) => ({
	margin: theme.spacing(3, 0, 0, 0),
	padding: theme.spacing(3),
	background: theme.palette.background.paper,
}));

export const UserVerificationMessage = styled(Typography)(({ theme }) => ({
	fontSize: '12px',
	textAlign: 'right',
	cursor: 'pointer',
	'&:hover': {
		color: theme.palette.primary.main,
		textDecoration: 'underline',
	},
}));
