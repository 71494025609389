import React from 'react';

import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';

import ChangePassword from './ChangePassword';
import UserProfileInfo from './InfoView';
import UserProfileUpdate from './Update';
import VerifyEmail from './VerifyEmail';
import VerifyPhone from './VerifyPhone';
import VerifyPhoneMethod from './VerifyPhoneMethod';

export enum USER_PROFILE_STEPS_ENUM {
	INFO = 'user-profile-info',
	UPDATE = 'user-profile-update',
	CHANGE_PASSWORD = 'user-profile-change-password',
	VERIFY_EMAIL = 'user-profile-verify-email',
	VERIFY_PHONE_METHOD = 'user-profile-verify-phone-method',
	VERIFY_PHONE = 'user-profile-verify-phone',
}

export interface UserProfileViewsFactoryComponentProps {
	currentUser: CurrentUserInterface;
	setCurrentStep(value: USER_PROFILE_STEPS_ENUM): void;
	handleCloseUserProfile(): void;
}

export type UserProfileViewsFactoryComponentType = (props: UserProfileViewsFactoryComponentProps) => JSX.Element;

const USER_PROFILE_VIEWS_MAP = new Map<USER_PROFILE_STEPS_ENUM, UserProfileViewsFactoryComponentType>([
	[
		USER_PROFILE_STEPS_ENUM.INFO,
		({ currentUser, handleCloseUserProfile, setCurrentStep }) => (
			<UserProfileInfo
				currentUser={currentUser}
				handleCloseUserProfile={handleCloseUserProfile}
				setCurrentStep={setCurrentStep}
			/>
		),
	],
	[
		USER_PROFILE_STEPS_ENUM.UPDATE,
		({ currentUser, setCurrentStep }) => (
			<UserProfileUpdate currentUser={currentUser} setCurrentStep={setCurrentStep} />
		),
	],
	[
		USER_PROFILE_STEPS_ENUM.CHANGE_PASSWORD,
		({ setCurrentStep, currentUser }) => <ChangePassword currentUser={currentUser} setCurrentStep={setCurrentStep} />,
	],
	[USER_PROFILE_STEPS_ENUM.VERIFY_EMAIL, ({ setCurrentStep }) => <VerifyEmail setCurrentStep={setCurrentStep} />],
	[
		USER_PROFILE_STEPS_ENUM.VERIFY_PHONE_METHOD,
		({ setCurrentStep }) => <VerifyPhoneMethod setCurrentStep={setCurrentStep} />,
	],
	[USER_PROFILE_STEPS_ENUM.VERIFY_PHONE, ({ setCurrentStep }) => <VerifyPhone setCurrentStep={setCurrentStep} />],
]);

export default USER_PROFILE_VIEWS_MAP;
