import { createDraftSafeSelector } from '@reduxjs/toolkit';

import THEME_ENUM from '@api/requests/assets/enums/THEME_ENUM.enum';

import cache from '@app/cache';
import { RootState } from '@app/redux/interface';

export const getAssetsState = (state: RootState) => state.assets;

export const getCurrentTheme = createDraftSafeSelector(getAssetsState, assetsState => {
	const theme = assetsState.currentTheme || cache.applicationTheme.get();

	return theme || THEME_ENUM.LIGHT;
});

export const getAssetsCountries = createDraftSafeSelector(getAssetsState, assetsState => assetsState.countries);

export const getAssetsStates = createDraftSafeSelector(getAssetsState, assetsState => assetsState.states);

export const getAssetsDocumentTypeList = createDraftSafeSelector(
	getAssetsState,
	assetsState => assetsState.documentTypes,
);

export const getAssetsQuestionTypeList = createDraftSafeSelector(
	getAssetsState,
	assetsState => assetsState.questionTypes,
);

export const getAssetsRuleSets = createDraftSafeSelector(getAssetsState, assetsState => assetsState.ruleSets);
