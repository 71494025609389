import { divide, multiply } from '../../utils/numberUtils';
import { getInitialTradingFormValues, TradingFormValues } from './schema';
import { getTradingFormMeta } from './TradingFormContext';

export const getTradingTotal = (values: TradingFormValues, quantity: number, lastPrice: number) => {
    const { limitOrder, stopOrder } = getTradingFormMeta(getInitialTradingFormValues(values));
    return multiply(
        values?.limitPrice && limitOrder
            ? values.limitPrice
            : values?.stopPrice && stopOrder
            ? values.stopPrice
            : lastPrice,
        quantity,
    );
};

export const getTradingQuantity = (values: TradingFormValues, orderValue: number, lastPrice: number) => {
    const { limitOrder, stopOrder } = getTradingFormMeta(getInitialTradingFormValues(values));
    return divide(
        orderValue,
        values?.limitPrice && limitOrder
            ? values.limitPrice
            : values?.stopPrice && stopOrder
            ? values.stopPrice
            : lastPrice,
    );
};
