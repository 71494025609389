import { ApiClient } from './ApiClient';

export class BaseApi {
    protected apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        if (!apiClient) {
            throw new Error('No API client provided');
        }

        this.apiClient = apiClient;
    }
}
