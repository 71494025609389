import { ACTION_TYPE_PREFIX } from '../constants';
import { createAction } from '@reduxjs/toolkit';

export const saveState = createAction<{
    scope?: string;
    persistKey: string;
    payload: any;
}>(`${ACTION_TYPE_PREFIX}/SaveState`);

export const removeScopedStates = createAction<string>(`${ACTION_TYPE_PREFIX}/RemoveScopedState`);

export const resetPersistState = createAction(`${ACTION_TYPE_PREFIX}/ResetPersistState`);
