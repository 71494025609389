import React from 'react';

import { Grid, Skeleton } from '@mui/material';

import { StyledAccountCardSkeleton } from './styles';

function AccountCardSkeleton() {
	return (
		<StyledAccountCardSkeleton>
			<Grid container justifyContent={'space-between'}>
				<Skeleton width={'45%'} height={20} />

				<Skeleton width={'45%'} height={20} />
			</Grid>

			<Skeleton width={'100%'} height={20} />

			<Skeleton width={'100%'} height={20} />

			<Skeleton width={'100%'} height={20} />
		</StyledAccountCardSkeleton>
	);
}

export default AccountCardSkeleton;
