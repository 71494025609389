import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import { NUM_BOOL_TYPE } from '@api/interfaces/SharedInterfaces.interface';
import configRequest from '@api/requests/configRequest';

import { showToast } from '@app/providers/ToastProvider';

export interface SaveMultipleOptionAnswerData {
	application_id: number;
	question_id: number;
	data: number | { data: number }[];
}

export interface SaveMultipleOptionAnswerResponse {
	answers?: SaveMultipleOptionAnswer[];
	answer: SaveMultipleOptionAnswer;
}

export interface SaveMultipleOptionAnswer {
	answered_by: number;
	data: string;
	deleted_at: string | null;
	id: number;
	is_encrypted_with_application_key: NUM_BOOL_TYPE | null;
	is_encrypted_with_public_key: NUM_BOOL_TYPE | null;
	option_id: number;
	question_id: number;
}

const saveMultipleOptionAnswer = configRequest<SaveMultipleOptionAnswerResponse, SaveMultipleOptionAnswerData>({
	requestPrefix: 'applications/saveMultipleOptionAnswer',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.APPLICATION_FORM.ANSWER(),
		method: HTTP_METHODS_ENUM.POST,
		data,
	}),
	onSuccess: () => {
		showToast.success('Answer has been saved successfully');
	},
});

export default saveMultipleOptionAnswer;
