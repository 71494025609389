import { Field, FieldProps } from 'formik';
import _ from 'lodash';
import React from 'react';
import { FieldConfigProps } from '.';
import { ClearableDatePicker, ClearableDatePickerProps } from '../ClearableDatePicker';

type FormDatePickerProps = FieldConfigProps & Partial<ClearableDatePickerProps<Date, Date>>;

export const FormDatePicker: React.FunctionComponent<FormDatePickerProps> = ({ name, validate, ...rest }) => {
    return (
        <Field name={name} validate={validate}>
            {({ field, form: { touched, errors, setFieldValue } }: FieldProps) => {
                // FIXME: can't use meta data due to https://github.com/jaredpalmer/formik/issues/343
                const error = _.get(touched, field.name) && (_.get(errors, field.name) as string);
                return (
                    <ClearableDatePicker
                        {...rest}
                        {...field}
                        onChange={(date) => {
                            rest.onChange?.(date);
                            setFieldValue(name, date);
                        }}
                        onClear={() => setFieldValue(name, null)}
                        error={error}
                    />
                );
            }}
        </Field>
    );
};
