import React from 'react';
import clsx from 'clsx';
import { alpha, Box, styled, Typography } from '@mui/material';
import { generateNamesObject } from '../../utils';

export const pulsingBadgeClasses = generateNamesObject(
    [
        'badge',
        'dangerBadge',
        'warningBadge',
        'infoBadge',
        'successBadge',
        'badgeBorder',
        'dangerBadgeBorder',
        'warningBadgeBorder',
        'infoBadgeBorder',
        'successBadgeBorder',
        'circle',
        'warningCircle',
        'infoCircle',
        'dangerCircle',
        'successCircle',
    ],
    'PulsingBadge',
);

const Container = styled(Box)(({ theme }) => ({
    [`& .${pulsingBadgeClasses.badge}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    [`& .${pulsingBadgeClasses.dangerBadge}`]: {
        color: theme.palette.error.main,
    },
    [`& .${pulsingBadgeClasses.warningBadge}`]: {
        color: theme.palette.warning.main,
    },
    [`& .${pulsingBadgeClasses.infoBadge}`]: {
        color: theme.palette.info.main,
    },
    [`& .${pulsingBadgeClasses.successBadge}`]: {
        color: theme.palette.success.main,
    },
    [`& .${pulsingBadgeClasses.badgeBorder}`]: {
        borderRadius: '6px',
    },
    [`& .${pulsingBadgeClasses.dangerBadgeBorder}`]: {
        border: `1px solid ${theme.palette.error.main}`,
    },
    [`& .${pulsingBadgeClasses.warningBadgeBorder}`]: {
        border: `1px solid ${theme.palette.warning.main}`,
    },
    [`& .${pulsingBadgeClasses.infoBadgeBorder}`]: {
        border: `1px solid ${theme.palette.info.main}`,
    },
    [`& .${pulsingBadgeClasses.successBadgeBorder}`]: {
        border: `1px solid ${theme.palette.success.main}`,
    },
    [`& .${pulsingBadgeClasses.circle}`]: {
        margin: 5,
        width: 8,
        height: 8,
        borderRadius: '50%',
    },
    [`& .${pulsingBadgeClasses.warningCircle}`]: {
        backgroundColor: theme.palette.warning.main,
        boxShadow: `0 0 0 ${alpha(theme.palette.warning.main, 0.5)}`,
        animation: `pulsing-warning 1500ms ${theme.transitions.easing.easeOut} infinite`,
    },
    [`& .${pulsingBadgeClasses.dangerCircle}`]: {
        backgroundColor: theme.palette.error.main,
        boxShadow: `0 0 0 ${alpha(theme.palette.error.main, 0.5)}`,
        animation: `pulsing-danger 1500ms ${theme.transitions.easing.easeOut} infinite`,
    },
    [`& .${pulsingBadgeClasses.infoCircle}`]: {
        backgroundColor: theme.palette.info.main,
        boxShadow: `0 0 0 ${alpha(theme.palette.info.main, 0.5)}`,
        animation: `pulsing-info 1500ms ${theme.transitions.easing.easeOut} infinite`,
    },
    [`& .${pulsingBadgeClasses.successCircle}`]: {
        backgroundColor: theme.palette.success.main,
        boxShadow: `0 0 0 ${alpha(theme.palette.success.main, 0.5)}`,
        animation: `pulsing-success 1500ms ${theme.transitions.easing.easeOut} infinite`,
    },
    '@keyframes pulsing-danger': {
        '0%': {
            boxShadow: `0 0 0 0 ${alpha(theme.palette.error.main, 0.5)}`,
        },
        '70%': {
            boxShadow: `0 0 0 8px ${alpha(theme.palette.error.main, 0.0)}`,
        },
        '100%': {
            boxShadow: `0 0 0 0 ${alpha(theme.palette.error.main, 0.0)}`,
        },
    },
    '@keyframes pulsing-warning': {
        '0%': {
            boxShadow: `0 0 0 0 ${alpha(theme.palette.warning.main, 0.5)}`,
        },
        '70%': {
            boxShadow: `0 0 0 8px ${alpha(theme.palette.warning.main, 0.0)}`,
        },
        '100%': {
            boxShadow: `0 0 0 0 ${alpha(theme.palette.warning.main, 0.0)}`,
        },
    },

    '@keyframes pulsing-info': {
        '0%': {
            boxShadow: `0 0 0 0 ${alpha(theme.palette.info.main, 0.5)}`,
        },
        '70%': {
            boxShadow: `0 0 0 8px ${alpha(theme.palette.info.main, 0.0)}`,
        },
        '100%': {
            boxShadow: `0 0 0 0 ${alpha(theme.palette.info.main, 0.0)}`,
        },
    },
    '@keyframes pulsing-success': {
        '0%': {
            boxShadow: `0 0 0 0 ${alpha(theme.palette.success.main, 0.5)}`,
        },
        '70%': {
            boxShadow: `0 0 0 8px ${alpha(theme.palette.success.main, 0.0)}`,
        },
        '100%': {
            boxShadow: `0 0 0 0 ${alpha(theme.palette.success.main, 0.0)}`,
        },
    },
}));

type Props = {
    withBorder: boolean;
    badgeLabel?: string;
    variant: 'info' | 'danger' | 'warning' | 'success';
    children?: React.ReactNode;
};

export const PulsingBadge: React.FunctionComponent<Props> = ({ withBorder = false, badgeLabel, variant, children }) => {
    return (
        <Container>
            <div
                className={clsx(pulsingBadgeClasses.badge, {
                    [pulsingBadgeClasses.badgeBorder]: withBorder,
                    [pulsingBadgeClasses.infoBadge]: variant === 'info',
                    [pulsingBadgeClasses.dangerBadge]: variant === 'danger',
                    [pulsingBadgeClasses.warningBadge]: variant === 'warning',
                    [pulsingBadgeClasses.successBadge]: variant === 'success',
                    [pulsingBadgeClasses.infoBadgeBorder]: variant === 'info' && withBorder,
                    [pulsingBadgeClasses.dangerBadgeBorder]: variant === 'danger' && withBorder,
                    [pulsingBadgeClasses.warningBadgeBorder]: variant === 'warning' && withBorder,
                    [pulsingBadgeClasses.successBadgeBorder]: variant === 'success' && withBorder,
                })}
            >
                <div
                    className={clsx(pulsingBadgeClasses.circle, {
                        [pulsingBadgeClasses.infoCircle]: variant === 'info',
                        [pulsingBadgeClasses.dangerCircle]: variant === 'danger',
                        [pulsingBadgeClasses.warningCircle]: variant === 'warning',
                        [pulsingBadgeClasses.successCircle]: variant === 'success',
                    })}
                />
                {badgeLabel && <Typography variant="caption">{badgeLabel}</Typography>}
            </div>
            {children}
        </Container>
    );
};
