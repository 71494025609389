import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import { FormSelect, FormSelectOption, FormSelectProps, renderSelectOptions } from '../../FormControls';
import { OrderTransaction, PositionType } from '../lookups';
import { fieldNames, TradingFormValues } from '../schema';
import {
    mutualTransactionOptions,
    optionTransactionOptions,
    spreadTransactionOptions,
    transactionOptions,
} from '../selectOptions';
import { TradingFormContext } from '../TradingFormContext';

export type TransactionSelectProps = Partial<FormSelectProps> & {
    canSell?: boolean;
    canShort?: boolean;
};

export const TransactionSelect: React.FunctionComponent<TransactionSelectProps> = ({
    canSell = true,
    canShort = false, // margin account and position is shortable
    ...formSelectProps
}) => {
    const { values, setFieldValue } = useFormikContext<TradingFormValues>();
    const {
        meta: { optionTrading, mutualFundTrading, spreadTrading, shorting, notionalOrder },
    } = useContext(TradingFormContext);
    const transactions = useMemo(() => {
        const result: Partial<typeof transactionOptions> = _.cloneDeep(transactionOptions);

        if (!canShort) {
            delete result.shortSell;
            delete result.buyToCover;
        }

        if (notionalOrder) {
            delete result.shortSell;
            delete result.buyToCover;
        }

        if (!canSell) {
            delete result.sell;
        }

        return Object.values(result) as FormSelectOption[];
    }, [canSell, canShort]);

    useEffect(() => {
        if (shorting || values.transaction === OrderTransaction.BuyToCover) {
            setFieldValue(fieldNames.positionType, PositionType.Short);
        }
    }, [values.transaction]);

    return (
        <FormSelect size={'small'} label="Transaction" {...formSelectProps} name={fieldNames.transaction}>
            {renderSelectOptions(
                Object.values(
                    optionTrading || spreadTrading || mutualFundTrading
                        ? spreadTrading
                            ? spreadTransactionOptions
                            : mutualFundTrading
                            ? mutualTransactionOptions
                            : optionTransactionOptions
                        : transactions,
                ),
            )}
        </FormSelect>
    );
};
