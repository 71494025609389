import { StyleSheet } from '@react-pdf/renderer';

const commonStyles = {
	fontSize: 14,
	fontColor: '#3E4056',
};

const styles = StyleSheet.create({
	documentHeader: {
		marginBottom: 30,
	},
	documentLogo: {
		width: 45,
		height: 45,
		marginBottom: 30,
	},
	documentBaseTextInfo: {
		fontSize: 12,
		marginBottom: 8,
		color: commonStyles.fontColor,
	},
	page: {
		position: 'relative',
		padding: 30,
	},
	section: {
		marginBottom: 34,
	},
	sectionHeader: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		marginBottom: 25,
	},
	sectionLabel: {
		fontSize: commonStyles.fontSize,
		fontStyle: 'bold',
		color: commonStyles.fontColor,
	},
	sectionMark: {
		width: 6,
		height: 6,
		marginRight: 6,
		backgroundColor: commonStyles.fontColor,
	},
	qaItem: {
		marginBottom: 20,
	},
	questionLabel: {
		marginBottom: 8,
		fontSize: commonStyles.fontSize,
		fontStyle: 'bold',
		color: commonStyles.fontColor,
	},
	answerLabel: {
		fontSize: commonStyles.fontSize,
		color: commonStyles.fontColor,
	},
	pageCounter: {
		position: 'absolute',
		bottom: 15,
		right: 15,
		fontSize: 12,
		color: commonStyles.fontColor,
	},
	pageTopTitle: {
		position: 'absolute',
		top: 15,
		right: 15,
		fontSize: 12,
	},
});

export default styles;
