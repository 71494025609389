import React from 'react';

import { Typography } from '@mui/material';

import DropButton from '@components/dropButton';

import useCreateApplication from '@pages/Applications/containers/CreateApplication/hooks';

import ListIcon from 'jsx:@assets/svgs/list.svg';

import { EmptyLayoutStyled } from './styles';

function EmptyState() {
	const { forms, disabled, loading, handleCreateApplication } = useCreateApplication();

	return (
		<EmptyLayoutStyled>
			<ListIcon />

			<Typography variant="h6" textAlign={'center'} mb={1} mt={2}>
				<b>You don’t have accounts yet.</b>
			</Typography>

			<Typography color={'textSecondary'} textAlign={'center'} mb={3}>
				To create a new account please start an application
			</Typography>
			<DropButton
				label={'Start New Application'}
				options={forms}
				onSelect={option => handleCreateApplication(option.id)}
				isLoading={loading}
				disabled={disabled}
			/>
		</EmptyLayoutStyled>
	);
}

export default EmptyState;
