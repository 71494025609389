import React from 'react';
import { Tooltip, IconButton, Icon, IconButtonProps, TooltipProps } from '@mui/material';
import { isString } from 'lodash';

type Props = IconButtonProps & {
    tooltip?: string;
    tooltipProps?: Partial<TooltipProps>;
    icon: string | React.ReactNode;
};

export const IconButtonWithTooltip: React.FunctionComponent<Props> = ({ tooltip, tooltipProps, icon, ...rest }) => {
    const button = (
        <IconButton {...rest}>{isString(icon) ? <Icon className={icon} fontSize="inherit" /> : icon}</IconButton>
    );

    if (!tooltip) {
        return button;
    }

    return (
        <Tooltip title={tooltip} {...tooltipProps}>
            <span>{button}</span>
        </Tooltip>
    );
};
