import { FormSelectOption } from '../FormControls';
import { OrderType } from '../TradingForm';
import { CryptoOrderTransaction, TimeInForce } from './lookups';

export const timeInForceOptions: {
    fillOrKill: FormSelectOption;
    immediateOrCancel: FormSelectOption;
} = {
    fillOrKill: {
        name: 'Fill Or Kill',
        value: TimeInForce.FillOrKill,
    },
    immediateOrCancel: {
        name: 'Immediate Or Cancel',
        value: TimeInForce.ImmediateOrCancel,
    },
};

export const cryptoOrderTypeOptions: {
    market: FormSelectOption;
    limit: FormSelectOption;
    // stop: FormSelectOption;
} = {
    limit: {
        name: 'Limit',
        value: OrderType.Limit,
    },
    market: {
        name: 'Market',
        value: OrderType.Market,
    },
    // Not apart of MVP
    // stop: {
    //     name: 'Stop',
    //     value: OrderType.Stop,
    // },
};

export const cryptoTransactionOptions: {
    buy: FormSelectOption;
    sell: FormSelectOption;
} = {
    buy: {
        name: 'Buy',
        value: CryptoOrderTransaction.Buy,
    },
    sell: {
        name: 'Sell',
        value: CryptoOrderTransaction.Sell,
    },
};
