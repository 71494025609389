import moment from 'moment';
import React from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';
import APPLICATION_DOCUMENT_STATUSES_ENUM from '@api/requests/applications/enums/APPLICATION_DOCUMENT_STATUSES_ENUM.enum';
import ApplicationDetailsInterface from '@api/requests/applications/interfaces/ApplicationDetailsInterface.interface';

import { showToast } from '@app/providers/ToastProvider';
import { getApplicationFormIdentifierQuestionsAnswers } from '@app/redux/applicationForm/applicationForm.selectors';
import { useAppSelector } from '@app/redux/hooks';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, CircularProgress, Divider, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';

import FileManager from '@components/fileManager';

import {
	getApplicationAccountDocumentIcon,
	getApplicationAccountTypeIcon,
} from '@pages/Applications/containers/ApplicationAccountCardList/components/utils';
import { StyledDivider } from '@pages/Applications/styles';

import * as environment from '@utils/environment';

import FolderIcon from 'jsx:@assets/svgs/folder.svg';

import { StyledInfoSection } from './styles';

function DetailsSection({ applicationDetails }: { applicationDetails: ApplicationDetailsInterface }) {
	const applicationFormIdentifierQuestionsAnswers = useAppSelector(getApplicationFormIdentifierQuestionsAnswers);

	const { isPending: isPendingSaveAnswer, startRequest: saveFileAnswer } = useTriggeredRequest(
		AccountClientRequests.Applications.saveFileAnswer,
	);

	const handleSaveAnswer = async (file: File, questionId: number) => {
		try {
			await saveFileAnswer({
				application_id: applicationDetails.id,
				question_id: questionId,
				file,
			});
		} catch (e) {
			showToast.error('Something went wrong!');
		}
	};

	const accountTypeIcon = getApplicationAccountTypeIcon(applicationFormIdentifierQuestionsAnswers.account_type);

	return (
		<StyledInfoSection>
			<Grid item xs={12} px={2}>
				<Grid container columnSpacing={5} rowSpacing={3}>
					{applicationFormIdentifierQuestionsAnswers.customer_type !== 'Does not exist' && (
						<Grid item xs={6} sm={6} md={4}>
							<Stack direction={'column'}>
								<Typography color="textSecondary" variant={'caption'}>
									Customer type
								</Typography>

								<Typography variant={'body2'}>{applicationFormIdentifierQuestionsAnswers.customer_type}</Typography>
							</Stack>
						</Grid>
					)}

					{applicationFormIdentifierQuestionsAnswers.account_type !== 'Does not exist' && (
						<Grid item xs={6} sm={6} md={4}>
							<Stack direction={'column'}>
								<Typography color="textSecondary" variant={'caption'}>
									Account type
								</Typography>
								<Stack direction={'row'} alignItems={'center'}>
									{accountTypeIcon}

									<Typography variant={'body2'} ml={accountTypeIcon ? 0.5 : 0}>
										{environment.env.FIRM_ENVIRONMENT === 'axos-crypto'
											? 'Crypto'
											: applicationFormIdentifierQuestionsAnswers.account_type}
									</Typography>
								</Stack>
							</Stack>
						</Grid>
					)}

					{applicationFormIdentifierQuestionsAnswers.options !== 'Does not exist' && (
						<Grid item xs={6} sm={6} md={4}>
							<Stack direction={'column'}>
								<Typography color="textSecondary" variant={'caption'}>
									Options
								</Typography>

								<Typography variant={'body2'}>{applicationFormIdentifierQuestionsAnswers.options}</Typography>
							</Stack>
						</Grid>
					)}
				</Grid>
			</Grid>

			<StyledDivider orientation="horizontal" flexItem />

			<Grid item xs={12} px={2}>
				<Grid container columnSpacing={5} rowSpacing={3}>
					<Grid item xs={6} sm={6} md={4}>
						<Stack direction={'column'}>
							<Typography color="textSecondary" variant={'caption'}>
								Created
							</Typography>

							<Typography variant={'body2'}>
								{applicationDetails.timestamps.created_at
									? moment(applicationDetails.timestamps.created_at).format('MMM Do, YYYY [at] h:mm A')
									: 'N/A'}
							</Typography>
						</Stack>
					</Grid>

					<Grid item xs={6} sm={6} md={4}>
						<Stack direction={'column'}>
							<Typography color="textSecondary" variant={'caption'}>
								Last updated
							</Typography>

							<Typography variant={'body2'}>
								{applicationDetails.timestamps.updated_at
									? moment(applicationDetails.timestamps.updated_at).format('MMM Do, YYYY [at] h:mm A')
									: 'N/A'}
							</Typography>
						</Stack>
					</Grid>

					<Grid item xs={6} sm={6} md={4}>
						<Stack direction={'column'}>
							<Typography color="textSecondary" variant={'caption'}>
								Submitted
							</Typography>

							<Typography variant={'body2'}>
								{applicationDetails.submitted_at
									? moment(applicationDetails.submitted_at).format('MMM Do, YYYY [at] h:mm A')
									: 'N/A'}
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</Grid>

			<StyledDivider orientation="horizontal" flexItem />

			<Grid item xs={12} px={2}>
				<Typography variant={'body1'} mb={5}>
					<b>Uploads</b>
				</Typography>

				{applicationDetails?.documents.length ? (
					<Grid container rowSpacing={2}>
						{applicationDetails?.documents.map(document => (
							<Grid item xs={12} key={document.id}>
								<Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
									<Typography variant={'caption'}>
										<b>{document.answer?.question?.translations?.[0].data || 'Document'}</b>
									</Typography>

									<Grid item display={'flex'} alignItems={'center'}>
										{getApplicationAccountDocumentIcon(document.current_status.status)}

										<Typography variant={'caption'} ml={1.5} sx={{ textTransform: 'capitalize' }} mr={2}>
											{document.current_status.status === 'pending' ? 'Approved' : document.current_status.status}
										</Typography>

										<Divider orientation="vertical" flexItem />

										<Box ml={1}>
											<Tooltip title="Re-upload file" placement="top">
												<FileManager
													type={'document-image'}
													onChange={newFile => handleSaveAnswer(newFile, document.answer.question.id)}
												>
													{({ handleOpenFileProvider }) => (
														<IconButton
															onClick={handleOpenFileProvider}
															disabled={document.current_status.status !== APPLICATION_DOCUMENT_STATUSES_ENUM.DECLINED}
														>
															{isPendingSaveAnswer ? <CircularProgress size={20} /> : <FileUploadIcon />}
														</IconButton>
													)}
												</FileManager>
											</Tooltip>
										</Box>
									</Grid>
								</Stack>
							</Grid>
						))}
					</Grid>
				) : (
					<Grid container alignItems={'center'} justifyContent={'center'} flexDirection={'column'} pt={2}>
						<FolderIcon />

						<Typography color="textSecondary" variant={'caption'} mt={1}>
							There aren&#39;t any uploaded documents yet
						</Typography>
					</Grid>
				)}
			</Grid>
		</StyledInfoSection>
	);
}

export default DetailsSection;
