import * as Yup from 'yup';

const currentUserFormValidationScheme = Yup.object().shape({
	first_name: Yup.string().required('This field is required.'),
	last_name: Yup.string().required('This field is required.'),
	email: Yup.string().email('Email address is invalid.').required('This field is required.'),
	phone: Yup.object().shape({
		code: Yup.string().required('Required.'),
		number: Yup.string().required('This field is required.'),
	}),
});

export default currentUserFormValidationScheme;
