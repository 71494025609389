import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import AssetsDocumentTypeInterface from '@api/requests/assets/interfaces/AssetsDocumentTypeInterface.interface';
import configRequest from '@api/requests/configRequest';

export type GetDocumentTypeListData = void;

export interface GetDocumentTypeListResponse {
	document_types: AssetsDocumentTypeInterface[];
}

const getDocumentTypeList = configRequest<GetDocumentTypeListResponse, GetDocumentTypeListData>({
	requestPrefix: 'assets/getDocumentTypeList',
	reqConfigCreator: () => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.ASSETS.DOCUMENT_TYPES.LIST(),
		method: HTTP_METHODS_ENUM.POST,
	}),
});

export default getDocumentTypeList;
