import { useEffect } from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { showToast } from '@app/providers/ToastProvider';
import { getApplicationDetails, getApplicationsList } from '@app/redux/applications/applications.selectors';
import { useAppSelector } from '@app/redux/hooks';

import useFetchApplicationForm from '@hooks/useFetchApplicationForm';

import useCreateApplication from '@pages/Applications/containers/CreateApplication/hooks';

function useApplicationsPage() {
	const applications = useAppSelector(getApplicationsList);
	const applicationDetails = useAppSelector(getApplicationDetails);

	const { startRequest: fetchApplicationsList, isPending: isLoadingApplicationsList } = useTriggeredRequest(
		AccountClientRequests.Applications.getApplications,
	);

	const { fetchApplicationForm, isLoading: isLoadingApplicationForm } = useFetchApplicationForm();
	const { loading: isCreatingNewApplication } = useCreateApplication();

	useEffect(() => {
		async function fetchApplicationData() {
			try {
				const {
					applications: [firstApplication],
				} = await fetchApplicationsList();

				if (firstApplication) {
					await fetchApplicationForm({
						applicationId: applicationDetails ? applicationDetails?.id : firstApplication?.id,
						formId: applicationDetails ? applicationDetails?.form_id : firstApplication?.form_id,
						formVersion: applicationDetails ? applicationDetails?.form_version : firstApplication?.form_version,
					});
				}
			} catch (e) {
				showToast.error('Something went wrong!');
			}
		}

		fetchApplicationData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchApplicationForm, fetchApplicationsList]);

	return {
		applications,
		isLoadingApplicationsList,
		isLoadingApplicationForm,
		isCreatingNewApplication,
		fetchApplicationForm,
	};
}

export default useApplicationsPage;
