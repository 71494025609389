import {
	APPLICATION_FORM_QUESTION_TYPES_ENUM,
	ApplicationFormQuestion,
} from '@app/redux/applicationForm/applicationForm.interfaces';

import AgreementFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/AgreementFieldApplicationForm';
import CheckboxGroupFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/CheckboxGroupFieldApplicationForm';
import CountryFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/CountryFieldApplicationForm';
import DateFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/DateFieldApplicationForm';
import FileFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/FileFieldApplicationForm';
import RadioGroupFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/RadioGroupFieldApplicationForm';
import SelectFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/SelectFieldApplicationForm';
import SignatureFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/SignatureFieldApplicationForm';
import StateFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/StateFieldApplicationForm';
import TextFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/TextFieldApplicationForm';

function getApplicationFormQuestionField({
	questionType,
	documentType,
}: {
	questionType: ApplicationFormQuestion['type'];
	documentType: ApplicationFormQuestion['document_type'];
}) {
	if (questionType === APPLICATION_FORM_QUESTION_TYPES_ENUM.FILE && documentType === 'digital-signature') {
		return SignatureFieldApplicationForm;
	}

	switch (questionType) {
		case APPLICATION_FORM_QUESTION_TYPES_ENUM.TEXT:
		case APPLICATION_FORM_QUESTION_TYPES_ENUM.TEXTAREA:
		case APPLICATION_FORM_QUESTION_TYPES_ENUM.NUMBER:
		case APPLICATION_FORM_QUESTION_TYPES_ENUM.EMAIL:
			return TextFieldApplicationForm;
		case APPLICATION_FORM_QUESTION_TYPES_ENUM.CHECKBOX:
			return CheckboxGroupFieldApplicationForm;
		case APPLICATION_FORM_QUESTION_TYPES_ENUM.RADIO:
			return RadioGroupFieldApplicationForm;
		case APPLICATION_FORM_QUESTION_TYPES_ENUM.SELECT:
			return SelectFieldApplicationForm;
		case APPLICATION_FORM_QUESTION_TYPES_ENUM.STATE:
			return StateFieldApplicationForm;
		case APPLICATION_FORM_QUESTION_TYPES_ENUM.COUNTRY:
			return CountryFieldApplicationForm;
		case APPLICATION_FORM_QUESTION_TYPES_ENUM.AGREEMENT:
			return AgreementFieldApplicationForm;
		case APPLICATION_FORM_QUESTION_TYPES_ENUM.FILE:
			return FileFieldApplicationForm;
		case APPLICATION_FORM_QUESTION_TYPES_ENUM.DATE:
			return DateFieldApplicationForm;
		default:
			return null;
	}
}

export default getApplicationFormQuestionField;
