import { NUM_BOOL_TYPE } from '@api/interfaces/SharedInterfaces.interface';

export enum APPLICATION_FORM_QUESTION_TYPES_ENUM {
	SELECT = 'select',
	RADIO = 'radio',
	AGREEMENT = 'agreement',
	CHECKBOX = 'checkbox',
	TEXT = 'text',
	NUMBER = 'number',
	EMAIL = 'email',
	STATE = 'state',
	COUNTRY = 'country',
	DATE = 'date',
	TEXTAREA = 'textarea',
	FILE = 'file',
}

export interface ApplicationFormAnswer {
	answered_by: number;
	value: string;
	options: number[];
	data: {
		locked: boolean;
		reason: string;
		declined: boolean;
	};
}

export interface ApplicationFormIdentifierQuestions {
	account_type_id: number;
	options_id: number;
	customer_type_id: number;
}

export interface ApplicationFormPage {
	id: number;
	position: number;
	label: string;
	number_of_sections: number;
}

export interface ApplicationFormSections {
	[key: number]: ApplicationFormSection[];
}

export interface ApplicationFormSection {
	id: number;
	position: number;
	page_id: number;
	label: string;
	number_of_questions: number;
	questions_ids: number[];
}

export interface ApplicationFormQuestions {
	[key: number]: ApplicationFormQuestion;
}

export interface ApplicationFormQuestion {
	id: number;
	type: string;
	rule_set: string;
	page_id: number;
	section_id: number;
	system_identifier: string;
	document_type: null | string;
	value: string | number;
	is_optional: boolean;
	is_encrypted: boolean;
	is_self_answered: boolean;
	locked_for_applicants_if_answered_by_non_applicant: NUM_BOOL_TYPE;
	locked_for_applicants: NUM_BOOL_TYPE;
	description: string;
	position: number;
	multiple_answers: boolean;
	is_locked: boolean;
	label: string;
	errors: string[];
	show_errors: boolean;
	dependencies: ApplicationFormDependency[];
	options: ApplicationFormOption[];
}

export interface ApplicationFormDependency {
	question_id: number;
	option_id: number;
	is_hidden: boolean;
}

export interface ApplicationFormOption {
	id: number;
	label: string;
	position: number;
	is_checked: boolean;
	dependencies: ApplicationFormDependency[];
	system_identifier: string;
}

export interface ApplicationFormPart {
	page: ApplicationFormPartPage;
	section: ApplicationFormPartSection;
	questions_ids: number[];
}

export interface ApplicationFormPartPage {
	id: number;
	label: string;
}

export interface ApplicationFormPartSection {
	id: number;
	label: string;
}

export interface ApplicationFormStateType {
	id: number;
	version: number;
	application_id: number;
	identifier_questions: ApplicationFormIdentifierQuestions;
	pages: ApplicationFormPage[];
	sections: ApplicationFormSections;
	questions: ApplicationFormQuestions;
	current: ApplicationFormPart;
}
