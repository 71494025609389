import { Button, styled } from '@mui/material';

export const BackSubmitButton = styled(Button)(({ theme }) => ({
	padding: theme.spacing(1, 3),
	fontSize: 16,
	fontWeight: 700,
	textTransform: 'capitalize',
	borderRadius: 4,
	height: 50,
}));
