import { generateNamesObject } from '@orbis-systems/orbis-react-toolkit';

import { Divider, Grid, styled } from '@mui/material';

export const mainPageClasses = generateNamesObject(['container'], 'MainPage');

export const MainPageContainer = styled(Grid)(({ theme }) => ({
	width: 'auto',
	margin: theme.spacing(4, 2, 15, 2),
	[theme.breakpoints.up('sm')]: {
		margin: theme.spacing(4, 4, 15, 4),
	},
	padding: theme.spacing(0, 34),
	[theme.breakpoints.down('xl')]: {
		padding: 0,
	},
	[`& .${mainPageClasses.container}`]: {
		margin: theme.spacing(3, 0, 0, 0),
		background: theme.palette.background.paper,
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: 4,
	},
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
	width: '100%',
	background: theme.palette.divider,
	margin: theme.spacing(1.5, 0),
}));
