import { useEffect, useRef, DependencyList } from 'react';

export function useInterval(callback: () => void, interval: number, invokeNow?: boolean, deps: DependencyList = []) {
    const timerRef = useRef<any>();

    useEffect(() => {
        if (invokeNow) {
            callback();
        }

        if (interval) {
            clearInterval(timerRef.current);
            timerRef.current = setInterval(callback, interval);
        }

        return () => clearInterval(timerRef.current);
    }, [...deps, interval]);

    return timerRef.current;
}
