import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import THEME_ENUM from '@api/requests/assets/enums/THEME_ENUM.enum';
import configRequest from '@api/requests/configRequest';

export type GetCurrentThemeData = void;

export interface GetCurrentThemeResponse {
	setting: {
		key: 'theme';
		value: [THEME_ENUM] | null;
	};
}

const getCurrentTheme = configRequest<GetCurrentThemeResponse, GetCurrentThemeData>({
	requestPrefix: 'assets/getCurrentTheme',
	reqConfigCreator: () => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.SETTINGS.GET(),
		method: HTTP_METHODS_ENUM.POST,
		data: {
			key: 'theme',
		},
	}),
});

export default getCurrentTheme;
