import React from 'react';
import { FormSelect, FormSelectProps, renderSelectOptions } from '../../FormControls';
import { fieldNames } from '../schema';
import { trailingStopPegTypeOptions } from '../selectOptions';

export type TrailingStopPegTypeProps = Partial<FormSelectProps>;

export const TrailingStopPegTypeSelect: React.FunctionComponent<TrailingStopPegTypeProps> = (props) => {
    return (
        <FormSelect label="Peg Type" size="small" {...props} name={fieldNames.trailingStopPegType}>
            {renderSelectOptions(Object.values(trailingStopPegTypeOptions))}
        </FormSelect>
    );
};
