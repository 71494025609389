import { ThemeOptions } from '@mui/material';
import { createTheme } from './createTheme';

export const nextGenThemeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#1D41F6',
            dark: '#1837D6',
            light: '#8EA0FA',
        },
        secondary: {
            main: '#799AA9',
            dark: '#799AA9',
            light: '#C6D5DC',
        },
        error: {
            main: '#FF2B6C',
            dark: '#E1255F',
            light: '#FF95B5',
        },
        warning: {
            main: '#E5B000',
            dark: '#E1A200',
            light: '#F2D780',
        },
        success: {
            main: '#18C673',
            dark: '#12B065',
            light: '#8BE2B9',
        },
        info: {
            main: '#54A1E5',
        },
        neutral: {
            main: '#323F52',
            contrastText: '#fff',
        },
        background: {
            default: '#F1F5F9',
            paper: '#FFFFFF',
        },
        common: {
            blue: '#54A1E5',
            green: '#0EAF4E',
            red: '#D13434',
            yellow: '#E5B000',
            pink: '#9969F5',
            darkBlue: '#1E2485',
            orange: '#E07B1D',
        },
        market: {
            up: '#18C673',
            down: '#FF2B6C',
        },
        consensus: {
            strongSell: '##FF2B6C',
            sell: '#FF2B6C',
            hold: '#54A1E5',
            buy: '#18C673',
            strongBuy: '#18C673',
        },
        accountClassification: {
            cash: '#E5B000',
            margin: '#18C673',
            crypto: '#E07B1D',
        },
        divider: '#E3EAED',
        text: {
            primary: '#1D2122',
            secondary: '#8EACB9',
        },
        action: {
            active: '#799AA9',
            hover: '#F1F5F9',
            disabled: '#E3EAED',
            activatedOpacity: 0.5,
        },
        analystRatings: {
            success: '#18C673',
            fail: '#FF2B6C',
            successBorder: '#18C673',
            failBorder: '#FF2B6C',
            topPercentageBadge: '#F1F5F9',
        },
    },
    shape: {
        borderRadius: 6,
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    border: '1px solid #E3EAED',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#C6D5DC',
                    color: '#1D2122',
                },
                arrow: {
                    color: '#C6D5DC',
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F1F5F9',
                },
            },
        },
    },
};

export const nextGenTheme = createTheme(nextGenThemeOptions);
