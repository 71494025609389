import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import AccountClientRequests from '@api/requests';

import { BranchEnvironmentVariable, getDefaultBranch } from '@utils/environment';

import { AuthStateType } from './auth.interfaces';

const initialState: AuthStateType = {
	isLoggedIn: false,
	branchEnvironment: getDefaultBranch(),
	currentUser: undefined,
	tokens: undefined,
	verifyPhone: {
		type: undefined,
		expires_at: undefined,
	},
	verifyEmail: {
		expires_at: undefined,
	},
	resetPassword: {
		phone: undefined,
		email: undefined,
		via: undefined,
		channel_type: undefined,
		expires_at: undefined,
	},
};

export const { actions: authActions, reducer: authReducer } = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setVerifyPhoneType(state, action: PayloadAction<'sms' | 'voice' | undefined>) {
			state.verifyPhone.type = action.payload;
		},
		setBranchEnvironment(state, action: PayloadAction<BranchEnvironmentVariable>) {
			state.branchEnvironment = action.payload;
		},
		setPasswordReset(state, action) {
			state.resetPassword = {
				...state.resetPassword,
				...action.payload,
			};
		},
	},
	extraReducers: builder =>
		builder
			.addCase(AccountClientRequests.Auth.login.fulfilled, (state, { payload }) => {
				state.isLoggedIn = true;
				state.currentUser = payload.login.user;
				state.tokens = payload.login.token;
			})
			.addCase(AccountClientRequests.Auth.refreshToken.fulfilled, (state, { payload }) => {
				state.tokens = payload.refresh.token;
			})
			.addCase(AccountClientRequests.Auth.refreshToken.rejected, state => {
				state.isLoggedIn = initialState.isLoggedIn;
				state.currentUser = initialState.currentUser;
				state.tokens = initialState.tokens;
			})
			.addCase(AccountClientRequests.Users.update.fulfilled, (state, { payload }) => {
				state.currentUser = { ...state.currentUser, ...payload.user };
			})
			.addCase(AccountClientRequests.Users.sendVerificationEmailCode.fulfilled, (state, { payload }) => {
				state.verifyEmail.expires_at = payload.email_verification.expires_at;
			})
			.addCase(AccountClientRequests.Users.verifyEmail.fulfilled, state => {
				if (state.currentUser) {
					state.currentUser.email_verified = 1;
				}
			})
			.addCase(AccountClientRequests.Users.sendVerificationPhoneCode.fulfilled, (state, { payload }) => {
				state.verifyEmail.expires_at = payload.phone_verification.expires_at;
			})
			.addCase(AccountClientRequests.Users.verifyPhone.fulfilled, state => {
				if (state.currentUser) {
					state.currentUser.phone_verified = 1;
				}
			})
			.addCase(AccountClientRequests.Auth.logout.fulfilled, state => {
				state.isLoggedIn = initialState.isLoggedIn;
				state.currentUser = initialState.currentUser;
				state.tokens = initialState.tokens;
				state.verifyEmail = initialState.verifyEmail;
				state.resetPassword = initialState.resetPassword;
			})
			.addCase(AccountClientRequests.Auth.sendResetPasswordCode.fulfilled, (state, { payload }) => {
				state.resetPassword = {
					...state.resetPassword,
					...payload.password_reset,
				};
			})
			.addCase(AccountClientRequests.Auth.resetPassword.fulfilled, state => {
				state.resetPassword = initialState.resetPassword;
			}),
});
