import { Box, styled, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { TableRow } from '..';
import { BaseFilter } from 'mui-enhanced-table';
import { generateNamesObject } from '../../../utils/stringUtils';

export const numberRangeFilterClasses = generateNamesObject('numberField', 'input');

const Container = styled(Box)(() => ({
    width: '100%',
    numberField: {
        marginTop: 8,
        marginBottom: 8,
        marginRight: 16,
        '& > div': {
            paddingRight: 8,
        },
    },
    input: {
        marginLeft: 8,
    },
}));

type Props = {
    variant?: 'min' | 'max' | 'range';
    min?: number;
    max?: number;
    defaultMin?: number;
    defaultMax?: number;
    minLabel?: string;
    maxLabel?: string;
    excludeMin?: boolean;
    excludeMax?: boolean;
    onChange?: (min?: number, max?: number) => void;
};

type State = {
    min?: number;
    max?: number;
};

export class NumberRangeFilter extends BaseFilter<Props, State> {
    state: State = {
        min: this.props.defaultMin,
        max: this.props.defaultMax,
    };

    componentDidMount = () => {
        this.props.onChange?.(this.state.min, this.state.max);
    };

    handleMinChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        this.setState(
            {
                min: value
                    ? Math.max(
                          Math.min(this.state.max ?? Number.MAX_SAFE_INTEGER, Number(value)),
                          this.props.min ?? Number.MIN_SAFE_INTEGER,
                      )
                    : undefined,
            },
            this.updateFilter,
        );
    };

    handleMaxChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        this.setState(
            {
                max: value
                    ? Math.min(
                          Math.max(this.state.min ?? Number.MIN_SAFE_INTEGER, Number(value)),
                          this.props.max ?? Number.MAX_SAFE_INTEGER,
                      )
                    : undefined,
            },
            this.updateFilter,
        );
    };

    filterData = (item: TableRow) => {
        let match = true;
        const filterBy = this.props.filterBy;
        const value = Number(_.get(item.data, _.isFunction(filterBy) ? filterBy(item) : filterBy || ''));
        const { excludeMin, excludeMax } = this.props;

        const { min, max } = this.state;

        if (min) {
            match = match && (value > min || (value === min && !excludeMin));
        }

        if (max) {
            match = match && (value < max || (value === max && !excludeMax));
        }

        return match;
    };

    updateFilter = () => {
        const { data, onChange, onUpdateFilter } = this.props;

        const { min, max } = this.state;

        onChange?.(min, max);
        onUpdateFilter(data.filter(this.filterData).map((item) => item.id));
    };

    render() {
        const { variant, name, minLabel = 'Minimum', maxLabel = 'Maximum' } = this.props;

        const { min, max } = this.state;

        return (
            <Container>
                <Typography variant="overline">{name}</Typography>
                <Box>
                    {(variant === 'min' || variant === 'range') && (
                        <TextField
                            size="small"
                            variant="outlined"
                            type="number"
                            label={minLabel}
                            value={min}
                            className={numberRangeFilterClasses.numberField}
                            onChange={this.handleMinChange}
                            inputProps={{
                                className: numberRangeFilterClasses.input,
                            }}
                        />
                    )}

                    {(variant === 'max' || variant === 'range') && (
                        <TextField
                            size="small"
                            variant="outlined"
                            type="number"
                            label={maxLabel}
                            value={max}
                            className={numberRangeFilterClasses.numberField}
                            onChange={this.handleMaxChange}
                            inputProps={{
                                className: numberRangeFilterClasses.input,
                            }}
                        />
                    )}
                </Box>
            </Container>
        );
    }
}
