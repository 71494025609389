export const FIRM_NAME_MAP = new Map<string, string>([
	['viewtrade', 'ViewTrade'],
	['abm', 'ViewTrade'],
	['viking', 'Viking'],
	['axos-crypto', 'Axos'],
	['invertir-online', 'Invertir Online'],
	['apex-brokerage-services', 'Apex'],
	['watchdog', 'Watchdog'],
	['lightspeed', 'Lightspeed'],
	['summit-securities', 'Summit Securities'],
	['adcap', 'Adcap'],
	['upstox', 'Upstox'],
	['bosonic', 'Bosonic'],
	['york', 'Trading Direct'],
	['secondlane', 'Secondlane'],
]);
