import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { FormTextField, FormTextFieldProps } from '../../FormControls';
import { TradeDestination } from '../lookups';
import { fieldNames, TradingFormValues } from '../schema';
import { TradingFormContext } from '../TradingFormContext';

export type AccountOrModelSelectProps = Partial<FormTextFieldProps> & {
    icon?: React.ReactNode;
};

export const AccountOrModelSelect: React.FunctionComponent<AccountOrModelSelectProps> = ({ icon, ...props }) => {
    const { values } = useFormikContext<TradingFormValues>();
    const {
        meta: { optionTrading, mutualFundTrading, modelTrading },
        options: { allowOptionTrading, allowMutualFundTrading },
    } = useContext(TradingFormContext);

    return (
        <FormTextField
            label={values.tradeDestination === TradeDestination.Model ? 'Select Model' : 'Select Account'}
            InputProps={{
                readOnly: true,
                endAdornment: icon,
                style: {
                    paddingRight: 8,
                },
            }}
            {...props}
            name={fieldNames.accountOrModel}
            validate={(value) => {
                let error = '';

                if (optionTrading && !modelTrading && !allowOptionTrading) {
                    error = 'This account cannot trade option';
                }

                if (mutualFundTrading && !modelTrading && !allowMutualFundTrading) {
                    error = 'This account cannot trade mutual fund';
                }

                return error || props.validate?.(value);
            }}
        />
    );
};
