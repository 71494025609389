import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["value", "defaultValue", "format", "onChange", "readOnly", "onError", "disableFuture", "disablePast", "minTime", "maxTime", "minutesStep", "shouldDisableClock", "shouldDisableTime", "disableIgnoringDatePartForTimeValidation", "selectedSections", "onSelectedSectionsChange", "ampm", "unstableFieldRef"];
import { singleItemFieldValueManager, singleItemValueManager } from '../internals/utils/valueManagers';
import { useField } from '../internals/hooks/useField';
import { validateTime } from '../internals/hooks/validation/useTimeValidation';
import { useUtils } from '../internals/hooks/useUtils';
const useDefaultizedTimeField = props => {
  var _props$ampm, _props$disablePast, _props$disableFuture, _props$format;
  const utils = useUtils();
  const ampm = (_props$ampm = props.ampm) != null ? _props$ampm : utils.is12HourCycleInCurrentLocale();
  const defaultFormat = ampm ? utils.formats.fullTime12h : utils.formats.fullTime24h;
  return _extends({}, props, {
    disablePast: (_props$disablePast = props.disablePast) != null ? _props$disablePast : false,
    disableFuture: (_props$disableFuture = props.disableFuture) != null ? _props$disableFuture : false,
    format: (_props$format = props.format) != null ? _props$format : defaultFormat
  });
};
export const useTimeField = ({
  props,
  inputRef
}) => {
  const _useDefaultizedTimeFi = useDefaultizedTimeField(props),
    {
      value,
      defaultValue,
      format,
      onChange,
      readOnly,
      onError,
      disableFuture,
      disablePast,
      minTime,
      maxTime,
      minutesStep,
      shouldDisableClock,
      shouldDisableTime,
      disableIgnoringDatePartForTimeValidation,
      selectedSections,
      onSelectedSectionsChange,
      ampm,
      unstableFieldRef
    } = _useDefaultizedTimeFi,
    other = _objectWithoutPropertiesLoose(_useDefaultizedTimeFi, _excluded);
  return useField({
    inputRef,
    forwardedProps: other,
    internalProps: {
      value,
      defaultValue,
      format,
      onChange,
      readOnly,
      onError,
      disableFuture,
      disablePast,
      minTime,
      maxTime,
      minutesStep,
      shouldDisableClock,
      shouldDisableTime,
      disableIgnoringDatePartForTimeValidation,
      selectedSections,
      onSelectedSectionsChange,
      ampm,
      unstableFieldRef
    },
    valueManager: singleItemValueManager,
    fieldValueManager: singleItemFieldValueManager,
    validator: validateTime,
    valueType: 'time'
  });
};