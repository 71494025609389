import { useFormikContext } from 'formik';
import React from 'react';
import { FormNumberField, FormNumberFieldProps } from '../../FormControls';
import { fieldNames, TradingFormValues } from '../schema';

export type TrailingStopPegDifferenceProps = Partial<FormNumberFieldProps>;

export const TrailingStopPegDifferenceSelect: React.FunctionComponent<TrailingStopPegDifferenceProps> = (props) => {
    const { setFieldValue } = useFormikContext<TradingFormValues>();

    return (
        <FormNumberField
            label="Peg Difference"
            size="small"
            {...props}
            name={fieldNames.trailingStopPegDifference}
            onChange={(value) => {
                if (value) {
                    setFieldValue(fieldNames.trailingStopPegDifference, value);
                }
            }}
        />
    );
};
