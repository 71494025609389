import React from 'react';

import RegistrationForm from '@components/forms/registration';

import useRegistration from './hooks';

function Registration() {
	const { initialValues, onSubmit } = useRegistration();

	return <RegistrationForm initialValues={initialValues} onSubmit={onSubmit} />;
}

export default Registration;
