import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { authActions } from '@app/redux/auth/auth.reducer';
import { useAppDispatch } from '@app/redux/hooks';

import { getBranchRoutes } from '@utils/environment';

function useBranchEnvironment() {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	useEffect(() => {
		const params = pathname.split('/').filter(param => param !== '');
		const branchRouteParams = params[0];
		const repRouteParam = params[1];
		const branchEnvironment = getBranchRoutes().find(
			route => route.branch_route === branchRouteParams && route.rep_route === repRouteParam,
		);

		if (branchEnvironment) {
			dispatch(authActions.setBranchEnvironment(branchEnvironment));
		}
	}, [dispatch, pathname]);
}

export default useBranchEnvironment;
