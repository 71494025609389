import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';

interface ApiClientConfig {
    baseUrl: string;
    onUnauthorized?: (error: AxiosError) => void;
}

export class ApiClient {
    private config: ApiClientConfig;
    private defaultHeaders: Record<string, string> = {};

    constructor(config: ApiClientConfig) {
        this.config = config;

        if (!config.baseUrl) {
            throw new Error('No base url provided');
        }
    }

    setDefaultHeaders(headers: Record<string, string>) {
        this.defaultHeaders = headers;
    }

    get(endpoint: string, config?: AxiosRequestConfig) {
        return this.request(endpoint, {
            ...config,
            method: 'GET',
        });
    }

    post(endpoint: string, config?: AxiosRequestConfig) {
        return this.request(endpoint, {
            ...config,
            method: 'POST',
        });
    }

    put(endpoint: string, config?: AxiosRequestConfig) {
        return this.request(endpoint, {
            ...config,
            method: 'PUT',
        });
    }

    patch(endpoint: string, config?: AxiosRequestConfig) {
        return this.request(endpoint, {
            ...config,
            method: 'PATCH',
        });
    }

    delete(endpoint: string, config?: AxiosRequestConfig) {
        return this.request(endpoint, {
            ...config,
            method: 'DELETE',
        });
    }

    error(error: AxiosError) {
        console.error(`${error.name}: ${error.message}`, '\nResponse:', error.response, '\nRequest:', error.request); // eslint-disable-line
    }

    async request(endpoint: string, config?: AxiosRequestConfig) {
        const { baseUrl } = this.config;
        const { method = 'GET', params, data, headers } = config || {};

        const request: AxiosRequestConfig = {
            method,
            params,
            data,
            headers: {
                ...this.defaultHeaders,
                ...headers,
            },
        };

        try {
            const response = await axios(`${baseUrl.replace(/(\/+)$/, '')}/${endpoint.replace(/^(\/+)/, '')}`, request);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                this.error(error);

                if (error.response?.status === StatusCodes.UNAUTHORIZED) {
                    this.config.onUnauthorized?.(error);
                }
            }

            throw error;
        }
    }
}
