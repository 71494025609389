export const getBranchLabel = (label: string): string => {
	switch (label) {
		case 'Bosonic Individual':
			return 'Individual';
		case 'Bosonic Entity':
			return 'Entity';
		default:
			return label;
	}
};
