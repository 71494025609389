import { useFormik } from 'formik';

import AssetsCountryInterface from '@api/requests/assets/interfaces/AssetsCountryInterface.interface';

import { getAssetsCountries } from '@app/redux/assets/assets.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { ResetMethodFormProps, ResetMethodFormValues } from './interfaces';
import { resetViaEmailFormValidationScheme, resetViaPhoneFormValidationScheme } from './validationScheme';

const PRIORITY_COUNTRIES_CODES = ['USA'];

function useResetPasswordMethodForm({
	initialValues,
	onSubmit,
	type,
}: Pick<ResetMethodFormProps, 'initialValues' | 'onSubmit' | 'type'>) {
	const assetsCountries = useAppSelector(getAssetsCountries);

	const formik = useFormik<ResetMethodFormValues>({
		initialValues,
		validationSchema: type === 'email' ? resetViaEmailFormValidationScheme : resetViaPhoneFormValidationScheme,
		onSubmit,
	});

	return {
		formik,
		phoneCodeOptions: [...assetsCountries]
			.reduce(
				(res, curr) => (PRIORITY_COUNTRIES_CODES.includes(curr.code) ? [curr, ...res] : [...res, curr]),
				[] as AssetsCountryInterface[],
			)
			.sort((a, b) =>
				PRIORITY_COUNTRIES_CODES.includes(a.code)
					? PRIORITY_COUNTRIES_CODES.indexOf(a.code) - PRIORITY_COUNTRIES_CODES.indexOf(b.code)
					: 1,
			)
			.map((country, index) => ({
				isPriorityGroup: index === PRIORITY_COUNTRIES_CODES.length - 1,
				dial_code: country.dial_code,
				country_name: country.name,
			})),
	};
}

export default useResetPasswordMethodForm;
