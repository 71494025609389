import { FormikComputedProps, Form as FormikForm, FormikHandlers, FormikProvider, FormikRegistration } from 'formik';
import { FormikHelpers, FormikState } from 'formik/dist/types';
import React from 'react';

import { Grid } from '@mui/material';

function Form<FormValues>({
	children,
	formik,
}: {
	children: React.ReactNode;
	formik: FormikState<FormValues> &
		FormikHelpers<FormValues> &
		FormikHandlers &
		FormikComputedProps<FormValues> &
		FormikRegistration;
}) {
	return (
		<Grid container>
			<FormikProvider value={formik}>
				<FormikForm>{children}</FormikForm>
			</FormikProvider>
		</Grid>
	);
}

export default Form;
