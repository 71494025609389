import { Box, styled } from '@mui/material';

export const StyledInfoSection = styled(Box)(({ theme }) => ({
	height: '100%',
	borderLeft: `1px solid ${theme.palette.divider}`,
	borderRight: `1px solid ${theme.palette.divider}`,
	padding: theme.spacing(2, 0),
	[theme.breakpoints.down('md')]: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		borderTop: `1px solid ${theme.palette.divider}`,
	},
}));
