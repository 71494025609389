import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { AnimateProps, AnimatedPanelProps, FadeProps } from './interfaces';

const Fade: React.FC<FadeProps> = ({ children, duration, id }) => (
	<motion.div
		key={id}
		id={id}
		exit={{ opacity: 0 }}
		initial={{ opacity: 0 }}
		animate={{ opacity: 1 }}
		transition={{
			duration: duration || 0.25,
		}}
		style={{ width: '100%', height: '100%' }}
	>
		{children}
	</motion.div>
);

const FadeHeight: React.FC<FadeProps> = ({ children, duration, id }) => {
	return (
		<motion.div
			key={id}
			id={id}
			exit={{ opacity: 0, height: 0 }}
			initial={{ opacity: 0, height: 0 }}
			animate={{ opacity: 1, height: 'auto' }}
			transition={{
				duration: duration || 0.25,
			}}
			style={{ width: '100%', height: '100%' }}
		>
			{children}
		</motion.div>
	);
};

const SlideLeft: React.FC<FadeProps> = ({ children, duration, id }) => (
	<motion.div
		key={id}
		exit={{ opacity: 0, scale: 1, x: -300 }}
		initial={{ opacity: 0, scale: 1, x: -300 }}
		animate={{ opacity: 1, scale: 1, x: 0 }}
		transition={{
			duration: duration || 0.25,
			ease: 'easeInOut',
		}}
		style={{ width: '100%', height: '100%' }}
	>
		{children}
	</motion.div>
);

const SlideRight: React.FC<FadeProps> = ({ children, duration, id }) => (
	<motion.div
		key={id}
		exit={{ opacity: 0, scale: 1, x: 300 }}
		initial={{ opacity: 0, scale: 1, x: 300 }}
		animate={{ opacity: 1, scale: 1, x: 0 }}
		transition={{
			duration: duration || 0.25,
			ease: 'easeInOut',
		}}
		style={{ width: '100%', height: '100%' }}
	>
		{children}
	</motion.div>
);

const animations = {
	fade: Fade,
	'fade-height': FadeHeight,
	'slide-left': SlideLeft,
	'slide-right': SlideRight,
};

export const AnimatedPanel: React.FC<AnimatedPanelProps> = ({ children, animation, duration, id }) => {
	const Animation = animations[animation];
	return (
		<Animation id={id} duration={duration}>
			{children}
		</Animation>
	);
};

export const Animate: React.FC<AnimateProps> = ({ children, exitBeforeEnter = true, initial = false }) => {
	return (
		<AnimatePresence exitBeforeEnter={exitBeforeEnter} initial={initial}>
			{children}
		</AnimatePresence>
	);
};
