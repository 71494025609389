import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';
import configRequest from '@api/requests/configRequest';

import { showToast } from '@app/providers/ToastProvider';

export interface UserChangePasswordData {
	user_id: number;
	old_password: string;
	password: string;
	password_confirmation: string;
}

export type UserChangePasswordResponse = {
	user: CurrentUserInterface;
};

const changePassword = configRequest<UserChangePasswordResponse, UserChangePasswordData>({
	requestPrefix: 'users/changePassword',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.USERS.UPDATE(),
		method: HTTP_METHODS_ENUM.POST,
		data,
	}),
	onSuccess: () => showToast.success('Password has been updated.'),
});

export default changePassword;
