import React, { useState } from 'react';
import {
    ButtonGroup,
    Button,
    Popper,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    useTheme,
    ButtonGroupProps,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';

export interface DropdownOption {
    value: number | string;
    name?: string;
    disabled?: boolean;
}

type DropdownProps = Omit<ButtonGroupProps, 'onClick'> & {
    dropdownOptions: DropdownOption[];
    onClick?: (
        event: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>,
        selectedValue: DropdownOption['value'],
    ) => void;
    variant?: ButtonGroupProps['variant'];
    forceDropdownButton?: boolean;
};

export const DropdownButton: React.FunctionComponent<DropdownProps> = (props) => {
    const { title, dropdownOptions, onClick, variant, forceDropdownButton, ...rest } = props;
    const theme = useTheme();
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [dropdownOptionsOpen, setDropdownOptionsOpen] = useState(false);

    const handleToggle = () => {
        setDropdownOptionsOpen(dropdownOptionsOpen === false ? true : false);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setDropdownOptionsOpen(false);
    };

    const renderOptions = (dropdownOptions: DropdownOption[]) => (
        <MenuList>
            {dropdownOptions.map(({ name, value }, index) => (
                <MenuItem
                    style={{
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                    }}
                    key={index}
                    onClick={(event) => {
                        setDropdownOptionsOpen(false);
                        onClick?.(event, value);
                    }}
                >
                    {name}
                </MenuItem>
            ))}
        </MenuList>
    );

    return (
        <>
            <ButtonGroup variant={variant ? variant : 'outlined'} color="primary" ref={anchorRef} {...rest}>
                <Button
                    onClick={(event) =>
                        forceDropdownButton ? handleToggle() : onClick?.(event, dropdownOptions[0].value)
                    }
                >
                    {title ? title : dropdownOptions[0].name}
                </Button>
                <Button color="primary" size="small" onClick={handleToggle}>
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    minWidth:
                        anchorRef.current?.offsetWidth !== undefined
                            ? anchorRef.current?.offsetWidth * 0.95
                            : anchorRef.current?.offsetWidth,
                    zIndex: 10000,
                    position: 'relative',
                }}
                open={dropdownOptionsOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
            >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>{renderOptions(dropdownOptions)}</ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
};
