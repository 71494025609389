import React from 'react';

import { Grid, Typography } from '@mui/material';

import Registration from '@pages/Auth/RegistrationPage/containers/Registration';
import { FIRM_NAME_MAP } from '@app/layouts/UnauthorizedPageLayout/constants';
import * as environment from '@utils/environment';

function RegistrationPage() {
	return (
		<Grid container direction="column" alignItems="center" justifyContent="center">
			<Typography variant="body1" mb={1} fontWeight={500}>
				<b>{`Welcome to ${FIRM_NAME_MAP.get(
					environment.env.FIRM_ENVIRONMENT)} accounts`}</b>
			</Typography>

			<Typography variant="body2" mb={4} color={'secondary'}>
				To register please fill out the information below
			</Typography>

			<Registration />
		</Grid>
	);
}

export default RegistrationPage;
