import React from 'react';

import { Button, CircularProgress } from '@mui/material';

import useSubmitApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/containers/SubmitApplicationForm/hooks';

function SubmitApplicationForm({
	runBackground,
	applicationId,
	formId,
	formVersion,
	needVerifyAccount,
}: {
	runBackground: boolean;
	applicationId: number;
	formId: number;
	formVersion?: number | null;
	needVerifyAccount: boolean;
}) {
	const { isSubmitting, handleSubmitApplicationForm } = useSubmitApplicationForm({
		applicationId,
		formId,
		formVersion,
		runBackground,
	});

	return (
		<Button
			disableElevation
			variant="contained"
			size="medium"
			color="primary"
			disabled={isSubmitting || needVerifyAccount}
			onClick={handleSubmitApplicationForm}
			sx={{ textTransform: 'capitalize', width: 120 }}
		>
			<b>{isSubmitting ? <CircularProgress color={'info'} size={25} /> : 'Submit form'}</b>
		</Button>
	);
}

export default SubmitApplicationForm;
