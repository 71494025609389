import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Field, FieldProps } from 'formik';
import _ from 'lodash';
import React from 'react';
import { FieldConfigProps } from '.';

export type FormTextFieldProps = FieldConfigProps &
    Partial<Omit<TextFieldProps, 'onChange'>> & {
        allCaps?: boolean;
        onChange?: (value: string) => void;
    };

export const FormTextField: React.FunctionComponent<FormTextFieldProps> = ({
    name,
    validate,
    variant,
    allCaps,
    value,
    ...rest
}) => {
    return (
        <Field name={name} validate={validate}>
            {({ field, form: { touched, errors, setFieldValue } }: FieldProps) => {
                // FIXME: can't use meta data due to https://github.com/jaredpalmer/formik/issues/343
                const error = _.get(touched, field.name) && (_.get(errors, field.name) as string);
                return (
                    // @ts-ignore: variant can be 'standard' | 'filled' | 'outlined'
                    <TextField
                        fullWidth
                        variant={variant ?? 'outlined'}
                        {...rest}
                        {...field}
                        onChange={(event) => {
                            let value: any = allCaps ? event.target.value.toUpperCase() : event.target.value;

                            if (rest.type === 'number') {
                                value = value !== '' ? Number(value) : undefined;
                            }

                            setFieldValue(name, value);
                            rest.onChange?.(value);
                        }}
                        error={!!error}
                        helperText={error ?? rest?.helperText}
                        value={field.value ?? value ?? ''}
                    />
                );
            }}
        </Field>
    );
};

export type FormNumberFieldProps = Omit<FormTextFieldProps, 'onChange'> & {
    min?: number;
    max?: number;
    step?: string;
    onChange?: (value: number | null) => void;
};

export const FormNumberField: React.FunctionComponent<FormNumberFieldProps> = ({
    name,
    validate,
    min,
    max,
    step,
    onChange,
    ...rest
}) => {
    return (
        <FormTextField
            name={name}
            validate={validate}
            {...rest}
            // @ts-ignore: value is already converted in FormTextField
            onChange={onChange}
            inputProps={{
                min,
                max,
                step,
                ...rest.inputProps,
            }}
            type="number"
        />
    );
};

const dollarSymbol = <InputAdornment position="start">$</InputAdornment>;
export const FormMoneyField: React.FunctionComponent<FormNumberFieldProps> = ({ name, validate, ...rest }) => {
    return (
        <FormNumberField
            name={name}
            validate={validate}
            {...rest}
            InputProps={{
                startAdornment: dollarSymbol,
                ...rest.InputProps,
            }}
        />
    );
};

const percentageSymbol = <InputAdornment position="start">%</InputAdornment>;
export const FormPercentageField: React.FunctionComponent<FormNumberFieldProps> = ({ name, validate, ...rest }) => {
    return (
        <FormNumberField
            name={name}
            validate={validate}
            {...rest}
            InputProps={{
                endAdornment: percentageSymbol,
                ...rest.InputProps,
            }}
        />
    );
};
