import React from 'react';
import { useHistory } from 'react-router';

import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { Grid, Typography } from '@mui/material';

import { isRetrievalOnly } from '@utils/environment';

import ArrowIcon from 'jsx:@assets/svgs/arrowRight.svg';
import EmailIcon from 'jsx:@assets/svgs/email.svg';
import MicrophoneIcon from 'jsx:@assets/svgs/microphone.svg';

import { VerificationItem } from './styles';

function ForgotPasswordPage() {
	const history = useHistory();
	const generateRoutePath = useGenerateRoutePath();

	return (
		<Grid container direction="column" alignItems="center" justifyContent="center">
			<Typography variant="body1" mb={2}>
				<b>
					{isRetrievalOnly()
						? 'Please select verification method to create your password.'
						: 'Please select verification method used to recover your password.'}
				</b>
			</Typography>

			<Grid container>
				<Grid item xs={12} mb={2}>
					<VerificationItem onClick={() => history.push(generateRoutePath(RoutePaths.AUTH.FORGOT_PASSWORD_VIA_VOICE))}>
						<Grid item display={'flex'} alignItems={'center'} justifyContent={'center'}>
							<MicrophoneIcon />

							<Typography variant="caption" ml={2}>
								<b>Voice</b>
							</Typography>
						</Grid>

						<ArrowIcon />
					</VerificationItem>
				</Grid>

				<Grid item xs={12} mb={2}>
					<VerificationItem onClick={() => history.push(generateRoutePath(RoutePaths.AUTH.FORGOT_PASSWORD_VIA_EMAIL))}>
						<Grid item display={'flex'} alignItems={'center'} justifyContent={'center'}>
							<EmailIcon />

							<Typography variant="caption" ml={2}>
								<b>Email</b>
							</Typography>
						</Grid>

						<ArrowIcon />
					</VerificationItem>
				</Grid>

				<Grid item xs={12} mb={2}>
					<VerificationItem onClick={() => history.push(generateRoutePath(RoutePaths.AUTH.FORGOT_PASSWORD_VIA_PHONE))}>
						<Grid item display={'flex'} alignItems={'center'} justifyContent={'center'}>
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<mask id="mask0_12441_95456" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
									<rect width="16" height="16" fill="#C4C4C4" />
								</mask>
								<g mask="url(#mask0_12441_95456)">
									<path
										d="M6.51558 1.00519C6.28521 0.988427 6.04916 1.01197 5.81769 1.08037L4.98925 1.32576C4.10647 1.58684 3.41672 2.28726 3.18336 3.1713C2.6652 5.13734 3.25795 7.46443 4.8006 10.0839C6.34107 12.6997 8.09639 14.3622 10.0788 14.9048C10.9716 15.1492 11.9344 14.9158 12.6106 14.2876L13.2409 13.7032C13.9461 13.0484 14.0512 11.9579 13.4815 11.183L12.571 9.94648C12.083 9.28359 11.2226 9.00136 10.4315 9.23903L9.00706 9.66555C8.94727 9.68301 8.80417 9.60158 8.67965 9.49399L8.67896 9.4933C8.45717 9.30045 8.14988 8.91065 7.82113 8.35317V8.35249C7.46862 7.75379 7.31826 7.36224 7.2579 7.13101C7.19751 6.89968 7.21216 6.87154 7.22099 6.79335C7.22558 6.75433 7.24411 6.71818 7.27567 6.68945L7.27635 6.68877L8.33856 5.71746C8.94712 5.16114 9.13084 4.27209 8.78765 3.52401L8.14717 2.12686V2.12618C7.84564 1.4689 7.20651 1.05545 6.51558 1.00519ZM6.43971 2.05236C6.76105 2.07637 7.05695 2.26912 7.19228 2.56433L7.83343 3.96147C7.9886 4.29969 7.91079 4.68672 7.63042 4.94302L6.56821 5.91364C6.35046 6.11218 6.21121 6.38288 6.17792 6.67509V6.67578C6.16924 6.75262 6.14875 7.03838 6.24217 7.39622C6.33559 7.75406 6.52846 8.22737 6.91613 8.88564H6.91682C7.27854 9.49914 7.62041 9.96522 7.99065 10.2869H7.99133C8.11157 10.391 8.61549 10.8739 9.30098 10.6738L9.30303 10.6731L10.7337 10.2445C11.1052 10.1329 11.5024 10.2662 11.7255 10.5692L12.6359 11.8057C12.8938 12.1569 12.8487 12.6344 12.5266 12.9335L11.8964 13.5186C11.4933 13.8932 10.8948 14.0394 10.3557 13.8918C8.75304 13.4532 7.16715 12.0332 5.70559 9.5514C4.24201 7.0662 3.78424 5.0107 4.1984 3.43925C4.33531 2.92061 4.75478 2.49009 5.28727 2.33261L6.11503 2.08722C6.22286 2.05536 6.3326 2.04436 6.43971 2.05236Z"
										fill="white"
									/>
								</g>
							</svg>

							<Typography variant="caption" ml={2}>
								<b>SMS</b>
							</Typography>
						</Grid>

						<ArrowIcon />
					</VerificationItem>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default ForgotPasswordPage;
