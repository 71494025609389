import { useCallback, useState } from 'react';

export function useToggle(value = false): [boolean, (value?: boolean) => void] {
    const [enable, setEnable] = useState(value);

    const toggle = useCallback(
        (value?: boolean) => {
            if (value === undefined) {
                setEnable(!enable);
            } else {
                setEnable(value);
            }
        },
        [enable],
    );

    return [enable, toggle];
}
