import { useCallback } from 'react';
import { useHistory } from 'react-router';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { showToast } from '@app/providers/ToastProvider';
import { authActions } from '@app/redux/auth/auth.reducer';
import { useAppDispatch } from '@app/redux/hooks';
import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { ResetMethodFormValues } from '@components/forms/resetPasswordMethodForm/interfaces';

function useResetPasswordCode(type: 'sms' | 'voice' | 'email') {
	const history = useHistory();
	const generateRoutePath = useGenerateRoutePath();
	const dispatch = useAppDispatch();

	const { startRequest } = useTriggeredRequest(AccountClientRequests.Auth.sendResetPasswordCode);

	const initialValues: ResetMethodFormValues = {
		channel_type: undefined,
		phone: {
			code: undefined,
			number: undefined,
		},
		email: undefined,
		via: undefined,
	};

	const onSubmit = useCallback(
		async (values: ResetMethodFormValues) => {
			try {
				const res = await startRequest({
					channel_type: type === 'email' ? undefined : type,
					email: values.email,
					phone: values.phone?.code && values.phone?.number ? values.phone?.code + values.phone?.number : undefined,
					via: type === 'email' ? 'email' : 'phone',
				});

				if (!res.data) {
					showToast.error(`User cannot be found or email and/or phone aren't verified.`);
				} else {
					dispatch(
						authActions.setPasswordReset({
							channel_type: type,
							email: values.email,
							phone: values.phone?.code && values.phone?.number ? values.phone?.code + values.phone?.number : undefined,
							via: type === 'email' ? 'email' : 'phone',
						}),
					);

					history.push(generateRoutePath(RoutePaths.AUTH.RESET_PASSWORD));
				}
			} catch (err) {
				showToast.error(`User cannot be found or email and/or phone aren't verified.`);
			}
		},
		[startRequest, type, dispatch, history, generateRoutePath],
	);

	return {
		initialValues,
		onSubmit,
	};
}

export default useResetPasswordCode;
