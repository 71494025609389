import * as Yup from 'yup';

export const resetViaEmailFormValidationScheme = Yup.object().shape({
	email: Yup.string().email('Email address is invalid.').required('This field is required.'),
});

export const resetViaPhoneFormValidationScheme = Yup.object().shape({
	phone: Yup.object().shape({
		code: Yup.string().required('Required.'),
		number: Yup.string().required('This field is required.'),
	}),
});
