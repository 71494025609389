import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import CurrentSessionInterface from '@api/requests/auth/interfaces/CurrentSessionInterface.interface';
import configRequest from '@api/requests/configRequest';

export type RefreshTokenData = void;

export interface RefreshTokenResponse {
	refresh: {
		token: CurrentSessionInterface;
	};
}

const refreshToken = configRequest<RefreshTokenResponse, RefreshTokenData>({
	requestPrefix: 'auth/refreshToken',
	reqConfigCreator: () => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.AUTH.REFRESH_TOKEN(),
		method: HTTP_METHODS_ENUM.POST,
	}),
});

export default refreshToken;
