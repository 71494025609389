import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { UnauthorizedHeader } from '@app/layouts/UnauthorizedPageLayout/header/styles';
import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { Button, Stack, Typography } from '@mui/material';

import ApplicationLogo from '@components/logo';

import * as environment from '@utils/environment';
import { isAllowUserRegistration } from '@utils/environment';

function UnauthorizedPageHeader() {
	const history = useHistory();
	const location = useLocation();
	const generateRoutePath = useGenerateRoutePath();

	const isLoginPage = location.pathname.includes('login');

	const buttonConfig = useMemo(() => {
		return {
			isVisible:
				!isLoginPage ||
				isAllowUserRegistration() ||
				(!isAllowUserRegistration() && environment.env.FIRM_ENVIRONMENT === 'invertir-online'),
			label: isLoginPage ? 'Don’t have an account?' : 'Already have an account?',
			buttonText: isLoginPage ? 'Sign up' : 'Log in',
			handleClick: isLoginPage
				? (isAllowUserRegistration() && (() => history.push(generateRoutePath(RoutePaths.AUTH.REGISTRATION)))) ||
				  (!isAllowUserRegistration() &&
						environment.env.FIRM_ENVIRONMENT === 'invertir-online' &&
						(() => (window.location.href = 'https://cuentaplus.invertironline.com/retail/online/register'))) ||
				  undefined
				: () => history.push(generateRoutePath(RoutePaths.AUTH.LOGIN)),
		};
	}, [generateRoutePath, history, isLoginPage]);

	return (
		<UnauthorizedHeader>
			<ApplicationLogo />

			{buttonConfig.isVisible ? (
				<Stack direction={'row'} alignItems={'center'}>
					<Typography noWrap variant="caption" pr={3}>
						<b>{buttonConfig.label}</b>
					</Typography>

					<Button
						variant="contained"
						color="primary"
						onClick={buttonConfig.handleClick}
						sx={{ textTransform: 'initial' }}
					>
						<b>{buttonConfig.buttonText}</b>
					</Button>
				</Stack>
			) : null}
		</UnauthorizedHeader>
	);
}

export default UnauthorizedPageHeader;
