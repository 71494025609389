import React from 'react';

import { Grid, Skeleton, Stack } from '@mui/material';

import { StyledDivider } from '@pages/Applications/styles';

import { StyledAccountDetailsSkeleton, detailSkeletonClasses } from './styles';

function AccountDetailsSkeleton() {
	return (
		<StyledAccountDetailsSkeleton container mt={3}>
			<Grid item xs={12} sm={6} md={3} order={{ sm: 1, md: 1 }}>
				<Grid p={2}>
					<Skeleton width={'60%'} height={24} />
					<Skeleton width={'80%'} height={20} />

					<Grid item xs={12} mt={4} mb={2}>
						<Skeleton variant="rounded" height={200} />
					</Grid>

					<Grid item xs={12}>
						<Stack direction={'column'}>
							<Skeleton height={20} />
							<Skeleton height={20} />
						</Stack>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12} sm={12} md={5} order={{ sm: 3, md: 2 }} className={detailSkeletonClasses.detailsSection}>
				<Grid container columnSpacing={5} p={2} pb={0}>
					<Grid item xs={6} sm={6} md={4}>
						<Stack direction={'column'}>
							<Skeleton height={12} />
							<Skeleton height={20} />
						</Stack>
					</Grid>

					<Grid item xs={6} sm={6} md={4}>
						<Stack direction={'column'}>
							<Skeleton height={12} />
							<Skeleton height={20} />
						</Stack>
					</Grid>

					<Grid item xs={6} sm={6} md={4}>
						<Stack direction={'column'}>
							<Skeleton height={12} />
							<Skeleton height={20} />
						</Stack>
					</Grid>
				</Grid>

				<StyledDivider orientation="horizontal" flexItem />

				<Grid container columnSpacing={5} pr={2} pl={2}>
					<Grid item xs={6} sm={6} md={4}>
						<Stack direction={'column'}>
							<Skeleton height={12} />
							<Skeleton height={14} />
							<Skeleton height={14} />
						</Stack>
					</Grid>

					<Grid item xs={6} sm={6} md={4}>
						<Stack direction={'column'}>
							<Skeleton height={12} />
							<Skeleton height={14} />
							<Skeleton height={14} />
						</Stack>
					</Grid>

					<Grid item xs={6} sm={6} md={4}>
						<Stack direction={'column'}>
							<Skeleton height={12} />
							<Skeleton height={14} />
							<Skeleton height={14} />
						</Stack>
					</Grid>
				</Grid>

				<StyledDivider orientation="horizontal" flexItem />

				<Grid container columnSpacing={5} rowSpacing={3} p={2}>
					<Grid item xs={12}>
						<Stack>
							<Skeleton width={'30%'} />
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Stack direction={'column'}>
							<Skeleton />
							<Skeleton />
							<Skeleton />
							<Skeleton />
							<Skeleton />
							<Skeleton />
						</Stack>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12} sm={6} md={4} order={{ sm: 2, md: 3 }}>
				<Grid p={2}>
					<Skeleton width={'60%'} />
					<Skeleton />
					<Skeleton />
					<Skeleton />
					<Skeleton />
					<Skeleton />
					<Skeleton />
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</Grid>
			</Grid>
		</StyledAccountDetailsSkeleton>
	);
}

export default AccountDetailsSkeleton;
