import { Tooltip } from '@mui/material';
import { More } from '@mui/icons-material';
import { FormatterProps, SearchHighlightedFormatter } from 'mui-enhanced-table';
import React from 'react';
import { getCurrentTheme } from '../../../themes';
import { formatMoney, formatNumber, formatPercentage, multiply } from '../../../utils';

enum InputType {
    Number,
    Money,
    Percentage,
    PercentageNoConversion,
    PercentageNoConversionWithChange,
    NumberMillions,
    NumberMillionsTrimZeros,
    SingleDigitMillions,
    SingleDigitMillionsNotMoney,
    SingleDigitMillionsNotMoneyTrimZeros,
}

class NumberFormatterClass extends SearchHighlightedFormatter {
    inputType: InputType;
    withChange: boolean;
    withArrow: boolean;

    constructor(inputType = InputType.Number, withChange = false, withArrow = true) {
        super();
        this.inputType = inputType;
        this.withChange = withChange;
        this.withArrow = withArrow;
    }

    format(props: FormatterProps) {
        const color = this.withChange
            ? props.value >= 0
                ? getCurrentTheme().palette.market.up
                : getCurrentTheme().palette.market.down
            : undefined;

        return (
            <span style={{ color }}>
                <bdi>{super.format(props)}</bdi>
            </span>
        );
    }

    getValueString(value: any) {
        const arrow = value > 0 ? '▲' : value < 0 ? '▼' : '';

        const newValue =
            this.inputType === InputType.Number
                ? formatNumber(value, { trimZeros: false })
                : this.inputType === InputType.Money
                ? formatMoney(value)
                : this.inputType === InputType.NumberMillions
                ? formatNumber(value, {
                      decimalPlaces: value > 9999.99 ? 2 : 0,
                      short: value > 9999.99 ? true : false,
                  })
                : this.inputType === InputType.NumberMillionsTrimZeros
                ? formatNumber(value, {
                      decimalPlaces: 2,
                      short: value > 9999.99 ? true : false,
                      trimZeros: true,
                  })
                : this.inputType === InputType.PercentageNoConversion
                ? formatPercentage(value, { trimZeros: false })
                : this.inputType === InputType.PercentageNoConversionWithChange
                ? formatPercentage(value, { trimZeros: false })
                : this.inputType === InputType.SingleDigitMillions
                ? formatMoney(multiply(value, 1000000), {
                      decimalPlaces: value * 1000000 > 9999.99 ? 2 : 0,
                      short: value * 1000000 > 9999.99 ? true : false,
                  })
                : this.inputType === InputType.SingleDigitMillionsNotMoney
                ? formatNumber(multiply(value, 1000000), {
                      decimalPlaces: value * 1000000 > 9999.99 ? 2 : 0,
                      short: value * 1000000 > 9999.99 ? true : false,
                  })
                : this.inputType === InputType.SingleDigitMillionsNotMoneyTrimZeros
                ? formatNumber(multiply(value, 1000000), {
                      decimalPlaces: value * 1000000 > 9999.99 ? 2 : 0,
                      short: value * 1000000 > 9999.99 ? true : false,
                      trimZeros: true,
                  })
                : formatPercentage(value, { withConversion: this.inputType === InputType.Percentage });

        return this.withChange && this.withArrow && arrow ? `${arrow} ${newValue}` : newValue;
    }
}

class WeightNumberFormatter extends NumberFormatterClass {
    format(props: FormatterProps) {
        const newValue = props.item.short ? -props.value : props.value;

        if (props.value && props.value < 0.00005) {
            return (
                <>
                    <Tooltip
                        placement="bottom"
                        title={formatPercentage(newValue, { decimalPlaces: 6, withConversion: true })}
                        style={{
                            verticalAlign: 'middle',
                            transform: 'rotateY(180deg)',
                            width: 12,
                            marginRight: 5,
                            opacity: 0.5,
                        }}
                    >
                        <More />
                    </Tooltip>
                    {super.format(props)}
                </>
            );
        }

        return super.format(props);
    }
}

export const NumberFormatter = new NumberFormatterClass();

export const WeightFormatter = new WeightNumberFormatter(InputType.Percentage);

export const MoneyFormatter = new NumberFormatterClass(InputType.Money);

export const MillionsFormatter = new NumberFormatterClass(InputType.NumberMillions, false, false);

export const MillionsFormatterTrimZeros = new NumberFormatterClass(InputType.NumberMillionsTrimZeros, false, false);

export const SingleDigitMillionsFormatter = new NumberFormatterClass(InputType.SingleDigitMillions, false, false);

export const SingleDigitMillionsNotMoneyFormatter = new NumberFormatterClass(
    InputType.SingleDigitMillionsNotMoney,
    false,
    false,
);

export const SingleDigitMillionsNotMoneyTrimZeros = new NumberFormatterClass(
    InputType.SingleDigitMillionsNotMoneyTrimZeros,
    false,
    false,
);
export const PercentageFormatter = new NumberFormatterClass(InputType.Percentage);

export const PercentageNoConversionFormatter = new NumberFormatterClass(InputType.PercentageNoConversion);

export const NumberWithChangeFormatter = new NumberFormatterClass(InputType.Number, true);

export const NumberWithChangeNoArrowFormatter = new NumberFormatterClass(InputType.Number, true, false);

export const MoneyWithChangeFormatter = new NumberFormatterClass(InputType.Money, true);

export const MoneyWithChangeNoArrowFormatter = new NumberFormatterClass(InputType.Money, true, false);

export const PercentageWithChangeFormatter = new NumberFormatterClass(InputType.Percentage, true);

export const PercentageNoConversionWithChangeFormatter = new NumberFormatterClass(
    InputType.PercentageNoConversion,
    true,
);

export const PercentageNoConversionWithChangeNoArrowFormatter = new NumberFormatterClass(
    InputType.PercentageNoConversionWithChange,
    true,
    false,
);
