import React, { useCallback } from 'react';

import { ApplicationFormSection as ApplicationFormSectionInterface } from '@app/redux/applicationForm/applicationForm.interfaces';
import {
	getApplicationFormProgress,
	getApplicationFormSections,
	getApplicationFormSelectedSection,
} from '@app/redux/applicationForm/applicationForm.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { Box } from '@mui/material';

import ApplicationFormSection from '@pages/ApplicationForm/containers/ApplicationFormNavigation/components/ApplicationFormSection';

function ApplicationFormSectionsList({ applicationFormPageId }: { applicationFormPageId: number }) {
	const applicationFormProgress = useAppSelector(getApplicationFormProgress);
	const applicationFormSelectedSection = useAppSelector(getApplicationFormSelectedSection);
	const applicationFormSections = useAppSelector(getApplicationFormSections)[applicationFormPageId].filter(
		applicationFormSection => applicationFormProgress.values.sections[applicationFormSection.id].visible > 0,
	);

	const getApplicationFormSectionStatus = useCallback(
		(applicationFormSection: ApplicationFormSectionInterface) =>
			(applicationFormProgress.errors.sections[applicationFormSection.id] > 0 && 'error') ||
			(applicationFormProgress.values.sections[applicationFormSection.id].answered ===
				applicationFormProgress.values.sections[applicationFormSection.id].required &&
				'completed') ||
			'pending',
		[applicationFormProgress.errors.sections, applicationFormProgress.values.sections],
	);

	return (
		<>
			{applicationFormSections.map((applicationFormSection, index) => (
				<Box key={applicationFormSection.id}>
					<ApplicationFormSection
						isSelected={applicationFormSelectedSection.id === applicationFormSection.id}
						applicationFormSection={applicationFormSection}
						applicationFormSectionPosition={index + 1}
						applicationFormSectionStatus={getApplicationFormSectionStatus(applicationFormSection)}
					/>
				</Box>
			))}
		</>
	);
}

export default ApplicationFormSectionsList;
