import React from 'react';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, CircularProgress } from '@mui/material';

import FileManager from '@components/fileManager';
import { FILE_TYPES } from '@components/fileManager/constants';

export interface UploadSignatureFileProps {
	fileType: FILE_TYPES;
	loading: boolean;
	disabled: boolean;
	handleSaveAnswer: (file: File) => Promise<void>;
}

function UploadSignatureFile({ loading, disabled, fileType, handleSaveAnswer }: UploadSignatureFileProps) {
	return (
		<FileManager type={fileType} onChange={handleSaveAnswer}>
			{({ handleOpenFileProvider }) => (
				<Button
					variant="contained"
					color="primary"
					disabled={disabled}
					startIcon={loading ? <CircularProgress color={'info'} size={20} /> : <FileUploadIcon />}
					onClick={handleOpenFileProvider}
					sx={{ width: 250, height: 40, textTransform: 'capitalize', margin: '15px 0' }}
				>
					<b>Upload Signature</b>
				</Button>
			)}
		</FileManager>
	);
}

export default UploadSignatureFile;
