import {
    Button,
    ButtonProps,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    Paper,
    PaperProps,
    styled,
    Typography,
    dialogClasses,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { isString } from 'lodash';
import React, { MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { IconButtonWithTooltip } from '../IconButtonWithTooltip';
import { generateNamesObject } from '../../utils/stringUtils';
import { faTimes, faWindowRestore, faWindowMaximize } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeSvgIcon } from '../SvgIcon';
import { ImageWithLoader } from '../ImageWithLoader';

export const alertDialogClasses = generateNamesObject(
    'actions',
    'title',
    'controlButtonsContainer',
    'controlButton',
    'card',
    'mediaCard',
    'overlay',
    'messageHtml',
    'neutralButton',
);

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${dialogClasses.root}`]: {},
    [`& .${alertDialogClasses.actions}`]: {
        justifyContent: 'space-between',
    },
    [`& .${dialogClasses.paper}`]: {
        overflow: 'visible',
        backgroundImage: 'none',
    },
    [`& .${alertDialogClasses.title}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'grab',
        [theme.breakpoints.down('md')]: {
            cursor: 'pointer'
        }
    },
    [`& .${alertDialogClasses.controlButtonsContainer}`]: {
        marginRight: `-${theme.spacing(1)}`,
        paddingLeft: theme.spacing(2),
        display: 'flex',
    },
    [`& .${alertDialogClasses.controlButton}`]: {
        '& svg': {
            fontSize: '1rem',
        },
        marginTop: `-${theme.spacing(1)}`,
    },
    [`& .${alertDialogClasses.card}`]: {
        position: 'relative',
    },
    [`& .${alertDialogClasses.mediaCard}`]: {
        position: 'relative',
        height: 400,
        boxShadow: 'none',
        marginBottom: theme.spacing(2),
        backgroundImage: 'none',
        border: 'none',
        borderRadius: '6px',
    },
    [`& .${alertDialogClasses.overlay}`]: {
        position: 'absolute',
        bottom: 10,
        right: 10,
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        opacity: 0.4,
        padding: 4,
    },
    [`& .${alertDialogClasses.messageHtml}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${alertDialogClasses.neutralButton}`]: {
        marginLeft: theme.spacing(1),
    },
}));

export interface AlertDialogProps extends Omit<DialogProps, 'children'> {
    title?: string;
    noTitle?: boolean;
    noControl?: boolean;
    message?: string | ReactNode;
    messageHtml?: string;
    positiveButton?: string | ReactNode;
    positiveButtonColor?: ButtonProps['color'];
    positiveButtonDisabled?: boolean;
    negativeButton?: string | ReactNode;
    negativeButtonColor?: ButtonProps['color'];
    negativeButtonDisabled?: boolean;
    neutralButton?: string | ReactNode;
    neutralButtonColor?: ButtonProps['color'];
    neutralButtonDisabled?: boolean;
    maximizable?: boolean;
    draggable?: boolean;
    onClose?: MouseEventHandler;
    onPositiveClick?: MouseEventHandler;
    onNegativeClick?: MouseEventHandler;
    onNeutralClick?: MouseEventHandler;
    children?: React.ReactNode;
    featuredImage?: string;
    featuredImageCopyright?: string;
    titleVariant?: Variant;
    maxCharacterLength?: number;
    trimEllipsis?: boolean;
    buttonProps?: ButtonProps;
}

const DraggablePaperComponent: React.FunctionComponent<PaperProps> = (props) => {
    return (
        <Draggable scale={1} handle={`.${alertDialogClasses.title}`} bounds="parent">
            <Paper {...props} />
        </Draggable>
    );
};

export const AlertDialog: React.FunctionComponent<AlertDialogProps> = (props) => {
    const {
        children,
        open,
        title = 'Alert',
        noTitle,
        noControl,
        message,
        messageHtml,
        positiveButton = 'OK',
        positiveButtonColor,
        positiveButtonDisabled,
        negativeButton,
        negativeButtonColor,
        negativeButtonDisabled,
        neutralButton,
        neutralButtonColor,
        neutralButtonDisabled,
        maximizable,
        draggable = true,
        onClose,
        onPositiveClick,
        onNegativeClick,
        onNeutralClick,
        featuredImage,
        featuredImageCopyright,
        titleVariant,
        maxCharacterLength,
        trimEllipsis,
        buttonProps,
        // exclude from `rest` props
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        classes: overriddenClasses,
        ...rest
    } = props;
    const theme = useTheme();
    const [maximized, setMaximized] = useState(false);
    const isMobileView = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    useEffect(() => {
        if (!open) {
            setTimeout(() => setMaximized(false), 100);
        }
    }, [open]);

    const controlButtons = (
        <div className={alertDialogClasses.controlButtonsContainer}>
            {maximizable && (
                <IconButtonWithTooltip
                    className={alertDialogClasses.controlButton}
                    tooltip={maximized ? 'Restore' : 'Maximize'}
                    onClick={() => setMaximized(!maximized)}
                    icon={
                        maximized ? (
                            <FontAwesomeSvgIcon icon={faWindowRestore} />
                        ) : (
                            <FontAwesomeSvgIcon icon={faWindowMaximize} />
                        )
                    }
                />
            )}

            <IconButtonWithTooltip
                className={alertDialogClasses.controlButton}
                tooltip="Close"
                onClick={onClose}
                icon={<FontAwesomeSvgIcon icon={faTimes} />}
            />
        </div>
    );

    return (
        <AlertDialogContext.Provider value={true}>
            <StyledDialog
                dir={theme.direction}
                open={open}
                scroll="paper"
                fullScreen={maximized}
                {...rest}
                PaperComponent={draggable && !maximized && !isMobileView ? DraggablePaperComponent : undefined}
            >
                {(!noTitle || !noControl) && (
                    <DialogTitle className={alertDialogClasses.title} variant="h6" component="div">
                        {!noTitle && (
                            <Typography variant={titleVariant ? titleVariant : 'h6'} component="div">
                                {title}
                            </Typography>
                        )}
                        {!noControl && controlButtons}
                    </DialogTitle>
                )}

                <DialogContent>
                    {featuredImage && (
                        <Card className={alertDialogClasses.mediaCard}>
                            <ImageWithLoader image={featuredImage} />
                            {featuredImageCopyright && (
                                <div className={alertDialogClasses.overlay}>{featuredImageCopyright}</div>
                            )}
                        </Card>
                    )}
                    {children}
                    {!children && messageHtml && maxCharacterLength && trimEllipsis && (
                        <DialogContentText
                            dangerouslySetInnerHTML={{
                                __html: messageHtml.slice(0, maxCharacterLength),
                            }}
                        />
                    )}
                    {!children && messageHtml && maxCharacterLength && !trimEllipsis && (
                        <DialogContentText
                            dangerouslySetInnerHTML={{
                                __html: messageHtml.slice(0, maxCharacterLength) + '…',
                            }}
                        />
                    )}
                    {!children && messageHtml && !maxCharacterLength && (
                        <DialogContentText dangerouslySetInnerHTML={{ __html: messageHtml }} />
                    )}

                    {!children && !messageHtml && <DialogContentText>{message}</DialogContentText>}
                </DialogContent>

                {(positiveButton || negativeButton) && (
                    <DialogActions className={alertDialogClasses.actions}>
                        <div>
                            {neutralButton && (
                                <>
                                    {isString(neutralButton) ? (
                                        <Button
                                            className={alertDialogClasses.neutralButton}
                                            color={neutralButtonColor || 'primary'}
                                            onClick={onNeutralClick}
                                            disabled={neutralButtonDisabled}
                                            {...buttonProps}
                                        >
                                            {neutralButton}
                                        </Button>
                                    ) : (
                                        <>{neutralButton}</>
                                    )}
                                </>
                            )}
                        </div>

                        <div>
                            {negativeButton && (
                                <Button
                                    color={negativeButtonColor || 'primary'}
                                    onClick={onNegativeClick || onClose}
                                    disabled={negativeButtonDisabled}
                                    {...buttonProps}
                                >
                                    {negativeButton}
                                </Button>
                            )}

                            {positiveButton && (
                                <Button
                                    color={positiveButtonColor || 'primary'}
                                    onClick={
                                        onPositiveClick || (!negativeButton && !neutralButton ? onClose : undefined)
                                    }
                                    disabled={positiveButtonDisabled}
                                    {...buttonProps}
                                >
                                    {positiveButton}
                                </Button>
                            )}
                        </div>
                    </DialogActions>
                )}
            </StyledDialog>
        </AlertDialogContext.Provider>
    );
};

export const AlertDialogContext = React.createContext<boolean>(false);
