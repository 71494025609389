import { alpha, Box, CircularProgress, Skeleton, SkeletonProps, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { AsyncActionStatus } from '../../constants';
import { generateNamesObject } from '../../utils/stringUtils';

export const loaderClasses = generateNamesObject(['container', 'skeletonContainer', 'overlay', 'hidden'], 'Loader');

const Container = styled(Box)(({ theme }) => ({
    zIndex: 1000,
    [`& .${loaderClasses.container}`]: {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    [`& .${loaderClasses.skeletonContainer}`]: {
        position: 'relative',
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    [`& .${loaderClasses.hidden}`]: {
        display: 'none',
    },
    [`& .${loaderClasses.overlay}`]: {
        backgroundColor: alpha(theme.palette.background.paper, 0.5),
    },
}));

export type LoaderProps = {
    status?: AsyncActionStatus;
    disabled?: boolean;
    overlay?: boolean;
    error?: React.ReactNode;
    loader?: React.ReactNode;
    size?: number;
    className?: string;
    showContentWhilePending?: boolean;
    loaderComponent?: 'spinner' | 'skeleton' | React.ReactNode;
    height?: number | string;
    width?: number | string;
    skeletonAmount?: number;
};

export const Loader: React.FunctionComponent<LoaderProps & SkeletonProps> = (props) => {
    const {
        status = AsyncActionStatus.Idle,
        disabled,
        overlay = false,
        error,
        size = 50,
        showContentWhilePending,
        className,
        children,
        loaderComponent = 'spinner',
        width = '100%',
        height = '100%',
        skeletonAmount,
        ...rest
    } = props;

    const completed = status === AsyncActionStatus.Idle || status === AsyncActionStatus.Fulfilled;

    return (
        <React.Fragment>
            <Container>
                <Box
                    className={clsx(className, {
                        [loaderClasses.skeletonContainer]: loaderComponent === 'skeleton',
                        [loaderClasses.container]: loaderComponent === 'spinner',
                        [loaderClasses.hidden]: disabled || completed,
                        [loaderClasses.overlay]: overlay,
                    })}
                    sx={{ width, height }}
                >
                    {status === AsyncActionStatus.Pending &&
                        (loaderComponent === 'spinner' ? (
                            <CircularProgress size={size} />
                        ) : loaderComponent === 'skeleton' && skeletonAmount ? (
                            Array.from({ length: skeletonAmount }, () => (
                                <Skeleton height={height} width={width} {...rest} />
                            ))
                        ) : loaderComponent === 'skeleton' ? (
                            <Skeleton height={height} width={width} {...rest} />
                        ) : (
                            loaderComponent
                        ))}
                    {status === AsyncActionStatus.Rejected && (error || <Typography>Error loading data</Typography>)}
                </Box>
            </Container>

            {(showContentWhilePending || completed) && children}
        </React.Fragment>
    );
};
