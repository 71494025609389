import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	PersistConfig,
	REGISTER,
	REHYDRATE,
	persistReducer,
	persistStore,
} from 'redux-persist';
import session_storage from 'redux-persist/lib/storage/session';

import { applicationFormReducer } from '@app/redux/applicationForm/applicationForm.reducer';
import { applicationsReducer } from '@app/redux/applications/applications.reducer';
import { assetsReducer } from '@app/redux/assets/assets.reducer';
import { authReducer } from '@app/redux/auth/auth.reducer';

import { isDevelopment } from '@utils/environment';

export const reducer = combineReducers({
	assets: assetsReducer,
	auth: authReducer,
	applications: applicationsReducer,
	applicationForm: applicationFormReducer,
});

const persistConfig: PersistConfig<ReturnType<typeof reducer>> = {
	key: 'root',
	storage: session_storage,
	blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware => {
		return getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat();
	},
	devTools: isDevelopment(),
});

const persist = persistStore(store);

export { store, persist };
