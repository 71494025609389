import { Formik, FormikConfig, FormikProps } from 'formik';
import React from 'react';
import {
    AccountOrModelSelect,
    FillTypeSelect,
    MarketTimeSelect,
    MutualFundCapitalGainSelect,
    MutualFundDividendsSelect,
    MutualFundUnitSelect,
    MutualFundValueSelect,
    NotionalValueSelect,
    OptionContractSelect,
    OrderLimitPriceSelect,
    OrderStopPriceSelect,
    OrderTypeSelect,
    PositionTypeSelect,
    QuantitySelect,
    SecurityTypeSelect,
    SymbolSelect,
    TradeDestinationSelect,
    TransactionSelect,
    ValiditySelect,
    OptionLegSelect,
    OrderValueSelect,
    TrailingStopPegTypeSelect,
    TrailingStopPegDifferenceSelect,
} from './controls';
import { fieldNames, tradingFormSchema, TradingFormValues } from './schema';
import {
    defaultTradingFormOptions,
    getTradingFormMeta,
    TradingFormContext,
    TradingFormMeta,
    TradingFormOptions,
} from './TradingFormContext';

type TradingFormControls = {
    AccountOrModelSelect: typeof AccountOrModelSelect;
    TradeDestinationSelect: typeof TradeDestinationSelect;
    SecurityTypeSelect: typeof SecurityTypeSelect;
    SymbolSelect: typeof SymbolSelect;
    TransactionSelect: typeof TransactionSelect;
    OrderTypeSelect: typeof OrderTypeSelect | null;
    OrderStopPriceSelect: typeof OrderStopPriceSelect | null;
    OrderLimitPriceSelect: typeof OrderLimitPriceSelect | null;
    QuantitySelect: typeof QuantitySelect | null;
    NotionalValueSelect: typeof NotionalValueSelect | null;
    FillTypeSelect: typeof FillTypeSelect | null;
    MarketTimeSelect: typeof MarketTimeSelect | null;
    ValiditySelect: typeof ValiditySelect | null;
    PositionTypeSelect: typeof PositionTypeSelect | null;
    OptionLegSelect: typeof OptionLegSelect | null;
    OptionContractSelect: typeof OptionContractSelect | null;
    MutualFundCapitalGainSelect: typeof MutualFundCapitalGainSelect | null;
    MutualFundDividendsSelect: typeof MutualFundDividendsSelect | null;
    MutualFundUnitSelect: typeof MutualFundUnitSelect | null;
    MutualFundValueSelect: typeof MutualFundValueSelect | null;
    OrderValueSelect: typeof OrderValueSelect | null;
    TrailingStopPegTypeSelect: typeof TrailingStopPegTypeSelect | null;
    TrailingStopPegDifferenceSelect: typeof TrailingStopPegDifferenceSelect | null;
};

export type TradingFormProps = Omit<FormikConfig<TradingFormValues>, 'children' | 'initialValues'> & {
    initialValues?: Partial<FormikConfig<TradingFormValues>['initialValues']>;
    options?: Partial<TradingFormOptions>;
    onSubmit: (values: TradingFormValues) => void;
    children: (
        props: FormikProps<TradingFormValues> & {
            fieldNames: typeof fieldNames;
            controls: TradingFormControls;
            meta: TradingFormMeta;
            options: TradingFormOptions;
        },
    ) => React.ReactElement;
};

export const TradingForm: React.FunctionComponent<TradingFormProps> = ({
    options: formOptions,
    onSubmit,
    children,
    initialValues,
    ...formikConfig
}) => {
    return (
        <Formik
            {...formikConfig}
            initialValues={{
                ...tradingFormSchema.getDefault(),
                ...initialValues,
            }}
            validationSchema={tradingFormSchema}
            onSubmit={onSubmit}
        >
            {(formikProps) => {
                const { values } = formikProps;
                const meta = getTradingFormMeta(values);
                const {
                    equityTrading,
                    optionTrading,
                    mutualFundTrading,
                    notionalOrder,
                    stopOrder,
                    limitOrder,
                    trailingStopOrder,
                } = meta;

                const hasStopPriceSelect = !mutualFundTrading && stopOrder;
                const hasLimitPriceSelect = !mutualFundTrading && limitOrder;
                const hasQuantitySelect = equityTrading && !notionalOrder;
                const hasFillTypeSelect = optionTrading || equityTrading;
                const hasOrderValueSelect = equityTrading && !notionalOrder;

                const options: TradingFormOptions = {
                    ...defaultTradingFormOptions,
                    ...formOptions,
                };

                return (
                    <TradingFormContext.Provider
                        value={{
                            fieldNames,
                            meta,
                            options,
                        }}
                    >
                        {children({
                            ...formikProps,
                            fieldNames,
                            meta,
                            options,
                            controls: {
                                AccountOrModelSelect,
                                TradeDestinationSelect,
                                SecurityTypeSelect,
                                SymbolSelect,
                                TransactionSelect,
                                OrderTypeSelect: !mutualFundTrading ? OrderTypeSelect : null,
                                OrderStopPriceSelect: hasStopPriceSelect ? OrderStopPriceSelect : null,
                                OrderLimitPriceSelect: hasLimitPriceSelect ? OrderLimitPriceSelect : null,
                                QuantitySelect: hasQuantitySelect ? QuantitySelect : null,
                                NotionalValueSelect: notionalOrder ? NotionalValueSelect : null,
                                FillTypeSelect: hasFillTypeSelect ? FillTypeSelect : null,
                                MarketTimeSelect: equityTrading ? MarketTimeSelect : null,
                                ValiditySelect: !mutualFundTrading ? ValiditySelect : null,
                                PositionTypeSelect: equityTrading ? PositionTypeSelect : null,
                                OptionLegSelect: optionTrading ? OptionLegSelect : null,
                                OptionContractSelect: optionTrading ? OptionContractSelect : null,
                                MutualFundCapitalGainSelect: mutualFundTrading ? MutualFundCapitalGainSelect : null,
                                MutualFundDividendsSelect: mutualFundTrading ? MutualFundDividendsSelect : null,
                                MutualFundUnitSelect: mutualFundTrading ? MutualFundUnitSelect : null,
                                MutualFundValueSelect: mutualFundTrading ? MutualFundValueSelect : null,
                                OrderValueSelect: hasOrderValueSelect ? OrderValueSelect : null,
                                TrailingStopPegTypeSelect: trailingStopOrder ? TrailingStopPegTypeSelect : null,
                                TrailingStopPegDifferenceSelect: trailingStopOrder
                                    ? TrailingStopPegDifferenceSelect
                                    : null,
                            },
                        })}
                    </TradingFormContext.Provider>
                );
            }}
        </Formik>
    );
};
