import { SearchHighlightedFormatter } from 'mui-enhanced-table';
import { formatDate } from '../../../utils/stringUtils';

class DateFormatterClass extends SearchHighlightedFormatter {
    distance: boolean;
    withTime: boolean;
    short?: boolean;

    constructor(distance = false, withTime = false, short = false) {
        super();
        this.distance = distance;
        this.withTime = withTime;
        this.short = short;
    }

    getValueString(value: any) {
        if (!value) {
            return '-';
        }

        return formatDate(value, { distance: this.distance, withTime: this.withTime, short: this.short });
    }
}

export const DateFormatter = new DateFormatterClass();

export const DateFormatterWithDistance = new DateFormatterClass(true, false);

export const DateFormatterWithTime = new DateFormatterClass(false, true);

export const DateFormatterWithNoYear = new DateFormatterClass(false, false, true);
