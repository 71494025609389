import React from 'react';

import { Button, Grid, Stack, TextField } from '@mui/material';

import DEFAULT_SIGNATURE_FONT_OPTIONS from '@pages/ApplicationForm/containers/ApplicationFormMain/components/SignatureFieldApplicationForm/components/UploadSignatureInput/constants';
import useUploadSignatureInput from '@pages/ApplicationForm/containers/ApplicationFormMain/components/SignatureFieldApplicationForm/components/UploadSignatureInput/hooks';

export interface UploadSignatureInputProps {
	questionId: number;
	disabled: boolean;
	handleSaveAnswer: (file: File) => Promise<void>;
}

function UploadSignatureInput({ disabled, handleSaveAnswer }: UploadSignatureInputProps) {
	const { inputTextValue, inputFontFamily, setInputTextValue, handleSaveInput } = useUploadSignatureInput({
		handleSaveAnswer,
	});

	return (
		<Grid container direction={'column'}>
			<Stack direction={'row'} gap={2} alignItems={'center'} my={2}>
				<TextField
					value={inputTextValue}
					onChange={e => setInputTextValue(e.target.value)}
					placeholder={'Sign here'}
					size={'small'}
					sx={{
						width: { xs: '100%', sm: '80%', md: '50%', lg: '33%' },
						'& > div': {
							fontFamily: DEFAULT_SIGNATURE_FONT_OPTIONS.find(({ id }) => id === inputFontFamily)?.fontFamily,
						},
					}}
					disabled={disabled}
				/>

				{/* <Select
					labelId={`question-${questionId}-select-label`}
					id={`question-${questionId}-select`}
					value={inputFontFamily}
					sx={{
						width: { xs: '100%', sm: '80%', md: '50%', lg: '33%' },
						fontFamily: DEFAULT_SIGNATURE_FONT_OPTIONS.find(({ id }) => id === inputFontFamily)?.fontFamily,
					}}
					onChange={e => setInputFontFamily(e.target.value as number)}
				>
					{DEFAULT_SIGNATURE_FONT_OPTIONS.map(option => (
						<MenuItem key={option.id} value={option.id}>
							{option.label}
						</MenuItem>
					))}
				</Select> */}
			</Stack>

			<Button
				disableElevation
				variant="contained"
				size="medium"
				color="primary"
				disabled={disabled || !inputTextValue}
				onClick={handleSaveInput}
				sx={{ textTransform: 'capitalize', width: 120 }}
			>
				<b>Sign</b>
			</Button>
		</Grid>
	);
}

export default UploadSignatureInput;
