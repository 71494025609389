import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const SvgIconNoViewBox: React.FunctionComponent<
    SvgIconProps & {
        component: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    }
> = ({ component: Component, ...props }) => (
    <SvgIcon
        {...props}
        component={({
            // we don't want default viewBox from material-ui
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            viewBox,
            ...props
        }) => <Component {...props} />}
    />
);

export { SvgIconNoViewBox as SvgIcon, SvgIconProps };
