import React from 'react';
import { useHistory } from 'react-router-dom';

import APPLICATION_STATUSES_ENUM from '@api/requests/applications/enums/APPLICATION_STATUSES_ENUM.enum';
import ApplicationDetailsInterface from '@api/requests/applications/interfaces/ApplicationDetailsInterface.interface';

import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { Button, Grid } from '@mui/material';

import * as environment from '@utils/environment';
import { isRetrievalOnly } from '@utils/environment';

import WelcomeLetter from './WelcomeLetter';
import { StyledWelcomeSection } from './styles';

function WelcomeSection({ applicationDetails }: { applicationDetails: ApplicationDetailsInterface }) {
	const history = useHistory();
	const generateRoutePath = useGenerateRoutePath();

	if (applicationDetails?.submitted_at !== null) {
		return (
			<StyledWelcomeSection>
				<WelcomeLetter firm={environment.env.FIRM_ENVIRONMENT} application_id={applicationDetails.id} />
			</StyledWelcomeSection>
		);
	}

	return (
		<StyledWelcomeSection>
			{isRetrievalOnly() ? null : (
				<Grid container justifyContent={'center'}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => history.push(generateRoutePath(RoutePaths.MAIN.APPLICATION_FORM))}
						sx={{ textTransform: 'initial' }}
					>
						<b>
							{applicationDetails.status === APPLICATION_STATUSES_ENUM.IN_PROGRESS && applicationDetails.account_number
								? 'Update Application'
								: 'Resume Application'}
						</b>
					</Button>
				</Grid>
			)}
		</StyledWelcomeSection>
	);
}

export default WelcomeSection;
