import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik/dist/types';
import { useCallback } from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';
import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';

import { CurrentUserFormValues } from '@components/forms/currentUser/interfaces';

import { getDialCode, getNational } from '@utils/phoneFormatter';

import { USER_PROFILE_STEPS_ENUM } from '../userProfileViewsFactory';

function useUserProfileUpdate({
	currentUser,
	setCurrentStep,
}: {
	currentUser: CurrentUserInterface;
	setCurrentStep(value: USER_PROFILE_STEPS_ENUM): void;
}) {
	const { startRequest } = useTriggeredRequest(AccountClientRequests.Users.update);

	const initialValues: CurrentUserFormValues = {
		first_name: currentUser.first_name,
		last_name: currentUser.last_name,
		email: currentUser.email,
		phone: {
			code: getDialCode(currentUser.phone || '') || '',
			number: getNational(currentUser.phone || '') || '',
		},
	};

	const onSubmit = useCallback(
		async (values: CurrentUserFormValues, formikHelpers: FormikHelpers<CurrentUserFormValues>) => {
			try {
				await startRequest({
					user_id: currentUser.id,
					first_name: values.first_name,
					last_name: values.last_name,
					email: values.email,
					phone: values.phone.code + values.phone.number,
				});

				setCurrentStep(USER_PROFILE_STEPS_ENUM.INFO);
			} catch (e) {
				const { response } = e as AxiosError<{
					messages: {
						first_name: string[];
						last_name: string[];
						email: string[];
						phone: string[];
					};
				}>;

				formikHelpers.setErrors({
					first_name: response?.data?.messages?.first_name?.join(' ') || '',
					last_name: response?.data?.messages?.last_name?.join(' ') || '',
					email: response?.data?.messages?.email?.join(' ') || '',
					phone: {
						code: '',
						number: response?.data?.messages?.phone?.join(' ') || '',
					},
				});
			}
		},
		[currentUser.id, setCurrentStep, startRequest],
	);

	return {
		initialValues,
		onSubmit,
	};
}

export default useUserProfileUpdate;
