import { mixed, number, object, string } from 'yup';
import { generateNamesObject } from '../../utils';
import { OptionLeg } from './controls/OptionLegSelect';
import {
    MutualFundCapitalGains,
    MutualFundDividends,
    MutualFundUnit,
    OrderFillType,
    OrderMarketTime,
    OrderTransaction,
    OrderType,
    OrderValidity,
    PositionType,
    SecurityType,
    TradeDestination,
    TrailingStopType,
} from './lookups';
import { tradeDestinationFormSelectOptions } from './selectOptions';

export const fieldNames = generateNamesObject(
    'symbol',
    'tradeDestination',
    'accountOrModel',
    'securityType',
    'optionLegs',
    'contracts',
    'transaction',
    'orderType',
    'stopPrice',
    'limitPrice',
    'quantity',
    'notionalValue',
    'fillType',
    'validity',
    'marketTime',
    'positionType',
    'mutualFundValue',
    'mutualFundCapitalGains',
    'mutualFundDividends',
    'mutualFundUnit',
    'accountId',
    'modelId',
    'orderValue',
    'trailingStopPegType',
    'trailingStopPegDifference',
);

export const tradingFormSchema = object({
    [fieldNames.symbol]: string().required().label('Symbol').default(''),
    [fieldNames.tradeDestination]: mixed<TradeDestination>()
        .required()
        .label('Destination')
        .default(TradeDestination.SingleAccount),
    [fieldNames.accountOrModel]: string().required('Please select account or model'),
    [fieldNames.securityType]: mixed<SecurityType>().required().label('Security Type').default(SecurityType.Equity),
    [fieldNames.optionLegs]: mixed<OptionLeg[]>().default([]),
    [fieldNames.contracts]: number()
        .integer()
        .label('Contracts')
        .when([fieldNames.securityType, fieldNames.orderType], {
            is: (securityType: SecurityType) =>
                securityType === SecurityType.Option || securityType === SecurityType.Spread,
            then: number().required().min(1),
            otherwise: number().min(1),
        }),
    [fieldNames.transaction]: mixed<OrderTransaction>().label('Transaction').required(),
    [fieldNames.orderType]: mixed<OrderType>().label('Order Type').default(OrderType.Limit).required(),
    [fieldNames.stopPrice]: number()
        .label('Stop Price')
        .when(fieldNames.orderType, {
            is: (value: OrderType) => value === OrderType.Stop || value === OrderType.StopLimit,
            then: number().required().min(1),
            otherwise: number().min(1),
        }),
    [fieldNames.limitPrice]: number()
        .label('Limit Price')
        .when(fieldNames.orderType, {
            is: (value: OrderType) => value === OrderType.Limit || value === OrderType.StopLimit,
            then: number().required().min(1),
            otherwise: number().min(1),
        }),
    [fieldNames.validity]: mixed<OrderValidity>().label('Validity').default(OrderValidity.GoodForDay).required(),
    [fieldNames.marketTime]: mixed<OrderMarketTime>()
        .label('Market Time')
        .default(OrderMarketTime.CoreMarket)
        .required(),
    [fieldNames.fillType]: mixed<OrderFillType>().label('Fill Type').default(OrderFillType.Any).required(),
    [fieldNames.quantity]: number()
        .label('Quantity')
        .when([fieldNames.securityType, fieldNames.orderType], {
            is: (securityType: SecurityType, orderType: OrderType) =>
                securityType === SecurityType.Equity && orderType !== OrderType.Notional,
            then: number().required().min(1),
            otherwise: number().min(1),
        }),
    [fieldNames.notionalValue]: number().label('Value').when(fieldNames.orderType, {
        is: OrderType.Notional,
        then: number().required().positive(),
        otherwise: number(),
    }),
    [fieldNames.positionType]: mixed<PositionType>().label('Position Type').default(PositionType.Cash).required(),
    [fieldNames.mutualFundValue]: number()
        .label('Value')
        .when(fieldNames.securityType, {
            is: SecurityType.MutualFund,
            then: number().required(),
            otherwise: number(),
        })
        .when('mutualFundUnit', {
            is: MutualFundUnit.Unit,
            then: number().label('Quantity'),
        }),
    [fieldNames.mutualFundUnit]: mixed<MutualFundUnit>().label('Unit').default(MutualFundUnit.Unit),
    [fieldNames.mutualFundCapitalGains]: mixed<MutualFundCapitalGains>()
        .label('Capital Gains')
        .default(MutualFundCapitalGains.Reinvest),
    [fieldNames.mutualFundDividends]: mixed<MutualFundDividends>()
        .label('Dividends')
        .default(MutualFundDividends.Reinvest),
    [fieldNames.accountId]: number().label('Account').when(fieldNames.tradeDestination, {
        is: tradeDestinationFormSelectOptions.singleAccount.value,
        then: number().required(),
        otherwise: number(),
    }),
    [fieldNames.modelId]: number().label('Model').when(fieldNames.tradeDestination, {
        is: tradeDestinationFormSelectOptions.model.value,
        then: number().required(),
        otherwise: number(),
    }),
    [fieldNames.orderValue]: number().label('Order Value'),
    [fieldNames.trailingStopPegType]: mixed<TrailingStopType>()
        .label('Peg Type')
        .when(fieldNames.orderType, {
            is: (value: OrderType) => value === OrderType.TrailingStop,
            then: mixed<TrailingStopType>().required(),
        }),
    [fieldNames.trailingStopPegDifference]: number()
        .label('Peg Difference')
        .when(fieldNames.orderType, {
            is: (value: OrderType) => value === OrderType.TrailingStop,
            then: number().required(),
            otherwise: number(),
        }),
});

export const getInitialTradingFormValues = (values?: any) =>
    tradingFormSchema.cast(values ?? undefined, { stripUnknown: false });

export type TradingFormValues = ReturnType<typeof tradingFormSchema.getDefault>;
