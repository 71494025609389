import { useState } from 'react';

import DEFAULT_SIGNATURE_FONT_OPTIONS from '@pages/ApplicationForm/containers/ApplicationFormMain/components/SignatureFieldApplicationForm/components/UploadSignatureInput/constants';

function useUploadSignatureInput({ handleSaveAnswer }: { handleSaveAnswer: (file: File) => Promise<void> }) {
	const [inputTextValue, setInputTextValue] = useState('');
	const [inputFontFamily, setInputFontFamily] = useState(DEFAULT_SIGNATURE_FONT_OPTIONS[0].id);

	const handleSaveInput = () => {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');

		if (ctx) {
			const textContentMeasure = ctx.measureText(inputTextValue);

			canvas.width = 500 + Math.floor(textContentMeasure.width);
			canvas.height = 300;

			ctx.font = `40px ${DEFAULT_SIGNATURE_FONT_OPTIONS.find(({ id }) => id === inputFontFamily)?.fontFamily}`;
			ctx.fillStyle = '#3D4056';
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';

			ctx.fillText(inputTextValue, canvas.width / 2, canvas.height / 2);

			canvas.toBlob(
				blob => {
					if (blob) {
						handleSaveAnswer(new File([blob], 'signature.png'));
					}
				},
				'image/png',
				1,
			);
		}
	};

	return {
		inputTextValue,
		setInputTextValue,
		inputFontFamily,
		setInputFontFamily,
		handleSaveInput,
	};
}

export default useUploadSignatureInput;
