import {
    Box,
    IconButton,
    InputAdornment,
    inputBaseClasses,
    OutlinedInput,
    outlinedInputClasses,
    OutlinedInputProps,
    styled,
} from '@mui/material';
import React, { useRef } from 'react';
import { generateNamesObject } from '../../utils';
import { Clear, Search } from '@mui/icons-material';

export type SearchBoxProps = Partial<OutlinedInputProps> & {
    onClear: () => void;
    placeholder?: string;
    startAdornmentIcon?: React.ReactElement;
    endAdornmentIcon?: React.ReactElement;
};

export const searchBoxClasses = generateNamesObject(['root', 'searchIconWrapper'], 'Container');

const Container = styled(Box)(({ theme }) => ({
    [`& .${inputBaseClasses.root}`]: {
        width: '100%',
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.action.hover,
        border: `1px solid ${theme.palette.divider}`,
    },

    [`& .${inputBaseClasses.input}`]: {
        padding: theme.spacing(1, 0),
        transition: theme.transitions.create('width'),
    },
    [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderWidth: 0,
    },
    [`& .${searchBoxClasses.searchIconWrapper}`]: {
        height: '100%',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            margin: theme.spacing(0, -3, 0, -1),
            transform: theme.rtl ? 'scaleX(-1)' : undefined,
        },
    },
}));

export const SearchBox: React.FunctionComponent<SearchBoxProps> = ({
    onClear,
    placeholder,
    startAdornmentIcon,
    endAdornmentIcon,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Container className={searchBoxClasses.root}>
            <OutlinedInput
                placeholder={placeholder ?? 'Search...'}
                startAdornment={
                    rest.startAdornment ? (
                        rest.startAdornment
                    ) : (
                        <InputAdornment position="start">{startAdornmentIcon ?? <Search />}</InputAdornment>
                    )
                }
                inputProps={{
                    ref: inputRef,
                }}
                endAdornment={
                    rest.endAdornment ? (
                        rest.endAdornment
                    ) : (
                        <InputAdornment
                            position="end"
                            style={{ display: inputRef.current?.value ? undefined : 'none' }}
                        >
                            <IconButton
                                size="small"
                                onClick={() => {
                                    if (inputRef.current) {
                                        inputRef.current.value = '';
                                        inputRef.current.focus();
                                    }
                                    onClear();
                                }}
                            >
                                {endAdornmentIcon ?? <Clear />}
                            </IconButton>
                        </InputAdornment>
                    )
                }
                {...rest}
            />
        </Container>
    );
};
