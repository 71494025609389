import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import configRequest from '@api/requests/configRequest';

export type GetNextGenTokenData = void;

export interface GetNextGenTokenResponse {
	sso_token: {
		token: string;
	};
}

const getNextGenToken = configRequest<GetNextGenTokenResponse, GetNextGenTokenData>({
	requestPrefix: 'auth/getNextGenToken',
	reqConfigCreator: () => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.AUTH.GET_NEXT_GEN_TOKEN(),
		method: HTTP_METHODS_ENUM.POST,
	}),
});

export default getNextGenToken;
