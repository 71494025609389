import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadingMessage, selectAlert } from '../../redux';
import { ProgressDialog } from '../ProgressDialog';

type LoaderHandlerComponentProps = {
    open: boolean;
    message?: LoadingMessage;
};

type LoaderHandlerProps = {
    render?: React.FunctionComponent<LoaderHandlerComponentProps>;
};

export const LoaderHandler: React.FunctionComponent<LoaderHandlerProps> = ({ render: Component = DefaultLoader }) => {
    const loadings = useSelector((state) => selectAlert(state)).loadings;
    const [message, setMessage] = useState(loadings[0]);

    useEffect(() => {
        if (loadings[0] && loadings[0] !== message) {
            setMessage(loadings[0]);
        }
    });

    return <Component open={loadings.length > 0} message={message} />;
};

export const DefaultLoader: React.FunctionComponent<LoaderHandlerComponentProps> = ({ open, message }) => {
    return <ProgressDialog open={open} message={message?.content} />;
};
