import { useToggle } from '@orbis-systems/orbis-react-toolkit';
import React from 'react';

import { Box, Typography } from '@mui/material';

import { ContactsButton, ContactsPopper, contactsPopperClasses } from '@components/contacts/styles';

import * as environment from '@utils/environment';

import CloseIcon from 'jsx:@assets/svgs/close.svg';
import ContactIcon from 'jsx:@assets/svgs/contacts.svg';

function ContactInfo() {
	const [isOpen, setOpen] = useToggle(false);

	if (
		!environment.env.SUPPORT_ACCOUNT_RELATED ||
		!environment.env.SUPPORT_PHONE_NUMBER ||
		!environment.env.SUPPORT_FAX ||
		!environment.env.SUPPORT_ADDRESS
	)
		return null;

	return (
		<div>
			<ContactsPopper open={isOpen} disablePortal>
				<Box p={2}>
					<Typography variant={'h6'} mb={1}>
						<b>Contacts</b>
					</Typography>

					<Box mb={2} className={contactsPopperClasses.item}>
						<Typography variant={'h6'}>
							<b>Account related</b>
						</Typography>

						<Typography variant={'body2'} mb={1}>
							{environment.env.SUPPORT_ACCOUNT_RELATED}
						</Typography>

						{environment.env.SUPPORT_PHONE_NUMBER !== 'none' && (
							<Typography variant={'body2'}>
								<Typography variant={'caption'}>Phone:</Typography> {environment.env.SUPPORT_PHONE_NUMBER}
							</Typography>
						)}
						{environment.env.SUPPORT_FAX !== 'none' && (
							<Typography variant={'body2'}>
								<Typography variant={'caption'}>Fax:</Typography> {environment.env.SUPPORT_FAX}
							</Typography>
						)}
					</Box>

					<Box className={contactsPopperClasses.item}>
						<Typography>
							<b>Address</b>
						</Typography>

						<Typography variant={'body2'}>{environment.env.SUPPORT_ADDRESS}</Typography>
					</Box>
				</Box>
			</ContactsPopper>

			<ContactsButton onClick={() => setOpen(!isOpen)}>{!isOpen ? <ContactIcon /> : <CloseIcon />}</ContactsButton>
		</div>
	);
}

export default ContactInfo;
