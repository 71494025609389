import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import AssetsRuleSetInterface from '@api/requests/assets/interfaces/AssetsRuleSetInterface.interface';
import configRequest from '@api/requests/configRequest';

export type GetRuleSetsData = void;

export interface GetRuleSetsResponse {
	rule_sets: AssetsRuleSetInterface[];
}

const getRuleSets = configRequest<GetRuleSetsResponse, GetRuleSetsData>({
	requestPrefix: 'assets/getRuleSets',
	reqConfigCreator: () => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.ASSETS.RULE_SETS.LIST(),
		method: HTTP_METHODS_ENUM.POST,
	}),
});

export default getRuleSets;
