import FILES_CONFIG from '@components/fileManager/constants';

export function validateDocumentPdfFile(pdfFile?: File) {
	if (!pdfFile) return false;

	return (
		pdfFile.size < FILES_CONFIG['document-pdf'].maxSize &&
		FILES_CONFIG['document-pdf'].acceptFormats.includes(pdfFile.type)
	);
}

export function validateDocumentImageFile(imageFile?: File) {
	if (!imageFile) return false;

	return (
		imageFile.size < FILES_CONFIG['document-image'].maxSize &&
		FILES_CONFIG['document-image'].acceptFormats.includes(imageFile.type)
	);
}
