import { ScrollSlider } from '@orbis-systems/orbis-react-toolkit';
import React from 'react';

import { Grid, Typography } from '@mui/material';

import EmptyState from '@components/emptyState';
import Loader from '@components/loader';

import AccountCardSkeleton from '@pages/Applications/components/AccountCardSkeleton';
import AccountDetailsSkeleton from '@pages/Applications/components/AccountDetailsSkeleton';
import ApplicationAccountCardList from '@pages/Applications/containers/ApplicationAccountCardList';
import ApplicationAccountDetails from '@pages/Applications/containers/ApplicationAccountDetails';
import CreateApplication from '@pages/Applications/containers/CreateApplication';
import useApplicationsPage from '@pages/Applications/hooks';

import { MainPageContainer, mainPageClasses } from './styles';

function ApplicationsPage() {
	const {
		applications,
		isLoadingApplicationsList,
		isLoadingApplicationForm,
		fetchApplicationForm,
		isCreatingNewApplication,
	} = useApplicationsPage();

	if (isCreatingNewApplication)
		return (
			<Grid container alignItems={'center'} justifyContent={'center'} sx={{ height: '100vh' }}>
				<Loader />
			</Grid>
		);

	if (!isLoadingApplicationsList && !applications.length) return <EmptyState />;

	return (
		<MainPageContainer container>
			<Grid container justifyContent={'space-between'} alignItems={'center'} mb={3}>
				<Typography variant={'h5'}>
					<b>Accounts & Applications</b>
				</Typography>

				<CreateApplication />
			</Grid>

			<Grid item xs={12}>
				{isLoadingApplicationsList ? (
					<ScrollSlider fullWidth childWidth={282} childHeight={132} space={16} noStretch>
						{Array.from(Array(4).keys()).map(index => (
							<AccountCardSkeleton key={`account-card-skeleton-${index}`} />
						))}
					</ScrollSlider>
				) : (
					<ApplicationAccountCardList handleSelectCard={fetchApplicationForm} />
				)}
			</Grid>

			{isLoadingApplicationsList || isLoadingApplicationForm ? (
				<Loader loaderFallBackComponent={AccountDetailsSkeleton} />
			) : (
				<Grid container className={mainPageClasses.container}>
					<ApplicationAccountDetails applicationStatusRefresh={fetchApplicationForm} />
				</Grid>
			)}
		</MainPageContainer>
	);
}

export default ApplicationsPage;
