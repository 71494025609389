import React from 'react';

import { getCurrentTheme } from '@app/redux/assets/assets.selectors';
import { useAppSelector } from '@app/redux/hooks';

import * as environment from '@utils/environment';

import { AppLogo } from './styles';

function ApplicationLogo() {
	const currentTheme = useAppSelector(getCurrentTheme);

	return <AppLogo firm={environment.env.FIRM_ENVIRONMENT} mode={currentTheme} />;
}

export default ApplicationLogo;
