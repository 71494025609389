import React from 'react';
import { useLocation } from 'react-router';

import ResetPasswordMethodForm from '@components/forms/resetPasswordMethodForm';

import useResetPasswordCode from './hooks';

function ContactForm() {
	const location = useLocation();
	const paths = location.pathname.split('/');
	const type = paths[paths.length - 1] as 'sms' | 'voice' | 'email';

	const { initialValues, onSubmit } = useResetPasswordCode(type);

	return <ResetPasswordMethodForm initialValues={initialValues} onSubmit={onSubmit} type={type} />;
}

export default ContactForm;
