import { useFormik } from 'formik';
import { useState } from 'react';

import { ChangePasswordFormProps, ChangePasswordFormValues } from './interfaces';
import changePasswordFormValidationScheme from './validationScheme';

function useChangePasswordForm({
	initialValues,
	onSubmit,
}: Pick<ChangePasswordFormProps, 'initialValues' | 'onSubmit'>) {
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

	const handleShowOldPassword = () => setShowOldPassword(!showOldPassword);
	const handleShowPassword = () => setShowPassword(!showPassword);
	const handleShowPasswordConfirmation = () => setShowPasswordConfirmation(!showPasswordConfirmation);

	const formik = useFormik<ChangePasswordFormValues>({
		initialValues,
		validationSchema: changePasswordFormValidationScheme,
		onSubmit,
	});

	return {
		showOldPassword,
		showPassword,
		showPasswordConfirmation,
		handleShowOldPassword,
		handleShowPassword,
		handleShowPasswordConfirmation,
		formik,
	};
}

export default useChangePasswordForm;
