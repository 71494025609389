import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import configRequest from '@api/requests/configRequest';

export interface SendResetPasswordCodeData {
	channel_type?: 'sms' | 'voice' | 'email'; // not required for 'email'
	email?: string;
	phone?: string;
	via?: 'phone' | 'email';
}

export interface SendResetPasswordCodeResponse {
	data: any;
	password_reset: {
		expires_at: string;
	};
}

const sendResetPasswordCode = configRequest<SendResetPasswordCodeResponse, SendResetPasswordCodeData>({
	requestPrefix: 'auth/send-reset-password-code',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.AUTH.RESET_PASSWORD_CODE(),
		method: HTTP_METHODS_ENUM.POST,
		data: data as SendResetPasswordCodeData,
	}),
});

export default sendResetPasswordCode;
