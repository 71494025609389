import { Autocomplete, autocompleteClasses, AutocompleteProps, styled, TextField, TextFieldProps } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { get, isArray } from 'lodash';
import React, { useEffect } from 'react';
import { FieldConfigProps } from '.';

const StyledField = styled(Field)(() => ({
    [`& .${autocompleteClasses.option}`]: {
        padding: 0,
    },
}));

type Props<
    T,
    Multiple extends boolean | undefined = true,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
> = FieldConfigProps &
    Omit<
        Partial<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>>,
        'ref' | 'classes' | 'options' | 'multiple'
    > & {
        multiple?: Multiple;
        options: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['options'];
        getOptionValue: (option: T) => string | number | undefined;
        textFieldProps?: TextFieldProps;
    };

export const FormAutocomplete: <T>(props: Props<T>) => React.ReactElement = ({
    name,
    validate,
    multiple,
    getOptionValue,
    textFieldProps,
    options,
    onChange,
    ...rest
}) => {
    return (
        <StyledField name={name} validate={validate}>
            {({ field, form: { touched, errors, setFieldValue } }: FieldProps) => {
                // FIXME: can't use meta data due to https://github.com/jaredpalmer/formik/issues/343
                const error = get(touched, field.name) && (get(errors, field.name) as string);
                let value: any;

                if (multiple) {
                    value = isArray(field.value)
                        ? options.filter((option) => field.value.includes(getOptionValue(option)))
                        : field.value !== undefined
                        ? [options.find((option) => field.value === getOptionValue(option))]
                        : [];
                } else {
                    value = options.find((option) => field.value === getOptionValue(option)) || null;
                }

                useEffect(() => {
                    const optionValues = options.map(getOptionValue);
                    const selectedValues = isArray(field.value) ? field.value : field.value ? [field.value] : [];

                    if (selectedValues.some((value) => !optionValues.includes(value))) {
                        setFieldValue(name, undefined);
                    }
                }, [options, field.value]);

                return (
                    <Autocomplete
                        multiple={multiple}
                        options={options}
                        renderInput={(params) => (
                            <TextField
                                variant="outlined"
                                {...textFieldProps}
                                {...params}
                                name={field.name}
                                onBlur={field.onBlur}
                                error={!!error}
                                helperText={error ?? textFieldProps?.helperText}
                            />
                        )}
                        isOptionEqualToValue={(option, value) => getOptionValue(option) === getOptionValue(value)}
                        {...rest}
                        value={value}
                        onChange={(event, options, reason, details) => {
                            setFieldValue(
                                name,
                                isArray(options)
                                    ? options.map((option) => getOptionValue(option))
                                    : options
                                    ? getOptionValue(options as any)
                                    : undefined,
                            );

                            onChange?.(event, options, reason, details);
                        }}
                    />
                );
            }}
        </StyledField>
    );
};
