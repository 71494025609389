import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import { NUM_BOOL_TYPE } from '@api/interfaces/SharedInterfaces.interface';
import APPLICATION_DOCUMENT_STATUSES_ENUM from '@api/requests/applications/enums/APPLICATION_DOCUMENT_STATUSES_ENUM.enum';
import configRequest from '@api/requests/configRequest';

import { showToast } from '@app/providers/ToastProvider';

export interface SaveFileAnswerData {
	application_id: number;
	question_id: number;
	file: File;
}

export interface SaveFileAnswerResponse {
	answer: {
		answered_by: number;
		data: null;
		deleted_at: string | null;
		id: number;
		document: {
			id: number;
			name: string;
			current_status: {
				id: number;
				reason: string | null;
				status: APPLICATION_DOCUMENT_STATUSES_ENUM;
			};
		};
		is_encrypted_with_application_key: NUM_BOOL_TYPE | null;
		is_encrypted_with_public_key: NUM_BOOL_TYPE | null;
		option_id: null;
		question_id: number;
	};
}

const saveFileAnswer = configRequest<SaveFileAnswerResponse, SaveFileAnswerData>({
	requestPrefix: 'applications/saveFileAnswer',
	reqConfigCreator: data => {
		const { application_id, question_id, file } = data as SaveFileAnswerData;

		const formData = new FormData();
		formData.append('data', file);
		formData.append('application_id', String(application_id));
		formData.append('question_id', String(question_id));
		formData.append('with[]', 'document.current_status');
		formData.append('with[]', 'question.translations');

		return {
			url: ACCOUNT_CLIENT_API_ENDPOINTS.APPLICATION_FORM.ANSWER(),
			method: HTTP_METHODS_ENUM.POST,
			data: formData,
		};
	},
	onSuccess: () => {
		showToast.success('Answer has been saved successfully');
	},
});

export default saveFileAnswer;
