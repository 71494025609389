import { generateNamesObject } from '@orbis-systems/orbis-react-toolkit';

import { Box, Divider, alpha, styled } from '@mui/material';

export const userProfileInfoClasses = generateNamesObject(
	['value', 'settingsPanel', 'logout', 'version'],
	'UserProfileInfoClasses',
);

export const StyledDivider = styled(Divider)(({ theme }) => ({
	borderWidth: 1,
	borderColor: theme.palette.divider,
}));

export const UserProfileInfoContainer = styled(Box)(({ theme }) => ({
	[`& .${userProfileInfoClasses.value}`]: {
		wordBreak: 'break-all',
	},
	[`& .${userProfileInfoClasses.version}`]: {
		fontSize: '10px',
		lineHeight: 1.66,
		marginTop: theme.spacing(0.5),
	},
	[`& .${userProfileInfoClasses.logout}`]: {
		'&:hover': {
			color: theme.palette.primary.main,
			'svg path': {
				fill: theme.palette.primary.main,
			},
		},
		'& svg path': {
			fill: theme.darkMode ? theme.palette.common.white : theme.palette.common.black,
		},
	},
	[`& .${userProfileInfoClasses.settingsPanel}`]: {
		backgroundColor: alpha(theme.palette.primary.main, theme.darkMode ? 0.2 : 0.1),
		padding: theme.spacing(2),
		margin: '16px -20px -20px',
		width: 'calc(100% + 40px)',
		cursor: 'pointer',
	},
}));
