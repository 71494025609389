import numbro from 'numbro';

export const num = (input: any) => numbro(input);

export const add = (a: number, b: number) => numbro(a).add(b).value();

export const subtract = (a: number, b: number) => numbro(a).subtract(b).value();

export const multiply = (a: number, b: number) => numbro(a).multiply(b).value();

export const divide = (a: number, b: number) => numbro(a).divide(b).value();

export const sum = (...args: number[]) => {
    const result = numbro(0);
    args.forEach((num) => result.add(num));
    return result.value();
};

export const sumBy = <T>(array: readonly T[], iteratee: (item: T) => number) => {
    let result: numbro.Numbro | undefined;

    array.forEach((item) => {
        const value = iteratee(item) ?? 0;

        if (result === undefined) {
            result = numbro(value);
        } else {
            result.add(value);
        }
    });

    return result?.value() ?? 0;
};

export const formatNumber = (
    input?: number | string,
    {
        decimalPlaces = 2,
        short = false,
        prefix = '',
        postfix = '',
        percent = false,
        trimZeros = false,
        forceAverage = undefined,
        thousandSeparated = true,
        rounding = true,
        currencySymbol = '',
        rtl = false,
    }: {
        decimalPlaces?: number;
        short?: boolean;
        prefix?: string;
        postfix?: string;
        percent?: boolean;
        trimZeros?: boolean;
        forceAverage?: numbro.Format['forceAverage'];
        thousandSeparated?: boolean;
        rounding?: boolean | 'up' | 'down';
        currencySymbol?: string;
        rtl?: boolean; // to be used only when cannot use <bdi>
    } = {},
): string => {
    if (isNaN(Number(input))) {
        return '-';
    }
    const formatOptions: numbro.Format = {
        prefix,
        postfix,
        mantissa: decimalPlaces,
        trimMantissa: trimZeros,
        average: short,
        thousandSeparated,
        output: percent ? 'percent' : currencySymbol ? 'currency' : 'number',
        currencyPosition: 'prefix',
    };

    if (forceAverage) {
        formatOptions.forceAverage = forceAverage;
    }

    if (rounding === 'up') {
        formatOptions.roundingFunction = Math.ceil;
    } else if (rounding === 'down' || rounding === false) {
        formatOptions.roundingFunction = Math.floor;
    }

    if (Number(input) < 0 && rtl) {
        return `${numbro(Math.abs(Number(input)))
            .format(formatOptions)
            .toUpperCase()}-`;
    }

    return numbro(input).format(formatOptions).toUpperCase();
};

export const formatMoney = (
    input?: number | string,
    {
        decimalPlaces = 2,
        short = false,
        aliasForZero = false,
        rtl = false, // to be used only when cannot use <bdi>
    }: { decimalPlaces?: number; short?: boolean; aliasForZero?: boolean; rtl?: boolean } = {},
): string => {
    const number = formatNumber(input, {
        decimalPlaces,
        short,
        rtl,
        currencySymbol: '$',
        trimZeros: false,
    });
    const num = number.replace(/[$]/g, '');

    if (aliasForZero) {
        if (parseFloat(num) === 0) {
            return 'N/A';
        }
        return number;
    } else {
        return number;
    }
};

export const formatPercentage = (
    input?: number | string,
    {
        decimalPlaces = 2,
        withConversion = false,
        withChange = false,
        withSymbol = true,
        trimZeros = false,
        rtl = false, // to be used only when cannot use <bdi>
    }: {
        decimalPlaces?: number;
        withConversion?: boolean;
        withChange?: boolean;
        withSymbol?: boolean;
        trimZeros?: boolean;
        rtl?: boolean;
    } = {},
): string => {
    let result = formatNumber(input, {
        decimalPlaces,
        postfix: withConversion ? undefined : '%',
        percent: withConversion,
        trimZeros: trimZeros,
        rtl,
    });
    result = `${(withChange && (Number(input) > 0 ? '' : '-')) || ''}${result}`;
    return withSymbol ? result : result.substring(0, result.length - 1);
};
