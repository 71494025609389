import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik/dist/types';
import { useCallback } from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';

import { VerifyEmailFormValues } from '@components/forms/verifyEmail/interfaces';

import { USER_PROFILE_STEPS_ENUM } from '../userProfileViewsFactory';

function useVerifyEmail({ setCurrentStep }: { setCurrentStep(value: USER_PROFILE_STEPS_ENUM): void }) {
	const { startRequest: verifyEmail } = useTriggeredRequest(AccountClientRequests.Users.verifyEmail);

	const initialValues: VerifyEmailFormValues = {
		code: '',
	};

	const onSubmit = useCallback(
		async (values: VerifyEmailFormValues, formikHelpers: FormikHelpers<VerifyEmailFormValues>) => {
			try {
				await verifyEmail({
					code: values.code,
				});

				setCurrentStep(USER_PROFILE_STEPS_ENUM.INFO);
			} catch (e) {
				const { response } = e as AxiosError<{
					messages: { code: string[] } | string[];
				}>;

				formikHelpers.setErrors({
					code: Array.isArray(response?.data?.messages)
						? response?.data?.messages?.join(' ') || 'Oops. Something went wrong.'
						: response?.data?.messages?.code?.join(' ') || 'Oops. Something went wrong.',
				});
			}
		},
		[setCurrentStep, verifyEmail],
	);

	return {
		initialValues,
		onSubmit,
	};
}

export default useVerifyEmail;
