import {
	ApplicationFormIdentifierQuestions,
	ApplicationFormQuestion,
} from '@app/redux/applicationForm/applicationForm.interfaces';

function assignIdentifierQuestionId({
	applicationFormQuestion,
	applicationFormIdentifierQuestions,
}: {
	applicationFormQuestion: ApplicationFormQuestion;
	applicationFormIdentifierQuestions: ApplicationFormIdentifierQuestions;
}) {
	switch (applicationFormQuestion.system_identifier) {
		case 'application.account.type': {
			applicationFormIdentifierQuestions.account_type_id = applicationFormQuestion.id;
			break;
		}
		case 'application.options.opt-in': {
			applicationFormIdentifierQuestions.options_id = applicationFormQuestion.id;
			break;
		}
		case 'application.customer.type': {
			applicationFormIdentifierQuestions.customer_type_id = applicationFormQuestion.id;
			break;
		}
	}
}

export default assignIdentifierQuestionId;
