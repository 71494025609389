import adcapFavicon from '@assets/svgs/adcap.png';
import adcapDark from '@assets/svgs/logoDesktop/dark/adcap.png';
import apexDark from '@assets/svgs/logoDesktop/dark/apex.svg';
import axosDark from '@assets/svgs/logoDesktop/dark/axos.svg';
import BosonicDark from '@assets/svgs/logoDesktop/dark/bosonic.svg';
import intertironlineDark from '@assets/svgs/logoDesktop/dark/intertironline.svg';
import lightspeedDark from '@assets/svgs/logoDesktop/dark/lightspeed.svg';
import SecondLaneDark from '@assets/svgs/logoDesktop/dark/secondlane-logo.svg';
import upstoxDark from '@assets/svgs/logoDesktop/dark/upstox.svg';
import viewtradeDark from '@assets/svgs/logoDesktop/dark/viewTrade.svg';
import watchdogDark from '@assets/svgs/logoDesktop/dark/watchdog.svg';
import YorkDark from '@assets/svgs/logoDesktop/dark/york.png';
import adcapLight from '@assets/svgs/logoDesktop/light/adcap.png';
import apexLight from '@assets/svgs/logoDesktop/light/apex.svg';
import axosLight from '@assets/svgs/logoDesktop/light/axos.svg';
import BosonicLight from '@assets/svgs/logoDesktop/light/bosonic.svg';
import intertironlineLight from '@assets/svgs/logoDesktop/light/intertironline.svg';
import lightspeedLight from '@assets/svgs/logoDesktop/light/lightspeed.svg';
import SecondLaneLight from '@assets/svgs/logoDesktop/light/secondlane-logo.svg';
import upstoxLight from '@assets/svgs/logoDesktop/light/upstox.svg';
import viewtradeLight from '@assets/svgs/logoDesktop/light/viewTrade.svg';
import watchdogLight from '@assets/svgs/logoDesktop/light/watchdog.svg';
import YorkLight from '@assets/svgs/logoDesktop/light/york.png';
import summitLogo from '@assets/svgs/logoDesktop/summit.png';
import AdcapDark from '@assets/svgs/logoMobile/dark/adcap.png';
import ApexDark from '@assets/svgs/logoMobile/dark/apex.svg';
import AxosDark from '@assets/svgs/logoMobile/dark/axos.svg';
import bosonicDark from '@assets/svgs/logoMobile/dark/bosonic.svg';
import secondLaneDark from '@assets/svgs/logoMobile/dark/secondlane-icon.svg';
import SummitDark from '@assets/svgs/logoMobile/dark/summit.svg';
import UpstoxDark from '@assets/svgs/logoMobile/dark/upstox.svg';
import yorkDark from '@assets/svgs/logoMobile/dark/york.png';
import IntertironlineLogo from '@assets/svgs/logoMobile/intertironline.svg';
import AdcapLight from '@assets/svgs/logoMobile/light/adcap.png';
import ApexLight from '@assets/svgs/logoMobile/light/apex.svg';
import AxosLight from '@assets/svgs/logoMobile/light/axos.svg';
import bosonicLight from '@assets/svgs/logoMobile/light/bosonic.svg';
import secondLaneLight from '@assets/svgs/logoMobile/light/secondlane-icon.svg';
import SummitLight from '@assets/svgs/logoMobile/light/summit.svg';
import UpstoxLight from '@assets/svgs/logoMobile/light/upstox.svg';
import yorkLight from '@assets/svgs/logoMobile/light/york.png';
import LightspeedLogo from '@assets/svgs/logoMobile/lightspeed.svg';
import ViewTradeLogo from '@assets/svgs/logoMobile/viewTrade.svg';
import VikingLogo from '@assets/svgs/logoMobile/viking.svg';
import WatchdogLogo from '@assets/svgs/logoMobile/watchdog.svg';

export const MOBILE_LOGO_MAP = new Map<string, JSX.Element>([
	['viewtrade_dark', ViewTradeLogo],
	['viewtrade_light', ViewTradeLogo],

	['york_dark', yorkDark],
	['york_light', yorkLight],

	['bosonic_dark', bosonicDark],
	['bosonic_light', bosonicLight],

	['adcap_dark', AdcapDark],
	['adcap_light', AdcapLight],

	['abm_dark', ViewTradeLogo],
	['abm_light', ViewTradeLogo],

	['viking_dark', VikingLogo],
	['viking_light', VikingLogo],

	['axos-crypto_dark', AxosLight],
	['axos-crypto_light', AxosDark],

	['invertir-online_dark', IntertironlineLogo],
	['invertir-online_light', IntertironlineLogo],

	['apex-brokerage-services_dark', ApexLight],
	['apex-brokerage-services_light', ApexDark],

	['watchdog_dark', WatchdogLogo],
	['watchdog_light', WatchdogLogo],

	['lightspeed_dark', LightspeedLogo],
	['lightspeed_light', LightspeedLogo],

	['summit-securities_dark', SummitLight],
	['summit-securities_light', SummitDark],

	['upstox_dark', UpstoxLight],
	['upstox_light', UpstoxDark],

	['secondlane_dark', secondLaneDark],
	['secondlane_light', secondLaneLight],
]);

export const DESKTOP_LOGO_MAP = new Map<string, JSX.Element>([
	['viewtrade_dark', viewtradeLight],
	['viewtrade_light', viewtradeDark],

	['york_dark', YorkDark],
	['york_light', YorkLight],

	['bosonic_dark', BosonicDark],
	['bosonic_light', BosonicLight],

	['adcap_dark', adcapDark],
	['adcap_light', adcapLight],

	['abm_dark', viewtradeLight],
	['abm_light', viewtradeDark],

	['viking_dark', VikingLogo],
	['viking_light', VikingLogo],

	['axos-crypto_dark', axosLight],
	['axos-crypto_light', axosDark],

	['invertir-online_dark', intertironlineLight],
	['invertir-online_light', intertironlineDark],

	['apex-brokerage-services_dark', apexDark],
	['apex-brokerage-services_light', apexLight],

	['watchdog_dark', watchdogLight],
	['watchdog_light', watchdogDark],

	['lightspeed_dark', lightspeedLight],
	['lightspeed_light', lightspeedDark],

	['summit-securities_dark', summitLogo],
	['summit-securities_light', summitLogo],

	['upstox_dark', upstoxLight],
	['upstox_light', upstoxDark],

	['secondlane_dark', SecondLaneDark],
	['secondlane_light', SecondLaneLight],
]);

export const FAVICON_MAP = new Map<string, string>([
	['viewtrade', ViewTradeLogo],
	['abm', ViewTradeLogo],
	['viking', VikingLogo],
	['axos-crypto', AxosDark],
	['invertir-online', IntertironlineLogo],
	['apex-brokerage-services', ApexDark],
	['watchdog', WatchdogLogo],
	['lightspeed', LightspeedLogo],
	['summit-securities', SummitDark],
	['upstox', UpstoxDark],
	['adcap', adcapFavicon],
	['bosonic', bosonicDark],
	['york', yorkLight],
	['secondlane', secondLaneDark],
]);
