import { AsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';

import { useAppDispatch } from '@app/redux/hooks';

function useTriggeredRequest<Returned, ThunkArg>(
	triggeredAsyncFn: AsyncThunk<Returned, ThunkArg, { rejectValue: AxiosError }>,
) {
	const dispatch = useAppDispatch();
	const [isProcessing, setIsProcessing] = useState(false);

	const startRequest = useCallback(
		async (args: ThunkArg): Promise<Returned> => {
			setIsProcessing(true);

			try {
				const result = await dispatch(triggeredAsyncFn(args)).unwrap();

				return result as Returned;
			} finally {
				setIsProcessing(false);
			}
		},
		[setIsProcessing, dispatch, triggeredAsyncFn],
	);

	return {
		startRequest,
		isPending: isProcessing,
	};
}

export default useTriggeredRequest;
