import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';

import { showToast } from '@app/providers/ToastProvider';

import { isRetrievalOnly } from '@utils/environment';

import configRequest from '../configRequest';

export interface ResetPasswordData {
	code: string;
	password: string;
	password_confirmation: string;
	phone?: string;
	email?: string;
	via?: 'phone' | 'email';
}

export interface ResetPasswordResponse {
	data: null;
	httpStatusMessage: string;
}

const resetPassword = configRequest<ResetPasswordResponse, ResetPasswordData>({
	requestPrefix: 'auth/reset-password',
	reqConfigCreator: data => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.AUTH.RESET_PASSWORD(),
		method: HTTP_METHODS_ENUM.POST,
		data: data as ResetPasswordData,
	}),
	onSuccess: () =>
		showToast.success(
			isRetrievalOnly() ? 'Password has been created successfully.' : 'Password has been reset successfully.',
		),
});

export default resetPassword;
