import { pdf } from '@react-pdf/renderer';
import React, { useState } from 'react';

import ApplicationDetailsInterface from '@api/requests/applications/interfaces/ApplicationDetailsInterface.interface';
import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';

import { showToast } from '@app/providers/ToastProvider';
import { getApplicationFormSections } from '@app/redux/applicationForm/applicationForm.selectors';
import {
	getAssetsDocumentTypeList,
	getAssetsQuestionTypeList,
	getAssetsRuleSets,
} from '@app/redux/assets/assets.selectors';
import { getCurrentUser } from '@app/redux/auth/auth.selectors';
import { useAppSelector } from '@app/redux/hooks';

import { Button, CircularProgress, Typography } from '@mui/material';

import PdfFormDataDocument from '@pages/Applications/containers/ApplicationAccountDetails/components/InfoSection/components/PdfFormDataDocument';

import downloadDocuments from '@utils/downloadDocuments';

import DownloadIcon from 'jsx:@assets/svgs/download.svg';

import { createListOfAllAnswers } from './utils';

function DownloadApplicationButton({ application }: { application: ApplicationDetailsInterface }) {
	const [pdfDocumentLoading, setPdfDocumentLoading] = useState(false);

	const currentUser = useAppSelector(getCurrentUser) as CurrentUserInterface;
	const applicationFormSections = useAppSelector(getApplicationFormSections);
	const assetsDocumentTypeList = useAppSelector(getAssetsDocumentTypeList);
	const assetsQuestionTypeList = useAppSelector(getAssetsQuestionTypeList);
	const assetsRuleSets = useAppSelector(getAssetsRuleSets);

	const pdfDocumentData = createListOfAllAnswers(
		application.answers,
		{
			question_types: assetsQuestionTypeList,
			document_types: assetsDocumentTypeList,
			rule_sets: assetsRuleSets,
		},
		applicationFormSections,
	);

	const pdfFileName = `${application.uid}-application.pdf`;

	const generatePdfDocumentAndDownload = () => {
		setPdfDocumentLoading(true);

		setTimeout(async () => {
			try {
				const pdfData = pdf(
					<PdfFormDataDocument user={currentUser} application={application} filledFormList={pdfDocumentData} />,
				);
				const pdfBlob = await pdfData.toBlob();

				downloadDocuments(pdfBlob, pdfFileName, 'application/pdf');
			} catch (e) {
				showToast.error('Something went wrong!');
			} finally {
				setPdfDocumentLoading(false);
			}
		}, 10000);
	};

	return (
		<Button
			variant="contained"
			color="secondary"
			onClick={generatePdfDocumentAndDownload}
			disabled={pdfDocumentLoading}
			sx={{ textTransform: 'initial', width: 200, height: 32 }}
		>
			{pdfDocumentLoading ? (
				<CircularProgress size={15} color={'info'} variant={'indeterminate'} />
			) : (
				<React.Fragment>
					<DownloadIcon />

					<Typography variant={'caption'} ml={1}>
						<b>Download application</b>
					</Typography>
				</React.Fragment>
			)}
		</Button>
	);
}

export default DownloadApplicationButton;
