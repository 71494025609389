import React from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import ApplicationDetailsInterface from '@api/requests/applications/interfaces/ApplicationDetailsInterface.interface';

import { ApplicationFormOption, ApplicationFormQuestion } from '@app/redux/applicationForm/applicationForm.interfaces';
import { applicationFormActions } from '@app/redux/applicationForm/applicationForm.reducer';
import {
	getApplicationFormProgress,
	getApplicationFormSelectedPage,
	getApplicationFormSelectedQuestions,
	getApplicationFormSelectedSection,
} from '@app/redux/applicationForm/applicationForm.selectors';
import { getApplicationDetails } from '@app/redux/applications/applications.selectors';
import { isVerificationAccount } from '@app/redux/auth/auth.selectors';
import { useAppDispatch, useAppSelector } from '@app/redux/hooks';
import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import SubmitApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/containers/SubmitApplicationForm';
import getApplicationFormQuestionField from '@pages/ApplicationForm/containers/ApplicationFormMain/getApplicationFormQuestionField';
import {
	ApplicationFormMainBox,
	UserVerificationMessage,
} from '@pages/ApplicationForm/containers/ApplicationFormMain/styles';

export interface ApplicationFormSelectedQuestionOption extends ApplicationFormOption {
	is_visible: boolean;
}

export interface ApplicationFormSelectedQuestion extends Omit<ApplicationFormQuestion, 'options'> {
	is_visible: boolean;
	options: ApplicationFormSelectedQuestionOption[];
}

function ApplicationFormMain() {
	const history = useHistory();
	const generateRoutePath = useGenerateRoutePath();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const urlQuery = new URLSearchParams(location.search);

	const { isVerificationEmailRequired, isEmailVerified, isVerificationPhoneRequired, isPhoneVerified } =
		useAppSelector(isVerificationAccount);
	const applicationFormSelectedSection = useAppSelector(getApplicationFormSelectedSection);
	const applicationFormSelectedPage = useAppSelector(getApplicationFormSelectedPage);
	const nextSectionInfo = useAppSelector(getApplicationFormProgress).navigation[applicationFormSelectedSection.id];
	const applicationFormSelectedQuestions = useAppSelector(getApplicationFormSelectedQuestions);
	const applicationDetails = useAppSelector(getApplicationDetails) as ApplicationDetailsInterface;

	const handleChangeNextSection = () => {
		dispatch(
			applicationFormActions.selectApplicationFormNextSection({
				page: nextSectionInfo?.next?.page,
				section: nextSectionInfo?.next?.section,
			}),
		);
		resetPageScroll();
	};

	const resetPageScroll = () => {
		document.documentElement.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const includesService = (service: string): boolean => {
		for (const item of applicationDetails.branch.services) {
			if (item.name === service) {
				return true;
			}
		}
		return false;
	};

	const getVerificationMessage = (): string => {
		if (isVerificationEmailRequired && !isEmailVerified && isVerificationPhoneRequired && !isPhoneVerified) {
			return 'email & phone number';
		} else if (isVerificationEmailRequired && !isEmailVerified) {
			return 'email';
		} else if (isVerificationPhoneRequired && !isPhoneVerified) {
			return 'phone';
		}
		return '';
	};

	const openUserProfile = () => {
		urlQuery.set('profile', 'true');
		history.push({ search: urlQuery.toString() });
	};

	return (
		<>
			<Grid container alignItems={'baseline'} justifyContent={'space-between'}>
				<Typography mb={{ xs: 2, sm: 0 }} sx={{ fontSize: 20 }}>
					<b>{applicationFormSelectedSection.label}</b>
				</Typography>

				<Stack flexDirection={'row'} sx={{ width: { xs: '100%', sm: 'auto' }, justifyContent: 'flex-end' }}>
					<Button
						disableElevation
						variant="contained"
						size="medium"
						color="secondary"
						onClick={() => history.push(generateRoutePath(RoutePaths.MAIN.APPLICATIONS))}
						sx={{ mr: 2.5, textTransform: 'capitalize' }}
					>
						<b>Exit</b>
					</Button>

					<SubmitApplicationForm
						runBackground={includesService('background_check')}
						applicationId={applicationDetails.id}
						formId={applicationDetails.form_id}
						formVersion={applicationDetails.form_version}
						needVerifyAccount={
							(isVerificationEmailRequired && !isEmailVerified) || (isVerificationPhoneRequired && !isPhoneVerified)
						}
					/>
				</Stack>
			</Grid>

			{(isVerificationEmailRequired && !isEmailVerified) || (isVerificationPhoneRequired && !isPhoneVerified) ? (
				<UserVerificationMessage mt={1} onClick={openUserProfile}>
					{`*Applicant must verify ${getVerificationMessage()} in their profile in order to submit the form.`}
				</UserVerificationMessage>
			) : null}

			{process.env.FIRM_ENVIRONMENT === 'york' && applicationFormSelectedPage.id === 607 && (
				<p>
					This form is used to request to transfer an account between brokerage firms. A complete copy of the most
					recent account statement (dated within 90 days) from the delivering account must accompany this form for the
					transfer to be processed.
				</p>
			)}

			<ApplicationFormMainBox>
				{applicationFormSelectedQuestions
					.filter(({ is_visible }) => is_visible)
					.sort((leftQuestion, rightQuestion) => leftQuestion.position - rightQuestion.position)
					.map(question => {
						const ApplicationFormQuestionField = getApplicationFormQuestionField({
							questionType: question.type,
							documentType: question.document_type,
						});

						if (!ApplicationFormQuestionField) return null;

						return (
							<Box key={`page-id-${question.page_id}-section-id-${question.section_id}-question-id-${question.id}`}>
								<ApplicationFormQuestionField applicationId={applicationDetails.id} question={question} />
							</Box>
						);
					})}
			</ApplicationFormMainBox>

			{!nextSectionInfo?.submit ? (
				<Grid container justifyContent={'flex-end'}>
					<Button
						disableElevation
						variant="contained"
						size="medium"
						color="primary"
						onClick={handleChangeNextSection}
						sx={{ my: 3, textTransform: 'capitalize' }}
					>
						<b>{nextSectionInfo?.label || '-'}</b>
					</Button>
				</Grid>
			) : null}
		</>
	);
}

export default ApplicationFormMain;
