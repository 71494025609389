import { useCallback } from 'react';
import { useHistory } from 'react-router';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';
import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';

import { showToast } from '@app/providers/ToastProvider';
import { getBranchEnvironment, getCurrentUser, isVerificationAccount } from '@app/redux/auth/auth.selectors';
import { useAppSelector } from '@app/redux/hooks';
import RoutePaths from '@app/routes/paths';
import useGenerateRoutePath from '@app/routes/paths/useGenerateRoutePath';

import useFetchApplicationForm from '@hooks/useFetchApplicationForm';

import { getBranchLabel } from '@utils/branchLabel';
import { isAllowApplicationCreation } from '@utils/environment';

function useCreateApplication() {
	const history = useHistory();
	const generateRoutePath = useGenerateRoutePath();

	const currentUser = useAppSelector(getCurrentUser) as CurrentUserInterface;
	const branchEnvironment = useAppSelector(getBranchEnvironment);
	const { needVerifyAccount } = useAppSelector(isVerificationAccount);

	const { startRequest: createApplication, isPending: isLoadingCreateApplication } = useTriggeredRequest(
		AccountClientRequests.Applications.createApplication,
	);

	const { fetchApplicationForm, isLoading: isLoadingApplicationForm } = useFetchApplicationForm();
	const forms: { id: number; label: string }[] = [];
	const addedIds: number[] = [];
	currentUser.branches.forEach(branch => {
		branch.forms.forEach(form => {
			if (!addedIds.includes(form.id)) {
				addedIds.push(form.id);
				forms.push({ id: form.id, label: getBranchLabel(form.translations[0].data) });
			}
		});
	});

	const handleCreateApplication = useCallback(
		async (form_id: number) => {
			if (!isAllowApplicationCreation()) {
				showToast.warning('You are not able to create Application.');

				return null;
			}

			try {
				const res = await createApplication({
					branch_id: branchEnvironment.branch_id,
					initiator_id: branchEnvironment.rep_id,
					form_id,
				});

				await fetchApplicationForm({
					applicationId: res.application.id,
					formId: res.application.form_id,
					formVersion: res.application.form_version,
				});

				history.push(generateRoutePath(RoutePaths.MAIN.APPLICATION_FORM));
			} catch (e) {
				showToast.error('Something went wrong!');
			}
		},
		[createApplication, currentUser.branches, fetchApplicationForm, generateRoutePath, history, needVerifyAccount],
	);

	return {
		disabled: !isAllowApplicationCreation() || isLoadingCreateApplication || isLoadingApplicationForm,
		loading: isLoadingCreateApplication || isLoadingApplicationForm,
		handleCreateApplication,
		forms,
	};
}

export default useCreateApplication;
