import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { alpha, Box, Paper, styled, svgIconClasses } from '@mui/material';
import clsx from 'clsx';
import React, { GetDerivedStateFromProps } from 'react';
import { isElement, isFragment } from 'react-is';
import Measure from 'react-measure';
import { getCurrentTheme } from '../../themes';
import { generateNamesObject } from '../../utils/stringUtils';

export const scrollSliderClasses = generateNamesObject(
    'sliderWrapper',
    'sliderContainer',
    'scrollSlider',
    'control',
    'icon',
    'disabled',
    'slider',
    'childAnimation',
);

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    flexGrow: 1,
    [`& .${scrollSliderClasses.sliderWrapper}`]: {
        flexGrow: 1,
    },
    [`& .${scrollSliderClasses.sliderContainer}`]: {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)',
        overflow: 'hidden',
    },
    [`& .${scrollSliderClasses.scrollSlider}`]: {
        display: 'flex',
        position: 'relative',
        '&:hover': {
            opacity: 1,
        },
    },
    [`& .${scrollSliderClasses.control}`]: {
        transition: '.3s all ease',
        cursor: 'pointer',
        opacity: 0.5,
        flexBasis: '48px',
        flexShrink: 0,
        position: 'absolute',
        borderRadius: '50%',
        height: 48,
        width: 48,
        margin: '0 16px',
        zIndex: 10,
        '&:hover': {
            opacity: 1,
        },
    },
    [`& .${svgIconClasses.root}`]: {
        position: 'relative',
        top: '17%',
        left: '15%',
        color: alpha(theme.palette.primary.main, 0.7),
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    [`& .${scrollSliderClasses.disabled}`]: {
        display: 'none',
        pointerEvents: 'none',
        color: alpha(theme.palette.primary.main, 0.15),
    },
    [`& .${scrollSliderClasses.slider}`]: {
        display: 'flex',
        position: 'absolute',
        top: '0',
        transition: '250ms all ease-in-out',
    },
    [`& .${scrollSliderClasses.childAnimation}`]: {
        position: 'relative',
    },
}));

type Props = {
    children?: React.ReactNode;
    width?: number | string;
    space: number;
    fullWidth?: boolean;
    childWidth: number;
    childHeight: number;
    childPadding?: number;
    noStretch?: boolean;
};

type State = {
    containerWidth: number;
    childWithPaddingWidth: number;
    sliderLeft: number;
    measureWidth: number;
    displayChildren: React.ReactNode[];
};

export class ScrollSlider extends React.Component<Props, State> {
    static defaultProps = {
        space: 16,
        fullWidth: false,
        childPadding: 0,
    };

    state: State = {
        containerWidth: 0,
        childWithPaddingWidth: this.props.childWidth + (this.props.childPadding || 0) * 2,
        sliderLeft: 0,
        measureWidth: 0,
        displayChildren: [],
    };

    static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (nextProps) => {
        const displayChildren: React.ReactNode[] = [];

        React.Children.forEach(nextProps.children, (child) => {
            if (child && !(isElement(child) && isFragment(child) && !child.props.children)) {
                displayChildren.push(child);
            }
        });

        return {
            displayChildren,
        };
    };

    scroll = (direction: 'left' | 'right') => {
        let position = 0;

        const { fullWidth, space } = this.props;

        const { childWithPaddingWidth, sliderLeft, measureWidth } = this.state;

        switch (direction) {
            case 'left': {
                position = fullWidth
                    ? Math.min(0, sliderLeft + childWithPaddingWidth + space)
                    : (position = sliderLeft + childWithPaddingWidth + space);
                break;
            }
            case 'right': {
                const sliderWidth = this.state.displayChildren.length * (childWithPaddingWidth + space) - space / 2;
                position = fullWidth
                    ? Math.max(sliderLeft - childWithPaddingWidth - space, -sliderWidth + measureWidth + space / 2)
                    : (position = sliderLeft - childWithPaddingWidth - space);
                break;
            }
        }

        this.setState({
            sliderLeft: position,
        });
    };

    updateSliderWidth = (newWidth: number) => {
        const space = this.props.space;
        const childWithPaddingWidth = this.state.childWithPaddingWidth;
        const numberOfPosibleChildrenVisible = Math.floor((newWidth + space) / (childWithPaddingWidth + space));
        const numberOfActualChildren = this.state.displayChildren.length;

        if (numberOfPosibleChildrenVisible >= numberOfActualChildren) {
            this.setState({
                containerWidth: numberOfActualChildren * childWithPaddingWidth + (numberOfActualChildren - 1) * space,
                measureWidth: newWidth,
            });
        } else {
            this.setState({
                containerWidth:
                    (numberOfPosibleChildrenVisible || 1) * childWithPaddingWidth +
                    ((numberOfPosibleChildrenVisible || 1) - 1) * space,
                measureWidth: newWidth,
            });
        }
    };

    render() {
        const { width, space, fullWidth, childHeight, childPadding, noStretch, childWidth } = this.props;

        const { sliderLeft, containerWidth, childWithPaddingWidth, measureWidth, displayChildren } = this.state;

        const theme = getCurrentTheme();
        const numberOfChildren = this.state.displayChildren.length;
        const sliderWidth = numberOfChildren * (childWithPaddingWidth + space) - space / 2;
        const lastIndex = numberOfChildren - 1;
        const controlStyle = {
            top: childHeight / 2 - 24,
        };

        return (
            <Root style={{ width: width || '100%' }}>
                <Paper
                    className={clsx(scrollSliderClasses.control, { [scrollSliderClasses.disabled]: sliderLeft >= 0 })}
                    style={{
                        ...controlStyle,
                        left: -30,
                    }}
                    onClick={() => this.scroll('left')}
                >
                    <KeyboardArrowLeft fontSize="large" />
                </Paper>
                <Measure
                    bounds
                    onResize={(contentRect) => {
                        this.updateSliderWidth(contentRect?.bounds?.width || 0);
                    }}
                >
                    {({ measureRef }) => (
                        <Box
                            ref={measureRef}
                            className={scrollSliderClasses.sliderWrapper}
                            style={{ height: childHeight }}
                        >
                            <Box
                                className={scrollSliderClasses.sliderContainer}
                                style={{ width: fullWidth ? '100%' : containerWidth, height: childHeight }}
                            >
                                <Box
                                    className={scrollSliderClasses.slider}
                                    style={{ left: sliderLeft, height: childHeight }}
                                >
                                    {React.Children.map(displayChildren, (child, index) => {
                                        return (
                                            <Box
                                                key={`scroll-slider-child-${index}`}
                                                className={scrollSliderClasses.childAnimation}
                                                style={{
                                                    minWidth: noStretch
                                                        ? childWidth
                                                        : Math.max(
                                                              childWithPaddingWidth,
                                                              (measureWidth - (numberOfChildren - 1) * space) /
                                                                  numberOfChildren,
                                                          ),
                                                    width: noStretch ? childWidth : 'auto',
                                                    maxWidth: childWidth,
                                                    height: childHeight,
                                                    marginRight:
                                                        lastIndex === index
                                                            ? theme.rtl
                                                                ? space
                                                                : 0
                                                            : index === 0 && theme.rtl
                                                            ? 0
                                                            : space,
                                                    paddingLeft: childPadding,
                                                    paddingRight: childPadding,
                                                }}
                                            >
                                                {child}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Measure>
                <Paper
                    className={clsx(scrollSliderClasses.control, {
                        [scrollSliderClasses.disabled]: sliderLeft <= -(sliderWidth - measureWidth - space / 2),
                    })}
                    style={{
                        ...controlStyle,
                        right: -30,
                    }}
                    onClick={() => this.scroll('right')}
                >
                    <KeyboardArrowRight fontSize="large" />
                </Paper>
            </Root>
        );
    }
}
