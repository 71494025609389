import { useCallback } from 'react';

import useTriggeredRequest from '@api/hooks/useTriggeredRequest';
import AccountClientRequests from '@api/requests';
import CurrentUserInterface from '@api/requests/auth/interfaces/CurrentUserInterface.interface';

import { applicationFormActions } from '@app/redux/applicationForm/applicationForm.reducer';
import buildApplicationForm from '@app/redux/applicationForm/utils/buildApplicationForm/buildApplicationForm.utils';
import {
	getAssetsDocumentTypeList,
	getAssetsQuestionTypeList,
	getAssetsRuleSets,
} from '@app/redux/assets/assets.selectors';
import { getCurrentUser } from '@app/redux/auth/auth.selectors';
import { useAppDispatch, useAppSelector } from '@app/redux/hooks';

function useFetchApplicationForm(handleFetchError?: () => void) {
	const dispatch = useAppDispatch();

	const currentUser = useAppSelector(getCurrentUser) as CurrentUserInterface;
	const assetsDocumentTypeList = useAppSelector(getAssetsDocumentTypeList);
	const assetsQuestionTypeList = useAppSelector(getAssetsQuestionTypeList);
	const assetsRuleSets = useAppSelector(getAssetsRuleSets);

	const { startRequest: fetchApplicationDetails, isPending: isLoadingApplicationDetails } = useTriggeredRequest(
		AccountClientRequests.Applications.getApplicationDetails,
	);

	const { startRequest: fetchFormDetails, isPending: isLoadingFormDetails } = useTriggeredRequest(
		AccountClientRequests.Forms.getFormDetails,
	);

	const fetchApplicationForm = useCallback(
		async ({
			applicationId,
			formId,
			formVersion,
		}: {
			applicationId: number;
			formId: number;
			formVersion?: number | null;
		}) => {
			try {
				const { application: applicationDetails } = await fetchApplicationDetails({
					application_id: Number(applicationId),
				});

				const { form: formDetails } = await fetchFormDetails({ id: formId, version: formVersion });

				await dispatch(
					applicationFormActions.setApplicationForm(
						buildApplicationForm({
							formDetails,
							formAssets: {
								question_types: assetsQuestionTypeList,
								document_types: assetsDocumentTypeList,
								rule_sets: assetsRuleSets,
							},
							applicationId: Number(applicationId),
							applicationAnswers: applicationDetails.answers,
							currentUserId: currentUser.id,
						}),
					),
				);
			} catch (e) {
				handleFetchError?.();
			}
		},
		[
			assetsDocumentTypeList,
			assetsQuestionTypeList,
			assetsRuleSets,
			currentUser.id,
			dispatch,
			fetchApplicationDetails,
			fetchFormDetails,
			handleFetchError,
		],
	);

	return {
		fetchApplicationForm,
		isLoading: isLoadingApplicationDetails || isLoadingFormDetails,
	};
}

export default useFetchApplicationForm;
