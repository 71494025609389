import { FormTextField } from '@orbis-systems/orbis-react-toolkit';
import { ErrorMessage } from 'formik';
import React from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ButtonBase, Grid, IconButton, InputAdornment, Typography } from '@mui/material';

import Form from '@components/forms';
import SubmitButton from '@components/submitButton';

import { Counter } from '@pages/Auth/ForgotPasswordPage/containers/ResetPassword/styles';

import useResetPasswordForm from './hooks';
import { ResetPasswordFormProps } from './interfaces';

function ResetPasswordForm({ initialValues, onSubmit }: ResetPasswordFormProps) {
	const {
		showPassword,
		showPasswordConfirmation,
		handleShowPassword,
		handleShowPasswordConfirmation,
		handleResendCode,
		minutes,
		seconds,
		formik,
	} = useResetPasswordForm({
		initialValues,
		onSubmit,
	});

	return (
		<Form formik={formik}>
			<Grid container rowSpacing={3} mt={0}>
				<Counter item xs={12} p={3}>
					<Typography variant="h4">
						<b>
							{minutes || seconds
								? `${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`
								: '00:00'}
						</b>
					</Typography>
				</Counter>

				<Grid item xs={12} textAlign={'right'} mb={1}>
					<ButtonBase onClick={handleResendCode}>
						<Typography variant="body2">
							<b>Resend code?</b>
						</Typography>
					</ButtonBase>
				</Grid>

				<Grid
					item
					xs={12}
					sx={{
						color: theme => theme.palette.error.main,
						textAlign: 'center',
					}}
				>
					<ErrorMessage name="resendCodeError" />
				</Grid>

				<Grid item xs={12}>
					<FormTextField
						name={'password'}
						label={'Password'}
						type={showPassword ? 'text' : 'password'}
						autoComplete="off"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton aria-label="Toggle password visibility" onClick={handleShowPassword}>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
						disabled={formik.isSubmitting}
					/>
				</Grid>

				<Grid item xs={12}>
					<FormTextField
						name={'password_confirmation'}
						label={'Password confirmation'}
						type={showPasswordConfirmation ? 'text' : 'password'}
						autoComplete="off"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton aria-label="Toggle password visibility" onClick={handleShowPasswordConfirmation}>
										{showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
						disabled={formik.isSubmitting}
					/>
				</Grid>

				<Grid item xs={12}>
					<FormTextField name={'code'} label={'Verification code'} autoComplete="off" disabled={formik.isSubmitting} />
				</Grid>

				<Grid item xs={12}>
					<SubmitButton label={'Reset'} disabled={formik.isSubmitting} />
				</Grid>

				<Grid
					item
					xs={12}
					sx={{
						color: theme => theme.palette.error.main,
						textAlign: 'center',
					}}
				>
					<ErrorMessage name="resetPasswordError" />
				</Grid>
			</Grid>
		</Form>
	);
}

export default ResetPasswordForm;
