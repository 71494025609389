import { useTheme } from '@mui/material';
import { merge } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import Joyride, { Props, FloaterProps, Styles } from 'react-joyride';

export const Walkthrough: React.FunctionComponent<Props> = (props) => {
    const theme = useTheme();
    const [isMounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    const floaterProp = useMemo<FloaterProps>(
        () =>
            merge(
                {
                    styles: {
                        wrapper: {
                            zIndex: theme.zIndex.tooltip,
                        },
                    },
                },
                props.floaterProps,
            ),
        [props.floaterProps],
    );

    const styles = useMemo<Styles>(
        () =>
            merge(
                {
                    options: {
                        zIndex: theme.zIndex.tooltip,
                    },
                    buttonClose: {
                        display: 'none',
                    },
                    buttonNext: {
                        display: 'none',
                    },
                },
                props.styles,
            ),
        [props.styles],
    );

    return (
        <Joyride {...props} disableScrolling run={props.run ?? isMounted} floaterProps={floaterProp} styles={styles} />
    );
};
