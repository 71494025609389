import { Box, Typography, styled } from '@mui/material';

export const ApplicationFormInfoBlock = styled(Box)(({ theme }) => ({
	maxHeight: 'calc(100vh - 64px)',
	overflowY: 'scroll',
	paddingTop: theme.spacing(10),
	boxSizing: 'border-box',
	fontSize: 14,
	li: {
		marginBottom: theme.spacing(1),
	},
}));

export const Paragraph = styled(Typography)(() => ({
	fontSize: 14,
	marginBottom: 10,
}));
