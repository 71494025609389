import ACCOUNT_CLIENT_API_ENDPOINTS from '@api/endpoints';
import HTTP_METHODS_ENUM from '@api/enums/HTTP_METHODS_ENUM.enum';
import configRequest from '@api/requests/configRequest';

export type LogoutData = void;

export type LogoutResponse = void;

const logout = configRequest<LogoutResponse, LogoutData>({
	requestPrefix: 'auth/logout',
	reqConfigCreator: () => ({
		url: ACCOUNT_CLIENT_API_ENDPOINTS.AUTH.LOGOUT(),
		method: HTTP_METHODS_ENUM.POST,
	}),
});

export default logout;
